import React from "react";

import Accordion from "../../../../shared/components/accordion";
import Link from "../../../../shared/link";
import Heading from "../../../../components/atoms/heading";
import HighlightSectionName from "../../../../shared/components/highLightSectionName";

import faqData from "./faq.constants";

import styles from "./faqs.module.scss";

const FAQs = () => {
  return (
    <div className="body-container">
      <div className={styles.container}>
        <HighlightSectionName text="UPSOLV FAQs" />
        <Heading text="Answers to your questions" className={styles.heading} />
        <Accordion items={faqData} limit={5} />
        <Link to="/faq" 
          onClick={()=>{
          window.dataLayer.push({'event': 'HomePage_FAQs_SeeMore'});
          window.webengage.track("HomePage_FAQs_SeeMore");
        }}>
          <button className={styles.seeMoreCta}>See more</button>
        </Link>
      </div>
    </div>
  );
};

export default FAQs;
