import React from 'react'
import ContentSection from './molecules/contentSection'
import HeroSections from './molecules/heroSection'
import StickyCTA from '../../shared/components/stickyCTA/StickyCTA'

const PrivacyPolicy = () => {
  return (
    <div>
      <HeroSections/>
      <ContentSection />
      <StickyCTA />
    </div>
  )
}

export default PrivacyPolicy