import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';

import makeSearchParams from './helpers/makeSearchParams';

const useNavigateToUrl = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate()

  const navigateToUrl = (pathname, searchParamsToBeForwarded) => {
    const searchPramsToBePassed = makeSearchParams(searchParams, searchParamsToBeForwarded)
    navigate({
      pathname,
      search: `?${createSearchParams(searchPramsToBePassed)}`,
    })
  };

  return navigateToUrl;
}

export default useNavigateToUrl;
