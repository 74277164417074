import { EMPTY_OBJECT } from "../constants/app"

import _noop from '../utils/noop';

const updateReferralInApplicationContext = (myReferrals, applicationContext = EMPTY_OBJECT) => {
  const {
    myReferral
  } = applicationContext;

  if (!myReferrals) return;

  const { setMyReferrals = _noop} = myReferral || EMPTY_OBJECT;

  setMyReferrals(myReferrals);
}

export default updateReferralInApplicationContext
