import React, { useContext, useEffect, useState } from "react";

import { Alert, Snackbar, Autocomplete, Chip, TextField } from "@mui/material";
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';

import { Formik } from "formik";


import Btn from "../../buttons";

import useCompanies from "../hooks/useCompanies";

import { GetQuoteContext } from "../../../modules/common-context";
import { ONLY_DIGIT_REGEX } from "../../../helpers/regex";
import ApiServices from "../../../services/api";
import { API_END_POINT } from "../constants/api-const";
import APP_DATA_CONFIG from "../../../helpers/app";


const EmploymentDetailsForm = (props) => {
  const { setCurrView, setDataPayload, dataPayload } = useContext(GetQuoteContext);
  const [userDetails, setUserDetails] = useState({});
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [isFormSubmit, setIsFormSubmit] = useState(false);

  const companyInputRef = React.useRef();
  const salaryInputRef = React.useRef();

  const {
    companies,
    isFetchingCompanies,
    onCompanyInputChange,
    onCompanySelect,
  } = useCompanies(dataPayload);
  
  // set formik field
  let setFieldValueFunction = null;

  const handleSelectFieldObject = (setFieldValue) => {
    setFieldValueFunction = setFieldValue
  }

  // handleSnackBar
  const handleSnackBar = () => {
    setSnackBarOpen(false);
  }

  // handleOnBlur
  const handleOnBlur = (value) => {
    // let patchPayload = {
    //   salary : value
    // }
    // patchUserData(patchPayload);

    window.webengage.user.setAttribute("we_salary", value);
  }

  const patchEmploymentDetails = (data) => {
    if(!data) return;
    const patchPayload = {
      company_id: data?.company_details?.id,
      company_name: data?.company_details?.label,
      company_pincode: data?.company_details.pincode,
      salary: data?.gross_salary,
      lead_id: dataPayload?.lead_id,
    };
    patchUserData(patchPayload);
  };

  // form submit
  const submitHandle = async (data) => {
    setIsFormSubmit(true);
    setUserDetails(dataPayload);
    patchEmploymentDetails(data);
    getQuotesHandle(dataPayload, data);
  };

  const patchUserData = async (payload) => {
    try {
      await ApiServices.patch(API_END_POINT.USER_SELF, payload);

    } catch (error) {
      const _error = error.response
      setSnackBarOpen(true);
      setSnackBarMessage(_error?.data?.message);    
      setIsFormSubmit(false);
    }
  }

  // Get Quotes details 
  const getQuotesHandle = async (storeUserDetails, data) => {

    try {
      const lead_id = storeUserDetails.lead_id;
      const payload = {
        name: storeUserDetails.name,
        salary: data.gross_salary,
        mobile: storeUserDetails.phone_number,
        lead_id
      }
      const res = await ApiServices.post(API_END_POINT.GET_OUR_PROTECTION_PLAN, payload);
      

      if (res.status === 200) {
        const quoteData = res?.data?.quotes;
        
        const _employee = {
          company_id : data.company_details.id,
          company_name : data.company_details.label,
          salary : data.gross_salary,
          company_pincode : data.company_details.pincode,
          employee_type : "Full Time",
          company_details : data.company_details,
          quoteData : quoteData,
          selected_quote: quoteData[0],
          selected_quote_planName: quoteData[0].name,
        }
        
        setUserDetails({...userDetails, ..._employee});

        setDataPayload({...dataPayload, ...userDetails, ..._employee});
        setCurrView("PLAN_DETAILS");
        setIsFormSubmit(false);

        window.dataLayer.push({'event':'get-quote_CompanySalary_Continue'});
        window.webengage.track("get-quote_CompanySalary_Continue", {
          company_id : data.company_details.id,
          company_name : data.company_details.label,
          salary : data.gross_salary,
          company_pincode : data.company_details.pincode,
          employee_type : "Full Time",
        });

        window.webengage.user.setAttribute("get-quote_CompanySalary_Continue", {
          company_id : data.company_details.id,
          company_name : data.company_details.label,
          salary : data.gross_salary,
          company_pincode : data.company_details.pincode,
          employee_type : "Full Time",
        });
        APP_DATA_CONFIG.GENERATE_LEAD.request('get-quote_CompanySalary_Continue', lead_id, {
          company_name : data.company_details.label,
          salary : data.gross_salary,
          name: userDetails.name,
          phone_number: userDetails.phone_number
        })

        window.webengage.user.setAttribute("we_salary", data.gross_salary);
        window.webengage.user.setAttribute("we_company", data.company_details.label);

      }
    } catch (error) {
      const _error = error.response
      setSnackBarOpen(true);
      setSnackBarMessage(_error?.data?.message);
      setIsFormSubmit(false);
    }
  }


  const companyChangeValue = (data) => {
    setFieldValueFunction("company_details", data);
    if(data !== null) {
      // let patchPayload = {
      //   company_id: data?.id,
      //   company_name: data?.label,
      //   company_pincode: data?.pincode,
      // };
      // patchUserData(patchPayload);
      window.webengage.user.setAttribute("we_company", data?.label);
    }
  }

  useEffect(() => {



    companyInputRef?.current?.focus();

    const storeUserDetails = dataPayload;
    setUserDetails(dataPayload);

    // check if userDetails is present in sessionStorage
    if(storeUserDetails.company_id != null && storeUserDetails.company_name != null) {
      const sessionUserDetails = storeUserDetails;
      setFieldValueFunction("company_details", {id: sessionUserDetails.company_id, label: sessionUserDetails.company_name, pincode: sessionUserDetails.company_pincode})
      setFieldValueFunction("gross_salary", sessionUserDetails.salary);
    } else if(storeUserDetails.company_id == null && storeUserDetails.company_name == null) {
      setFieldValueFunction("company_details", '')

      const _salary = storeUserDetails?.salary === null ? "" : storeUserDetails?.salary

      setFieldValueFunction("gross_salary", _salary);
    }
  }, [dataPayload, setFieldValueFunction])


  return (
    <div className="form-wrap">
      <div className="form-row">
        <div className="card-highlight-wrap card-highlight-right">
          <div className="mr-5 text-wrap">
            <p className="subtitle font-family-regular black-color">Employment Type</p>
          </div>
          <Chip 
            label="Full-Time"
            className="chip chip-black letter-space-2 font-family-regular text-uppercase"
            size="medium"
          ></Chip>
        </div>
      </div>
      <Snackbar open={snackBarOpen && !!snackBarMessage} autoHideDuration={6000} onClose={handleSnackBar}>
        <Alert onClose={handleSnackBar} severity="error">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Formik
        initialValues={{
          company_details: "",
          gross_salary: ""
        }}
        validate={(values) => {
          const errors = {};
          if(!values.company_details) {
            errors.company_details = "Required";
          } else if(!(typeof values.company_details === 'object')){
            errors.company_details = "Please select company from list";
          }
          if(!values.gross_salary) {
            errors.gross_salary = "Required";
          } else if(!ONLY_DIGIT_REGEX.test(values.gross_salary)) {
            errors.gross_salary = "Only digit is required"
          }
          return errors;
        }}
        onSubmit={(values) => {
          submitHandle(values);
        }}
      >
        {({handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched}) => (
          <React.Fragment>
            {
              handleSelectFieldObject(setFieldValue)
            }
            <div className="form-row">
              <Autocomplete
                loading={isFetchingCompanies}
                loadingText="Fetching companies ..."
                disablePortal
                id="company_details"
                options={companies}
                onChange={(_, val)=> {onCompanySelect(); companyChangeValue(val)}}
                value={values.company_details}
                clearIcon={false}
                filterOptions={(options) => options}
                freeSolo
                renderInput={(params) => 
                  <TextField {...params} 
                    inputRef={companyInputRef}
                    label="Company's Registered Name"
                    required
                    id="company_details"
                    value={values.company_details?.label}
                    variant="outlined"
                    onChange={(event) => {
                      handleChange(event);
                      onCompanyInputChange(event?.target?.value); 
                    }}
                    onBlur={(event)=> {
                      handleBlur(event);
                    }}
                    error={
                      Boolean(errors.company_details) && touched.company_details && Boolean(errors.company_details)
                    }
                    helperText={
                      errors.company_details && touched.company_details && errors.company_details
                    }
                />}
              />
            </div>
            <div className="form-row">
              <TextField 
                inputRef={salaryInputRef}
                id="gross_salary" 
                label="Current Monthly Gross Salary" 
                variant="outlined" 
                required={true}
                size="large"
                type="tel"
                value={values.gross_salary}
                InputProps={{
                  startAdornment: (
                    <CurrencyRupeeIcon/>
                  ),
                }}
                onChange={(event,_val) => {
                  handleChange(event);
                }}
                onBlur={(event)=> {
                  handleBlur(event);
                  handleOnBlur(event?.target?.value);
                }}
                error={
                  Boolean(errors.gross_salary) && touched.gross_salary && Boolean(errors.gross_salary)
                }
                helperText={
                  errors.gross_salary && touched.gross_salary && errors.gross_salary
                }
              />
              <div className="mt-1 helper-text text-wrap">
                <p className="small-caption">Gross Salary is the salary before any deductions are made from it (excluding bonus & incentives)</p> 
              </div>
            </div>
            <div className="form-row">
              <div className="btn-large">
                <Btn 
                  label="Continue"
                  className="btn-primary font-family-medium full-width"
                  size="large"
                  onClick={() => {handleSubmit()}}
                  type="submit"
                  disabled={isFormSubmit}
                  loading={isFormSubmit}
                ></Btn>
              </div>
            </div>
          </React.Fragment>
        )}
      </Formik>
    </div>
  )
}

export default EmploymentDetailsForm;