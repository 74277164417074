import React from "react";

import Heading from "../../../../components/atoms/heading";
import HighLightSectionName from "../../../../shared/components/highLightSectionName";

import LinkedinSvg from "../../../../assets/images/social/linkedin.svg";
import AnkitImage from "../../../../assets/images/Ankit.png";
import RajatImage from "../../../../assets/images/Rajat.png";

import styles from "./meetOurFounder.module.scss";
import ReactRouteDomLink from "../../../../shared/link/Link";

const MeetOurFounders = () => {
  return (
    <section className={styles.container}>
      <HighLightSectionName text="MEET OUR FOUNDERS" />
      <Heading text="Brain behind UPSOLV" className={styles.heading} />
      <div className={styles.founders}>
        <div className={styles.founder}>
          <img className={styles.photo} src={AnkitImage} alt="" />
          <p className={styles.name}>Ankit Khandelwal</p>
          <p className={styles.designation}>Co-Founder</p>
          <div className={styles.socialHandles}>
            <ReactRouteDomLink
              to="https://www.linkedin.com/in/ankitkhandelwal35/"
              target="_blank"
              onClick={()=>{
                window.dataLayer.push({'event':'AboutUs_MeetourFounders_AnkitKhandelwal'});
                window.webengage.track("AboutUs_MeetourFounders_AnkitKhandelwal");
              }}
            >
              <img
                className={styles.linkIcon}
                src={LinkedinSvg}
                alt="linkedin icon"
              />
            </ReactRouteDomLink>
          </div>
          <p className={styles.about}>
            Ankit has spent the last 7 years learning from the best in the
            start-up ecosystem, by working with the likes of OYO, Unacademy,
            InVideo, and more. As someone from a middle-class family of small
            business owners, he understands how income instability can be
            life-altering. He's also witnessed this reality first-hand in his
            professional life, during mass layoffs. <br />
            <br /> UPSOLV is a result of these two life experiences knowing how to build a product and the problems that come with a lack of income security
          </p>
        </div>
        <div className={styles.founder}>
          <img className={styles.photo} src={RajatImage} alt="" />
          <p className={styles.name}>Rajat Rustagi</p>
          <p className={styles.designation}>Co-Founder</p>
          <div className={styles.socialHandles}>
            <ReactRouteDomLink
              to="https://www.linkedin.com/in/rajatrustagi88/"
              target="_blank"
              onClick={()=>{
                window.dataLayer.push({'event':'AboutUs_MeetourFounders_RajatRustagi'});
                window.webengage.track("AboutUs_MeetourFounders_RajatRustagi");
              }}
            >
              <img
                className={styles.linkIcon}
                src={LinkedinSvg}
                alt="linkedin icon"
              />
            </ReactRouteDomLink>
          </div>
          <p className={styles.about}>
            With stints at companies like Yes Bank, OYO and Unacademy, Rajat
            gained invaluable experience in investment, banking and the startup
            ecosystem over the last 14 years. His middle-class roots taught him
            the value of a stable income to the average Indian household.
            <br />
            <br /> Today, he’s building his dream with UPSOLV, and helping countless others to build theirs.
          </p>
        </div>
      </div>
    </section>
  );
};

export default MeetOurFounders;
