import React from "react";

import Nav from "../../layout/Nav";
import Body from "../../layout/Body";
import Footer from "../../layout/Footer";

import TermsAndConditions from "../../modules/termsAndConditions";

const TermsAndConditionsPage = () => {
  return (
    <div>
      <Nav />
      <Body>
        <TermsAndConditions />
      </Body>
      <Footer />
    </div>
  );
};

export default TermsAndConditionsPage;
