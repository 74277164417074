import React from "react";

import SupportSvg from "../../../../assets/images/Support.svg";
import GeneralQueriesSvg from "../../../../assets/images/GeneralQueries.svg";

import styles from "./queriesAndConnect.module.scss";

const QueriesAndConnect = () => {
  return (
    <section className={styles.sectionContainer}>
      <div className={styles.container}>
        <div className={styles.left}>
          <img className={styles.icon} src={GeneralQueriesSvg} alt="queries icon" />
          <p className={styles.heading}>General Queries</p>
          <p className={styles.text}>
            For any general enquiry, please chat with us on whatsapp or call us on +917795178080.
          </p>
        </div>
        <div className={styles.right}>
          <img className={styles.icon} src={SupportSvg} alt="support icon" />
          <p className={styles.heading}>Support</p>
          <p className={styles.text}>
            For support, email us at support@upsolv.in
          </p>
        </div>
      </div>
    </section>
  );
};

export default QueriesAndConnect;
