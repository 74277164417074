const PHONE_NUMBER_REGEX = /^[6-9]{1}[0-9]{9}$/;
const ONLY_DIGIT_REGEX = /^[0-9]*$/;
const PAN_CARD_REGEX = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+/;
const PINCODE_REGEX = /^[1-9][0-9]{5}$/;
const ONLY_NAME_REGEX = /^[a-zA-Z_ ]*$/;

export {
  PHONE_NUMBER_REGEX,
  ONLY_DIGIT_REGEX,
  PAN_CARD_REGEX,
  EMAIL_REGEX,
  PINCODE_REGEX,
  ONLY_NAME_REGEX
}