const computeGiftCardAmount = (netAmount = 0) => {
  let orderAmountInPaisa = (+netAmount);
  console.log("orderAmountInPaisa >>", orderAmountInPaisa);

  let referral_amount = 0;

  if (orderAmountInPaisa <= 179999) {
    referral_amount = 0
  } else if (orderAmountInPaisa >= 199900 && orderAmountInPaisa < 399000) {
    referral_amount = 100
  } else if (orderAmountInPaisa >= 399800 && orderAmountInPaisa < 549899) {
    referral_amount = 300
  } else if (orderAmountInPaisa >= 549900 && orderAmountInPaisa < 699899) {
    referral_amount = 300
  } else if (orderAmountInPaisa >= 699900 && orderAmountInPaisa < 899899) {
    referral_amount = 500
  } else if (orderAmountInPaisa >= 899900 && orderAmountInPaisa < 1349899){
    referral_amount = 500
  } else if (orderAmountInPaisa >= 1349900){
    referral_amount = 500
  } else {
    referral_amount = 100
  }

  return referral_amount;
}

export default computeGiftCardAmount
