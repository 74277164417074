import React, { useContext } from "react";

import ReactRouteDomLink from "../../../../link/Link";
import Btn from "../../../../../components/buttons";
import { EMPTY_OBJECT } from "../../../../constants/app";
import useRetryPaymentForContinuePurchase from "./hooks/useRetryPaymentForContinuePurchase";

import { UserContext } from "../../../../../modules/common-context";

import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";

import styles from "./continuePurchase.module.scss";

const ContinuePurchase = ({ order }) => {
  const { user } = useContext(UserContext);
  const { name } = user || EMPTY_OBJECT;

  const [isRetryingPayment, onRetryPayment] =
    useRetryPaymentForContinuePurchase(order);

  return (
    <div className="bg-white-color">
      <div className="body-container">
        <div className={styles.wrapper}>
          <div className={styles.container}>
            <div className={styles.left}>
              <p className={styles.heading}>
                Hi <span className="uppercase">{name || "Upsolv User"}</span>
              </p>
              <p className={styles.subText}>
                Thank You for you interest in Upsolv 😃
              </p>
              <p className={styles.subText}>
              You are on the right track to secure your income against Job Loss 
              with Upsolv subscription Plans starting ₹999/year
              </p>
            </div>
            <div className={styles.right}>
              <ReactRouteDomLink to="/get-quote">
                <Btn
                  label="Edit"
                  className={styles.edit}
                  variant="outlined"
                  startIcon={<EditOutlinedIcon />}
                />
              </ReactRouteDomLink>
              <Btn
                label="Retry Payment"
                variant="contained"
                className={styles.retryPayment}
                onClick={onRetryPayment}
                startIcon={<ReplayOutlinedIcon />}
                disabled={isRetryingPayment}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContinuePurchase;
