
import React from "react";

import { Logo } from "../../../assets/images/index";

const QuoteHeader = (props) => {

  return (
    <div className="header-wrap">
      <div className="nav-wrap">
        <div className="navigate-btn-wrap">
          {props.btn}
        </div>
        <div className={props.logoStyleWrap + " logo-wrap "} onClick={props.logoClick}>
          <img alt="Logo" src={Logo}></img>
        </div>
      </div>
    </div>
  )
}

QuoteHeader.defaultProps = {
  logoStyleWrap: '',
}

export default QuoteHeader;
