import React, { useCallback, useEffect, useState } from "react";

import Heading from "../../../../components/atoms/heading";

import "./ourPlans.scss"
import { Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TableRow } from "@mui/material";
import { CapsuleTag, Loader } from "../../../../components";
import { CircleDash, CircleTick } from "../../../../assets/images";
import ApiServices from "../../../../services/api";
import { API_END_POINT } from "../../../../components/getQuotes/constants/api-const";
import { PLAN_COLOR } from "../../../../constants/planColor";
import { ICON_MAPPING } from "../../../../components/getQuotes/constants/getQuotes-plan-const";
import useNavigateToGetQuote from "../../../../shared/hooks/useNavigateToGetQuote";
import amountWithCommas from "../../../../shared/utils/amount-seperator";

const OurPlans = () => {

  const paise = 100;
  const [planQuotes, setPlanQuotes] = useState([]);
  const [planBenefits, setPlanBenefits] = useState([]);
  const [planWithBenefits, setPlanWithBenefits] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const iconMapping = ICON_MAPPING;

  const navigateToGetQuote = useNavigateToGetQuote();

  const getPlanQuotes = async () => {
    try {
      const payload = {
        name: "",
        mobile: "",
        salary: ""
      }
      return await ApiServices.post(API_END_POINT.GET_OUR_PROTECTION_PLAN, payload);

    } catch (error) {
      console.log("getPlan_Error :", error);
    }
  }

  const getPlanBenefits = async () => {
    try {
      return await ApiServices.get(API_END_POINT.GET_BENEFITS);
    } catch (error) {
      console.log("getPlanBenefits_Error :", error);
    }
  }

  const getPlanQuotesAndBenefitsData = () => {
    setIsLoading(true)
    const getPlanQuotesFunction = getPlanQuotes();
    const getPlanBenefitsFunction = getPlanBenefits();
    const listOfFunctions = [getPlanQuotesFunction, getPlanBenefitsFunction];
    Promise.all(listOfFunctions)
      .then((values) => {
        
        const planQuotesRes = values[0];
        const planBenefitsRes = values[1];

        if (planQuotesRes.status === 200) {
          setPlanQuotes(planQuotesRes.data.quotes);
        }

        if (planBenefitsRes.status === 200) {
          setPlanBenefits(planBenefitsRes.data.benefits);
        }
        setIsLoading(false)

      })
      .catch((error) => {
        setIsLoading(false)
      })
  }

  const formatPlanAndBenefitData = useCallback(() => {
    try {

      if (planQuotes.length > 0) {
        let planQuotesResult = [];
        planQuotes.forEach((item, index) => {
          const filteredBenefitArr = planBenefits.filter(obj => item.benefit_ids.includes(obj.id));
          item['benefits'] = filteredBenefitArr;
          planQuotesResult.push(item);
        });

        setPlanWithBenefits(planQuotesResult)
      }

    } catch (error) {
      
    }
  }, [planBenefits, planQuotes])

  const planBenefitsCircleTickDashView = (benefitID) => {
    let isBenefitIDPresentResult = [];
    planWithBenefits.forEach((item, index) => {
      const isBenefitIDPresent = item.benefit_ids.includes(benefitID);
      isBenefitIDPresentResult.push(isBenefitIDPresent);
    });

    const result = isBenefitIDPresentResult.map((data) => (
      <TableCell align="center">
        <img src={(data === true) ? CircleTick : CircleDash} className="status-check-img" alt="CircleTick"></img>
      </TableCell >
    ))

    return result;
  }

  useState(() => {
    getPlanQuotesAndBenefitsData();
  }, [])

  useEffect(() => {
    formatPlanAndBenefitData()
  }, [planQuotes, planBenefits, formatPlanAndBenefitData]);

  const handleGetQuoteCta = (e) => {
    window.dataLayer.push({'event': 'HomePage_OurPlan_GetStarted'});
    window.webengage.track("HomePage_OurPlan_GetStarted");
    e?.stopPropagation();
    navigateToGetQuote();
  }


  return (
    <div className="bg-mud-pink-color">
      {
        isLoading && <Loader open={isLoading}></Loader>
      }
      
      <div className="body-container ">
        <div className="pt-4">
          <Heading
            text="Our Plans"
            className={"text-center"}
          />
        </div>
        <div className="pb-4">
          <div className="plan-table-wrap">
            <TableContainer className="table-container td-no-border td-size-small-x thead-border tbody-row-highlight  tfooter-highlight first-last-space">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell className="table-sticky-column1">Plan Description</TableCell>
                    {/* <div><pre>{JSON.stringify(planWithBenefits, null, 2)}</pre></div> */}
                    {
                      planWithBenefits.map((item, index) => (
                        <TableCell align="center" key={item.id} className={`${item.ref_code === 'PLAN_1' && 'recommended-plan'}`}>
                          {/* {item.ref_code === 'PLAN_1' && (
                            <div className="upgarde-tag">Recommended</div>
                          )} */}
                          <CapsuleTag
                            title="UP"
                            subtitle={item.name}
                            className={`${PLAN_COLOR[item.name.toUpperCase()]} mobile-column-wrap ${index === 0 ? 'mx-auto' : ''}`}
                          />
                        </TableCell>
                      ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    planBenefits.map((item, index) => {
                      if(planBenefits.length - 1 === index) {
                        return (
                          <TableRow key={item.id}>
                          <TableCell scope="row" className={`table-sticky-column1 ${item.ref_code === 'PLAN_1' && 'recommended-plan'}`}>
                            <div className="plan-name-wrap">
                              <img src={iconMapping[item.image]} alt={item.name}></img> {item.name == 'Unemployment Allowance' && 'Upto ₹6 Lacs Salary Protection'}
                            </div>
                          </TableCell>

                          {planBenefitsCircleTickDashView(item.id)}
                        </TableRow>
                        )
                      }
                    })
                  }
                  {
                    planBenefits.map((item, index) => {
                      if(planBenefits.length - 2 === index) {
                        return (
                            <TableRow key={item.id}>
                              <TableCell scope="row" className={`table-sticky-column1 ${item.ref_code === 'PLAN_1' && 'recommended-plan'}`}>
                                <div className="plan-name-wrap">
                                  <img src={iconMapping[item.image]} alt={item.name}></img> {item.name}
                                </div>
                              </TableCell>

                              {planBenefitsCircleTickDashView(item.id)}
                            </TableRow>
                          )
                        }
                    })
                  }
                  {
                    planBenefits.slice(1,-2).map((item, index) => {
                      return (
                          <TableRow key={item.id}>
                          <TableCell scope="row" className={`table-sticky-column1 ${item.ref_code === 'PLAN_1' && 'recommended-plan'}`}>
                            <div className="plan-name-wrap">
                              <img src={iconMapping[item.image]} alt={item.name}></img> {item.name}
                            </div>
                          </TableCell>

                          {planBenefitsCircleTickDashView(item.id)}
                        </TableRow>
                      )
                    })
                  }
                  {
                    planBenefits.map((item, index) => {
                      if(index === 0) {
                        return (
                            <TableRow key={item.id}>
                              <TableCell scope="row" className={`table-sticky-column1 ${item.ref_code === 'PLAN_1' && 'recommended-plan'}`}>
                                <div className="plan-name-wrap">
                                  <img src={iconMapping[item.image]} alt={item.name}></img> {item.name}
                                </div>
                              </TableCell>

                              {planBenefitsCircleTickDashView(item.id)}
                            </TableRow>
                          )
                        }
                    })
                  }
                </TableBody>

                <TableFooter>
                  <TableRow>
                    <TableCell scope="row" component="th" className="table-sticky-column1">
                      Price
                    </TableCell>
                    {
                      planWithBenefits.map((item) => {
                        const roundedPrice = Math.ceil(((((item.price) / 12) / paise)).toFixed(2));
                        return <TableCell align="center">
                          <span className="opacity-5 td-small text-nowrap">As low as</span> <br></br>
                          <span>₹{amountWithCommas(roundedPrice)}</span> <br></br>
                          <span className="opacity-5 td-small">/ month</span>
                        </TableCell>
                        
                        }
                      )
                    }
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
            <div className="btn-wrap p-4 d-flex justify-content-center">
              <button className="btn btn-bold btn-primary btn-large" onClick={() => { handleGetQuoteCta(); }}>Choose Plan</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurPlans;