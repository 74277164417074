import React from "react";

import Covered1Logo from "../../../../assets/images/4.1_Covered.svg";
import Covered2Logo from "../../../../assets/images/4.2_Covered.svg";
import Covered3Logo from "../../../../assets/images/4.3_Covered.svg";
import Covered4Logo from "../../../../assets/images/4.4_Covered.svg";
import Covered5Logo from "../../../../assets/images/4.5_Covered.svg";

import CoveredItem from "./CoveredItem";
import Heading from "../../../../components/atoms/heading";

import styles from "./whatIsCovered.module.scss";
import { Link } from "react-router-dom";
import { RightArrow } from "../../../../assets/images";

const WhatIsCovered = () => {
  return (
    <div className="bg-light-cyan40-color">
      <div className="body-container">
        <div className={styles.container}>
          <Heading text="What’s covered?" className={styles.heading} />
          <div className={styles.coveredItems}>
            <CoveredItem
              logo={Covered1Logo}
              heading="Bankruptcy"
              subheading="When your employer files for bankruptcy and you suffer job loss."
            />
            <CoveredItem
              logo={Covered2Logo}
              heading="M&A"
              subheading="When your employer merges with or is acquired by another company resulting in your job loss."
            />
            <CoveredItem
              logo={Covered3Logo}
              heading="Termination"
              subheading="If your job loss is because your employer is going out of business, has surplus labour or the product/service they create is no longer needed."
            />
            <CoveredItem
              logo={Covered4Logo}
              heading="Restructuring"
              subheading="If your employer is restructuring their company and you experience job loss as a result of downsizing."
            />
            <CoveredItem
              logo={Covered5Logo}
              heading="Economic Cycles"
              subheading="If you experience job loss because recession or macro economic factors."
            />
          </div>
          <div className="mt-3 d-flex justify-content-center">
            <Link to="/faq" onClick={()=>{
              window.dataLayer.push({'event': 'HomePage_Whatscovered_KnowMore'});
              window.webengage.track("HomePage_Whatcovered_KnowMore");
              }}
              className="link-wrap"
            >
              <p>Learn More</p>
              <img src={RightArrow} alt="learn-more" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatIsCovered;
