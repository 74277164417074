import React, { useEffect, useRef } from "react";
import { register } from "swiper/element/bundle";

import Heading from "../../../../components/atoms/heading";
import HighLightSectionName from "../../../../shared/components/highLightSectionName";

import "./mentors.scss"
import { MENTORS_DATA } from "../../../../constants/mentors";
import MentorCard from "../../../../components/mentor-card";
import useNavigateToUrl from "../../../../shared/hooks/useNavigateToUrl";

register();

const Mentors = () => {

  const navigateToUrl = useNavigateToUrl();

  const swiperRef = useRef(null);

  useEffect(() => {
    const swiperContainer = swiperRef.current;
    const params = {
      navigation: true,
      breakpoints: {
        300: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1280: {
          slidesPerView: 3,
          spaceBetween: 13,
        },
      },
      injectStyles: [
        `
          .swiper-wrapper {
            padding-bottom: 40px;
          }
          .swiper-button-next{
            color: #fd930d;
            height: 40px;
            width: 40px;
            z-index: 1;
            background: #ffffff;
            border-radius: 50%;
          }
          .swiper-button-next:after {
            font-size: 16px;
            font-weight: bold;
          }
          .swiper-button-prev{
            color: #fd930d;
            height: 40px;
            width: 40px;
            z-index: 1;
            background: #ffffff;
            border-radius: 50%;
          }
          .swiper-button-prev:after {
            font-size: 16px;
            font-weight: bold;
          }
          .swiper-pagination-bullet{
            width: 10px;
            height: 10px;
            background-color: grey;
          }
          .swiper-pagination-bullets {
            margin-bottom: -10px;
          }
      `,
      ],
    };

    Object.assign(swiperContainer, params);
    swiperContainer.initialize();
  }, []);

  return (
    <div className="bg-dark-grey-color pb-4">
      <div className="body-container pb-2 pt-4">
        <div className="pt-4 text-center mb-4">
          <HighLightSectionName 
            text="MENTORS"
          />
          <Heading
            text="Prepare for your interviews with industry experts"
            className={"text-center white-color pt-3"}
          />
        </div>

        <div className="mentor-slider-wrap mb-lg-1 mb-0">
          <swiper-container ref={swiperRef} init="false">
            {
              MENTORS_DATA.sort((a, b) => a.order > b.order ? 1 : -1).map((item, index) => {
                return (
                  <swiper-slide>
                    <MentorCard
                      data={item}
                    >
                    </MentorCard>
                  </swiper-slide>
                )
              })
            }
          </swiper-container>
        </div>

        <div className="btn-wrap d-flex justify-content-center pb-lg-2 pb-0">
          <button className="btn btn-large btn-primary-outline font-bold" onClick={()=>{navigateToUrl('/mentors')}}>Explore all Mentors</button>
        </div>

      </div>
    </div>
  )
}

export default Mentors