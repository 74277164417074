import userReferralConfig from "../../../helpers/userReferral";

const fetchReferrals = async (applicationContext) => {
  try {
    const results = await Promise.allSettled([userReferralConfig.request()]);
    const [fetchReferralsSettledPromise] = results;
    const params = {
      applicationContext,
    };

    userReferralConfig.onSettled(params, fetchReferralsSettledPromise);
  } catch (_err) {
    // Handle errors, show snackbar, etc.
  }
};

export default fetchReferrals;
