import POLICY_STATUS from "../components/policyWidget/constants/policyStatus";
import getPolicyAndStatus from "../components/policyWidget/helpers/getPolicyAndStatus";
import { EMPTY_OBJECT } from "../constants/app";

export const handleUserContext = (user, userContext = EMPTY_OBJECT) => {
  const { setUserDetails } = userContext;
  setUserDetails(user);
};

export const handleGetQuoteContext = (user, getQuoteContext = EMPTY_OBJECT) => {
  const { setDataPayload, dataPayload } = getQuoteContext;
  const updateDataPayload = {
    ...user,
    ...dataPayload,
  };

  // console.log(updateDataPayload, "handleGetQuoteContext_dataPayload")

  setDataPayload(updateDataPayload);
};

export const handleApplicationContext = (
  user,
  applicationContext = EMPTY_OBJECT
) => {
  const { policyWidget = EMPTY_OBJECT } = applicationContext;

  const { handlePolicyWidgetUpdate } = policyWidget;
  const { status: policyStatus, order: policyOrder } = getPolicyAndStatus(
    user?.orders
  );
  const policyData = {
    showPolicyWidget: policyStatus === POLICY_STATUS.CONTINUE_PURCHASE,
    policyStatus,
    order: policyOrder,
    showPolicyWidgetConfigurable:
      policyStatus !== POLICY_STATUS.CONTINUE_PURCHASE,
  };
  handlePolicyWidgetUpdate(policyData);
};
