import { Link, useLocation } from "react-router-dom";

import styles from "./link.module.scss";

const ReactRouteDomLink = (props) => {
  const { to, disableParamsForwarding, ...rest } = props;
  const location = useLocation();
  const { search } = location;

  const updatedTo = disableParamsForwarding ? to : to + search;
  return <Link className={styles.wrapper} {...rest} to={updatedTo} />;
};
export default ReactRouteDomLink;
