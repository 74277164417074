import React from "react";
import { CallUs, Dashboard_Edit, Email } from "../../../assets/images";

const EditPlans = (props) => {
  return (
    <div className="dashboard-page-wrap">
      <div className="text-wrap mb-4">
        <p className="subtitle1 black-color font-family-medium">Edit My Plan</p>
      </div> 
      <div className="dash-plan-card">
        <div className="plan-header-wrap">
          <div className="text-wrap mt-2">
            <h5 className="black-color font-family-medium">We are here to Help</h5>
            <p className="subtitle1 black-color font-family-thin">With our dedicated support for you always.</p>
          </div>
        </div>
        <div className="plan-body-footer-wrap">
          <div className="plan-body-wrap plan-body-radius-wrap">
            <div className="row">
              <div className="col col-12 col-lg-6">
                <div className="dasboard-connect-wrap dasboard-connect-end-wrap">
                  <div className="text-wrap">
                    <img src={CallUs} alt="call" className="connect-large-img"></img>
                    <p className="subtitle font-family-thin">Call us for Claim</p>
                    <h5 className="black-color font-family-thin">
                      <a href="tel:+91 77951 78080">+91 77951 78080</a>
                    </h5>
                  </div>
                  <div className="text-wrap mt-3">
                    <img src={Email} alt="call" className="connect-small-img"></img>
                    <p className="caption font-family-thin">Email</p>
                    <p className="subtitle1 black-color font-family-thin">
                      <a href="mailto:support@upsolv.in">support@upsolv.in</a>
                    </p>
                  </div>
                </div>
              </div>
              <div className="col col-12 col-lg-6">
                <div className="plan-illust-wrap">
                  <img src={Dashboard_Edit} alt="plan-illust-wrap"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPlans;
