import React from 'react';

import styles from './table.module.scss';

const Table = ({ tableData }) => {
  const { table } = tableData;
  const { columns, rows, heading, subHeading } = table;

  return (
    <div>
      <div className={styles.content}>
        {
          heading ? <><p>▪️ &nbsp;&nbsp;{heading}</p></> : null
        }
        {
          subHeading ? <><p>▪️ &nbsp;&nbsp;{subHeading}</p></> : null
        }
      </div>
      <div className={styles.tableContainer}>
        <table className={styles.table}>
          <thead>
            <tr>
              {columns.map((column, index) => (
                <th key={index}>{column.header}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {columns.map((column, colIndex) => (
                  <td key={colIndex}>{row[column.key]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Table;
