import React from "react";
import cx from "classnames";

import { layoffCards } from "../../constants";

import InfiniteHzAutoScroll from "../../../../components/molecules/InfiniteHzAutoScroll";
import LayoffCard from "./LayoffCard";

import styles from "./layoffCards.module.scss";

const LayoffCards = () => {
  return (
    <div>
      <InfiniteHzAutoScroll className={styles.container}>
        {layoffCards.map((item, index) => {
          const { card1, card2 } = item;
          let isEvenCard = false;

          if (index % 2 === 0) {
            isEvenCard = true;
          }

          return (
            <div
              className={cx({
                [styles.evenCard]: isEvenCard,
              })}
            >
              <LayoffCard
                img={card1.img}
                date={card1.date}
                employees={card1.employees}
                location={card1.location}
                className={styles.topCard}
              />
              <LayoffCard
                img={card2.img}
                date={card2.date}
                employees={card2.employees}
                location={card2.location}
              />
            </div>
          );
        })}
      </InfiniteHzAutoScroll>
      <p className={styles.disclaimerText}>*Data has been collected from publically available news sources. Please use your own discretion.</p>
    </div>
  );
};

export default LayoffCards;
