
import React, { useContext, useEffect, useState } from "react";
import { Chip, Dialog } from "@mui/material";

import { GetQuoteContext } from "../../../../modules/common-context";

import { Btn } from "../../../../components/index"
import { Edit } from "../../../../assets/images/index";
import PersonalDetailsForm from "./personal-details-form";
import dayjs from "dayjs";

const PersonalDetails = () => {

  const {dataPayload} = useContext(GetQuoteContext);

  const [showEditModal, setToggleEditModal] = useState(false);
  const [userDetails, setuserDetails] = useState({});

  const onClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setToggleEditModal(false);
  }
  useEffect(() => {
    let emplyeeData = dataPayload;
    let _employeDetails = {
      name: emplyeeData?.name != null ? emplyeeData?.name : "-",
      gender: emplyeeData?.gender != null ? emplyeeData?.gender : "-",
      email: emplyeeData?.email != null ? emplyeeData?.email : "-",
      phone_number: emplyeeData.phone_number != null ? emplyeeData.phone_number : "-",
      date_of_birth: emplyeeData.date_of_birth != null ? dayjs(emplyeeData?.date_of_birth).format("YYYY-MM-DD") : "-"
    }
    setuserDetails(_employeDetails);
  }, [showEditModal])
  return (
    <React.Fragment>
      <div className="plan-info-row">
        <div className="plan-info-head">
          <Chip 
            label="PERSONAL DETAILS"
            className="chip chip-light-torques letter-space-2 font-family-regular text-uppercase"
            size="medium"
          ></Chip>
          <Btn
            label="Edit"
            startIcon={<img alt="Edit" src={Edit}/>}
            variant="text"
            className="btn btn-black font-family-medium"
            onClick={() => { setToggleEditModal(true) }}
          />
        </div>
        <div className="plan-info-content mt-2">
          <div className="plan-info-content-row">
            <div className="text-wrap">
              <p className="caption black-color opacity-5 font-family-medium">Name</p>
            </div>
            <div className="text-wrap">
              <p className="caption black-color font-family-medium text-capitalize">{userDetails.name}</p>
            </div>
          </div>
          <div className="plan-info-content-row">
            <div className="text-wrap">
              <p className="caption black-color opacity-5 font-family-medium">Date Of Birth</p>
            </div>
            <div className="text-wrap">
              <p className="caption black-color font-family-medium">{userDetails.date_of_birth}</p>
            </div>
          </div>
          <div className="plan-info-content-row">
            <div className="text-wrap">
              <p className="caption black-color opacity-5 font-family-medium">Gender</p>
            </div>
            <div className="text-wrap">
              <p className="caption black-color font-family-medium text-capitalize">{userDetails.gender}</p>
            </div>
          </div>
          <div className="plan-info-content-row">
            <div className="text-wrap">
              <p className="caption black-color opacity-5 font-family-medium">Email ID</p>
            </div>
            <div className="text-wrap">
              <p className="caption black-color font-family-medium">{userDetails.email}</p>
            </div>
          </div>
          <div className="plan-info-content-row">
            <div className="text-wrap">
              <p className="caption black-color opacity-5 font-family-medium">Mobile</p>
            </div>
            <div className="text-wrap">
              <p className="caption black-color font-family-medium">{userDetails.phone_number}</p>
            </div>
          </div>
        </div>
      </div>
      <Dialog onClose={onClose} open={showEditModal} className="dialog-wrap">
        <PersonalDetailsForm onClose={onClose}/>
      </Dialog>
    </React.Fragment>
  )
}

export default PersonalDetails;
