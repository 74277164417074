import React from "react";

import Nav from "../../layout/Nav";
import Body from "../../layout/Body";
import Footer from "../../layout/Footer";

import Home from "../../modules/Home";

const HomePage = () => {
  return (
    <div className="theme-bg">
      <Nav />
      <Body>
        <Home />
      </Body>
      <Footer />
    </div>
  );
};

export default HomePage;
