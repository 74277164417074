import { API_END_POINT } from "../components/getQuotes/constants/api-const";
import ApiServices from "../services/api";
import getUrlParams from "../shared/utils/get-url-param";
import getDataFromResponse from "../shared/utils/getDataFromResponse";


const refreshLead = (status = 'homePage', extraParams = {}) => {
    const payload = {
        status,
        // "utm_medium": getUrlParams('utm_medium'),
        // "utm_campaign": getUrlParams('utm_campaign'),
        // "utm_id": getUrlParams('utm_id'),
        // ...extraParams,
        // fieldsToUpdate: Object.keys(extraParams)
    }

    // const utm_referral = getUrlParams('utm_referral');

    // if (utm_referral) {
    //     payload.utm_referral = utm_referral;
    // }

    return ApiServices.post(API_END_POINT.REFRESH_LEAD, payload); 
}

const onSuccess = (params, refreshLeadResponse) => {
    const { getQuoteContext } = params;
    const refreshLead = getDataFromResponse(refreshLeadResponse);
    
    const leadId = refreshLead?.lead_id; 
    const { setDataPayload, dataPayload } = getQuoteContext;
    dataPayload.lead_id = leadId;
    
    setDataPayload({
      ...(dataPayload || {})
    });
  };
  
  const onFailure = (_params, _err) => {
    // DO NOTHING
    return undefined;
  };

const onSettled = (params, settledPromise) => {
    const {
      status,
      value,
      reason
    } = settledPromise;
    if (status === 'fulfilled') return onSuccess(params, value);
    if (status === 'rejected') return onFailure(params, reason);
    return undefined;
  }

const refreshLeadConfig = {
    request: refreshLead,
    onSettled,
}

export default refreshLeadConfig;