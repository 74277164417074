const getTruncatedText = (text = '', maxWords = 999) => {
  const words = text.split('');
  let truncatedText = words.slice(0, maxWords).join('');

  if (words.length > maxWords) {
    truncatedText = truncatedText + "...";
  }

  return truncatedText;
}

export default getTruncatedText;
