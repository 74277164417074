import React from 'react'

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ShareIcon from '@mui/icons-material/Share';

import useMediaQuery from '@mui/material/useMediaQuery';

const EndIcon = props => {

  const isPhoneOrTabLayout = useMediaQuery('(max-width:1279px)');

  if (isPhoneOrTabLayout) return <ShareIcon />

  return <ContentCopyIcon />
}

export default EndIcon
