import useNavigateToUrl from "../useNavigateToUrl";

const useNavigateToGetQuote = () => {
  const navigateToUrl = useNavigateToUrl();

  const navigateToGetQuote = () => {
    const url = "/get-quote";
    const searchParamsToBeForwarded = [
      "utm_medium",
      "utm_campaign",
      "utm_source",
      "utm_referral"
    ];
    navigateToUrl(url, searchParamsToBeForwarded);
  };

  return navigateToGetQuote;
};

export default useNavigateToGetQuote;
