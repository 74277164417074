import React from "react";
import PropTypes from "prop-types";
import cx from 'classnames';

import Heading from "./Heading";
import Text from "./Text";
import Image from "./Image";

import styles from "./card.module.scss";

const Card = ({ children, className }) => {
  return <div className={cx(styles.container, className)}>{children}</div>;
};

Card.propTypes = {
  children: PropTypes.element,
};

Card.Heading = Heading;
Card.Text = Text;
Card.Image = Image;

export default Card;
