import { CalendarTime, Chair, Insure, Interview, PlacementNetwork, PlacemnetExpert, PlanFeature1, PlanFeature2, PlanFeature3, PlanFeature4, PlanFeature5, PlanFeature6, Portal, Profile, Salary, Security } from "../../../assets/images"

const ICON_MAPPING = {
  'BENE_1_OLD': Salary,
  'BENE_3_OLD': Security,
  'BENE_4_OLD': Chair,
  'BENE_5_OLD': Profile,
  'BENE_6_OLD': Portal,
  'BENE_1': PlanFeature1,
  'BENE_2': PlanFeature2,
  'BENE_3': PlanFeature3,
  'BENE_4': PlanFeature4,
  'BENE_5': PlanFeature5,
  'BENE_6': PlanFeature6,
  'BENE_7': PlacemnetExpert,
  'BENE_8': Interview,
  'BENE_9': PlacementNetwork,
  'EXCL_1': Salary,
  'EXCL_2': Security,
  'EXCL_3': Insure,
  'EXCL_4': CalendarTime
}

const EXCLUSION = [
  {
    "id": "EXCL_4",
    "name": "Job loss in the first 90 days",
    "description": "A waiting period of at least 90 days should be completed from the time of plan purchase."
  },
  {
    "id": "EXCL_1",
    "name": "If you resign / retire on your own",
    "description": "If you decide to voluntarily resign or retire from their job, the plan will not be applicable to them."
  },
  {
    "id": "EXCL_3",
    "name": "War, civil war or labour disputes",
    "description": "Situations of war or civil unrest will not be applicable for plan benefits."
  },
  {
    "id": "EXCL_2",
    "name": "Job loss due to illegal activities",
    "description": "Job loss due to engaging in unlawful activities or bad behaviour, the plan will not be applicable."
  }
]

export {
  ICON_MAPPING,
  EXCLUSION
}