import React from "react";

const InvalidHelper = () => {
  return (
    <div className="helper-text text-wrap mt-1">
      <p className="caption font-family-regular">Code Invalid</p>
    </div>
  );
};

export default InvalidHelper;
