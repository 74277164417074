import React from "react";
import { HERO_SECTION } from "../../constants/heroSection";

import HighLightSectionName from "../../../../shared/components/highLightSectionName/HighLightSectionName";

import styles from "./heroSection.module.scss";

function BoldLine({ line }) {
  const parts = line.split("**");
  return (
    <p className={styles.item}>
      {parts.map((part, index) =>
        index % 2 === 0 ? (
          part
        ) : (
          <span className={styles.bold} key={index}>
            {part}
          </span>
        )
      )}
    </p>
  );
}

const HeroSection = () => {
  return (
    <div className={styles.container}>
      <HighLightSectionName text="KNOW OUR TERMS" />
      <div className={styles.heading}>{HERO_SECTION.heading}</div>
      <div>
        {HERO_SECTION.items.map((item, idx) => {
          return <BoldLine key={`heroSection-${idx}`} line={item.content} />;
        })}
      </div>
    </div>
  );
};

export default HeroSection;
