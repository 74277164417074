import React, { useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";

import Loader from "../../loader";
import { getCookie, setCookie } from "../../../shared/utils/cookie";
import ApiServices from "../../../services/api";
import { API_END_POINT } from "../constants/api-const";
import useNavigateToGetQuote from "../../../shared/hooks/useNavigateToGetQuote";
import useNavigateToUrl from "../../../shared/hooks/useNavigateToUrl";
import PAYMENT_STATUS from "../../../shared/components/policyWidget/constants/paymentStatus";

window.dataLayer = window.dataLayer || [];

const PaymentStatus = () => {
  const navigateToUrl = useNavigateToUrl();

  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const navigateToGetQuote = useNavigateToGetQuote();

  // handleSnackBar
  const handleSnackBar = () => {
    setSnackBarOpen(false);
  };

  const verifyPayment = async (_order_id) => {
    try {
      const res = await ApiServices.get(
        `${API_END_POINT.VERIFY_PAYMENT}?order_id=${_order_id}`
      );
      const data = res?.data;

      if (data?.order?.status === PAYMENT_STATUS.SUCCESS) {
        window.dataLayer.push({
          event: "PaymentStatus_VerifyPayment_Verification",
        });
        window.webengage.track("PaymentStatus_VerifyPayment_Verification", {
          protection_plan_id: data?.order?.protection_plan_id
        });
        navigateToUrl("/payment-success");
        setCookie("js_protection_plan_id", data?.order?.protection_plan_id);
        return;
      }

      if (data?.order?.status === PAYMENT_STATUS.FAILED) {
        navigateToUrl("/payment-failed");
        return;
      }

      navigateToUrl("/payment-failed");
      return;
    } catch (error) {
      const _error = error.response;
      setSnackBarOpen(true);
      setSnackBarMessage(_error?.data?.message);
    }
  };

  useEffect(() => {
    const _order_id = getCookie('js_order_id');

    if (!_order_id) {
      navigateToGetQuote();
    } else {
      verifyPayment(_order_id);
    }
  }, []);

  return (
    <React.Fragment>
      <Snackbar
        open={snackBarOpen && !!snackBarMessage}
        autoHideDuration={6000}
        onClose={handleSnackBar}
      >
        <Alert onClose={handleSnackBar} severity="error">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Loader open={true} label="Verifing your payment..." />
    </React.Fragment>
  );
};

export default PaymentStatus;
