import { Bulb, Claim, Edit_Pen, My_Plan, Quote_for_Friend, Refer_Your_Friend } from "../../assets/images"

const NAVIGATION_CONST = {
  VIEW_PLANS: "my-plan",
  CLAIMS: "claims",
  EDIT_PLAN: "edit-my-plan",
  REFER_FOR_FRIENDS: "refer-your-friends",
  QUOTES_FOR_FRIENDS: "quote-for-friends",
  LOGOUT: "logout"

}
const HEAD_NAVIGATION = [
  {image: My_Plan, label: "My Plan", value: NAVIGATION_CONST.VIEW_PLANS},
  { image: Claim, label: "Avail benefits", value: NAVIGATION_CONST.CLAIMS },
  {image: Edit_Pen, label: "Edit My Plan", value: NAVIGATION_CONST.EDIT_PLAN},
  {image: Refer_Your_Friend, label: "Refer Your Friends", value: NAVIGATION_CONST.REFER_FOR_FRIENDS},
  {image: Quote_for_Friend, label: "Quote for Friends", value: NAVIGATION_CONST.QUOTES_FOR_FRIENDS},
]
const FOOTER_NAVIGATION = [
  {image: Bulb, label: "About us", value: "about", link: "/about"},
  {image: Bulb, label: "FAQs", value: "faqs", link: "/faq"},
  { image: Bulb, label: "Contact Us", value: "contact-us", link: "/contact" },
]
const POPOVER_NAVIGATION = [
  {image: Bulb, label: "Logout", value: "logout"}
]

export {
  HEAD_NAVIGATION,
  FOOTER_NAVIGATION,
  POPOVER_NAVIGATION,
  NAVIGATION_CONST
}