import React from "react";

import HeroSection from "./molecules/heroSection";
import LittleStory from "./molecules/littleStory";
import MeetOurFounders from "./molecules/meetOurFounders";
import JoinTheTeam from "./molecules/joinOurTeam";
import QueriesAndConnect from "./molecules/queriesAndConnect";
import StickyCTA from "../../shared/components/stickyCTA/StickyCTA";

const AboutUs = () => {
  return (
    <div>
      <HeroSection />
      <LittleStory />
      <MeetOurFounders />
      <JoinTheTeam />
      <QueriesAndConnect />
      <StickyCTA />
    </div>
  );
};

export default AboutUs;
