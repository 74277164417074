import React, { useContext, useEffect, useState, useCallback } from "react";
import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import {
  Outlet,
  RouterProvider,
  createBrowserRouter,
  createSearchParams,
  useLocation,
  useNavigate,
} from "react-router-dom";

import HomePage from "./pages/home";
import AboutUsPage from "./pages/aboutUs";
import TermsAndConditions from "./pages/termsAndConditions";
import Disclaimers from "./pages/disclaimers/Disclaimers";
import PrivacyPolicy from "./pages/privacyPolicy";
import Faqs from "./pages/faqs";
import ContactUs from "./pages/contactUs";
import Blogs from "./pages/blogs/Blogs";
import GetQuote from "./pages/get-quote";
import PlanOnHold from "./components/getQuotes/plan-on-hold";
import PaymentStatus from "./components/getQuotes/payment-status";
import PlanSuccess from "./components/getQuotes/plan-success";
import LoginModal from "./shared/components/loginModal";

import ApplicationContextWrapper from "./shared/contexts/applicationContext/ApplicationContextWrapper";
import BlogContextWrapper from "./shared/contexts/blogsContext/BlogContextWrapper";
import {
  GetQuoteContext,
  GetQuoteContextWrapper,
  UserContext,
  UserContextWrapper,
} from "./modules/common-context";

import styles from "./app.module.scss";
import "./styles/styles.css";
import "./styles/root.scss";
import { Loader } from "./components";
import ApplicationContext from "./shared/contexts/applicationContext/ApplicationContext";
import PaymentFailed from "./components/getQuotes/paymentFailed";
import APP_DATA_CONFIG from "./helpers/app";
import Mentors from "./pages/mentors";
import Dashboard from "./dashboard";
import useNavigateToUrl from "./shared/hooks/useNavigateToUrl";
import ApiServices from "./services/api";
import { API_END_POINT } from "./components/getQuotes/constants/api-const";
import { getCookie } from "./shared/utils/cookie";


const PageRenderer = () => {
  const userContext = useContext(UserContext);
  const getQuoteContext = useContext(GetQuoteContext);
  const applicationContext = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(true);
  const navigateToUrl = useNavigateToUrl();
  const navigate = useNavigate();
  

  const handleInitialAppLoad = useCallback(() => {
    let leadsCalled = false;

    const params = {
      userContext,
      applicationContext,
      getQuoteContext,
    }

    setIsLoading(true);

    const handleInitialAppLoadSuccess = (params) => (results) => {
      const [
        fetchInitialAppDataPromise,
        generateLeadPromise,
        myReferralsPromise,
        userDashboardPromise
      ] = results;

      APP_DATA_CONFIG.FETCH_INITIAL_APP_DATA.onSettled(params, fetchInitialAppDataPromise);
      APP_DATA_CONFIG.GENERATE_LEAD.onSettled(params, generateLeadPromise);
      APP_DATA_CONFIG.USER_REFERRAL.onSettled(params, myReferralsPromise);
      APP_DATA_CONFIG.USER_DASHBOARD.onSettled(params, userDashboardPromise);

      // set webengage we_email once we fetch the user details.
      setWebEngageAttribute(fetchInitialAppDataPromise);
      setUserDashboardData(userDashboardPromise)
    };

    const handleInitialAppLoadError = (_params) => (_err) => {
      // DO NOTHING
      return undefined;
    };

    const handleInitialAppLoadFinally = (params) => () => {
      // setIsLoading(false);
    };


    const setUserDashboardData = async (params) => {

      const userDashboardData =  APP_DATA_CONFIG.USER_DASHBOARD.getDataOnSettled(params);

      const userPlanStatus = {
        user_plan_status : userDashboardData?.user_plan_status
      }

      const _dataPayload = {
        ...getQuoteContext?.dataPayload,
        ...userPlanStatus
      }

      getQuoteContext?.setDataPayload(_dataPayload);
    }
    
    const setWebEngageAttribute = (params) => {

      // console.log(params, "APP_DATA_CONFIG.FETCH_INITIAL_APP_DATA")

      const userData =  APP_DATA_CONFIG.FETCH_INITIAL_APP_DATA.getDataOnSettled(params);

      // console.log(userData, "userData");
      
      if(userData !== "undefined" && userData.email !== null){
        window.webengage.user.setAttribute("we_email", userData.email);
      }

      if(userData !== "undefined" && userData.name !== null){
        window.webengage.user.setAttribute("we_first_name", userData.name);
      }

      if(userData !== "undefined" && userData.phone_number !== null){
        window.webengage.user.setAttribute("we_phone", userData.phone_number);
      }
    }
    
    if(!leadsCalled){
      Promise.allSettled([
        APP_DATA_CONFIG.FETCH_INITIAL_APP_DATA.request(),
        APP_DATA_CONFIG.GENERATE_LEAD.request(),
        APP_DATA_CONFIG.USER_REFERRAL.request(),
        APP_DATA_CONFIG.USER_DASHBOARD.request(),
      ])
      .then(handleInitialAppLoadSuccess(params))
      .catch(handleInitialAppLoadError(params))
      .finally(handleInitialAppLoadFinally(params))
    }
    
    leadsCalled = true;
  }, []); // eslint-disable-line react-hooks/exhaustive-deps 
  //  to avoid excessive re-renders hence not including it into dependencies array applicationContext, userContext, getQuoteContext  

  useEffect(() => {
    handleInitialAppLoad();
  }, [handleInitialAppLoad]);

  useEffect(() => {
    if(getQuoteContext?.dataPayload?.lead_id && userContext?.user?.name){
      const extraParam = {
        name: userContext?.user?.name,
        phone_number: userContext?.user?.phone_number,
        email: userContext?.user?.email,
        salary: userContext?.user?.salary,
        company_name: userContext?.user?.company_name
      }
      APP_DATA_CONFIG.GENERATE_LEAD.request('homePage',getQuoteContext.dataPayload.lead_id, extraParam)
    }
    
  }, [userContext?.user?.name,userContext?.user?.company_name,userContext?.user?.email,userContext?.user?.phone_number, userContext?.user?.salary, getQuoteContext?.dataPayload?.lead_id]);

  useEffect(() => {
    
    const _islogin = getCookie("js_login");

    if(_islogin === 'true' && getQuoteContext?.dataPayload?.user_plan_status?.has_purchased) {
      if(window.location.pathname == "/") {
        navigate({ 
          pathname: '/dashboard'
        });
      }
      setIsLoading(false)
    } else {
      if(window.location.pathname == "/dashboard") {
        // navigateToUrl("/");
        navigate({ 
          pathname: '/', 
          search: createSearchParams({ login: _islogin === 'true' ? "" : "modal" }).toString() 
        });
      }
      setIsLoading(false)
    } 
    
  },[getQuoteContext?.dataPayload])  

  if (isLoading) {
    return <Loader open={isLoading} />;
  }

  return (
    <>
      <Outlet />
      <LoginModal />
    </>
  );
};

const Root = () => {
  let location = useLocation();
  useEffect(() => {
    // gtmId: "GTM-PRF492C", old gtmId
    const TagManagerArgs = {
      gtmId: "GTM-MM78JZZF",
    };
    TagManager.initialize(TagManagerArgs);
    // ReactGA.initialize("G-S9W4VLZSLY");
    ReactGA.initialize("G-2D81ELHF6R");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, [location]);

  return (
    <ApplicationContextWrapper>
      <UserContextWrapper>
        <BlogContextWrapper>
          <GetQuoteContextWrapper>
            <PageRenderer />
          </GetQuoteContextWrapper>
        </BlogContextWrapper>
      </UserContextWrapper>
    </ApplicationContextWrapper>
  );
};

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: "/",
        element: <HomePage />,
      },
      {
        path: "/about",
        element: <AboutUsPage />,
      },
      {
        path: "/terms",
        element: <TermsAndConditions />,
      },
      {
        path: "/disclaimers",
        element: <Disclaimers />,
      },
      {
        path: "/faq",
        element: <Faqs />,
      },
      {
        path: "/contact",
        element: <ContactUs />,
      },
      {
        path: "/privacy",
        element: <PrivacyPolicy />,
      },
      {
        path: "/blogs",
        element: <Blogs />,
      },
      {
        path: "/get-quote",
        element: <GetQuote />,
      },
      {
        path: "/get-quote-declaration",
        element: <PlanOnHold />,
      },
      {
        path: "/payment-status",
        element: <PaymentStatus />,
      },
      {
        path: "/payment-failed",
        element: <PaymentFailed />,
      },
      {
        path: "/payment-success",
        element: <PlanSuccess />,
      },
      {
        path: "/mentors",
        element: <Mentors />,
      },
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
    ],
  },
]);

function App() {
  return (
    <div className={styles.container}>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
