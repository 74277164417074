import React from "react";

import HighLightSectionName from "../../../../shared/components/highLightSectionName";
import LayoffCards from "./LayoffCards";
import useNavigateToGetQuote from "../../../../shared/hooks/useNavigateToGetQuote";

import styles from "./layoffAllAround.module.scss";
import Heading from "../../../../components/atoms/heading";

const LayoffAllAround = () => {
  const navigateToGetQuote = useNavigateToGetQuote();

  const handleGetQuoteCta = (e) => {
    window.dataLayer.push({'event': 'HomePage_Layoff_GetQuote'});
    window.webengage.track("HomePage_Layoff_GetQuote");
    e?.stopPropagation();
    navigateToGetQuote();
  }

  return (
    <div className={styles.container}>
      {/* <HighLightSectionName 
        text="LAYOFFS AROUND THE WORLD"
      /> */}
      <Heading
        text="Layoffs can happen to anyone, at any time"
        className={"text-center white-color pt-3 px-2"}
      />
      {/* <p className={styles.subHeading}>Secure your monthly income now</p> */}
      {/* <button className={"btn btn-primary btn-medium mt-3"} onClick={()=>{handleGetQuoteCta();}}>Check Price!</button> */}
      <LayoffCards />
    </div >
  );
};

export default LayoffAllAround;
