import fetchInitialAppDataConfig from './fetchInitialAppData';
import generateLeadConfig from './generateLead';
import refreshLeadConfig from './refreshLead';
import userReferralConfig from './userReferral';
import userDashboardConfig from './userDashboard';

const APP_DATA_CONFIG = {
  FETCH_INITIAL_APP_DATA: { ...fetchInitialAppDataConfig },
  GENERATE_LEAD: { ...generateLeadConfig },
  REFRESH_LEAD: { ...refreshLeadConfig },
  USER_REFERRAL: { ...userReferralConfig },
  USER_DASHBOARD: { ...userDashboardConfig }
}

export default APP_DATA_CONFIG;
