
import React, { useContext, useEffect, useRef, useState } from "react";
import { Alert, IconButton, Snackbar, TextField } from "@mui/material";


import Card from "../../Card";
import { GetQuoteContext } from "../../../../modules/common-context";

import { Btn } from "../../../../components/index"
import { Cancel, TickWhite } from "../../../../assets/images/index";
import { Formik } from "formik";
import { PAN_CARD_REGEX } from "../../../../helpers/regex";
import ApiServices from "../../../../services/api";
import { API_END_POINT } from "../../constants/api-const";
import APP_DATA_CONFIG from "../../../../helpers/app";


const KycDetailsForm = (props) => {

  const { setDataPayload, dataPayload } = useContext(GetQuoteContext);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const panInputRef = useRef(null);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  // set formik field
  let setFieldValueFunction = null;

  const handleSelectFieldObject = (setFieldValue) => {
    setFieldValueFunction = setFieldValue
  }

  // handleSnackBar
  const handleSnackBar = () => {
    setSnackBarOpen(false);
  }

  const submitHandle = async (value) => {
    setIsFormSubmit(true);
    try {
      const payload = {
        pan: value?.pan_number?.toUpperCase(),
        lead_id: dataPayload?.lead_id
      }

      const res = await ApiServices.post(API_END_POINT.VALIDATE_PAN, payload);
      if(res.status === 200){
        props.onSuccess(true, 'verify');
        props.getPanResponse({
          panNo: value?.pan_number?.toUpperCase(), name: res?.data?.pan_details?.name, gender: res?.data?.pan_details?.gender
        });
        setIsFormSubmit(false);
      }
    } catch (error) {

      panWithoutVerify(value);
    }
  }

  // pand without verify
  const panWithoutVerify = async(value) => {
    setIsFormSubmit(true);
    const lead_id = dataPayload?.lead_id
    try {
      const payload = {
        pan: value?.pan_number?.toUpperCase(),
        lead_id
      }
      const res = await ApiServices.patch(API_END_POINT.USER_SELF, payload);
      if(res?.data?.status === 'Success'){
        props.onClose();
        setIsFormSubmit(false);
        props.onSuccess(true, 'no-verify');
        setDataPayload({...dataPayload, ...payload});
        window.dataLayer.push({'event':'get-quote_ProposalPage_KycDetailsSave'});
        window.webengage.track("get-quote_ProposalPage_KycDetailsSave", {
          pan: value?.pan_number?.toUpperCase(),
          lead_id
        });
        
        window.webengage.user.setAttribute("get-quote_ProposalPage_KycDetailsSave", {
          pan: value?.pan_number?.toUpperCase(),
          lead_id
        });
        APP_DATA_CONFIG.GENERATE_LEAD.request("get-quote_ProposalPage_KycDetailsSave", lead_id)
      }
    } catch (error) {
      const _error = error.response
      setSnackBarOpen(true);
      setSnackBarMessage(_error?.data?.message);
      setIsFormSubmit(false);
    }
  }

  useEffect(() => {
    let userData = dataPayload;
    if (userData?.pan != null) {
      setFieldValueFunction("pan_number", userData?.pan);
    }
  }, []);
  

  return (
    <React.Fragment>
      <Snackbar open={snackBarOpen && !!snackBarMessage} onClose={handleSnackBar}>
        <Alert onClose={handleSnackBar} severity="error">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Card classes={"card card-shadow"}>
        <div className="card-header-wrap d-flex">
          <div className="text-wrap">
            <h3 className="font-family-bold black-color">KYC Details</h3>
          </div>
          <div className="btn-wrap">
            <IconButton
              size="small"
              onClick={props.onClose}
            >
              <img alt="Cancel" src={Cancel} />
            </IconButton>
          </div>
        </div>
        <Formik
          initialValues={{
            pan_number: "",
          }}
          validate={(values) => {
            const errors = {};
            if(!values.pan_number) {
              errors.pan_number = "Required";
            } else if(!PAN_CARD_REGEX.test(values.pan_number)) {
              errors.pan_number = "Enter valid PAN number";
            }
            return errors;
          }}
          onSubmit={(values) => {
            submitHandle(values);
          }}
        >
        {({handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched}) => (
          <React.Fragment>
            {
              handleSelectFieldObject(setFieldValue)
            }
            <div className="card-content-wrap">
              <div className="form-wrap">
                <div className="form-row">
                  <TextField
                    className="form-field-uppercase"
                    inputRef={panInputRef}
                    id="pan_number"
                    label="PAN Card"
                    variant="outlined"
                    required={true}
                        value={values.pan_number}
                    size="large"
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    onBlur={(event)=> {
                      handleBlur(event);
                    }}
                    error={
                      Boolean(errors.pan_number) && touched.pan_number && Boolean(errors.pan_number)
                    }
                    helperText={
                      errors.pan_number && touched.pan_number && errors.pan_number
                    }
                  />
                </div>
              </div>
            </div>
            <div className="card-footer-wrap card-btn-end-wrap">
              <div className="btn-wrap">
                <Btn
                  label="Cancel"
                  variant="text"
                  className="btn btn-black font-family-medium"
                  size="large"
                  onClick={props.onClose}
                />
                <Btn
                  label="Verify"
                  variant="contained"
                  className="btn btn-primary font-family-medium"
                  size="large"
                  startIcon={
                    <img alt="TickWhite" src={TickWhite} />
                  }
                  onClick={() => {handleSubmit()}}
                  disabled={isFormSubmit}
                  loading={isFormSubmit}
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </Formik>
        
      </Card>
    </React.Fragment>
  )
}

export default KycDetailsForm;
