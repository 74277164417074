
import { createContext, useState } from "react";
import KeyMirror from "keymirror";

export const UserContext = createContext({
  user: null,
  setUserDetails: () => {
    // Update user details if loggedin
  }
});

export const GetQuoteViews = KeyMirror({
  LOGIN: null,
  OTP: null,
  EMPLOYMENT_DETAILS: null,
  PLAN_DETAILS: null,
  PLAN_CONFIRMATION: null,
  PLAN_SUCCESS: null,
  // PLAN_ON_HOLD: null
})

export const GetQuoteContext = createContext({
  currView: GetQuoteViews.LOGIN,
  setCurrView: (view) => {
    // Update the curr view
    this.currView = view;
  },
  dataPayload: {},
  setDataPayload: (data) => {
    // Update dataPayload
    this.dataPayload = data
  }
});

export const GetQuoteContextWrapper = (props) => {

  const [currView, setCurrView] = useState(GetQuoteViews.LOGIN);
  const [payload, setPayload] = useState({});

  const updatePayload = (updatedPayload, forceUpdatePayload = false) => {

    if(!updatedPayload){
      setPayload({});
      return;
    }

    if(forceUpdatePayload) {
      setPayload(updatedPayload);
      return;
    }

    setPayload(prevPayload => ({
      ...prevPayload,
      ...updatedPayload
    }));
  }
  
  return (
    <GetQuoteContext.Provider value={{currView, setCurrView, dataPayload: payload, setDataPayload: updatePayload}}>
      {props.children}
    </GetQuoteContext.Provider>
  )
};

export const UserContextWrapper = (props) => {
  const [user, setUser] = useState(null);

  return (
    <UserContext.Provider value={{ user, setUserDetails: setUser}}>
      {props.children}
    </UserContext.Provider>
  )
}