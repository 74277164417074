import { useState } from "react";

const useMyReferralInitialState = () => {
  const [showMyReferralModal, setShowMyReferralModal] = useState(false);
  const [myReferrals, setMyReferrals] = useState([]);

  return {
    showMyReferralModal,
    setShowMyReferralModal,
    myReferrals,
    setMyReferrals,
  };
};

export default useMyReferralInitialState;
