import React, { useCallback, useContext, useEffect, useState, useRef } from "react";
import { Back, Logo } from "../../assets/images";
import { Avatar, IconButton, Popover } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { FOOTER_NAVIGATION, HEAD_NAVIGATION, NAVIGATION_CONST, POPOVER_NAVIGATION } from "./sidenav.constant";

import "./index.scss"
import { Link } from "react-router-dom";
import isUndefined from "../../shared/utils/isUndefined";
import { GetQuoteContext } from "../../modules/common-context";
import useNavigateToUrl from "../../shared/hooks/useNavigateToUrl";
import useLogout from "../../shared/hooks/useLogout";
// import PolicyWidget from "../../shared/components/policyWidget/PolicyWidget";
import ApplicationContext from "../../shared/contexts/applicationContext/ApplicationContext";
import { EMPTY_OBJECT } from "../../shared/constants/app";
import MyReferral from "../../shared/components/myReferral";
import useNavigateToGetQuote from "../../shared/hooks/useNavigateToGetQuote";
import { useNavigate } from "react-router-dom";

const currentAppState = {
  LOGIN: "Login",
  LOGOUT: "Logout",
};

const SideNav = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { dataPayload } = useContext(GetQuoteContext);
  const applicationContext = useContext(ApplicationContext);

  const [menuToggle, setMenuToggle] = useState(null);
  const [userName, setUserName] = useState("");
  
  // const { login = EMPTY_OBJECT, myReferral = EMPTY_OBJECT, policyWidget = EMPTY_OBJECT } = applicationContext;
  const { myReferral = EMPTY_OBJECT } = applicationContext;
  
  // const { setShowLoginModal, showLoginModal } = login;
  
  const { setShowMyReferralModal, myReferrals } = myReferral;
  const myReferralModalOpenStateRef = useRef(false);


  const [loginTextState, setLoginTextState] = useState(() => {
    if (dataPayload?.id) return currentAppState.LOGOUT;
    return currentAppState.LOGIN;
  });

  const navigateToUrl = useNavigateToUrl();
  const navigateToGetQuote = useNavigateToGetQuote();
  const logout = useLogout();
  const navigate = useNavigate();

  const handlePopoverClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChangeView = (data) => {
    
    if(data.value == NAVIGATION_CONST.REFER_FOR_FRIENDS) {
      handleMyReferralClick()
    } else if(data.value == NAVIGATION_CONST.QUOTES_FOR_FRIENDS) {
      handleGetQuoteCtaRedirect()
    } else {
      props.onChangeView(data.value);
      handleMenuOpen();
    }
  }

  useEffect(()=> {
    setUserName(dataPayload?.name)
  },[])

  const handleMenuOpen = (intent) => {
    setMenuToggle((p) => {
      if (!isUndefined(intent)) return intent;
      return !p;
    });
  };

  const handlePopAction = (val) => {
    if(val == NAVIGATION_CONST.LOGOUT) {
      handleLoginAndLogout()
    } 
  }

  const handleLoginAndLogout = () => {
    if (currentAppState.LOGIN === loginTextState) {
      // setShowLoginModal(true);
      return;
    }
    if (currentAppState.LOGOUT === loginTextState) {
      logout();
      return;
    }
    // setShowLoginModal(true);
    return;
  };

  const checkIsLogin = useCallback(() => {
    if (dataPayload?.id) {
      setLoginTextState(currentAppState.LOGOUT);
    } else {
      setLoginTextState(currentAppState.LOGIN);
    }
  }, [dataPayload?.id]);

  const handleRouteToNavigate = useCallback(() => {   
    if(dataPayload?.user_plan_status?.has_purchased) {
      // navigateToUrl("/dashboard");
      navigate({ pathname: '/dashboard' });
    } else {
      // navigateToUrl("/");
      navigate({ pathname: '/' });
    }
  }, [dataPayload?.user_plan_status]);

  useEffect(() => {
    checkIsLogin();
  }, [dataPayload, checkIsLogin]);


  // handles opening of referral modal after successful login, when users tries to open
  // MyReferrals when not logged in.
  if (myReferralModalOpenStateRef.current && currentAppState.LOGOUT === loginTextState) {
    myReferralModalOpenStateRef.current = false;
    setShowMyReferralModal(true);
  }

  const handleMyReferralClick = () => {
    setShowMyReferralModal(p => !p)
  }

  // const referralsCount = myReferrals?.length || 0;

  // handleGetQuoteCtaRedirect
  const handleGetQuoteCtaRedirect = (e) => {
    e?.stopPropagation();
    navigateToGetQuote();
    window.dataLayer.push({ event: "DashboardPage_Header_GetQuote" });
    window.webengage.track("Header_GetQuote");
  };


 

  return (
    <React.Fragment>
      {
        menuToggle && <div className="sidenav-bacdrop" onClick={() => handleMenuOpen()}></div>
      }
      <div className={`sidenav-wrap ${menuToggle && 'active'}`}>
        <div className="sidenav-header-wrap">
          <div className="sidenav-navigation-wrap">
            <div className="side-logo-wrap" onClick={()=>{handleRouteToNavigate()}}>
              <img src={Logo}></img>
            </div>
            <div className="sidenav-hamburger-wrap">
              <IconButton onClick={() => handleMenuOpen()}>
                {menuToggle ? (
                  <CloseIcon/>
                ) : (
                  <MenuIcon/>
                )}
              </IconButton>
            </div>
          </div>
          <div className={`sidenav-header-list-wrap ${menuToggle && 'active'}`}>
            <ul className="side-nav-list">
              {
                HEAD_NAVIGATION.map((item, index) => {
                  return (
                    <li key={index} onClick={()=>{handleChangeView(item)}} className={`${props.activeView == item.value && 'active'}`}>
                      <img src={item.image}></img> {item.label}
                    </li>
                  )
                })
              }
            </ul>
          </div>
        </div>
        <div className={`sidenav-footer-list-wrap ${menuToggle && 'active'}`}>
          <div className="sidenav-divider-wrap">
            <ul className="side-nav-list side-nav-inlinelist">
              {
                FOOTER_NAVIGATION.map((item, index) => {
                  return (
                    <li key={index}>
                      <Link to={item.link}>
                        {item.label}
                      </Link>
                    </li>
                  )
                })
              }
            </ul>
          </div>
          <div className="sidenav-drop-action-wrap">
            <div className="sidenav-popover-wrap">
              <div className="sidenav-popover-action" aria-describedby={id} variant="contained" onClick={(event)=> {handlePopoverClick(event)}}>
                <Avatar className="avtar-wrap text-uppercase">
                  {
                    userName.split(' ').map(word => word.charAt(0)).join(' ')
                  }
                </Avatar>
                <div className="popover-action">
                  <div className="action-name-wrap text-capitalize">{userName}</div>
                  <img src={Back}></img>
                </div>
              </div>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                className="sidenav-popover-list"
              >
                <ul className="side-nav-list">
                  {
                    POPOVER_NAVIGATION.map((item, index) => {
                      return (
                        <li onClick={()=>{handlePopAction(item.value)}}>{item.label}</li>
                      )
                    })
                  }
                </ul>
              </Popover>
            </div>
          </div>
        </div>
      </div>
      <MyReferral />
      {/* <PolicyWidget /> */}
    </React.Fragment>
  );
};

export default SideNav;

