import React, { useState } from "react";

import SideNav from "./sidenav";
import DashboardPages from "./pages";

import "./styles/index.scss"

const Dashboard = (props) => {

  const [viewPage, setViewPage] = useState("my-plan");

  return (
    <div className="dashboard-wrap">
      <SideNav
        onChangeView={(val) => {setViewPage(val)}}
        activeView={viewPage}
      />
      <DashboardPages
        activeView={viewPage}
      />
    </div>
  );
};

export default Dashboard;
