
import React, { useContext, useState, useEffect } from "react";
import { Alert, Chip, Dialog, Snackbar } from "@mui/material";

import { GetQuoteContext } from "../../../../modules/common-context";

import { Btn } from "../../../../components/index"
import { Add, Edit } from "../../../../assets/images/index";
import CommunicationDetailsForm from "./communication-details-form";
import ApiServices from "../../../../services/api";
import { API_END_POINT } from "../../constants/api-const";

const CommunicationDetails = () => {

  const {dataPayload, setDataPayload} = useContext(GetQuoteContext);

  const [showEditModal, setToggleEditModal] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");


  const onClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setToggleEditModal(false);
  }

  // handleSnackBar
  const handleSnackBar = () => {
    setSnackBarOpen(false);
  }

  // on pincode location
  const onPincodeChange = async (val) => {
    if(val != null && val.length > 5 ) {
      try {
        const res = await ApiServices.get(`${API_END_POINT.SEARCH_PINCODE}?pincode=${val}`);

        if(res.data.pincodes.length > 0) {
          const storeUserDetails = dataPayload;
          setUserDetails({...storeUserDetails, state_code: res?.data?.pincodes[0]?.stateCode});
          setDataPayload({...dataPayload, ...storeUserDetails, state_code: res?.data?.pincodes[0]?.stateCode})
        } else {
          setSnackBarOpen(true);
          setSnackBarMessage("No Pincode found");
        }

        
      } catch (error) {
        const _error = error.response;
        setSnackBarOpen(true);
        setSnackBarMessage(_error?.data?.message);
      }
    }
  }

  useEffect(() => {
    const storeUserDetails = dataPayload;
    setUserDetails(storeUserDetails);

    if(storeUserDetails?.state_code === undefined) {
      onPincodeChange(storeUserDetails?.pincode);
    }


  }, [showEditModal]);


  return (
    <React.Fragment>
      <Snackbar open={snackBarOpen && !!snackBarMessage} autoHideDuration={6000} onClose={handleSnackBar}>
        <Alert onClose={handleSnackBar} severity="error">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <div className="plan-info-row">
        <div className="plan-info-head">
          <Chip 
            label="COMMUNICATION DETAILS"
            className="chip chip-light-torques letter-space-2 font-family-regular text-uppercase"
            size="medium"
          ></Chip>
          {
            userDetails?.address == null ? (
              <Btn
            label="Add"
            startIcon={<img src={Add} alt="Add" />}
            variant="text"
            className="btn btn-black font-family-medium"
            onClick={()=> {setToggleEditModal(true)}}
          />
            ) : (
              <Btn
            label="Edit"
            startIcon={<img src={Edit} alt="Edit" />}
            variant="text"
            className="btn btn-black font-family-medium"
                onClick={() => { setToggleEditModal(true) }}
              />
            )
          }


        </div>
        {
          userDetails?.address != null && (
            <div className="plan-info-content mt-2">
              <div className="plan-info-content-row">
                <div className="text-wrap w-100">
                  <p className="caption black-color opacity-5 font-family-medium">
                    {`${userDetails?.address}, ${userDetails?.state} ${userDetails?.pincode}`}
                  </p>
                </div>
              </div>
            </div>
          )
        }
      </div>
      <Dialog onClose={onClose} open={showEditModal} className="dialog-wrap">
        <CommunicationDetailsForm onClose={onClose}/>
      </Dialog>
    </React.Fragment>
  )
}

export default CommunicationDetails;
