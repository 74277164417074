export const HERO_SECTION = {
  heading: "Upsolv Privacy Statement (formerly Jobsurance)",
  items: [
    {
      content:
        "Vicinal Jobsurance Private Limited (Company, we, our, us) takes the privacy of your information very seriously and is of critical importance and priority for the Company. This Privacy Policy (Privacy Policy) describes how the Company uses the information provided or disclosed to it (which may include personal information), and also brings to your notice, by this privacy policy published on its website, the rights which are afforded to you as per the terms of this Privacy Policy.",
    },
    {
      content:
        "This Privacy Policy complies with applicable laws of India and must be read in conjunction with the Terms of Service (https://upsolv.in/terms/)",
    },
    {
      content:
        "Our Services are not applicable to non-Indian nationals and are not available outside India. Foreign nationals must not disclose their information including any personal information.",
    },
    {
      content:
        "All capitalized terms (including the term Service) that have not been specifically defined in this Privacy Policy shall have the same meaning as provided under the (https://upsolv.in/terms/).",
    },
    {
      content:
        "By accessing the website www.upsolv.in (hereinafter Website) or using our Services or filling any form on our website for waitlist or any other purpose, you agree to be governed by this Privacy Policy. If you do not agree to this Privacy Policy please immediately close this website and stop using our Services.",
    },
  ],
};
