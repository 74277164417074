import { EMPTY_OBJECT } from "../constants/app"

import _noop from '../utils/noop';

export const handleUserContext = (user, userContext = EMPTY_OBJECT) => {
  const { setUserDetails } = userContext;
  setUserDetails(user);
};

export const handleGetQuoteContext = (user, getQuoteContext = EMPTY_OBJECT) => {
  const { setDataPayload, dataPayload } = getQuoteContext;

  
  dataPayload.user_plan_status = user.user_plan_status;
  
  const updateDataPayload = {
    ...dataPayload,
  };

  // console.log(updateDataPayload, "handleGetQuoteContext_dataPayload")

  setDataPayload(updateDataPayload);
};

export const updateUserDashboardInApplicationContext = (dashboard, applicationContext = EMPTY_OBJECT) => {
  const {
    userDashboard
  } = applicationContext;

  if (!dashboard) return;

  const { setUseDashboard = _noop} = userDashboard || EMPTY_OBJECT;

  setUseDashboard(dashboard);
}
