import { useState } from "react";

const useDashboardInitialState = () => {
  const [dashboard, setUseDashboard] = useState([]);

  return {
    dashboard,
    setUseDashboard,
  };
};

export default useDashboardInitialState;
