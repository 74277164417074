import React from "react";

import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";

import './index.scss';

const Btn = (props) => {
  return (
    <Button
      variant={props.variant}
      className={`btn ${props.className}`}
      startIcon={props.startIcon}
      endIcon={props.endIcon}
      size={props.size}
      disabled={props.disabled}
      onClick={props.onClick}
      type={props.type}
    >
      {props.label}
      {
        props.loading && (
          <CircularProgress size={16}/>
        )
      }
    </Button>
  );
};

Btn.defaultProps = {
  label: '',
  size: '',
  startIcon: null,
  className: 'btn-primary',
  variant: 'contained',
  disabled: false,
  type: '',
  loading: false
}

export default Btn;
