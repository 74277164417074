import React from "react";

import styles from "./testimonialCard.module.scss";

import linkedinImage from "../../../../../assets/images/testimonials/linkedIn.png";
import appreciateImage from "../../../../../assets/images/testimonials/appreciate.png";
import thumbImage from "../../../../../assets/images/testimonials/thumb.png";

import Link from "../../../../../shared/link";

const TestiMonialCard = ({
  name,
  photo,
  text,
  recommendedBy,
  likesCount,
  url,
  claimed,
  footer
}) => {
  return (
    <div className={styles.container}>
      <div>
        <div className={styles.header}>
          {!claimed && <div className={styles.photo}>
            <img src={photo} alt={name} />
          </div>}
          <div className={styles.personalInfo}>
            <p>{name}</p>
            {!claimed && <div className={styles.socialShareIconWrapper}>
              <Link to={url}>
                <img src={linkedinImage} alt="linkedin" />
              </Link>
            </div>}
          </div>
        </div>
        <div className={styles.body}>{text}</div>
      </div>
      <div className={styles.footer}>
        {!claimed && <div className={styles.likesIconContainer}>
          <img src={thumbImage} alt="likeImage" />
          <img src={appreciateImage} alt="appreciateImage" />
        </div>}
        { claimed ? <p>{footer}</p>:<p>{`${recommendedBy} and ${likesCount} others`}</p>}
      </div>
    </div>
  );
};

export default TestiMonialCard;
