export const DISCLAIMER_ITEMS = [
  {
    content: 'Upsolv is a technology intermediary engaged in the business of marketing, listing, communicating and sale of value added lifestyle support products and services through its website www.Upsolv.in'
  },
  {
    content: 'The legal entity operating Jobsurance is Vicinal Upsolv Private Limited, Workflo by OYO, 3rd Floor, Plot No. 224, Ranka Junction, Near Tin Factory, Bengaluru - 560036 (hereinafter Upsolv or Company).'
  },
  {
    content: ' Upsolv works with several services providers and partners (Providers) and has curated a bouquet of services that are provided to paying subscriber(s).'
  },
  {
    content: 'Any claims relating to the services availed from these Providers are the sole responsibility of the Providers and they are legally and contractually obligated to Upsolv to resolve any customer care related issues.'
  },
  {
    content: 'As a User, you may come across services on content on our website which you may find to be harmful, misleading, inaccurate, improper or inappropriate. We impress upon you to make reasonable efforts and seek professional guidance to investigate whether any content or product or services are useful or beneficial to you. We do not take any responsibility for your purchase of any products or services.'
  },
  {
    content: 'Upsolv shall in no event be liable for any act of commission or omission or negligence or deficiency in services of the Providers. Notwithstanding anything contained herein, in no event shall Upsolv be liable for any loss, claim, or direct damages or any special, exemplary, punitive, incidental, or consequential damages of any kind, resulting from any delay or failure of any services.'
  },
  {
  
    content: 'The website and any services on the website (including, without limitation, any content) is provided “as is” and “as available” and is without warranty of any kind, express or implied, including, but not limited to, the implied warranties of title, non-infringement, merchantability and fitness for a particular purpose, and any warranties implied by any course of performance or usage of trade, all of which are expressly disclaimed. Your use of the website and services provided via the website are solely at your own risk. The company and its directors, employees, agents, and partners do not warrant that:',
    items: [
      {
        content: 'The services will be secure or available at any particular time or location; or,'
      },
      {
        content: 'Any defects or errors will be corrected; or,'
      },
      {
        content: ' Any content or software available at or through the service is free of viruses or other harmful components; or,'
      },
      {
        content: 'The results of using the service will meet your requirements.'
      },
      {
        content: 'The services are fit for your circumstances or life stage in any manner'
      }
    ],
    orderType: 'a',
  },
  {
    orderType: 'a',
    content: 'To the fullest extent permitted by law, in no event shall company (nor its directors, employees, agents, sponsors, partners, suppliers, content providers, licensors or resellers,) be liable under contract, tort, strict liability, negligence or any other legal or equitable theory with respect to the service:',
    items: [
      {
        content: 'for any lost profits, data loss, loss of goodwill or opportunity, or special, indirect, incidental, punitive, or consequential damages of any kind whatsoever; ',
      },
      {
        content: 'for your reliance on the service;'
      },
      {
        content: 'for any direct damages in excess (in the aggregate) of the inr 10,000/-'
      },
      {
        content: 'for any matter beyond its or their reasonable control, even if the company has been advised of the possibility of any of the aforementioned damages.'
      }
    ]
  },
]