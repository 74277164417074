import { MENTORS } from "../assets/images"


const MENTORS_DATA = [
  {
    name: "Ravi Handa",
    desgination: "Founder",
    experience: "16+ Yrs",
    companies: "Handa ka Fanda",
    college: "IIT Kharaghpur",
    domain: "Career Counselling",
    linkedin: "https://www.linkedin.com/in/ravihanda/",
    image: MENTORS["1_Ravi_Handa"],
    style: "bg-mud-pink-color",
    type: "business",
    order: 3,
  },
  {
    name: "Mukund Tripathi",
    desgination: "Director of Engineering",
    experience: "10+ Yrs",
    companies: "Atlan",
    college: "DAIICT",
    domain: "Tech/Engineering",
    linkedin: "https://www.linkedin.com/in/tripathimukund/",
    image: MENTORS["2_Mukund_Tripathi"],
    style: "bg-light-torques-color",
    type: "tech",
    order: 8,
  },
  {
    name: "Mayank Singhal",
    desgination: "Engineer Manager",
    experience: "10+ Yrs",
    companies: "Razorpay, Practo, Cricbuzz",
    college: "DAIICT",
    domain: "Tech/Engineering",
    linkedin: "https://www.linkedin.com/in/mayank-singhal-3005/",
    image: MENTORS["3_Mayank_Singhal"],
    style: "bg-light-green-color",
    type: "tech",
    order: 9,
  },
  {
    name: "Preksha Mangal",
    desgination: "Consultant, Strategy",
    experience: "9+ Yrs",
    companies: "Mck, OYO, Byjus",
    college: "IIM Bangalore",
    domain: "Consultant",
    linkedin: "https://www.linkedin.com/in/preksha-mangal-0816935b/",
    image: MENTORS["4_Preksha_Mangal"],
    style: "bg-mud-pink-color",
    type: "business",
    order: 10
  },
  /* {
    name: "Kuldeep Dubey",
    desgination: "Founder",
    experience: "9+ Yrs",
    companies: "Damacloid",
    college: "",
    domain: "Tech/Engineering",
    linkedin: "https://www.linkedin.com/in/kuldeepdubey/",
    image: MENTORS["5_Kuldeep_Dubey"],
    style: "bg-light-torques-color",
    type: "tech",
    order: 11
  }, */
  {
    name: "Vishesh Narwaria",
    desgination: "Investment Banker",
    experience: "8+ Yrs",
    companies: "Ambit Capital, HDFC, Axis",
    college: "IIM Indore",
    domain: "Investment Banking",
    linkedin: "https://www.linkedin.com/in/vishesh-narwaria/",
    image: MENTORS["6_Vishesh_Narwaria"],
    style: "bg-light-green-color",
    type: "finance",
    order: 23
  },
  {
    name: "Agam Jain",
    desgination: "Technology architect",
    experience: "10+ Yrs",
    companies: "Zapr Labs, Samsung",
    college: "DAIICT",
    domain: "Tech/Engineering",
    linkedin: "https://www.linkedin.com/in/agam-jain-b6394338/",
    image: MENTORS["7_Agam_Jain"],
    style: "bg-mud-pink-color",
    type: "tech",
    order: 12
  },
  {
    name: "Kush Beejal",
    desgination: "Founder",
    experience: "15+ Yrs",
    companies: "Neo Stencil, Stoxhero",
    college: "IIT Bombay, IIM Calcutta",
    domain: "Startup",
    linkedin: "https://www.linkedin.com/in/kushbeejal/",
    image: MENTORS["8_Kush_Beejal"],
    style: "bg-light-torques-color",
    type: "business",
    order: 1
  },
  {
    name: "Arun Rajendrean",
    desgination: "Growth Head",
    experience: "11+ Yrs",
    companies: "Flipkart, Razorpay, PayTM",
    college: "NMIMS",
    domain: "Growth Marketing",
    linkedin: "https://www.linkedin.com/in/arun-kumar-rajendran-36554418/",
    image: MENTORS["9_Arun_Rajendrean"],
    style: "bg-light-green-color",
    type: "growth",
    order: 17
  },
  {
    name: "Raghav Maheswari",
    desgination: "CBO",
    experience: "13+ Yrs",
    companies: "InMobi, Affle, Mobavenue",
    college: "ISB",
    domain: "Affliate Marketing",
    linkedin: "https://www.linkedin.com/in/raghav-maheshwari-316b324a/",
    image: MENTORS["10_Raghav_Maheswari"],
    style: "bg-mud-pink-color",
    type: "growth",
    order: 22
  },
  {
    name: "Pratyush Kukreja",
    desgination: "Sales Head",
    experience: "13+ Yrs",
    companies: "Haptik, Scrut Automation, Pepper Content",
    college: "ISB",
    domain: "B2B Sales",
    linkedin: "https://www.linkedin.com/in/pratyushkukreja/",
    image: MENTORS["11_Pratyush_Kukreja"],
    style: "bg-light-torques-color",
    type: "business",
    order: 7
  },
  {
    name: "Aakash Kalp",
    desgination: "Business Head",
    experience: "10+ Yrs",
    companies: "OYO, Unacademy, Pepper Content",
    college: "",
    domain: "Startup",
    linkedin: "https://www.linkedin.com/in/akash-kalp-24b9a343/",
    image: MENTORS["12_Aakash_Kalp"],
    style: "bg-light-green-color",
    type: "business",
    order: 18
  },
  {
    name: "Punith",
    desgination: "Content Marketing",
    experience: "6+ Yrs",
    companies: "Cred, Unacademy",
    college: "PES",
    domain: "Content Marketing",
    linkedin: "https://www.linkedin.com/in/punithreddy/",
    image: MENTORS["13_Punith"],
    style: "bg-mud-pink-color",
    type: "growth",
    order: 24
  },
  {
    name: "Aseem Goyal",
    desgination: "Sales Head",
    experience: "14+ Yrs",
    companies: "Unacademy, Shiprocket, Amazon, Cashify",
    college: "IMT Ghaziabad",
    domain: "B2B Sales",
    linkedin: "https://www.linkedin.com/in/aseem-goyal/",
    image: MENTORS["14_Assem"],
    style: "bg-light-torques-color",
    type: "business",
    order: 20
  },
  /* {
    name: "Vivek Sinha",
    desgination: "Founder",
    experience: "14+ Yrs",
    companies: "Lodha, OYO, Unacademy",
    college: "ISB",
    domain: "Startups",
    linkedin: "https://www.linkedin.com/in/viveksinha85/",
    image: MENTORS["15_Vivek_Sinha"],
    style: "bg-light-green-color",
    type: "business"
  }, */
  {
    name: "Himanshu Sirohi",
    desgination: "Head - Digital Marketing",
    experience: "10+ Yrs",
    companies: "Adobe, Reliance, Apollo 24/7",
    college: "IIT Roorkee",
    domain: "Performance Marketing",
    linkedin: "https://www.linkedin.com/in/himanshusirohi/",
    image: MENTORS["16_Himanshu_Sirohi"],
    style: "bg-mud-pink-color",
    type: "growth",
    order: 6
  },
  {
    name: "Sagnik Ghosh",
    desgination: "Head - Strategy",
    experience: "6+ Yrs",
    companies: "BCG, Unacademy",
    college: "IIM Delhi, IIM Calcutta",
    domain: "Consultant/Strategy",
    linkedin: "https://www.linkedin.com/in/sagnik-ghosh-9301a5aa/",
    image: MENTORS["17_Sagnik_Ghosh"],
    style: "bg-light-torques-color",
    type: "growth",
    order: 16
  },
  {
    name: "Rajat Rustagi",
    desgination: "Founder",
    experience: "13+ Yrs",
    companies: "Upsolv",
    college: "ISB",
    domain: "Investment Banking",
    linkedin: "https://www.linkedin.com/in/rajatrustagi88/",
    image: MENTORS["18_Rajat_Rustagi"],
    style: "bg-light-green-color",
    type: "finance",
    order: 4
  },
  {
    name: "Ankit Khandelwal",
    desgination: "Founder",
    experience: "8+ Yrs",
    companies: "Upsolv",
    college: "MDI",
    domain: "Marketing",
    linkedin: "https://www.linkedin.com/in/ankitkhandelwal35/",
    image: MENTORS["19_Ankit"],
    style: "bg-mud-pink-color",
    type: "growth",
    order: 25
  },
  {
    name: "Aditi Saxena",
    desgination: "HR",
    experience: "11+ Yrs",
    companies: "AbInBev, Aditya Birla",
    college: "SIBM Pune",
    domain: "HR",
    linkedin: "https://www.linkedin.com/in/aditi-saxena-5a176194/",
    image: MENTORS["20_Aditi_Saxena"],
    style: "bg-light-torques-color",
    type: "hr",
    order: 14
  },
  {
    name: "Sunil Dubey",
    desgination: "HR Head",
    experience: "15+ Yrs",
    companies: "Flipkart, OYO",
    college: "",
    domain: "HR",
    linkedin: "https://www.linkedin.com/in/sunil-dubey-512810/",
    image: MENTORS["21_Sunil_Dubey"],
    style: "bg-light-green-color",
    type: "hr",
    order: 15
  },
  {
    name: "Hitesh Agarwal",
    desgination: "Product Manager",
    experience: "9+ Yrs",
    companies: "Razorpay, PineLabs",
    college: "IIM Ahmedabad",
    domain: "Product Manager",
    linkedin: "https://www.linkedin.com/in/hiteshagrwl/",
    image: MENTORS["22_Hitesh_Agarwal"],
    style: "bg-mud-pink-color",
    type: "product",
    order: 13
  },
  {
    name: "Akansha Goel",
    desgination: "Credit Analyst",
    experience: "6+ Yrs",
    companies: "Standard Chartered, ICICI, Kotak",
    college: "CA",
    domain: "Chartered Accountant",
    linkedin: "https://www.linkedin.com/in/ca-akansha-goel-17a519125/",
    image: MENTORS["23_Akansha_Goel"],
    style: "bg-light-torques-color",
    type: "finance",
    order: 19
  },
  {
    name: "Hina Arora",
    desgination: "Engineering Manager",
    experience: "7+ Yrs",
    companies: "Jio, Byjus, GoMechanic",
    college: "",
    domain: "Tech/Engineering",
    linkedin: "https://www.linkedin.com/in/hinaaroraa/",
    image: MENTORS["25_Hina_Arora"],
    style: "bg-light-torques-color",
    type: "tech",
    order: 2
  },
  {
    name: "Mahima Hans",
    desgination: "Software Engineer",
    experience: "2+ Yrs",
    companies: "Jio, Byjus, GoMechanic",
    college: "",
    domain: "Tech/Engineering",
    linkedin: "https://www.linkedin.com/in/mahima-hans-3b9976155/",
    image: MENTORS["24_Mahima_hans"],
    style: "bg-light-torques-color",
    type: "tech",
    order: 5
  }
]

export {
  MENTORS_DATA
}