const getQuoteBasedOnSalary = (amount_payable, quoteData) => {
  const quote = quoteData?.find((quote) => {
    const netAmount = quote?.premium?.netAmount;
    const netAmountInPaisa = netAmount.toString()?.split(".").join("");
    return netAmountInPaisa === amount_payable.toString();
  });

  return quote || {};
};

export default getQuoteBasedOnSalary;
