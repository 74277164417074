
import React, { useContext } from "react";
import Layout from '../../components/getQuotes/layout';
import { GetQuoteContext, GetQuoteViews, UserContext } from "../../modules/common-context";
import PlanConfirmation from "../../components/getQuotes/plan-confirmation";
import Login from "../../components/getQuotes/login";
import Otp from "../../components/getQuotes/otp";
import EmploymentDetails from "../../components/getQuotes/employment-details";
import PlanDetails from "../../components/getQuotes/plan-details";
import PlanSuccess from "../../components/getQuotes/plan-success";
import PlanOnHold from "../../components/getQuotes/plan-on-hold";
import PlanDetailsV2 from "../../components/getQuotes/plan-details-v2";


const GetQuotePage = () => {
  const { currView } = useContext(GetQuoteContext);
  const { user } = useContext(UserContext);

  if (user?.id) {
    if (currView === GetQuoteViews.LOGIN || currView === GetQuoteViews.OTP) {
      return <EmploymentDetails />
    }
  };

  const COMPONENT_MAPPING = {
    [GetQuoteViews.LOGIN]: <Login />,
    [GetQuoteViews.OTP]: <Otp />,
    [GetQuoteViews.EMPLOYMENT_DETAILS]: <EmploymentDetails />,
    // [GetQuoteViews.PLAN_DETAILS]: <PlanDetails />,
    [GetQuoteViews.PLAN_DETAILS]: <PlanDetailsV2 />,
    // [GetQuoteViews.PLAN_DETAILS_V2]: <PlanDetailsV2 />,
    [GetQuoteViews.PLAN_CONFIRMATION]: <PlanConfirmation />,
    [GetQuoteViews.PLAN_SUCCESS]: <PlanSuccess />,
    // [GetQuoteViews.PLAN_ON_HOLD]: <PlanOnHold />
  }

  return (
    <Layout>
      {COMPONENT_MAPPING[currView]}
    </Layout>
  )
}

const GetQuoteWrapper = () => {


  return (
    <React.Fragment>
      <GetQuotePage />
    </React.Fragment>
  )
};

export default GetQuoteWrapper;