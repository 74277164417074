import React from "react";

import Heading from "../../../../components/atoms/heading";
import HighLightSectionName from "../../../../shared/components/highLightSectionName/HighLightSectionName";
import ProblemSvg from "../../../../assets/images/Problem.svg";
import SolutionSvg from "../../../../assets/images/Solution.svg";

import styles from "./littleStory.module.scss";

const LittleStory = () => {
  return (
    <section className={styles.container}>
      <HighLightSectionName text="A LITTLE THROWBACK" />
      <Heading
        text="The Story Behind UPSOLV (formerly Jobsurance)"
        className={styles.header}
      />
      <div className={styles.story}>
        <p className={styles.firstPara}>
          In late 2022, our founders — Rajat and Ankit — noticed a worrying,
          persistent trend in private-sector workplaces: layoffs. Companies that
          were once perceived as ‘dream workplaces’ suddenly started laying off
          thousands of people at a time when the job market was stagnant.
        </p>
        <br />
        <p className={styles.secondPara}>
          This uprooted not just the lives of these employees, but their
          families too — with little to no support for their immediate future.
          Many of these ex-employees took to LinkedIn with their CVs, in the
          hopes of finding new employment opportunities.
        </p>
        <br />
        <p className={styles.secondPara}>
          Our founders, who have worked with two of India’s biggest employers
          had front-row seats to the devastation caused by these ‘mass layoffs’.
        </p>
        <br />
        <p className={styles.secondPara}>
          Moved by what they saw, they set out to create change and make a
          massive difference in this new normal for private sector workplaces.
        </p>
        <br />
        <p className={styles.secondPara}>
          They spoke to former colleagues and friends across various roles in
          companies like Google, Meta, and Unacademy, gathering invaluable
          insights into the challenges faced by those who had lost their jobs.
        </p>
        <br />
      </div>
      <div className={styles.problemAndSolution}>
        <div className={styles.problem}>
          <img className={styles.image} src={ProblemSvg} alt="problem icon" />
          <div>
            <p className={styles.heading}>Problem</p>
            <p className={styles.content}>
              Mass layoffs cause financial and mental hardships that companies
              cannot adequately compensate for, either through outplacement or
              severance. Most employees who are laid off don't have adequate
              savings or an emergency fund to be financially secure, pay off
              loans/EMIs, and maintain their lifestyles.
            </p>
          </div>
        </div>
        <div className={styles.divider}></div>
        <div className={styles.solution}>
          <img className={styles.image} src={SolutionSvg} alt="solution icon" />
          <div>
            <p className={styles.heading}>Solution</p>
            <p className={styles.content}>
              India’s only dedicated subscription plan against layoffs. Even if you lose your job, you can recover quickly with premium TA portals, mental health support, expert mentoring, unemployment allowance. With UPSOLV, you can continue to meet your financial and life commitments with peace of mind.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LittleStory;
