import React, { useCallback, useContext, useEffect, useState } from "react";
import { Allowance_Plain, CircleTick, Dimond, InfoCircle, Mental_Plain, Next } from "../../../assets/images";
import { Btn, CapsuleTag } from "../../../components";
import { IconButton } from "@mui/material";
import PlanBreakupDialog from "../../../components/getQuotes/plan-details-v2/plan-detail-breakup";
import ApiServices from "../../../services/api";
import { API_END_POINT } from "../../../components/getQuotes/constants/api-const";
import { GetQuoteContext } from "../../../modules/common-context";
import { PLAN_COLOR } from "../../../constants/planColor";
import { BENEFITS_SLIDER } from "../../../constants/benefits";
import { PAISE } from "../../../shared/constants/app";
import amountWithCommas from "../../../shared/utils/amount-seperator";
import { useNavigate } from "react-router-dom";



const UpgradePlan = () => {
    const { setCurrView, setDataPayload, dataPayload } = useContext(GetQuoteContext);

    const navigate = useNavigate();

    const [isLoading, setIsloading] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [showPriceBreakUpModal, priceBreakupToggleModal] = useState(false);
    const [planBenefits, setPlanBenefits] = useState([]);
    const [upgradePlanDetails, setUpgradePlanDetails] = useState({});
    const [planSliderData, setPlanSliderData] = useState([]);

    const [planSelection, setPlanSelection] = useState('');
    const [priceBreakGrossAmount, setPriceBreakGrossAmount] = useState(null);
    const [priceBreakNetAmount, setPriceBreakNetAmount] = useState(null);
    const [priceBreakTax, setPriceBreakTax] = useState(null);
    const [extraBenefitsPrice, setExtraBenefitsPrice] = useState(null);
    const [priceGrossBenefits, setPriceGrossBenefits] = useState(null);
    const [protectionAmount, setProtectionAmount] = useState(null);
    const [previouslyPaid, setPreviouslyPaid] = useState(null);
    const [planUpgradeAmount, setPlanUpgradeAmount] = useState(null);

    const handlePriceBreakupModal = () => {
        priceBreakupToggleModal(true);
    }

    const onClosePriceBreakupModal = () => {
        priceBreakupToggleModal(false)
    }

    const getBenefit = async () => {
        try {
            let response = await ApiServices.get(API_END_POINT.GET_BENEFITS);
            if (response.status === 200) {
                setPlanBenefits(response?.data?.benefits);
            } else {
                setPlanBenefits([]);
            }
        } catch (error) {
            console.log("getBenefit.Error", error);
        }
    }

    const getPlanUpgradeDetails = useCallback(async () => {
        try {
            const payload = {
                name: dataPayload.name,
                mobile: dataPayload.phone_number,
                salary: dataPayload.salary
            }
            const response = await ApiServices.post(API_END_POINT.USER_DASHBOARD_UPGRADE_PROTECTION_PLAN, payload);
            if (response.status === 200) {
                setUpgradePlanDetails(response?.data?.quotes);
            }
        } catch (err) {
            console.log("getPlanUpgradeDetails error:", err);
        }
    }, [dataPayload.name, dataPayload.phone_number, dataPayload.salary])

    const formatPlanBenefitData = useCallback(() => {
        try {
            const formatedPlanBenefit = [];
            planBenefits.forEach((item) => {
                if (upgradePlanDetails?.benefit_ids?.includes(item.id)) {
                    const icon = BENEFITS_SLIDER.find((obj) => obj.ref_code === item.ref_code);
                    item["title"] = item?.name;
                    item["image"] = icon?.image;

                    formatedPlanBenefit.push(item);
                }
            })
            setPlanSliderData(formatedPlanBenefit);
        } catch (error) {
            console.log("formatPlanBenefitData >>", error)
        }
    }, [planBenefits, upgradePlanDetails.benefit_ids])


    const handlePriceBreakup = (data) => {
        setPlanSelection(data?.name);

        const NumberOfMonthForProtectionAmount = 3;
        let priceBreakGrossAmount = (data?.premium?.grossAmount / PAISE).toFixed(0);
        let priceBreakNetAmount = (data?.premium?.netAmount / PAISE).toFixed(0);
        let priceBreakTax = (data?.premium?.tax / PAISE).toFixed(0);
        let extraBenefitsCharge = (data?.premium?.extraBenefitsCharge / PAISE).toFixed(0);
        let priceGrossBenefits = ((data?.premium?.netAmount - data?.premium?.extraBenefitsCharge) / PAISE).toFixed(0);
        // let protectionAmount = ((data?.premium?.protectionAmount / PAISE) / NumberOfMonthForProtectionAmount).toFixed(0);
        let protectionAmount = ((data?.premium?.protectionAmount / PAISE)).toFixed(0);
        let previouslyPaid = (data?.previouslyPaid / 100).toFixed(0);
        let planUpgradeAmount = (data?.planUpgradeAmount / 100).toFixed(0);

        setPriceBreakGrossAmount(amountWithCommas(priceBreakGrossAmount));
        setPriceBreakNetAmount(amountWithCommas(priceBreakNetAmount));
        setPriceBreakTax(amountWithCommas(priceBreakTax));
        setExtraBenefitsPrice(amountWithCommas(extraBenefitsCharge));
        setPriceGrossBenefits(amountWithCommas(priceGrossBenefits));
        setProtectionAmount(amountWithCommas(protectionAmount));
        setPreviouslyPaid(amountWithCommas(previouslyPaid));
        setPlanUpgradeAmount(amountWithCommas(planUpgradeAmount));
        console.log('protectionAmount', data)
    }

    const setStoreUserDetails = useCallback((data, userDetails) => {

        userDetails.selected_quote = data;
        userDetails.selected_quote_planName = data.name;
        setUserDetails(userDetails);

        setDataPayload({ ...dataPayload, ...userDetails });

    }, [dataPayload, setDataPayload]);

    const handlePlanUpgrade = () => {
        const result = upgradePlanDetails;

        // set selected quote
        setStoreUserDetails(result, userDetails)

        navigate('/get-quote', {
            state: {
                upgradePlan: true,
                backTo: "/dashboard"
            }
        });
        setCurrView("PLAN_CONFIRMATION");
    }

    useEffect(() => {
        getBenefit();
    }, []);

    useEffect(() => {
        getPlanUpgradeDetails();
    }, [getPlanUpgradeDetails])

    useEffect(() => {
        formatPlanBenefitData();
    }, [formatPlanBenefitData, planBenefits])

    useEffect(() => {
        handlePriceBreakup(upgradePlanDetails);
    }, [upgradePlanDetails]);


    return (
        <React.Fragment>
            <div className="dashboard-page-wrap upgarde-plan-item">
                {/* <div className="text-wrap mb-4">
                    <p className="subtitle1 black-color font-family-medium"></p>
                </div> */}
                <div className="dashboard-upgarde-plan">
                    <div className="">
                        <div className="upgarde-tag">
                            <p>Upgrade to</p>
                        </div>
                        <div className="dash-plan-card selected-card">
                            <div className="plan-header-wrap active">
                                <div className="plan-header-action-wrap">
                                    <CapsuleTag
                                        title="UP"
                                        subtitle={upgradePlanDetails.name}
                                        className={`${PLAN_COLOR[upgradePlanDetails.name?.toUpperCase()]}`}
                                    />
                                </div>
                                <div className="text-wrap mt-2">
                                    <p className="subtitle1 black-color font-family-regular">{upgradePlanDetails?.description}</p>
                                </div>
                            </div>
                            <div className="plan-body-footer-wrap">
                                <div className="plan-body-wrap plan-body-top-radius-wrap">
                                    <div className="text-wrap wrap-heading">
                                        <h6>Everything in Pro and…</h6>
                                    </div>
                                    <div className="row">
                                        {
                                            planSliderData.map((item) => (
                                                <div className="col col-12 col-lg-6">
                                                    <div className="benefits-info-wrap p-0">
                                                        <div className="coloum-wise-list text-wrap px-0">
                                                            <img src={item.image} alt="benefits-include" className="illustration-img"></img>
                                                            <img src={CircleTick} alt="benefits-include" className="benefits-list-img"></img>
                                                            
                                                            <p className="subtitle1 black-color font-familt-regular">{item.name !== 'Unemployment Allowance' ? item.name : 
                                                                <span className="benefits-top-priority">
                                                                    Max unemployment allowance upto ₹{protectionAmount}
                                                                    <span className="benefits-list-breakout mt-2">
                                                                        <span>
                                                                        <span className="bullet">1</span> <span>50% on 45 days of unemployment</span>
                                                                        </span>
                                                                        <span>
                                                                        <span className="bullet">2</span> <span>50% on 90 days of unemployment</span>
                                                                        </span>
                                                                    </span>
                                                                </span>  }
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>

                                <div className="plan-footer-wrap">
                                    <div className="plan-footer-action-wrap">
                                        <div className="plan-price-details">
                                            <div className="text-wrap">
                                                <p class="caption black-color opacity-5">Just pay additional</p>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => { handlePriceBreakupModal() }}
                                                >
                                                    <img src={InfoCircle} alt="InfoCircle" className="info-icon" />
                                                </IconButton>
                                            </div>
                                            <div className="text-wrap">
                                                <p className="caption font-family-medium black-color">
                                                    <span className="subtitle1 text-nowrap">₹ {(upgradePlanDetails?.planUpgradeAmount / 100).toFixed(0)}</span> <br></br>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="upgarde-item">
                                            <Btn
                                                label="Upgrade"
                                                size="medium"
                                                className="btn btn-upgrade font-family-medium"
                                                endIcon={<img src={Next} alt="download-plan"></img>}
                                                startIcon={<img src={Dimond} alt="download-plan"></img>}
                                                onClick={() => { handlePlanUpgrade() }}
                                            >
                                            </Btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <PlanBreakupDialog
                            planName={planSelection}
                            extraBenefitsPrice={extraBenefitsPrice}
                            priceBreakGrossAmount={priceBreakGrossAmount}
                            priceBreakTax={priceBreakTax}
                            priceGrossBenefits={priceGrossBenefits}
                            priceBreakNetAmount={priceBreakNetAmount}
                            planUpgrade={true}
                            paidPreviously={previouslyPaid}
                            planUpgradeAmount={planUpgradeAmount}
                            onClose={(val) => { onClosePriceBreakupModal(val) }}
                            open={showPriceBreakUpModal}
                        />
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default UpgradePlan;