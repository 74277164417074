import React from 'react';

const Card = ({classes, children}) => {
    return (
        <div className={`${classes} bg-white rounded-md`}>
            {children}
        </div>
    )
}

export default Card;
