import React from "react";

import { DISCLAIMER_ITEMS } from "../../constants/disclaimerItem";
import { getAlphabetsFromAscii } from "../../../../shared/utils";

import styles from "./disclaimersItems.module.scss";

const RecursiveList = ({ items = [], itemClass, orderType }) => {
  return (
    <ol>
      {items.map((item, index) => {
        const itemNumberBaseAscii = getAlphabetsFromAscii(
          index,
          orderType ? orderType.charCodeAt(0) : 1
        );
        return (
          <div className={styles.itemWrapper}>
            <span className={styles.marginNumber}>{itemNumberBaseAscii}.</span>
            <li key={`recursiveList-${item.content}`} className={styles.item}>
              {item.content}
              {item.items && (
                <RecursiveList
                  items={item.items}
                  itemClass={itemClass}
                  orderType={item.orderType}
                />
              )}
            </li>
          </div>
        );
      })}
    </ol>
  );
};
const DisclaimersItems = () => {
  return (
    <div className={styles.container}>
      <ol>
        {DISCLAIMER_ITEMS.map((item, index) => {
          return (
            <div key={`DisclaimerItem-${index}`} className={styles.itemWrapper}>
              <span className={styles.marginNumber}>{index + 1}.</span>
              <li className={styles.item}>
                {item.content}
                {item.items && (
                  <RecursiveList
                    itemClass={styles.item}
                    items={item.items}
                    orderType={item.orderType}
                  />
                )}
              </li>
            </div>
          );
        })}
      </ol>
    </div>
  );
};

export default DisclaimersItems;
