
import Star from './5.2_Star.svg';
import HideCharacter from './hide-character.svg';
import Logo from './logo/logo.png';
import LogoWhite from './logo/logo-white.png';
import Back from './icon/back.svg';
import Add from './icon/add.svg';
import Bulb from './icon/Bulb.svg';
import Cancel from './icon/Cancel.svg';
import Congratulations from './icon/Congratulations.svg';
import Download from './icon/Download.svg';
import Edit from './icon/Edit.svg';
import Next from './icon/Next.svg';
import Valid from './icon/Valid.svg';
import Verified from './icon/Verified.svg';
import Home from './icon/Web1_Home.svg';
import India from './icon/india.png';
import TickWhite from './icon/tick-white.svg';
import Thankyou from './icon/Web_Thank_You.png';
import Que from './icon/que.svg';
import Dimond from './icon/dimond.png';
import StartUpIndia from "./startupIndia.png";

import CalendarTime from './icon/calendar-time.svg';
import Chair from './icon/chair.svg';
import Interview from './icon/interview.svg';
import PlacementNetwork from './icon/placementNetwork.svg';
import PlacemnetExpert from './icon/placemnetExpert.svg';
import Portal from './icon/portal.svg';
import Profile from './icon/profile.svg';
import Salary from './icon/salary.svg';
import Insure from './icon/insure.svg';
import Security from './icon/security.svg';
import ThankYouText from './icon/thank_you.svg';
import AvtarMale from './icon/avatar-male.svg';
import AvtarFemale from './icon/avatar-female.svg';
import AvtarOther from './icon/avatar-other.svg';
import WhatsappIcon from './social/whatsapp.svg';
import CircleTick from './icon/tick.svg';
import CircleDash from './icon/dash.svg';
import InfoCircle from './icon/info-icon.svg';
import PlanFeature1 from './icon/Plan_Feature_1.svg';
import PlanFeature2 from './icon/Plan_Feature_2.svg';
import PlanFeature3 from './icon/Plan_Feature_3.svg';
import PlanFeature4 from './icon/Plan_Feature_4.svg';
import PlanFeature5 from './icon/Plan_Feature_5.svg';
import PlanFeature6 from './icon/Plan_Feature_6.svg';
import Clapping from './icon/Clapping_Icon.svg';


import Mental_Health_Globe from './Mental_Health_Globe.svg';
import Mental_Health_Media from './Mental_Health_Media.svg';
import Mental_Health_Setting from './Mental_Health_Setting.svg';
import Mental_Health_New_Icon_1 from './Mental_Health_New_Icon_1.svg';
import Mental_Health_New_Icon_2 from './Mental_Health_New_Icon_2.svg';
import Mental_Health_New_Icon_3 from './Mental_Health_New_Icon_3.svg';
import Mental_Health_New_Icon_4 from './Mental_Health_New_Icon_4.svg';
import Mentor_Benefits_1 from './Mentor_Benefits_1.svg';
import Mentor_Benefits_2 from './Mentor_Benefits_2.svg';
import Mentor_Benefits_3 from './Mentor_Benefits_3.svg';
import Mentor_Benefits_4 from './Mentor_Benefits_4.svg';

import LinkedInWhite from './social/linkedin-white.svg';
import Corporates from './Corporates.svg';
import ListedCompanies from './Listed companies.svg';
import Unicorns from './Unicorns.svg';
import standoutHassleFree from './standoutHassleFree.svg';
import standoutInstant from './standoutInstant.svg';
import standoutSavings from './standoutSavings.svg';

import RightArrow from './icons/rightArrow.png';
import MentalHealthSvg from './Mental_Health.svg';

import Session_1to1 from './illustration/1_1to1_Session.svg';
import TAPortal from './illustration/2_TA.svg';
import ResumeBuilding from './illustration/3_Resume_Building.svg';
import Allowance from './illustration/4_allowance.svg';
import Mental from './illustration/5_Mental.svg';
import MentorHeader from './illustration/Mentor_Header.svg';

import Session1_1to1_Plain from './illustration/1_1to1_Session__Dashboard.svg';
import TAPortal_Plain from './illustration/2_TA__Dashboard.svg';
import ResumeBuilding_Plain from './illustration/3_Resume_Building_Dashboard.svg';
import Exclusive_Support from './illustration/6.2_Placement_Support.svg';
import Allowance_Plain from './illustration/4_allowance__Dashboard.svg';
import Mental_Plain from './illustration/5_Mental__Dashboard.svg';
import Dashboard_Claim from './illustration/Dashboard_Claim.svg';
import Dashboard_Edit from './illustration/Dashboard_Edit.svg';
import ThankYou from './illustration/thank-you.svg';
import Stars_5 from './illustration/stars.svg';

import Edit_Pen from './icon/Edit_Pen.svg';
import Quote_for_Friend from './icon/Quote_for_Friend.svg';
import Refer_Your_Friend from './icon/Refer_Your_Friend.svg';
import My_Plan from './icon/My_Plan.svg';
import Claim from './icon/Claim.svg';
import CallUs from './icons/call.png'
import Email from './icons/email.png'

const MENTORS  = {
  "1_Ravi_Handa": require("./mentor/1_Ravi_Handa.jpg"),
  "2_Mukund_Tripathi": require("./mentor/2_Mukund_Tripathi.jpg"),
  "3_Mayank_Singhal": require("./mentor/3_Mayank_Singhal.jpg"),
  "4_Preksha_Mangal": require("./mentor/4_Preksha_Mangal.jpg"),
  "5_Kuldeep_Dubey": require("./mentor/5_Kuldeep_Dubey.jpg"),
  "6_Vishesh_Narwaria": require("./mentor/6_Vishesh_Narwaria.jpg"),
  "7_Agam_Jain": require("./mentor/7_Agam_Jain.jpg"),
  "8_Kush_Beejal": require("./mentor/8_Kush_Beejal.jpg"),
  "9_Arun_Rajendrean": require("./mentor/9_Arun_Rajendrean.jpg"),
  "10_Raghav_Maheswari": require("./mentor/10_Raghav_Maheswari.jpg"),
  "11_Pratyush_Kukreja": require("./mentor/11_Pratyush_Kukreja.jpg"),
  "12_Aakash_Kalp": require("./mentor/12_Aakash_Kalp.jpg"),
  "13_Punith": require("./mentor/13_Punith.jpg"),
  "14_Assem": require("./mentor/14_Assem.jpg"),
  "15_Vivek_Sinha": require("./mentor/15_Vivek_Sinha.jpg"),
  "16_Himanshu_Sirohi": require("./mentor/16_Himanshu_Sirohi.jpg"),
  "17_Sagnik_Ghosh": require("./mentor/17_Sagnik_Ghosh.jpg"),
  "18_Rajat_Rustagi": require("./mentor/18_Rajat_Rustagi.jpg"),
  "19_Ankit": require("./mentor/19_Ankit.jpg"),
  "20_Aditi_Saxena": require("./mentor/20_Aditi_Saxena.jpg"),
  "21_Sunil_Dubey": require("./mentor/21_Sunil_Dubey.jpg"),
  "22_Hitesh_Agarwal": require("./mentor/22_Hitesh_Agarwal.jpg"),
  "23_Akansha_Goel": require("./mentor/23_Akansha_Goel.jpg"),
  "24_Mahima_hans": require("./mentor/24_mahima_hans.jpeg"),
  "25_Hina_Arora": require("./mentor/25_Hina_Arora.jpeg"),
}

export {
  LogoWhite, Star, Logo, Back, Add, AvtarMale, Bulb, Cancel, Congratulations, Download, Edit, Next, Valid, Verified, Home, India, CalendarTime, Chair, Insure, Security, TickWhite, Thankyou, ThankYouText, Que, AvtarFemale, AvtarOther, Interview, PlacementNetwork, PlacemnetExpert, Portal, Profile, Salary, HideCharacter, WhatsappIcon, CircleTick, CircleDash, MENTORS, LinkedInWhite, Corporates, ListedCompanies, Unicorns, RightArrow, MentalHealthSvg, InfoCircle, Session_1to1, TAPortal, ResumeBuilding, Allowance, Mental, Mental_Health_Globe, Mental_Health_Media, Mental_Health_Setting, Mentor_Benefits_1, MentorHeader, Mentor_Benefits_2, Mentor_Benefits_3, Mentor_Benefits_4, standoutHassleFree, standoutInstant, standoutSavings, PlanFeature1, PlanFeature2, PlanFeature3, PlanFeature4, PlanFeature5, PlanFeature6, Mental_Health_New_Icon_1, Mental_Health_New_Icon_2, Mental_Health_New_Icon_3, Mental_Health_New_Icon_4, Edit_Pen, Quote_for_Friend, Refer_Your_Friend, My_Plan, Claim, Session1_1to1_Plain, TAPortal_Plain, Exclusive_Support, ResumeBuilding_Plain, Allowance_Plain, Mental_Plain, Clapping, Dashboard_Claim, Dashboard_Edit, CallUs, Email, ThankYou, Stars_5, Dimond, StartUpIndia
}