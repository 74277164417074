
import React, { useState } from "react";


import { ItemList } from "../../../components/index"
import { Que } from "../../../assets/images/index";

import DialogBox from "../../dialog-box";

const PlanNotCovered = (props) => {

  const [showModal, setToggleModal] = useState(false);
  const [modalDescription, setModalDescription] = useState("");
  const [modalTitle, setModalTitle] = useState("");

  const onClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setToggleModal(false);
  }

  const setModalContent = (title, description) => {
    setModalTitle(title)
    setModalDescription(description)
  }
  
  
  return (
    <div>
      <div className="plan-list-wrap">
        <ul>
          {
            props.dataList.map((item, index) => {
              return (
                <ItemList
                  key={index}
                  className="item-list-border item-invalid"
                  primary={
                    <div className="text-wrap">
                      <p className="caption font-family-thin">{item.name}</p>
                    </div>
                  }
                  avatar={
                    <img alt="icon" src={item.icon} />
                  }
                  secondaryAction={
                    <div className="list-action-wrap" onClick={() => {setToggleModal(true); setModalContent(item.name, item.description)}}>
                      <img alt="queue" src={Que} />
                    </div>
                  }
                />
              )
            })
          }
        </ul>
      </div>
      <DialogBox
        title={
          <div className="text-wrap">
            <h3 className="font-family-bold black-color">What isn't covered</h3>
          </div>
        }
        open={showModal}
        onClose={(val)=>{onClose(val)}}
        content={
          <div className="card-highlight-wrap card-highlight-left">
            <div className="text-wrap">
              <p className="subtitle font-family-regular black-color mb-2">{modalTitle}</p>
              <p className="caption font-family-regular black-color">{modalDescription}</p>
            </div>
          </div>
        }
      />
    </div>
  )
}

export default PlanNotCovered;
