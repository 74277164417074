import { EMPTY_ARRAY } from "../../../constants/app";
import PAYMENT_STATUS from "../constants/paymentStatus";
import POLICY_STATUS from "../constants/policyStatus";

const validatePolicyBeenPurchased = (userOrders = EMPTY_ARRAY) => {
  const purchasedOrder = userOrders?.find((order) => {
    const { protection_plan_id, status } = order;
    return status === PAYMENT_STATUS.SUCCESS;
  });

  return {
    isPurchased: !!purchasedOrder,
    order: purchasedOrder,
  };
};

const validateFailedPurchase = (userOrders) => {
  const failedOrder = userOrders?.find((order) => {
    const { proposal_id, status } = order;
    return (
      !!proposal_id &&
      (status === PAYMENT_STATUS.FAILED || status === PAYMENT_STATUS.INITIATED)
    );
  });

  return {
    isFailedPurchase: !!failedOrder,
    order: failedOrder,
  };
};

export const getPolicyAndStatus = (userOrders = EMPTY_ARRAY) => {
  const result = {
    status: "",
    order: {},
  };

  // VALIDATE IF POLICY HAS BEEN PURCHASED
  const validatedPolicyBeenPurchased = validatePolicyBeenPurchased(userOrders);
  const { isPurchased, order: purchasedPolicyOrder } =
    validatedPolicyBeenPurchased;

  if (isPurchased) {
    result.status = POLICY_STATUS.PURCHASED;
    result.order = purchasedPolicyOrder;
    return result;
  }

  // VALIDATE IF PAYMENT HAS BEEN FAILED WHILE PURCHASING POLICY
  const validatedFailedPurchase = validateFailedPurchase(userOrders);
  const { isFailedPurchase, order: failedPurchaseOrder } =
    validatedFailedPurchase;

  if (isFailedPurchase) {
    result.status = POLICY_STATUS.CONTINUE_PURCHASE;
    result.order = failedPurchaseOrder;
    return result;
  }

  // VALIDATE IF USER HAS INITIATED BUT NOT COMPLETED THE ORDER
  // TO DO :

  return result;
};

export default getPolicyAndStatus;
