import React from "react";
import { HERO_SECTION } from "../../constants/HeroSection";
import HighLightSectionName from "../../../../shared/components/highLightSectionName/HighLightSectionName";
import styles from "./heroSection.module.scss";

const HeroSections = () => {
  return (
    <div className={styles.container}>
      <div className={styles.headerSection}>
        <HighLightSectionName text="PRIVACY POLICY" />
        <div className={styles.heading}>{HERO_SECTION.heading}</div>
      </div>

      {HERO_SECTION.items.map((item, index) => {
        return (
          <p key={`HeroSection-${index}`} className={styles.item}>
            {item.content}{" "}
          </p>
        );
      })}
    </div>
  );
};

export default HeroSections;
