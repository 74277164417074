import Cookies from "universal-cookie";
const cookies = new Cookies();
const expirationDate = new Date();
expirationDate.setDate(expirationDate.getDate() + 1);
export async function setCookie(key,value) {
  cookies.set(key, value, { path: '/', expires: expirationDate });
}
export function getCookie(value) {
  return cookies.get(value);
}
export function removeCookie(value) {
  return cookies.remove(value)
}
