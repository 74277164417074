import React from "react";
import cx from 'classnames';

import StarImg from "../../../assets/images/5.2_Star.svg";

import styles from "./starIconOrder.module.scss";

const StarIconOrder = ({ text, imgClassName }) => {
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <img className={cx(styles.img, imgClassName)} alt="Terms Point" src={StarImg} />
        <span className={styles.number}>{text}</span>
      </div>
    </div>
  );
};

export default StarIconOrder;
