import { useState } from "react";

const useLoginInitiateState = () => {
  const [showLoginModal, setShowLoginModal] = useState(false);

  return {
    showLoginModal,
    setShowLoginModal,
  };
};

export default useLoginInitiateState;
