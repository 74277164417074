export const HERO_SECTION  = {
    heading: 'Terms of Service',
    items: [
        {
            content: 'This website **www.upsolv.in** (formerly Jobsurance) is owned and operated by Vicinal Jobsurance Private Limited, **(Company, we, our, us )** Workflo by OYO, 3rd Floor, Plot No. 224, Ranka Junction, Near Tin Factory, Bengaluru - 560036.'
        },
        {
            content: 'Through the website the Company provides you with marketing, listing, communication and sale of value added lifestyle support products and services. These ToS of Website (ToS) govern your access to and use of our Website and any Content available on the Website.'
        },
        {
            content: 'By accessing or downloading content from or using our Website, you agree to be bound by these TOS, and you further explicitly consent to our Privacy Policy (https://upsolv.in/privacy/) . We urge you to access, review and familiarize yourself with these ToS periodically, for your continued use of the Website, which will constitute in your consent and agreement to these ToS.'
        },
        {
            content: 'In the event the ToS are not agreeable to you, we request you to desist from accessing, downloading, using the Website in any manner, whatsoever.'
        },
        {
            content: 'For ease of reference, we are defining certain terms as below which will be used throughout the ToS and associated policies:'
        },
        {
            content: 'The term **Website** shall mean and include the website **www.upsolv.in** accessible from any device and for ease of messaging includes the Upsolv App.'
        },
        {
            content: '**Content** means and includes, without limitation, any information, data, text, pictures, audio, video, GIFs, polls, user profiles, software, tags, graphics, and interactive features generated, provided, or otherwise made accessible either by you or other users or by us or our partners or sponsors on or through the Website/s.'
        },
        {
            content: '**You** or **User** shall mean any user who interacts with the Website.'
        },
    ]
}