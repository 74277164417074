
import React, { useContext, useEffect, useState } from "react";
import { Alert, IconButton, Snackbar, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';



import Card from "../../Card";
import { GetQuoteContext } from "../../../../modules/common-context";

import { Btn } from "../../../../components/index"
import { Cancel, India, Verified } from "../../../../assets/images/index";
import { EMAIL_REGEX, PHONE_NUMBER_REGEX } from "../../../../helpers/regex";
import { Formik } from "formik";
import dayjs from "dayjs";
import ApiServices from "../../../../services/api";
import { API_END_POINT } from "../../constants/api-const";
import APP_DATA_CONFIG from "../../../../helpers/app";

const PersonalDetailsForm = (props) => {
  // Define the minimum and maximum dates
  const minDate = dayjs(new Date(new Date().setFullYear(new Date().getFullYear() - 65)))
  const maxDate = dayjs(new Date(new Date().setFullYear(new Date().getFullYear() - 18)))

  const {setDataPayload,  dataPayload} = useContext(GetQuoteContext);
  const [genderSelection, setGenderSelection] = useState('Male');
  const [userDetails, setUserDetails] = useState(props);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [isFormSubmit, setIsFormSubmit] = useState(false);

  // handleSnackBar
  const handleSnackBar = () => {
    setSnackBarOpen(false);
  }

  // set formik field
  let setFieldValueFunction = null;

  const handleSelectFieldObject = (setFieldValue) => {
    setFieldValueFunction = setFieldValue
  }

  const handleGenderSelection = (event, val) => {
    if (val !== null) {
      setGenderSelection(val);
      setFieldValueFunction("gender", val);
    }
  };

  const patchUserData = async (payload) => {
    try {
      const res = await ApiServices.patch(API_END_POINT.USER_SELF, payload);
      if(res.status === 200){
        props.onClose();
        props.onChange(new Date());
        setIsFormSubmit(false);
      }

    } catch (error) {
      const _error = error.response
      setSnackBarOpen(true);
      setSnackBarMessage(_error?.data?.message);
      setIsFormSubmit(false);
    }
  }

  const submitHandle = (data) => {
    setIsFormSubmit(true);

    const _employeeDetails = {
      date_of_birth: dayjs(data.date_of_birth).format("YYYY-MM-DD"),
      email: data.email,
      gender: data.gender,
      name: data.name,
      lead_id: dataPayload?.lead_id,
    }

    patchUserData(_employeeDetails)
    setDataPayload({ ...dataPayload, ...userDetails, ..._employeeDetails, })

    window.dataLayer.push({'event':'get-quote_ProposalPage_PersonalDetailsSave'});
    window.webengage.track("get-quote_ProposalPage_PersonalDetailsSave", _employeeDetails);
    window.webengage.user.setAttribute("get-quote_ProposalPage_PersonalDetailsSave", _employeeDetails);
    APP_DATA_CONFIG.GENERATE_LEAD.request("get-quote_ProposalPage_PersonalDetailsSave", dataPayload?.lead_id)
    window.webengage.user.setAttribute("we_email", data.email);
    window.webengage.user.setAttribute("we_gender", data.gender);
    window.webengage.user.setAttribute("we_birth_date", data.date_of_birth);

  }

  useEffect(()=> {
    const storeUserDetails = dataPayload;
    setUserDetails(dataPayload);

    setFieldValueFunction("name", storeUserDetails.name);
    setFieldValueFunction("phone_number", storeUserDetails.phone_number);
    setFieldValueFunction("gender", storeUserDetails.gender == null ? 'Male' : storeUserDetails.gender);

    if (storeUserDetails.date_of_birth != null && storeUserDetails.email != null) {
      setFieldValueFunction("date_of_birth", storeUserDetails.date_of_birth != null ? dayjs(storeUserDetails.date_of_birth): "");
      setFieldValueFunction("email", storeUserDetails.email);
      setGenderSelection(storeUserDetails.gender)
    } else if(storeUserDetails.name == null && storeUserDetails.phone_number == null && storeUserDetails.gender == null && storeUserDetails.email == null) {
      setFieldValueFunction("name", '');
      setFieldValueFunction("phone_number", '');
      setFieldValueFunction("date_of_birth", '');
      setFieldValueFunction("email", '');
      setFieldValueFunction("gender", genderSelection);
    }

  
  }, [])

  return (
    <React.Fragment>
      <Snackbar open={snackBarOpen && !!snackBarMessage} autoHideDuration={6000} onClose={handleSnackBar}>
        <Alert onClose={handleSnackBar} severity="error">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Card classes={"card card-shadow"}>
        <div className="card-header-wrap d-flex">
          <div className="text-wrap">
            <h3 className="font-family-bold black-color">Personal Details</h3>
          </div>
          <div className="btn-wrap">
            <IconButton
              size="small"
              onClick={props.onClose}
            >
              <img alt="Cancel" src={Cancel}/>
            </IconButton>
          </div>
        </div>
        <Formik
        initialValues={{
          name: "",
          date_of_birth: "",
          gender: "",
          email: "",
          phone_number: ""
        }}
        validate={(values) => {
          const errors = {};
          if(!values.name) {
            errors.name = "Required";
          } 
          if(!values.date_of_birth) {
            errors.date_of_birth = "Required";
          } 
          if(!values.gender) {
            errors.gender = "Required";
          } 
          if(!values.email) {
            errors.email = "Required";
          } else if(!EMAIL_REGEX.test(values.email)) {
            errors.email = "Enter valid email"
          }
          if(!values.phone_number) {
            errors.phone_number = "Required";
          } else if(!PHONE_NUMBER_REGEX.test(values.phone_number)) {
            errors.phone_number = "Enter valid phone number"
          }
          return errors;
        }}
        onSubmit={(values) => {
          submitHandle(values);
        }}
      >
        {({handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched}) => (
          <React.Fragment>
            {
              handleSelectFieldObject(setFieldValue)
            }
            <div className="card-content-wrap">
              <div className="form-wrap">
                <div className="form-row">
                  <TextField 
                    id="name" 
                    label="Name" 
                    variant="outlined" 
                    required={true}
                    size="large"
                    value={values.name}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    onBlur={(event)=> {
                      handleBlur(event);
                    }}
                    error={
                      Boolean(errors.name) && touched.name && Boolean(errors.name)
                    }
                    helperText={
                      errors.name && touched.name && errors.name
                    }
                  />
                </div>
                <div className="form-row">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                      label="Date of Birth *"
                      format="YYYY-MM-DD"
                      value={values.date_of_birth}
                      onChange={(event, val)=>{
                        setFieldValueFunction("date_of_birth", dayjs(event).format('YYYY-MM-DD'))
                      }}
                      onBlur={(event)=> {
                        handleBlur(event);
                      }}
                        minDate={minDate}
                        maxDate={maxDate}
                    />
                  </LocalizationProvider>
                </div>
                <div className="form-row">
                  <div className="d-flex align-items-center">
                    <div className="text-wrap mr-4">
                      <p className="caption black-color font-family-thin">Gender</p>
                    </div>
                    <div className="toggle-btn-wrap toggle-seperate-btn toogle-btn-small">
                      <ToggleButtonGroup
                        color="primary"
                        value={genderSelection}
                        exclusive
                        onChange={handleGenderSelection}
                      >
                        <ToggleButton value="Male" className="btn btn-black btn-outline">Male</ToggleButton>
                        <ToggleButton value="Female" className="btn btn-outline">Female</ToggleButton>
                        <ToggleButton value="Others" className="btn btn-outline">Others</ToggleButton>
                      </ToggleButtonGroup>
                    </div>
                  </div>
                </div>
                <div className="form-row">
                  <TextField 
                    id="email" 
                    label="Email ID" 
                    variant="outlined" 
                    required={true}
                    size="large"
                    value={values.email}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    onBlur={(event)=> {
                      handleBlur(event);
                    }}
                    error={
                      Boolean(errors.email) && touched.email && Boolean(errors.email)
                    }
                    helperText={
                      errors.email && touched.email && errors.email
                    }
                  />
                </div>
                <div className="form-row">
                  <TextField 
                    id="phone_number" 
                    label="Mobile Number" 
                    variant="outlined" 
                    required={true}
                    size="large"
                    type="tel"
                    value={values.phone_number}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    onBlur={(event)=> {
                      handleBlur(event);
                    }}
                    error={
                      Boolean(errors.phone_number) && touched.phone_number && Boolean(errors.phone_number)
                    }
                    helperText={
                      errors.phone_number && touched.phone_number && errors.phone_number
                    }
                    InputProps={{
                      startAdornment: (
                        <div className="mobile-country-wrap">
                          <img alt="India" src={India}/>
                          +91 <KeyboardArrowDownIcon/>
                        </div>
                      ),
                      endAdornment: (
                        <div className="field-verified-wrap">
                          <img alt="Verified" src={Verified}/>
                          Verified
                        </div>
                      )
                    }}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="card-footer-wrap card-btn-end-wrap">
              <div className="btn-wrap">
                <Btn
                  label="Cancel"
                  variant="text"
                  className="btn btn-black font-family-medium"
                  size="large"
                  onClick={props.onClose}
                />
                <Btn
                  label="Save"
                  variant="contained"
                  className="btn btn-primary font-family-medium"
                  size="large"
                  onClick={() => handleSubmit()}
                  disabled={isFormSubmit}
                  loading={isFormSubmit}
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </Formik>
      </Card>
    </React.Fragment>
  )
}

export default PersonalDetailsForm;
