import { EMPTY_ARRAY } from "../../../constants/app";

const computeRewardsEarned = (referral) => {
  return +referral.referral_amount || 0;
};

const calculateTotalRewards = (referrals) => {
  return referrals.reduce((total, referral) => total + computeRewardsEarned(referral), 0);
};

const formatRewardsText = (rewardsInRupees) => {
  return `You have won Amazon vouchers worth ₹${rewardsInRupees}`;
};

const getReferralRewards = (myReferrals = EMPTY_ARRAY) => {
  const totalRewardsEarned = calculateTotalRewards(myReferrals);

  if (!totalRewardsEarned) {
    return {
      showReferralRewards: false,
      referralRewardText: "",
    };
  }

  const referralRewardText = formatRewardsText(totalRewardsEarned);
  return {
    showReferralRewards: true,
    referralRewardText,
  };
};

export default getReferralRewards;
