import React from "react";

import Header from "./molecules/Header";
// import Footer from "./molecules/footer";

import DisclaimersItems from "./molecules/disclaimersItems";
import StickyCTA from "../../shared/components/stickyCTA/StickyCTA";

const Disclaimers = () => {
  return (
    <div>
      <Header />
      <DisclaimersItems />
      {/* <Footer /> */}
      <StickyCTA />
    </div>
  );
};

export default Disclaimers;
