
import React, { useContext, useEffect, useState } from "react";
import { Alert, Chip, Snackbar } from "@mui/material";

import Card from "../Card";
import { GetQuoteContext } from "../../../modules/common-context";

import { Btn } from "../../../components/index"
import { Edit, Home } from "../../../assets/images/index";

import QuoteHeader from "../quote-header";
import OtpForm from "./otp-form";

import phoneNumberMask from "../../../shared/utils/phone-number-mask";
import ApiServices from "../../../services/api";
import { API_END_POINT } from "../constants/api-const";
import StickyCTA from "../../../shared/components/stickyCTA/StickyCTA";
import useNavigateToUrl from "../../../shared/hooks/useNavigateToUrl";
import getQuoteLoginFlowReaders from "../readers/login";

const Otp = () => {

  const navigateToUrl = useNavigateToUrl();


  const {dataPayload, setCurrView, setDataPayload} = useContext(GetQuoteContext);
  const resendOtpCounter = 30;
  const [timer, setTimer] = useState(resendOtpCounter);
  const [isResending, setIsResending] = useState(true);
  const [resendTrigger, isResendTrigger] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [isFormSubmit, setIsFormSubmit] = useState(false);

  const backTo = () => {
    navigateToUrl('/');
    window.dataLayer.push({'event':'otp_back_to_home'});
  }
  const backToLogin = () => {
    setCurrView("LOGIN")
  }


  // handleSnackBar
  const handleSnackBar = () => {
    setSnackBarOpen(false);
  }

  // Function to decrement the timer every second
  useEffect(() => {
    let intervalId;

    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }

    if(timer === 0) {
      setIsResending(false)
    }

    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, [timer]);

   // Convert the timer value to display minutes and seconds
   const displayTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  // call resend otp
  const resendOtpHandle = async()=>{
    setIsResending(true);
    setTimer(resendOtpCounter);
    setIsFormSubmit(true);
    isResendTrigger(true);
    
    try {
      const data = {
        name: dataPayload?.name,
        phone_number: dataPayload?.phone_number,
        lead_id: dataPayload?.lead_id
      }
      const res = await ApiServices.post(API_END_POINT.SEND_OTP, data);
      if (res) {
        const token = getQuoteLoginFlowReaders.token(res.data);
        setDataPayload({
          ...dataPayload,
          token
        });
        setIsFormSubmit(false);
      }
    } catch (error) {
      const _error = error.response
      setSnackBarOpen(true);
      setSnackBarMessage(_error?.data?.message);
      setIsFormSubmit(false);
    }
  }


  return (
    <React.Fragment>
      <QuoteHeader
        btn={
          <Btn
            className="btn-white font-family-regular"
            label="Back to Home"
            size="small"
            startIcon={<img src={Home} alt="Home" />}
            onClick={()=> {backTo()}}
          />
        }
      />
      <Snackbar open={snackBarOpen && !!snackBarMessage} autoHideDuration={6000} onClose={handleSnackBar}>
        <Alert onClose={handleSnackBar} severity="error">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <div className="layout-conatiner"> 
        <div className="small-container auth-wrap">
          <Card classes={"card card-large card-shadow"}>
            <div className="card-header-wrap">
              <Chip 
                label="Otp"
                className="chip chip-orange letter-space-2 font-family-regular text-uppercase"
                size="medium"
              ></Chip>
              <div className="mt-2 mb-2 text-wrap">
                <h1 className="font-family-bold black-color">One Time Password</h1>
              </div>
              <div className="auth-timer-wrap">
                <div className="auth-mobile-edit">
                  <div className="text-wrap">
                    <p className="black-color font-family-medium">+91-{phoneNumberMask(dataPayload?.phone_number)}</p>
                  </div>
                  <div className="btn-wrap">
                    <Btn
                      label="Edit"
                      variant="text"
                      className="btn btn-black font-family-medium"
                      size="medium"
                      startIcon={<img src={Edit} alt="Edit" />}
                      onClick={() => {backToLogin()}}
                    />
                  </div>
                </div>
                <div className="text-wrap">
                  <p className="caption black-color font-family-light">{displayTime()}</p>
                </div>
              </div>
            </div>
            <div className="card-content-wrap">
              <OtpForm
                resendTrigger={resendTrigger}
              ></OtpForm>
            </div>
            <div className={`card-footer-wrap dotted-border ${!isResending ? '' : 'visibility-none'}`}>
              <div className="auth-footer-wrap">
                <div className="text-wrap">
                  <p className="caption dark-grey-color font-family-medium opacity-5">Didn’t recieve the verification code?</p>
                </div>
                <div className="btn-wrap">
                  <Btn
                    label="Resend"
                    variant="text"
                    className="btn btn-black font-family-medium"
                    size="medium"
                    disabled={isResending}
                    onClick={()=>{resendOtpHandle()}}
                    loading={isFormSubmit}
                  />
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <StickyCTA 
        onClick={()=>{
          window.dataLayer.push({'event': 'get-quote_OTPVerification_Whatsapp'});
          window.webengage.track("get-quote_OTPVerification_Whatsapp");
        }}
      />
    </React.Fragment>
  )
}

export default Otp;
