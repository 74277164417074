import React from "react";
import HeroSection from "./molecules/heroSection";
import ContactAddress from "./molecules/ContactAddress";
import HighLightSectionName from "../../shared/components/highLightSectionName";
import Heading from "../../components/atoms/heading";

import styles from "./contactUs.module.scss";
import StickyCTA from "../../shared/components/stickyCTA/StickyCTA";

const ContactUs = () => {
  return (
    <div className="body-container">
      <div className={styles.container}>
        <HeroSection />
        <div className={styles.contentWrapper}>
          <HighLightSectionName
            className={styles.highLightHeading}
            text="CONTACT US"
          />
          <Heading className={styles.heading} text="We are here to Help" />
          <p className={styles.description}>
            With our dedicated support for you always.
          </p>
          <ContactAddress />
          <StickyCTA />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
