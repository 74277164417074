import { API_END_POINT } from '../components/getQuotes/constants/api-const';
import ApiServices from '../services/api';
import updateReferralInApplicationContext from '../shared/helpers/updateReferralInApplicationContext';
import getDataFromResponse from '../shared/utils/getDataFromResponse';

const fetchUserReferrals = async () => ApiServices.get(API_END_POINT.USER_REFERRAL);
// const fetchUserReferrals = async () => {
//   return {
//     data: {
//       "status": "Success",
//       "myReferrals": [
//         { "rewards": 1000 },
//         { "rewards": 2000 },
//         { "rewards": 3000 }
//       ]
//     }
//   };
// };

const onSuccess = (params, userReferralResponse) => {
  const { applicationContext } = params;
  const myReferrals = getDataFromResponse(userReferralResponse)?.referrals || [];
  updateReferralInApplicationContext(myReferrals, applicationContext);
};

const onFailure = (_params, _err) => {
  // DO NOTHING
  return undefined;
};

const onSettled = (params, settledPromise) => {
  const {
    status,
    value,
    reason
  } = settledPromise;
  if (status === 'fulfilled') return onSuccess(params, value);
  if (status === 'rejected') return onFailure(params, reason);
  return undefined;
}

const userReferralConfig = {
  request: fetchUserReferrals,
  onSettled,
};

export default userReferralConfig;
