import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./highLightSectionName.module.scss";

const HighLightSectionName = ({ text, className }) => {
  return <div className={cx(styles.container, className)}>{text}</div>;
};

HighLightSectionName.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

HighLightSectionName.defaultProps = {
  text: "",
  className: "",
};

export default HighLightSectionName;
