import { useCallback, useEffect, useState } from "react";

import  {fetchCompanies}  from "../employment-details/services/employmentDetails.general";
import { makeCompanyOptions } from "../employment-details/helpers/employeeDetails.general";


import debounce from "../../../shared/utils/debounce";

const INPUT_DELAY = 300;

const useCompanies = (dataPayload) => {
  const [companyName, setCompanyName] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [companies, setCompanies] = useState([]);

  const handleFetchCompanies = useCallback(async (userInput) => {

    
    try {
      setIsFetching(true);
      const fetchedCompanies = await fetchCompanies(userInput, dataPayload);
      const formattedCompaniesOptions = makeCompanyOptions(fetchedCompanies);
      setCompanies(formattedCompaniesOptions);
    } catch (err) {
      return undefined;
    } finally {
      setIsFetching(false);
    }
  }, [setIsFetching, setCompanies, dataPayload]);

  useEffect(() => {
    handleFetchCompanies();
  }, [handleFetchCompanies]);

  const onCompanyInputChange = useCallback(
    debounce(async (_e, userInput) => {
      try {
        setCompanyName(_e);
        handleFetchCompanies(_e);
      } catch (err) {
        setCompanies([]);
      } finally {
        setIsFetching(false);
      }
    }, INPUT_DELAY),
    [setCompanyName]
  );

  const onCompanySelect = useCallback((_e, selectedOption) => {
    setCompanyName(selectedOption);
  }, []);

  return {
    companyName,
    companies,
    isFetching,
    onCompanyInputChange,
    onCompanySelect,
  };
};

export default useCompanies;
