import React from "react";

import Link from "../../link";

import whatsAppSvg from "../../../assets/images/social/whatsapp.svg";

import styles from "./stickyCTA.module.scss";

const StickyCTA = (props) => {
  const handleClick = (ev) => {
    ev?.stopPropagation();
    props?.onClick?.();
  }
  return (
    <Link className={styles.wrapper} to="https://wa.me/917795178080?text=Hi" target="_blank" disableParamsForwarding onClick={handleClick}>
      <div className={styles.container}>
        <img className={styles.image} src={whatsAppSvg} alt="social-share" />
      </div>
    </Link>
  );
};

export default StickyCTA;
