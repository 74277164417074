import React, { useCallback, useEffect, useState } from "react";

import "./index.scss";
import { Loader } from "../../../components";
import ApiServices from "../../../services/api";
import { API_END_POINT } from "../../../components/getQuotes/constants/api-const";
import getPolicyAndStatus from "../../../shared/components/policyWidget/helpers/getPolicyAndStatus";
import ViewPlanV1 from "./view-plan-v1";
import ViewPlanV2 from "./view-plan-v2";
import UpgradePlan from "./upgrade-plan";

const ViewPlans = (props) => {
    const [isLoading, setIsloading] = useState(false);
    const [policyStatus, setPolicyStatus] = useState("");
    const [protectionPlan, setProtectionPlan] = useState([]);
    const [purchasedPolicy, setPurchasedPolicy] = useState({});
    const [viewPlanVersion, setViewPlanVersion] = useState("");
    const [showUpgradePlan, setShowUpgradePlan] = useState(false);

    const getProtectionPlan = async () => {
        try {
            let response = await ApiServices.get(API_END_POINT.USER_DASHBOARD_GET_OUR_PROTECTION_PLAN);
            if (response.status === 200) {
                const protectionPlan = response?.data?.protectionPlan;
                setProtectionPlan(protectionPlan);
                setIsloading(false);
            } else {
                setProtectionPlan([]);
                setIsloading(false);
            }
        } catch (error) {}
    };

    const handlePurchsePlanSelection = useCallback(() => {
        try {
            const { status: policyStatus, order: policyOrder } = getPolicyAndStatus(protectionPlan);
            setPolicyStatus(policyStatus);
            setPurchasedPolicy(policyOrder);
            setViewPlanVersion(policyOrder?.order_version);
            if (policyOrder?.user_plan_proposal?.plans?.ref_code === "PLAN_1" || policyOrder?.protection_plan_id !== null) {
                setShowUpgradePlan(false);
            } else {
                setShowUpgradePlan(true);
            }
        } catch (error) {}
    }, [protectionPlan]);

    useEffect(() => {
        setIsloading(true);
        getProtectionPlan();
    }, []);

    useEffect(() => {
        handlePurchsePlanSelection();
    }, [handlePurchsePlanSelection, protectionPlan]);

    return (
        <React.Fragment>
            <Loader open={isLoading} />
            <div className="w-100 dashboard-plan-master">
                {
                    // * viewPlanVersion v1: For Existing Customer/User
                    viewPlanVersion === "v1" ? (
                        <ViewPlanV1 protectionPlan={protectionPlan} purchasedPolicy={purchasedPolicy} policyStatus={policyStatus} viewPlanVersion={viewPlanVersion} />
                    ) : (
                        <ViewPlanV2 protectionPlan={protectionPlan} purchasedPolicy={purchasedPolicy} policyStatus={policyStatus} viewPlanVersion={viewPlanVersion} />
                    )
                }
                {showUpgradePlan === true && <UpgradePlan />}
            </div>
        </React.Fragment>
    );
};

export default ViewPlans;
