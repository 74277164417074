import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../../../shared/constants/app";

const makeWorkplaceOption = (workplace = EMPTY_OBJECT) => {
  const { id, locality, district, city, pincode, stateId, state, stateCode } = workplace;
  return {
    id,
    label: locality,
    district, city, pincode, stateId, state, stateCode
  };
};

export const makeWorkplaceOptions = (workplaceOptions = EMPTY_ARRAY) => {
  return workplaceOptions?.map(makeWorkplaceOption);
};
