import React from "react";

import Heading from "../../../../components/atoms/heading";
import HighLightSectionName from "../../../../shared/components/highLightSectionName";

import styles from "./header.module.scss";

const Header = () => {
  return (
    <div className={styles.container}>
      <HighLightSectionName text="DISCLAIMERS" />
      <Heading className={styles.heading} text="Upsolv Disclosures" />
    </div>
  );
};

export default Header;
