import React, { useCallback, useEffect, useState } from "react";

import "../index.scss";
import { PLAN_COLOR } from "../../../../constants/planColor";
import { Btn, CapsuleTag, Loader } from "../../../../components";
import { CircleTick, Download, InfoCircle } from "../../../../assets/images";
import { CalendarIcon } from "@mui/x-date-pickers";
import { IconButton } from "@mui/material";
import PlanBreakupDialog from "../../../../components/getQuotes/plan-details-v2/plan-detail-breakup";
import PlanSlider from "../plan-slider";
import ApiServices from "../../../../services/api";
import { API_END_POINT } from "../../../../components/getQuotes/constants/api-const";
import moment from "moment/moment";
import { PAISE } from "../../../../shared/constants/app";
import amountWithCommas from "../../../../shared/utils/amount-seperator";
import { BENEFITS_SLIDER } from "../../../../constants/benefits";
import POLICY_STATUS from "../../../../shared/components/policyWidget/constants/policyStatus";
import useDownloadPolicy from "../../../../shared/components/policyWidget/molecules/purchasedPolicy/hooks/useDownloadPolicy";

const ViewPlanV2 = (props) => {
    const [isLoading, setIsloading] = useState(true);
    const [showPriceBreakUpModal, priceBreakupToggleModal] = useState(false);
    const [planBenefits, setPlanBenefits] = useState([]);
    const [protectionPlan, setProtectionPlan] = useState([]);

    const [policyStatus, setPolicyStatus] = useState("");
    const [purchasedPolicy, setPurchasedPolicy] = useState({});

    const [planSelection, setPlanSelection] = useState(""); // * return plan-name
    const [priceBreakGrossAmount, setPriceBreakGrossAmount] = useState(null);
    const [priceBreakNetAmount, setPriceBreakNetAmount] = useState(null);
    const [priceBreakTax, setPriceBreakTax] = useState(null);
    const [extraBenefitsPrice, setExtraBenefitsPrice] = useState(null);
    const [priceGrossBenefits, setPriceGrossBenefits] = useState(null);
    const [protectionAmount, setProtectionAmount] = useState(null);

    const [planSliderData, setPlanSliderData] = useState([]);

    const [isDownloadPolicyLink, handleDownloadPolicyLink] = useDownloadPolicy(purchasedPolicy);

    // handle price breakup
    const onClosePriceBreakupModal = () => {
        priceBreakupToggleModal(false);
    };

    const handlePriceBreakupModal = () => {
        priceBreakupToggleModal(true);
    };

    const handlePriceBreakup = (data) => {
        setPlanSelection(data?.user_plan_proposal?.plans?.name);
        const NumberOfMonthForProtectionAmount = 3;
        let priceBreakGrossAmount = (data?.premium?.grossAmount / PAISE).toFixed(0);
        let priceBreakNetAmount = (data?.premium?.netAmount / PAISE).toFixed(0);
        let priceBreakTax = (data?.premium?.tax / PAISE).toFixed(0);
        let extraBenefitsCharge = (data?.premium?.extraBenefitsCharge / PAISE).toFixed(0);
        let priceGrossBenefits = ((data?.premium?.netAmount - data?.premium?.extraBenefitsCharge) / PAISE).toFixed(0);
        // let protectionAmount = ((data?.user_plan_proposal?.proposals?.protection_amount / PAISE) / NumberOfMonthForProtectionAmount).toFixed(0);
        let protectionAmount = Math.round(data?.user_plan_proposal?.proposals?.protection_amount / PAISE).toFixed(0);

        setPriceBreakGrossAmount(amountWithCommas(priceBreakGrossAmount));
        setPriceBreakNetAmount(amountWithCommas(priceBreakNetAmount));
        setPriceBreakTax(amountWithCommas(priceBreakTax));
        setExtraBenefitsPrice(amountWithCommas(extraBenefitsCharge));
        setPriceGrossBenefits(amountWithCommas(priceGrossBenefits));
        setProtectionAmount(amountWithCommas(protectionAmount));
    };

    const getBenefit = async () => {
        try {
            let response = await ApiServices.get(API_END_POINT.GET_BENEFITS);
            if (response.status === 200) {
                setPlanBenefits(response?.data?.benefits);
            } else {
                setPlanBenefits([]);
            }
        } catch (error) {
            console.log("getBenefit.Error", error);
        }
    };

    const planBenefitsCircleTickDashView = (benefitId, benefitName, benefitRefCode) => {
        const planBenefitArr = purchasedPolicy?.user_plan_proposal?.plans?.benefit_ids || [];
        const result = planBenefitArr.includes(benefitId);
        if (result) {
            return (
                <div className="benefits-list text-wrap px-0">
                    <img src={CircleTick} alt="benefits-include" className="benefits-list-img"></img>
                    <p className="subtitle1 black-color font-familt-regular">
                        {benefitName !== "Unemployment Allowance" ? (
                            benefitName
                        ) : (
                            <span className="benefits-top-priority">
                                Max unemployment allowance upto ₹{protectionAmount}
                                <span className="benefits-list-breakout mt-2">
                                    <span>
                                        <span className="bullet">1</span> <span>50% on 45 days of unemployment</span>
                                    </span>
                                    <span>
                                        <span className="bullet">2</span> <span>50% on 90 days of unemployment</span>
                                    </span>
                                </span>
                            </span>
                        )}
                    </p>
                </div>
            );
        } else {
            return null;
        }
    };

    const formatPlanBenefitData = useCallback(() => {
        const formatedPlanBenefit = [];
        planBenefits.forEach((item) => {
            if (purchasedPolicy?.user_plan_proposal?.plans?.benefit_ids?.includes(item.id)) {
                const icon = BENEFITS_SLIDER.find((obj) => obj.name === item.name);
                item["title"] = item?.name;
                item["image"] = icon?.image;

                formatedPlanBenefit.push(item);
            }
        });

        setPlanSliderData(formatedPlanBenefit);
    }, [planBenefits, purchasedPolicy?.user_plan_proposal?.plans?.benefit_ids]);

    useEffect(() => {
        setIsloading(true);
        getBenefit();
    }, []);

    useEffect(() => {
        setProtectionPlan(props.protectionPlan);
        setIsloading(false);
    }, [props.protectionPlan]);

    useEffect(() => {
        setPolicyStatus(props?.policyStatus);
        setPurchasedPolicy(props?.purchasedPolicy);
    }, [props?.policyStatus, props?.purchasedPolicy]);

    useEffect(() => {
        formatPlanBenefitData();
    }, [formatPlanBenefitData]);

    useEffect(() => {
        handlePriceBreakup(purchasedPolicy);
    }, [purchasedPolicy]);

    return (
        <React.Fragment>
            <Loader open={isLoading} />
            <div className="dashboard-page-wrap existing-plan-item">
                <div className="text-wrap mb-4">
                    <p className="subtitle1 black-color font-family-medium">My Plan</p>
                </div>
                <div className="dash-plan-card">
                    <div className={"plan-header-wrap " + (planSelection === "Iconic" ? "iconic-plan" : "")}>
                        <div className="plan-header-action-wrap">
                            <CapsuleTag
                                title="UP"
                                subtitle={purchasedPolicy?.user_plan_proposal?.plans?.name}
                                className={`${PLAN_COLOR[purchasedPolicy?.user_plan_proposal?.plans?.name.toUpperCase()]}`}
                            />
                            {policyStatus === POLICY_STATUS.PURCHASED && planSelection === "Iconic" && (
                                <div className="text-wrap text-center">
                                    <Btn
                                        label="Download Plan"
                                        size="medium"
                                        className="btn btn-green font-family-medium btn-download-action"
                                        startIcon={<img src={Download} alt="download-plan"></img>}
                                        loading={isDownloadPolicyLink}
                                        onClick={handleDownloadPolicyLink}
                                    ></Btn>
                                    {/* <p className="small-caption mt-2 download-plan-action-text">3 months unemployment allowance plan </p> */}
                                </div>
                            )}
                        </div>
                        {/* <div className="text-wrap mt-2">
                            <p className="subtitle1 black-color font-family-regular">{purchasedPolicy?.user_plan_proposal?.plans?.description}</p>
                        </div> */}
                    </div>
                    <div className="plan-body-footer-wrap">
                        <div className="plan-body-wrap">
                            <div className="row">
                                <div className="col col-12 col-lg-6">
                                    <div className="benefits-info-wrap p-0">
                                        {planBenefits.map((planBenefititem) => planBenefitsCircleTickDashView(planBenefititem.id, planBenefititem.name, planBenefititem.ref_code))}
                                    </div>
                                </div>
                                <div className="col col-12 col-lg-6">
                                    <div className="plan-slider-wrap">
                                        <PlanSlider viewPlanVersion={props.viewPlanVersion} sliderData={planSliderData} protectionAmount={protectionAmount} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="plan-footer-wrap">
                            <div className="plan-footer-action-wrap">
                                <div className="plan-date-details">
                                    <div className="text-wrap">
                                        <CalendarIcon />
                                        <p className="caption black-color font-family-regular">
                                            <span>
                                                Plan Starts on{" "}
                                                <span className="font-family-medium text-nowrap">{moment(purchasedPolicy?.plan_start_date).format("Do MMM YYYY")}</span>{" "}
                                            </span>
                                            <span className="">
                                                {" "}
                                                to Expire on <span className="font-family-medium text-nowrap">{moment(purchasedPolicy.plan_end_date).format("Do MMM YYYY")}</span>
                                            </span>
                                            <span className="d-block mt-2 ">
                                                {" "}
                                                Waiting period of 120 days starting from{" "}
                                                <span className="font-family-medium text-nowrap">{moment(purchasedPolicy?.plan_start_date).format("Do MMM YYYY")}</span>
                                            </span>
                                        </p>
                                    </div>
                                </div>
                                <div className="plan-price-details">
                                    <div className="text-wrap">
                                        <p className="caption font-family-medium black-color">
                                            <span className="subtitle1 text-nowrap">₹ {priceBreakNetAmount}</span> <br></br>
                                            <span className="opacity-6">/ year</span>
                                        </p>
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                handlePriceBreakupModal();
                                            }}
                                        >
                                            <img src={InfoCircle} alt="InfoCircle" className="info-icon" />
                                        </IconButton>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {policyStatus === POLICY_STATUS.PURCHASED && planSelection === "Iconic" && (
                    <div className="btn-wrap btn-download-action-mb">
                        <Btn
                            label="Download Plan"
                            size="medium"
                            className="btn btn-green font-family-medium"
                            startIcon={<img src={Download} alt="download-plan"></img>}
                            loading={isDownloadPolicyLink}
                            onClick={handleDownloadPolicyLink}
                        ></Btn>
                    </div>
                )}
                <PlanBreakupDialog
                    planName={planSelection}
                    extraBenefitsPrice={extraBenefitsPrice}
                    priceBreakGrossAmount={priceBreakGrossAmount}
                    priceBreakNetAmount={priceBreakNetAmount}
                    priceBreakTax={priceBreakTax}
                    priceGrossBenefits={priceGrossBenefits}
                    onClose={(val) => {
                        onClosePriceBreakupModal(val);
                    }}
                    open={showPriceBreakUpModal}
                />
            </div>
        </React.Fragment>
    );
};

export default ViewPlanV2;
