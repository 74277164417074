import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./infiniteHzAutoScroll.module.scss";

const InfiniteHzAutoScroll = ({ children, speed, className }) => {
  return (
    <div className={cx(styles.container, className)}>
      <div className={styles.wrapper}>
        <section style={{ "--speed": `${speed}ms` }} className={styles.section}>
          {children}
        </section>
        <section style={{ "--speed": `${speed}ms` }} className={styles.section}>
          {children}
        </section>
        <section style={{ "--speed": `${speed}ms` }} className={styles.section}>
          {children}
        </section>
      </div>
    </div>
  );
};

InfiniteHzAutoScroll.propTypes = {
  children: PropTypes.node,
  speed: PropTypes.number,
};

InfiniteHzAutoScroll.defaultProps = {
  speed: 30000,
};

export default InfiniteHzAutoScroll;
