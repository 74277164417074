import POLICY_STATUS from "../constants/policyStatus";

import ContinuePurchase from "../molecules/continuePurchase/ContinuePurchase";
import PurchasedPolicy from "../molecules/purchasedPolicy/PurchasedPolicy";

const POLICY_STATUS_VS_COMPONENT = {
  [POLICY_STATUS.PURCHASED]: PurchasedPolicy,
  [POLICY_STATUS.CONTINUE_PURCHASE]: ContinuePurchase,
};

export default POLICY_STATUS_VS_COMPONENT;
