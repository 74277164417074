import React from "react";

import RightTickDarkSvg from "../../../../../../assets/images/rightTickDark.svg";

import styles from "./plans.module.scss";

const plans = [
  "Do you Love problem-solving and making an impact in people's lives?",
  "Are you obsessed with customer centricity?",
  "If Yes - you are just a click away from us.",
];

const Plans = () => {
  return (
    <div className={styles.container}>
      {plans.map((plan, idx) => {
        return (
          <div key={`plans-${idx}`} className={styles.planContainer}>
            <img className={styles.icon} src={RightTickDarkSvg} alt="" />
            <div className={styles.plan}>{plan}</div>
          </div>
        );
      })}
    </div>
  );
};

export default Plans;
