import React from "react";

import Heading from "../../../../components/atoms/heading";

import styles from "./WrappingUp.module.scss";

import { ReactComponent as Mise } from "../../../../assets/images/mise.svg";
// import useNavigateToGetQuote from "../../../../shared/hooks/useNavigateToGetQuote";

import Link from "../../../../shared/link";
import { StartUpIndia } from "../../../../assets/images";
const WrappingUp = () => {

  // const navigateToGetQuote = useNavigateToGetQuote();

  // const handleGetQuoteCTA = (e) => {
  //   window.dataLayer.push({'event': 'HomePage_WhyJobsurance_GetQuote'});
  //   window.webengage.track("HomePage_WhyJobsurance_GetQuote");
  //   e?.stopPropagation();
  //   navigateToGetQuote();
  // }

  return (
    <div className="body-container">
      <div className={styles.container}>
        <div className="flex flex-wrap justify-content-center">
        <Heading
          className={styles.heading}
          text="We are recognized by"
        /> &nbsp;
        <span className="mt-2 mt-sm-0 ml-1"><img className="w-80 h-auto" src={StartUpIndia}/></span>
        </div>
        <div className={styles.textContainer}>
          {/* <span className={styles.text}>
            Certificate No: DIPP133136
          </span> */}
        </div>
        <div className={styles.getQuoteContainer}>
          <Link to="https://drive.google.com/file/d/1nn6BQ_PtusrmU8O1_Ztg-FOT1tna3RDI/view" target="_blank">
          <button className={styles.getQuoteCTA}>Check us out</button>
          </Link>
          <Mise className={styles.mise} />
        </div>
      </div>
    </div>
  );
};

export default WrappingUp;
