
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { Alert, IconButton, Snackbar } from "@mui/material";

import Card from "../../Card";
import { GetQuoteContext, UserContext } from "../../../../modules/common-context";

import { Btn } from "../../../../components/index"
import { Cancel, Next } from "../../../../assets/images/index";
import ApiServices from "../../../../services/api";
import { API_END_POINT } from "../../constants/api-const";
import { setCookie } from "../../../../shared/utils/cookie";
import APP_DATA_CONFIG from "../../../../helpers/app";
import useNavigateToUrl from "../../../../shared/hooks/useNavigateToUrl";
import { PAISE } from "../../../../shared/constants/app";

const DeclarationDetails = (props) => {
  const location = useLocation();
  const {dataPayload} = useContext(GetQuoteContext);
  const { user } = useContext(UserContext)
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [planUpgradeStatus, setPlanUpgradeStatus] = useState(false);
  const navigateToUrl = useNavigateToUrl();

   // handleSnackBar
   const handleSnackBar = () => {
    setSnackBarOpen(false);
  }

  const submitHandle = async() => {
    setIsFormSubmit(true);

    const storeUserDetails = dataPayload;
    const netAmount = (planUpgradeStatus === false) ? storeUserDetails?.selected_quote?.premium?.netAmount : storeUserDetails?.selected_quote?.planUpgradeAmount
    
    const payload = {
      ak_plan_id: storeUserDetails?.selected_quote?.ak_plan_id,
      ak_benefits: storeUserDetails?.selected_quote?.ak_benefits,
      ak_exclusions: storeUserDetails?.selected_quote?.ak_exclusions,
      ak_premium: storeUserDetails?.selected_quote?.ak_premium,
      plan_id: storeUserDetails?.selected_quote?.id,
      plan_name: storeUserDetails?.selected_quote?.name,
      netAmount: netAmount,
      name: storeUserDetails?.name,
      phone_number: storeUserDetails?.phone_number,
      salary: storeUserDetails?.salary,
      gender: storeUserDetails?.gender,
      dob: storeUserDetails?.date_of_birth,
      email: storeUserDetails?.email,
      pan: storeUserDetails?.pan,
      communication_address: storeUserDetails?.address,
      communication_pincode: storeUserDetails?.pincode,
      communication_state: storeUserDetails?.state_code,
      company_id: storeUserDetails?.company_id,
      company_pincode_id: storeUserDetails?.company_pincode_id,
      date_of_joining: storeUserDetails?.date_of_joining,
      lead_id: storeUserDetails?.lead_id,
      plan_type: storeUserDetails?.selected_quote?.name, // FOR LEAD UPDATE
      plan_cost: (netAmount/PAISE), // FOR LEAD UPDATE
    }

    const referralCodeInGetQuoteContext = user.applied_referral_code;
    if(referralCodeInGetQuoteContext){
      payload.referral_code = referralCodeInGetQuoteContext;
    }

    try {
      const res = await ApiServices.post(API_END_POINT.GET_PROPOSAL, payload);

      if(res.data.status === "Success"){

        // added for page on hold and update currentView on check of page_on_hold
        // setCurrView("PLAN_ON_HOLD");
        navigateToUrl("/get-quote-declaration")
        // added for page on hold and update currentView 

        // initiatePayment(res.data?.order_id);
      }

    } catch (error) {
      const _error = error.response
      setSnackBarOpen(true);
      setSnackBarMessage(_error?.data?.message);
      setIsFormSubmit(false);

      // added for page on hold and update currentView on check of page_on_hold
      // setCurrView("PLAN_ON_HOLD");
      // navigateToUrl("/get-quote-declaration")
      // added for page on hold and update currentView 
    }
  }

  const initiatePayment = async (val) => {
    try {
      const lead_id = dataPayload?.lead_id;

      const payload = {
        order_id: val,
        lead_id
      }
      APP_DATA_CONFIG.GENERATE_LEAD.request("get-quote_Declaration_Continue", lead_id)
      const res = await ApiServices.post(API_END_POINT.INITIATE_PAYMENT, payload);
      if(res.data.status === "Success"){
        setCookie('js_order_id',val);
        
        setTimeout(()=> {
          props.onClose();
          setIsFormSubmit(false);
          window.dataLayer.push({'event':'get-quote_Declaration_Continue'});
          window.webengage.track("get-quote_Declaration_Continue", {
            order_id: val,
            lead_id
          });
          
          window.webengage.user.setAttribute("get-quote_Declaration_Continue", {
            order_id: val,
            lead_id
          });

          window.open(res.data?.payment_details?.url, "_self");
        }, 200);
      }

    } catch (error) {
      const _error = error.response
      setSnackBarOpen(true);
      setSnackBarMessage(_error?.data?.message);
      setIsFormSubmit(false);
      props.onClose();
    }
  }

  useEffect(()=>{
    if(location.state !== null){
      setPlanUpgradeStatus(location.state.upgradePlan);
    }
  }, [location.state])

  return (
    <React.Fragment>
      <Snackbar open={snackBarOpen && !!snackBarMessage} autoHideDuration={6000} onClose={handleSnackBar}>
        <Alert onClose={handleSnackBar} severity="error">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Card classes={"card card-shadow"}>
        <div className="card-header-wrap d-flex">
          <div className="text-wrap">
            <h3 className="font-family-bold black-color">Declaration</h3>
            <p className="caption font-family-regular black-color opacity-6">I hereby declare that all the information provided by me is true and accurate to my knowledge:</p>
          </div>
          <div className="btn-wrap">
            <IconButton
              size="small"
              onClick={props.onClose}
            >
              <img src={Cancel} alt="Cancel" />
            </IconButton>
          </div>
        </div>
        <div className="card-content-wrap">
          <div className="flex-wrap card-highlight-wrap card-highlight-left">
            <div className="mb-3 text-wrap d-flex">
              <p className="mr-3 caption font-family-medium black-color">1</p>
              <p className="caption font-family-thin black-color">I am currently not under any performance improvement plan (PIP) or any similar plan with my employer</p>
            </div>
            <div className="mb-3 text-wrap d-flex">
              <p className="mr-3 caption font-family-medium black-color">2</p>
              <p className="caption font-family-thin black-color">I am currently not on my notice period or have been notified of any breach of the terms of my employment</p>
            </div>
            <div className="mb-3 text-wrap d-flex">
              <p className="mr-3 caption font-family-medium black-color">3</p>
              <p className="caption font-family-thin black-color">I am currently not aware of any situations relating to me or my employer that could result in any lay-off or loss of employment</p>
            </div>
            <div className="mb-3 text-wrap d-flex">
              <p className="mr-3 caption font-family-medium black-color">4</p>
              <p className="caption font-family-thin black-color">I am aware that to raise a claim, I need to have completed at least 180 days in my current organisation</p>
            </div>
            <div className="text-wrap d-flex">
              <p className="mr-3 caption font-family-medium black-color">5</p>
              <p className="caption font-family-thin black-color">I acknowledge that in order to raise a claim, I must complete a waiting period of 120 days from the date of purchase</p>
            </div>
          </div>
        </div>
        <div className="card-footer-wrap card-btn-end-wrap">
          <div className="btn-wrap">
            <Btn
              label="Continue"
              variant="contained"
              className="btn btn-primary font-family-medium"
              endIcon={<img src={Next} alt="Next" />}
              size="large"
              onClick={()=>{submitHandle()}}
              disabled={isFormSubmit}
              loading={isFormSubmit}
            />
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}

export default DeclarationDetails;
