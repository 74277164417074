/*
TO DO
 - move existingUser logic to src/pages/get-quote/index.js
 - move loader logic to src/pages/get-quote/index.js
 - move generateLead logic to src/pages/get-quote/index.js
 - separate generateLead info and user info
*/

import React, { useState } from "react";
import { Alert, Chip, Snackbar } from "@mui/material";

import Card from "../Card";

import { Btn, Loader } from "../../../components/index";
import { Home } from "../../../assets/images/index";

import QuoteHeader from "../quote-header";
import LoginForm from "./login-form";
import useNavigateToUrl from "../../../shared/hooks/useNavigateToUrl";

window.dataLayer = window.dataLayer || [];

const Login = () => {
  const navigateToUrl = useNavigateToUrl();

  const [isLoading] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage] = useState("");

  const backTo = () => {
    navigateToUrl("/");
    window.dataLayer.push({'event': 'login_back_to_home'});
    window.webengage.track("login_back_to_home");
  }

  const handleSnackBar = () => {
    setSnackBarOpen(false);
  };

  return (
    <React.Fragment>
      <QuoteHeader
        btn={
          <Btn
            className="btn-white font-family-regular"
            label="Back to Home"
            size="small"
            startIcon={<img src={Home} alt="home" />}
            onClick={() => backTo()}
          />
        }
      />
      <Loader open={isLoading} label="Loading..." />
      <Snackbar
        open={snackBarOpen && !!snackBarMessage}
        autoHideDuration={6000}
        onClose={handleSnackBar}
      >
        <Alert onClose={handleSnackBar} severity="error">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      {!isLoading && (
        <div className="layout-conatiner">
          <div className="small-container auth-wrap">
            <Card classes={"card card-large card-shadow"}>
              <div className="card-header-wrap">
                <Chip
                  label="Basic Details"
                  className="chip chip-orange letter-space-2 font-family-regular text-uppercase"
                  size="medium"
                ></Chip>
                <div className="mt-2 text-wrap">
                  <h1 className="font-family-bold black-color">
                    Let's get started
                  </h1>
                </div>
              </div>
              <div className="card-content-wrap">
                <LoginForm></LoginForm>
              </div>
            </Card>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default Login;
