import React from "react";

import Heading from "../../../../components/atoms/heading";
import Card from "../../../../components/molecules/Card";
import HighlightSectionName from "../../../../shared/components/highLightSectionName";

import rightTickImg from "../../../../assets/images/rightTick.svg";

import styles from "./benefits.module.scss";
import { Mentor_Benefits_1, Mentor_Benefits_2, Mentor_Benefits_3, Mentor_Benefits_4 } from "../../../../assets/images";

const Benefits = (props) => {
  return (
    <div className="body-container">
      <div className={styles.container}>
        <div className={styles.highlightSectionWrapper}>
          <HighlightSectionName text="MENTORSHIP" />
        </div>
        <Heading
          text="Benefits of Mentorships"
          className={`mb-4 ${styles.heading}`}
        />
        {/* <p className={styles.subHeading}></p> */}
        <div className={styles.benefitItems}>
          <Card className={styles.card}>
            <Card.Image src={Mentor_Benefits_1} className={styles.cardImage} />
            <div className={styles.cardContentWrapper}>
              <Card.Text text="1:1 Mentoring Sessions with Expert" className={`${styles.cardText} font-family-medium`} />
            </div>
          </Card>
          <Card className={styles.card}>
            <Card.Image src={Mentor_Benefits_2} className={styles.cardImage} />
            <div className={styles.cardContentWrapper}>
              <Card.Text
                text="Get Expert Advice"
                className={`${styles.cardText} font-family-medium`}
              />
            </div>
          </Card>
          <Card className={styles.card}>
            <Card.Image src={Mentor_Benefits_3} className={styles.cardImage} />
            <div className={styles.cardContentWrapper}>
              <Card.Text
                text="Right Mentor for your Success"
                className={`${styles.cardText} font-family-medium`}
              />
            </div>
          </Card>
          <Card className={styles.card}>
            <Card.Image src={Mentor_Benefits_4} className={styles.cardImage} />
            <div className={styles.cardContentWrapper}>
              <Card.Text
                text="Become confident with right help"
                className={`${styles.cardText} font-family-medium`}
              />
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
