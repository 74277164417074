import { useCallback, useEffect, useState } from "react";

import { makeWorkplaceOptions } from "../helpers/employmentDetails.general";

import debounce from "../../../../../shared/utils/debounce";
import { fetchWorkplaceLocation } from "../../services/planConfirmation.employmentDetails";

const INPUT_DELAY = 300;

const useFetchWorkLocation = () => {
  const [locationInput, setLocationInput] = useState();
  const [isFetching, setIsFetching] = useState(false);
  const [locations, setLocations] = useState([]);

  const handleFetchLocations = async (userInput) => {
    try {
      setIsFetching(true);
      const fetchedLocations = await fetchWorkplaceLocation(userInput);
      const formattedLocations = makeWorkplaceOptions(fetchedLocations);
      setLocations(formattedLocations);
    } catch (err) {
      setLocations([]);
    } finally {
      setIsFetching(false);
    }
  };

  useEffect(() => {
    handleFetchLocations();
  }, []);

  const onLocationOptionSelect = useCallback((_e, selectedOption) => {
    setLocationInput(selectedOption);
  }, []);

  const onLocationInputChange = useCallback(
    debounce(async (_e, userInput) => {
      try {
        setLocationInput(_e);
        handleFetchLocations(_e);
      } catch (err) {
        setLocations([]);
      } finally {
        setIsFetching(false);
      }
    }, INPUT_DELAY),
    []);

  return {
    locationInput,
    locations,
    isFetching,
    onLocationOptionSelect,
    onLocationInputChange,
  };
};

export default useFetchWorkLocation;
