import React from "react";
import PropTypes from "prop-types";

import styles from "./body.module.scss";
import ScrollToTop from "../../shared/components/scrollToTop/ScrollToTop";

const Body = ({ children }) => {
  return (
    <div className={styles.container}>
      <ScrollToTop />
      <>{children}</>
    </div>
  );
};

Body.defaultProps = {
  children: null,
};

Body.propTypes = {
  children: PropTypes.element,
};

export default Body;
