import React, { useContext } from "react";
import { UserContext } from "../../../../../modules/common-context";
import { EMPTY_OBJECT } from "../../../../constants/app";

import ReactRouteDomLink from "../../../../link/Link";
import Btn from "../../../../../components/buttons";

import useDownloadPolicy from "./hooks/useDownloadPolicy";

import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import NorthEastIcon from "@mui/icons-material/NorthEast";

import styles from "./purchasedPolicy.module.scss";

const PurchasedPolicy = ({ order }) => {
  const { user = EMPTY_OBJECT } = useContext(UserContext);
  const { name } = user;

  const [isDownloadPolicyLink, handleDownloadPolicyLink] =
    useDownloadPolicy(order);

  return (
    <div className={styles.wrapper}>
      <div className="body-container">
        <div className={styles.container}>
          <div className={styles.left}>
            <p className={styles.heading}>
              Hi <span className="uppercase">{name || "Upsolv User"}</span>
            </p>
            <p className={styles.subText}>
              Thank You!!! Your have been Upsolved 😃
            </p>
            <p className={styles.subText}>
              Congratulations for taking the decision to secure your income
              against Job Loss with Upsolv Income Protection Plan.
            </p>
          </div>
          <div className={styles.right}>
            <ReactRouteDomLink
              to="https://upsolv.incomeprotect.in/claim"
              target="_blank"
            >
              <Btn
                label="Claim Benefits"
                className={styles.claimPolicy}
                variant="outlined"
                startIcon={<NorthEastIcon />}
              />
            </ReactRouteDomLink>
            <Btn
              label="Download Plan"
              variant="contained"
              className={styles.downloadPan}
              loading={isDownloadPolicyLink}
              onClick={handleDownloadPolicyLink}
              startIcon={<FileDownloadOutlinedIcon />}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurchasedPolicy;
