import React from "react";

import dwebCustomerSupport from "../../../../assets/images/customerSupport/Support_Star_Web.png";
import mwebCustomerSupport from "../../../../assets/images/customerSupport/Support_Star_Mobile.png";

import HighlightSectionName from "../../../../shared/components/highLightSectionName";
import Link from "../../../../shared/link";
import Heading from "../../../../components/atoms/heading";

import StarPoint from "./StarPoint";

import styles from "./instantSupport.module.scss";
import { RightArrow } from "../../../../assets/images";

const InstantSupport = () => {
  return (
    <div className="body-container">
      <div className={styles.container}>
        <div className={styles.leftSection}>
          <div className={styles.headerSection}>
            <HighlightSectionName text="NEED HELP?" />
            <Heading text="Hum Hai Na! 24x7" className={styles.leftHeading} />
            <p className={styles.leftSubheading}>
              With our dedicated support for you always.
            </p>
          </div>
          <div className={styles.mwebCoverPhoto}>
            <img
              src={mwebCustomerSupport}
              alt="Cover"
              className={styles.mwebCoverPhoto}
            />
          </div>
          <StarPoint text="Read our" highlightedText="FAQs" to="/faq" onClick={()=>{
            window.dataLayer.push({'event': 'HomePage_InstantSupport_FAQs'});
            window.webengage.track("HomePage_InstantSupport_FAQs");
            }}/>
          <StarPoint
            text="Email us"
            highlightedText="support@upsolv.in"
            to="mailto:support@upsolv.in"
            onClick={()=>{
              window.dataLayer.push({'event': 'HomePage_InstantSupport_support@jobsurance.in'});
              window.webengage.track("HomePage_InstantSupport_support@jobsurance.in");
            }}
            
          />
          <StarPoint
            text="Call us"
            highlightedText="+91 7795178080"
            to="tel:+917795178080"
            onClick={()=>{
              window.dataLayer.push({'event': 'HomePage_InstantSupport_+917795178080'})
              window.webengage.track("HomePage_InstantSupport_+917795178080");
            }}
          />
          <StarPoint
            text="Chat with us on"
            highlightedText="WhatsApp"
            to="https://wa.me/917795178080?text=Hi"
            onClick={()=>{
              window.dataLayer.push({'event': 'HomePage_InstantSupport_WhatsApp'});
              window.webengage.track("HomePage_InstantSupport_WhatsApp");
            }}
          />
          <Link to="/faq" onClick={()=>{
            window.dataLayer.push({'event': 'HomePage_InstantSupport_LearnMore'});
            window.webengage.track("HomePage_InstantSupport_LearnMore");
          }}
          className="link-wrap"
          >
            <p>Learn More</p>
            <img src={RightArrow} alt="learn-more" />
          </Link>
        </div>
        <div className={styles.rightSection}>
          <div className={styles.dwebCoverPhoto}>
            <img src={mwebCustomerSupport} alt="Cover" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstantSupport;
