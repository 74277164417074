import React from "react";
import cx from "classnames";

import styles from "./howItWorks.module.scss";
import StarIconOrder from "../../../../shared/components/starIconOrder/StarIconOrder";

const Step = ({ stepNo, heading, subheading, className }) => {
  return (
    <div className={cx(styles.step, className)}>
      <StarIconOrder text={stepNo} />
      <div className={styles.stepRightSection}>
        <h3 className={styles.stepHeading}>{heading}</h3>
        <h4 className={styles.stepSubheading}>{subheading}</h4>
      </div>
    </div>
  );
};

export default Step;
