import React from "react";

import { CONTENT_SECTION } from "../../constants/contentSections";
import StarImg from "../../../../assets/images/5.2_Star.svg";

import styles from "./contentSection.module.scss";
import Table from "../../../../shared/components/table/Table";

function RecursiveList({ items = [] }) {
  return (
    <ol>

      {items.map((item, index) => (
        <div className={styles.itemWrapper}>
          <span className={styles.marginNumber}>{index + 1}.</span>
          <li key={`recursiveList-${item.content}`} className={styles.listItem}>
            {item.content}
            {item.items && <RecursiveList items={item.items} />}
          </li>
        </div>
      ))}
    </ol>
  );
}

const ContentSection = () => {
  return (
    <div className={styles.container}>
      <div>
        {CONTENT_SECTION.map((section, index) => {
          return (
            <div key={`contentSection-${index}`} className={styles.section}>
              <div className={styles.sectionHeader}>
                <div className={styles.icon}>
                  <img className={styles.img} alt="Terms Point" src={StarImg} />
                  <span className={styles.number}>{index + 1}</span>
                </div>
                <div className={styles.sectionHeading}>
                  {section.sectionHeading}
                </div>
              </div>

              {section.tabularData ? <Table tableData={section.tabularData} /> : null}
              {section.description && <div className={styles.description}>{section.description}</div>}
              <div className={styles.list}>
                <RecursiveList items={section.items} />
              </div>
              {section.footer && <div className={styles.description}>{section.footer}</div>}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ContentSection;
