import React from "react";
import cx from "classnames";

import { ReactComponent as EmployeesLogo } from "../../../../assets/images/employees.svg";

import styles from "./layoffCards.module.scss";

const LayoffCard = (props) => {
  const { img: companyLogo, employees, className } = props;

  return (
    <div className={cx(styles.cardWrapper, className)}>
      <div className={styles.logoContainer}>
        <img src={companyLogo} alt="" />
      </div>
      <h2 className={styles.employeesCnt}>{employees}</h2>
      <div className={styles.employeesTextWrapper}>
        <EmployeesLogo />
        <span className={styles.employeesText}>Employees</span>
      </div>
    </div>
  );
};

export default LayoffCard;
