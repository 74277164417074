import React from "react";

import Body from "../../layout/Body/Body";
import Footer from "../../layout/Footer/Footer";
import Disclaimers from "../../modules/disclaimers";

import Nav from "../../layout/Nav/Nav";

const DisclaimersPage = () => {
  return (
    <>
      <Nav />
      <Body>
        <Disclaimers />
      </Body>
      <Footer />
    </>
  );
};

export default DisclaimersPage;
