import React from "react";
import { TypeAnimation } from 'react-type-animation';


import "./heroSection.scss";
import SearchBar from "../searchBar";
import HeroSlider from "./heroSlider";

const HeroSectionV2 = (props) => {

  return (
    <div className="hero-container hero-mob-conatiner">
      <div className="body-container">
        <div className="hero-wrap mob-hero-swap-wrap">
          <div className="hero-left-wrap">
            <div className="text-wrap">
              <h1 className="black-color font-family-bold mb-2">
                <TypeAnimation
                  sequence={[
                    'Upto ₹6 Lacs Salary Protection', 5000, // Waits 10s
                    'Get Placement Support', 2000, // Waits 2s
                    'Linkedin Subscription', 2000, // Waits 2s
                    'Mental Wellness', 2000, // Waits 2s
                    '1:1 Interview Preparation', 2000, // Waits 2s
                  ]}
                  wrapper="span"
                  cursor={true}
                  repeat={Infinity}
                  className="primary-color"
                  omitDeletionAnimation={true}
                  style={{ fontSize: '1.2em', display: 'inline-block' }}
                />
                {/* <span className="primary-color">Stop Worrying!</span> */}
                <span className="d-block">in case of Job Loss!</span>
              </h1>
              {/* <p className="subtitle1 font-family-medium black-color"></p> */}
            </div>
            <div className="search-wrap mt-3">
              <SearchBar 
                placeholder="Enter your salary"
                btnLabel="Check Price!"
              />
            </div>
          </div>
          <div className="hero-right-wrap">
            <HeroSlider/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSectionV2;
