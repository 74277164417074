import React, { useCallback, useContext, useEffect, useRef, useState } from "react";

import Dialog from "@mui/material/Dialog";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";

import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import CardGiftcardSharpIcon from "@mui/icons-material/CardGiftcardSharp";
import CircularProgress from "@mui/material/CircularProgress";

import { GetQuoteContext, UserContext } from "../../../../modules/common-context";

import { Btn, CapsuleTag, DialogBox } from "../../../../components/index";
import { InfoCircle, Next } from "../../../../assets/images/index";
import DeclarationDetails from "../declaration-details";

import amountWithCommas from "../../../../shared/utils/amount-seperator";
import getUrlParams from "../../../../shared/utils/get-url-param";
import getTruncatedText from "../../../../shared/utils/getTruncatedText";

import { API_END_POINT } from "../../constants/api-const";
import ApiServices from "../../../../services/api";
import computeGiftCardAmount from "./helpers/computeGiftCardAmount";
import getDataFromResponse from "../../../../shared/utils/getDataFromResponse";
import APP_DATA_CONFIG from "../../../../helpers/app";
import { PAISE } from "../../../../shared/constants/app";
import { PLAN_COLOR } from "../../../../constants/planColor";
import PlanBreakupDialog from "../../plan-details-v2/plan-detail-breakup";
import { useLocation } from "react-router-dom";

const PlanCostDetails = () => {

  const location = useLocation();
  const { setUserDetails, user } = useContext(UserContext)
  const { dataPayload } = useContext(GetQuoteContext);

  const [upgardePlanStatus, setUpgradePlanStatus] = useState(null);

  const referralCodeInGetQuoteContext = user.applied_referral_code;
  const hasReferralCodeInGetQuoteContext = !!referralCodeInGetQuoteContext;

  const [showDeclarationModal, setToggleModal] = useState(false);
  const [planDetails, setPlanDetails] = useState();
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [referralCode, setReferralCode] = useState(
    getUrlParams('utm_referral') ||
    referralCodeInGetQuoteContext ||
    ""
  );
  const [isApplied, setIsApplied] = useState(hasReferralCodeInGetQuoteContext);
  const [isValidatingReferralCode, setIsValidatingReferralCode] = useState(false);
  const [showPriceBreakUpModal, priceBreakupToggleModal] = useState(false);
  const [priceBreakGrossAmount, setPriceBreakGrossAmount] = useState(null);
  const [priceBreakNetAmount, setPriceBreakNetAmount] = useState(null);
  const [priceBreakTax, setPriceBreakTax] = useState(null);
  const [extraBenefitsPrice, setExtraBenefitsPrice] = useState(null);
  const [priceGrossBenefits, setPriceGrossBenefits] = useState(null);
  const [planSelection, setPlanSelection] = useState('');
  const [previouslyPaid, setPreviouslyPaid] = useState(null);
  const [planUpgradeAmount, setPlanUpgradeAmount] = useState(null);


  const validatedResponseRef = useRef();

  const netAmount = dataPayload?.selected_quote?.premium?.netAmount?.toFixed(0);


  // handleSnackBar
  const handleSnackBar = () => {
    setSnackBarOpen(false);
  }

  const onClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setToggleModal(false);
  }

  useEffect(() => {
    const storeUserDetails = dataPayload;

    const grossAmount = (storeUserDetails?.selected_quote?.premium?.grossAmount / PAISE).toFixed(0);
    const tax = (storeUserDetails?.selected_quote?.premium?.tax / PAISE).toFixed(0);
    const netAmountInRupee = (netAmount / PAISE).toFixed(0);

    const previouslyPaid = (storeUserDetails?.selected_quote?.previouslyPaid / PAISE).toFixed(0) ?? 0;
    const planUpgradeAmount = (storeUserDetails?.selected_quote?.planUpgradeAmount / PAISE).toFixed(0) ?? 0;

    const _planDetails = {
      grossAmount: amountWithCommas(grossAmount),
      tax: amountWithCommas(tax),
      netAmount: amountWithCommas(netAmountInRupee),
      previouslyPaid: amountWithCommas(previouslyPaid),
      planUpgradeAmount: amountWithCommas(planUpgradeAmount),
    }
    setPlanDetails(_planDetails);
    setPreviouslyPaid(previouslyPaid);
    setPlanUpgradeAmount(planUpgradeAmount);

    let extraBenefitsCharge = (storeUserDetails?.selected_quote?.premium?.extraBenefitsCharge / PAISE).toFixed(0);
    let priceGrossBenefits = ((storeUserDetails?.selected_quote?.premium?.netAmount - storeUserDetails?.selected_quote?.premium?.extraBenefitsCharge) / PAISE).toFixed(0);



    setPlanSelection(storeUserDetails?.selected_quote_planName)

    setPriceBreakGrossAmount(amountWithCommas(grossAmount));
    setPriceBreakNetAmount(amountWithCommas(netAmountInRupee));
    setPriceBreakTax(amountWithCommas(tax));
    setExtraBenefitsPrice(amountWithCommas(extraBenefitsCharge));
    setPriceGrossBenefits(amountWithCommas(priceGrossBenefits));

    return () => {
      validatedResponseRef.current = undefined;
    }

  }, []);

  const submitHandle = () => {

    const storeUserDetails = dataPayload;

    if (storeUserDetails.company_pincode_id == null && storeUserDetails.date_of_joining == null) {
      setSnackBarOpen(true);
      setSnackBarMessage("Please fill all Employment Details");
    } else if (storeUserDetails.pan == null) {
      setSnackBarOpen(true);
      setSnackBarMessage("Please fill all KYC Details");
    } else if (storeUserDetails.date_of_birth == null && storeUserDetails.email == null && storeUserDetails.gender == null) {
      setSnackBarOpen(true);
      setSnackBarMessage("Please fill all Personal Details");
    } else if (storeUserDetails.address == null && storeUserDetails.pincode == null && storeUserDetails.state == null) {
      setSnackBarOpen(true);
      setSnackBarMessage("Please fill all Communication Details");
    } else {
      setToggleModal(true);
      window.dataLayer.push({ 'event': 'get-quote_ProposalPage_Continue' });
      window.webengage.track("get-quote_ProposalPage_Continue", planDetails);
      window.webengage.user.setAttribute("get-quote_ProposalPage_Continue", planDetails);
      APP_DATA_CONFIG.GENERATE_LEAD.request("get-quote_ProposalPage_Continue", storeUserDetails?.lead_id)
    }
  }


  const handleReferralCodeInput = (e) => {
    setReferralCode(e?.target?.value || "");
  }

  const handleApplyClick = async () => {
    if (isValidatingReferralCode) return;
    try {
      setIsValidatingReferralCode(true);
      const payload = { referral_code: referralCode, order_amount: netAmount };
      const validatedReferralResponse = await ApiServices.post(API_END_POINT.VALIDATE_REFERRAL_CODE, payload);
      const validatedResponse = getDataFromResponse(validatedReferralResponse);
      validatedResponseRef.current = validatedResponse;
      setIsApplied(true);
      setUserDetails({
        ...user,
        applied_referral_code: referralCode,
      })
    }
    catch (err) {
      const _error = err.response
      setIsApplied(false);
      setSnackBarOpen(true);
      setSnackBarMessage(_error?.data?.message || "Invalid Referral Code");
    }
    finally {
      setIsValidatingReferralCode(false);
    }
  };

  const handleEditClick = () => {
    setUserDetails({
      ...user,
      applied_referral_code: '',
    })
    setIsApplied(false);
  };

  const handlePriceBreakup = () => {
    priceBreakupToggleModal(true);
  }

  // handle price breakup
  const onClosePriceBreakupModal = () => {
    priceBreakupToggleModal(false)
  }

  const giftCardAmount = computeGiftCardAmount(netAmount);

  const getGiftCardSupportText = () => {
    const userName = dataPayload?.name;
    if (isApplied) {
      const referee = validatedResponseRef.current?.referer_name;
      if (referee) {
        return `Congratulations ${userName}, You and ${referee} are eligible for ₹${giftCardAmount} worth of amazon gift card.`;
      }
      return `Congratulations ${userName}, you are eligible for ₹${giftCardAmount} worth of amazon gift card.`;
    }

    return `Enter Referral code & get ₹${giftCardAmount} worth of Amazon Voucher`;
  }

  // * based on netAmount changes we have to apply and maintaine the referral code.
  useEffect(() => {

    if (!referralCode) return;

    handleApplyClick();
  }, [])

  useEffect(() => {
    if (location.state !== null) {
      setUpgradePlanStatus(location.state.upgradePlan)
    }
  }, [location.state])


  return (
    <React.Fragment>
      <div className="plan-cost-row pb-2">
        <div className="plan-cost-head">
          <div className="text-wrap">
            <h3 className="black-color font-family-medium">{dataPayload?.selected_quote_planName} Protection Plan</h3>
            <p className="subtitle black-color opacity-6 font-family-medium">Cost breakdown</p>
          </div>
        </div>
      </div>
      <Snackbar open={snackBarOpen && !!snackBarMessage} autoHideDuration={6000} onClose={handleSnackBar}>
        <Alert onClose={handleSnackBar} severity="error">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <div className="plan-cost-row no-border pt-2 pb-2">
        <div className="plan-cost-content mt-2">
          <div className="plan-cost-content-row mb-2">
            <div className="text-wrap">
              <p className="caption black-color opacity-5 font-family-medium">Plan Cost</p>
            </div>
            <div className="text-wrap">
              <p className="caption black-color font-family-medium">₹{planDetails?.grossAmount}/-</p>
            </div>
          </div>
          <div className="plan-cost-content-row mb-2">
            <div className="text-wrap">
              <p className="caption black-color opacity-5 font-family-medium">Taxes</p>
            </div>
            <div className="text-wrap">
              <p className="caption black-color font-family-medium">₹{planDetails?.tax}/-</p>
            </div>
          </div>
          <div className="plan-cost-content-row mb-2">
            <div className="text-wrap plan-cost-info">
              <p className="caption black-color opacity-5 font-family-medium">Total</p>
              <IconButton
                size="small"
                onClick={() => { handlePriceBreakup() }}
              >
                <img src={InfoCircle} alt="InfoCircle" className="info-icon" />
              </IconButton>
            </div>
            <div className="text-wrap">
              <p className="caption black-color font-family-medium">₹{planDetails?.netAmount}/-</p>
            </div>
          </div>
          {
            upgardePlanStatus && (
              <>
                <div className="plan-cost-content-row mb-2">
                  <div className="text-wrap">
                    <p className="caption black-color opacity-5 font-family-medium">Paid Previously</p>
                  </div>
                  <div className="text-wrap">
                    <p className="caption black-color font-family-medium">₹ {planDetails.previouslyPaid}/-</p>
                  </div>
                </div>

                <div className="plan-cost-content-row mb-2">
                  <div className="text-wrap">
                    <p className="caption black-color opacity-5 font-family-medium">Net Pay</p>
                  </div>
                  <div className="text-wrap">
                    <p className="caption black-color font-family-medium">₹ {planDetails.planUpgradeAmount}/-</p>
                  </div>
                </div>
              </>
            )
          }

          <div className="referral-code-container mb-2">
            {!isApplied ? (
              <div className="referral-code">
                <input
                  className="referral-code-input"
                  value={referralCode}
                  onChange={handleReferralCodeInput}
                  placeholder="Enter Referral code"
                />
                <button className="apply" onClick={handleApplyClick}>
                  {isValidatingReferralCode ? <CircularProgress size={24} color="inherit" /> : "Apply"}
                </button>
              </div>
            ) : (
              <div className="applied-referral-code-ui">
                <div className="applied-referral-code-ui-left">
                  <span className="applied-code-container">
                    <div className="applied-text">
                      <CheckCircleIcon fontSize="small" className='applied-icon' />
                      <span>
                        Code applied!
                      </span>
                    </div>
                    <p className="code">&nbsp;{getTruncatedText(referralCode, 10)}</p>
                  </span>
                </div>
                <div className="applied-referral-code-ui-right">
                  <IconButton onClick={handleEditClick}>
                    <EditIcon />
                  </IconButton>
                </div>
              </div>
            )}
            {
              (giftCardAmount > 0) ?
                (
                  <div className="referral-code-support-text">
                    <div className="referral-code-gift">
                      <CardGiftcardSharpIcon fontSize="small" />
                    </div>
                    <p className="caption black-color opacity-5 font-family-medium leading-4">{getGiftCardSupportText()}</p>
                  </div>
                )
                : null
            }
          </div>

        </div>
      </div>
      <div className="plan-cost-structure-wrap">
        <div className="text-wrap">
          {
            upgardePlanStatus === true ? (
              <h5 className="white-color font-family-medium">₹{planDetails?.planUpgradeAmount}/-</h5>
            )
              :
              (
                <h5 className="white-color font-family-medium">₹{planDetails?.netAmount}/-</h5>
              )
          }
          <p className="small-caption white-color opacity-5 font-family-light">for annual subscription</p>
        </div>
        <div className="btn-wrap">
          <Btn
            label="Continue"
            endIcon={<img src={Next} alt="Next" />}
            className="btn btn-primary"
            onClick={() => { submitHandle() }}
          />
        </div>
      </div>
      <Dialog onClose={onClose} open={showDeclarationModal} className="dialog-wrap">
        <DeclarationDetails onClose={onClose} />
      </Dialog>
      <PlanBreakupDialog
        planName={planSelection}
        extraBenefitsPrice={extraBenefitsPrice}
        priceBreakGrossAmount={priceBreakGrossAmount}
        priceBreakNetAmount={priceBreakNetAmount}
        priceBreakTax={priceBreakTax}
        priceGrossBenefits={priceGrossBenefits}
        planUpgrade={upgardePlanStatus}
        paidPreviously={previouslyPaid}
        planUpgradeAmount={planUpgradeAmount}
        onClose={(val) => { onClosePriceBreakupModal(val) }}
        open={showPriceBreakUpModal}
      />
    </React.Fragment>
  )
}

export default PlanCostDetails;
