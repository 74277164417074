import React, { useEffect, useRef, useState } from "react";
import { register } from "swiper/element/bundle";
import { Allowance_Plain, Mental_Plain, ResumeBuilding_Plain, Session1_1to1_Plain, Exclusive_Support } from "../../../assets/images";

register();

const PlanSlider = (props) => {
    const [PLAN_SLIDER, setPlanSlider] = useState([
        { image: Session1_1to1_Plain, title: "TA Portal Subscription" },
        { image: ResumeBuilding_Plain, title: "Resume Building" },
        { image: Exclusive_Support, title: "Exclusive Placement Support" },
        { image: Mental_Plain, title: "1:1 session with industry domain specialist" },
        { image: Mental_Plain, title: "Mental Wellness sessions with experts" },
        { image: Allowance_Plain, title: "3 month Unemployment allowance" },
    ]);

    const swiperRef = useRef(null);

    useEffect(() => {
        setPlanSlider(props.sliderData);
        swiperInit();
    }, [props.sliderData]);

    const swiperInit = () => {
        const swiperContainer = swiperRef.current;
        const params = {
            navigation: true,
            pagination: { clickable: true },
            autoplay: {
                delay: 2500,
                disableOnInteraction: false,
            },
            injectStyles: [
                `
          .swiper-wrapper {
            padding-bottom: 20px;
          }
          .swiper-button-next{
            z-index: 1;
            display: none;
          }
          .swiper-button-prev{
            z-index: 1;
            display: none;
          }
          .swiper-pagination-bullet{
            width: 8px;
            height: 8px;
            background-color: #fd930d;
            cursor: pointer
          }
          .swiper-pagination-bullets {
          }
      `,
            ],
        };

        Object.assign(swiperContainer, params);
        swiperContainer.initialize();
    };

    return (
        <div className="plan-slider-wrap">
            <swiper-container ref={swiperRef} init="false">
                {PLAN_SLIDER.map((item, index) => {
                    return (
                        <swiper-slide>
                            <div className="plan-slider-item text-wrap">
                                <img src={item.image} alt={item.title} />
                                <p className="mt-3 caption black-color font-family-regular text-center my-2">
                                    {item.title !== "Unemployment Allowance" ? (
                                        item.title
                                    ) : props.viewPlanVersion !== "v1" ? (
                                        <span className="small-caption">
                                            Max unemployment allowance upto ₹{props.protectionAmount}
                                            <br />
                                            Benefit payout:
                                            <br />
                                            - 50% on 45 days of unemployment
                                            <br />- 50% on 90 days of unemployment
                                        </span>
                                    ) : (
                                        <span>{item.title}</span>
                                    )}
                                </p>
                            </div>
                        </swiper-slide>
                    );
                })}
            </swiper-container>
        </div>
    );
};

export default PlanSlider;
