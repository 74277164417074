import React, { useEffect, useRef } from "react";
import { register } from "swiper/element/bundle";

import "./heroSection.scss";
import { HERO_SLIDER } from "./heroSlider.constant";

register();

const HeroSlider = (props) => {

  const swiperRef = useRef(null);

  useEffect(() => {
    swiperInit()
  }, []);

  const swiperInit = () => {
    const swiperContainer = swiperRef.current;
    const params = {
      navigation: true,
      // effect: "cards",
      // direction: "vertical",
      // autoHeight: true,
      // centeredSlides: true,
      // rewind: true,
      // slidesPerView: 1,
      // spaceBetween: 30,
      // loop: true,
      // spaceBetween: 1,
      // slidesPerView: 1,
      
      slidesPerView: 5,
      centeredSlides: true,
      roundLengths: true,
      loop: true,
      loopAdditionalSlides: 50,
      spaceBetween: 0,
      direction: "vertical",
      // autoplay: {
      //   delay: 2000,
      //   disableOnInteraction: false,
      // },
      breakpoints: {
        300: {
          slidesPerView: 3,
        },
        768: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 5,
        },
      },
      injectStyles: [
        `
          .swiper-wrapper {
            padding-bottom: 24px;
          }
          .swiper-button-next{
            color: #fd930d;
            height: 40px;
            width: 40px;
            z-index: 1;
            background: #ffffff;
            border-radius: 50%;
            display: none
          }
          .swiper-button-next:after {
            font-size: 16px;
            font-weight: bold;
          }
          .swiper-button-prev{
            color: #fd930d;
            height: 40px;
            width: 40px;
            z-index: 1;
            background: #ffffff;
            border-radius: 50%;
            display: none
          }
          .swiper-button-prev:after {
            font-size: 16px;
            font-weight: bold;
          }
          .swiper-pagination-bullet{
            width: 10px;
            height: 10px;
            background-color: grey;
          }
          .swiper-pagination-bullets {
            margin-bottom: -10px;
          }
      `,
      ],
    };

    Object.assign(swiperContainer, params);
    swiperContainer.initialize();
  }

  return (
    <div className="hero-card-slider">
      <swiper-container ref={swiperRef} init="false">  
        {
          HERO_SLIDER.map((item, index) => {
            return (
              <swiper-slide>
                <div className="hero-card-wrap" key={index}>
                  <div className="hero-card-img">
                    <img src={item.image} alt={item.title}></img>
                  </div>
                  <div className="text-wrap">
                    <p className="subtitle font-family-medium black-color">{item.title}</p>
                  </div>
                </div>
              </swiper-slide>
            )
          })
        }
      </swiper-container>
    </div>
  );
};

export default HeroSlider;
