import React from "react";
import HighLightSectionName from "../../../../shared/components/highLightSectionName";
import Heading from "../../../../components/atoms/heading";
import Card from "../../../../components/molecules/Card";
import { Corporates, MentalHealthSvg, Mental_Health_Globe, Mental_Health_Media, Mental_Health_New_Icon_1, Mental_Health_New_Icon_2, Mental_Health_New_Icon_3, Mental_Health_New_Icon_4, Mental_Health_Setting, Mentor_Benefits_1, RightArrow } from "../../../../assets/images";
import { Link } from "react-router-dom";

import "./mentalHealth.scss"

const MentalHealth = () => {
  return (
    <div className="body-container py-4">
      <div className="mental-health-wrap">
        <div className="mental-health-left-wrap">
          <div className="m-title-wrap pt-0 pt-lg-4 mb-4">
            <HighLightSectionName 
              text="MENTAL HEALTH"
            />
            <Heading
              text="Be mentally strong with jumpingMinds"
              className={"mt-3"}
            />
            <Heading
              text="Get sessions with experts to keep your calm during layoffs"
              className={"subheading mt-2 font-family-medium"}
            />
          </div>
          <div className="m-infographic-wrap d-lg-none">
            <img src={MentalHealthSvg} alt="MentalHealth" className="w-100"></img>
            <p className="health-heading">Focus on Holistic wellness</p>
          </div>
          <div className="mental-health-info-wrap">
            <Card className={"m-info-wrap"}>
              <Card.Image src={Mental_Health_New_Icon_1} className={"m-image-wrap"}/>
              <Card.Heading
                text="1-1 Anonymous Therapy"
                className={"mt-2 health-heading font-family-medium"}
              />
            </Card>
            <Card className={"m-info-wrap"}>
              <Card.Image src={Mental_Health_New_Icon_2} className={"m-image-wrap"}/>
              <Card.Heading
                text="Instant Therapy with experts"
                className={"mt-2 health-heading font-family-medium"}
              />
            </Card>
            <Card className={"m-info-wrap"}>
              <Card.Image src={Mental_Health_New_Icon_3} className={"m-image-wrap"}/>
              <Card.Heading
                text="24x7 Community Support"
                className={"mt-2 health-heading font-family-medium"}
              />
            </Card>
            <Card className={"m-info-wrap"}>
              <Card.Image src={Mental_Health_New_Icon_4} className={"m-image-wrap"}/>
              <Card.Heading
                text="Self care toolkit"
                className={"mt-2 health-heading font-family-medium"}
              />
            </Card>
          </div>

          <div className="mt-2">

            <a className="d-flex link-wrap" href="https://www.jumpingminds.ai/" target="_blank">
              <p>Learn More</p>
              <img src={RightArrow} alt="learn-more" />
            </a>

          </div>
        </div>
        <div className="mental-health-right-wrap d-none d-lg-block">
          <div className="m-infographic-wrap">
            <img src={MentalHealthSvg} alt="MentalHealth" className="w-100"></img>
            <p className="health-heading">Focus on Holistic wellness</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MentalHealth