import React, { useContext } from "react";

import Layout from "../layout";
import QuoteHeader from "../quote-header";
import Btn from "../../buttons";
import useNavigateToUrl from "../../../shared/hooks/useNavigateToUrl/useNavigateToUrl";
import WarningIcon from "@mui/icons-material/Warning";
import ReplayOutlinedIcon from "@mui/icons-material/ReplayOutlined";

import { Home } from "../../../assets/images/index";

import styles from "./paymentFailed.module.scss";
import { useNavigate } from "react-router-dom";
import { getCookie } from "../../../shared/utils/cookie";
import { GetQuoteContext } from "../../../modules/common-context";
import ApplicationContext from "../../../shared/contexts/applicationContext/ApplicationContext";
import { EMPTY_OBJECT } from "../../../shared/constants/app";
import useRetryPaymentForContinuePurchase from "../../../shared/components/policyWidget/molecules/continuePurchase/hooks/useRetryPaymentForContinuePurchase";
import POLICY_STATUS from "../../../shared/components/policyWidget/constants/policyStatus";

const PaymentFailed = () => {
  const navigateToUrl = useNavigateToUrl();
  const navigate = useNavigate();
  const { dataPayload } = useContext(GetQuoteContext);
  const { policyWidget = EMPTY_OBJECT } = useContext(ApplicationContext);
  const { order, policyStatus } = policyWidget;
  const [isRetryingPayment, onRetryPayment] = useRetryPaymentForContinuePurchase(order);

  const backTo = () => {
    const _islogin = getCookie("js_login");

    if (_islogin === 'true' && dataPayload?.user_plan_status?.has_purchased) {
      navigate({
        pathname: '/dashboard'
      });
    } else {
      navigateToUrl("/")
    }

    window.dataLayer.push({ 'event': 'login_back_to_home' });
  };

  const PaymentRetryView = () => {
    try {
      if (policyStatus === POLICY_STATUS.CONTINUE_PURCHASE) {
        return (
          <Btn
            label="Retry Payment"
            variant="contained"
            className={styles.retryPayment}
            onClick={onRetryPayment}
            startIcon={<ReplayOutlinedIcon />}
            disabled={isRetryingPayment}
          />
        )
      }
    } catch (error) {
      console.log("PaymentRetryView Error: ", error);
    }
  }

  
  return (
    <div>
      <Layout>
        <QuoteHeader
          btn={
            <Btn
              className="btn-white font-family-regular"
              label="Back to Home"
              size="small"
              startIcon={<img src={Home} alt="home" />}
              onClick={() => backTo()}
            />
          }
        />
        <div className={styles.container}>
          <div className={styles.paymentFailedBox}>
            <div>
              <WarningIcon className={styles.warningIcon} />
            </div>
            <p>Payment Failed</p>
            <PaymentRetryView />
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default PaymentFailed;
