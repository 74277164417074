import React from 'react';
import './layout.scss';
import { isMobile } from 'react-device-detect';

const Layout = ({children}) => {
    return (
        <div className={`${!isMobile?'layout-background':''} layout-bg-color`}>
            {children}
            <div className="copyright-wrap pt-2 pb-2">
                <div className="text-wrap">
                    <p className="caption black-color text-center">© 2023 UPSOLV. All Rights Reserved.</p>
                </div>
            </div>
            {/* <Typography type="px_16">
                <div className="flex justify-center">
                    <div className="layout-footer">
                        © 2023 UPSOLV. All Rights Reserved.
                    </div>
                </div>
            </Typography> */}
        </div>
    )
}

export default Layout;
