import React from "react";
import styles from "./heroSection.module.scss";
import ContactUsImage from "../../../../assets/images/Contact_Us.svg";

const HeroSection = () => {
  return (
    <div className={styles.container}>
      <img className={styles.heroImage} src={ContactUsImage} alt="Contact us" />
    </div>
  );
};

export default HeroSection;
