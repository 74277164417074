import React from "react";
import styles from "./contactAddress.module.scss";
import { CONTACT_ADDRESS } from "../../constant/contactAddress";
import ReactRouteDomLink from "../../../../shared/link/Link";

const ContactAddress = () => {
  return (
    <div className={styles.container}>
      {CONTACT_ADDRESS.map((item, idx) => {
        const { icon, text1, label, link } = item;
        return (
          <ReactRouteDomLink to={link}>
            <div className={styles.item} key={`contact-address-${idx}`}>
              <img width="30" src={icon} className={styles.icon} alt="" />
              <div className={styles.textContainer}>
                <div className={styles.label}>{label}</div>
                <div className={styles.text1}>{text1}</div>
              </div>
            </div>
          </ReactRouteDomLink>
        );
      })}
    </div>
  );
};

export default ContactAddress;
