import React from "react";
import cx from "classnames";

import { CONTENT_SECTIONS } from "../../constants/contentSections";

import BoldLine from "../../../../shared/components/boldLine/BoldLine";
import StarIconOrder from "../../../../shared/components/starIconOrder/StarIconOrder";
import { getAlphabetsFromAscii } from "../../../../shared/utils";

import styles from "./contentSection.module.scss";

function RecursiveList({ items = [], orderType }) {
  return (
    <ol>
      {items.map((item, index) => {
        const itemNumberBaseAscii = getAlphabetsFromAscii(
          index,
          orderType ? orderType.charCodeAt(0) : 1
        );
        return (
          <div
            className={cx(styles.itemWrapper, {
              [styles["itemWrapper--nospace"]]: item.noSpacing,
            })}
          >
            <span className={styles.marginNumber}>{itemNumberBaseAscii}.</span>
            <li key={index} className={styles.listItem}>
              <BoldLine text={item.content} />
              {item.items && (
                <RecursiveList items={item.items} orderType={item.orderType} />
              )}
            </li>
          </div>
        );
      })}
    </ol>
  );
}

const ContentSection = () => {
  return (
    <div className={styles.container}>
      {CONTENT_SECTIONS.map((section, index) => {
        const {
          sectionHeading,
          description = [],
          items = [],
          footer,
        } = section;

        return (
          <div className={styles.section}>
            <div className={styles.header}>
              <StarIconOrder text={getAlphabetsFromAscii(index)} imgClassName={styles.starIconOrderImg} />
              <p className={styles.sectionHeading}>{sectionHeading}</p>
            </div>
            {description.map((item) => (
              <p
                className={cx(styles.description, {
                  [styles["description--nospace"]]: item.noSpacing,
                })}
              >
                <BoldLine text={item.content} />
              </p>
            ))}
            <div className={styles.list}>
              {items.map((item, index) => {
                return (
                  <div
                    key={`DisclaimerItem-${index}`}
                    className={cx(styles.itemWrapper, {
                      [styles["itemWrapper--nospace"]]: item.noSpacing,
                    })}
                  >
                    <span className={styles.marginNumber}>{index + 1}.</span>
                    <p className={styles.listItem}>
                      <BoldLine text={item.content} />
                      {item.items && (
                        <RecursiveList
                          items={item.items}
                          orderType={item.orderType}
                        />
                      )}
                    </p>
                  </div>
                );
              })}
            </div>
            {footer && (
              <p className={styles.footer}>
                <BoldLine text={footer} />
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default ContentSection;
