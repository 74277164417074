import React, { useContext, useEffect, useState } from "react";

import QuoteHeader from "../quote-header";
import Btn from "../../buttons";
import { CircleDash, CircleTick, Congratulations, Download, Home, Thankyou } from "../../../assets/images";
import Card from "../Card";
import { getCookie, removeCookie } from "../../../shared/utils/cookie";
import Loader from "../../loader";
import { API_END_POINT } from "../constants/api-const";
import ApiServices from "../../../services/api";
import { Alert, Snackbar } from "@mui/material";
import useNavigateToGetQuote from "../../../shared/hooks/useNavigateToGetQuote";
import useNavigateToUrl from "../../../shared/hooks/useNavigateToUrl";
import { handleApplicationContext } from "../../../shared/helpers/computeAndSetInitialData";
import ApplicationContext from "../../../shared/contexts/applicationContext/ApplicationContext";
import { GetQuoteContext } from "../../../modules/common-context"
import APP_DATA_CONFIG from "../../../helpers/app";
import { PLAN_COLOR } from "../../../constants/planColor";
import CapsuleTag from "../../capsule-tag";

window.dataLayer = window.dataLayer || [];

const PlanSuccess = (props) => {

  const applicationContext = useContext(ApplicationContext);
  const getQuoteContext = useContext(GetQuoteContext);

  const [userDetails, setUserDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [planDetails, setPlanDetails] = useState({});
  const [protectionPlanDetails, setProtectionPlanDetails] = useState({});
  const [planBenefits, setPlanBenefits] = useState([]);
  const navigateToGetQuote = useNavigateToGetQuote();
  const navigateToUrl = useNavigateToUrl();


  const backToHome = () => {
    navigateToUrl("/dashboard");
    window.dataLayer.push({ 'event': 'plan-success_back_to_dashboard' });
    window.webengage.track("plan-success_back_to_dashboard");
  }

  // handleSnackBar
  const handleSnackBar = () => {
    setSnackBarOpen(false);
  }

  useEffect(() => {

    setTimeout(() => {
      removeCookie('js_order_id');
      removeCookie('js_protection_plan_id');
    }, 3000)

    const _order_id = getCookie('js_order_id');

    if (_order_id === undefined || _order_id === null) {
      navigateToGetQuote();
      setIsLoading(false);
    } else {
      setIsLoading(false);
      getUserDetails();
      getPlanBenefits();
      getProtectionPlanFromOrderID();
    }

  }, []);


  const getUserDetails = async () => {
    try {
      let userData = await ApiServices.get(API_END_POINT.USER_SELF);
      setUserDetails(userData?.data?.user);
      handleApplicationContext(userData?.data?.user, applicationContext)
    } catch (error) {
      const _error = error.response
      setSnackBarOpen(true);
      setSnackBarMessage(_error?.data?.message);
    }
  }

  const getProtectionPlanFromOrderID = async () => {
    const order_id = getCookie('js_order_id');
    const payload = {
      order_id: order_id,
    };

    const response = await ApiServices.post(API_END_POINT.GET_ORDER_PROTECTION_PLAN, payload);
    if (response.status === 200) {
      setPlanDetails(response?.data?.data?.plan);

      // * if plan name is Iconic then getProtectionPlan
      if (response?.data?.data?.plan?.ref_code === 'PLAN_1') {
        getProtectionPlan();
      } else {
        // * if it is not 'PLAN_1' need to update webengage and lead for other plan(plus, pro).
        updateWebengageEvenetAndLead(response?.data?.data);
      }
    }
  }

  const getPlanBenefits = async () => {
    const response = await ApiServices.get(API_END_POINT.GET_BENEFITS);
    if (response.status === 200) {
      setPlanBenefits(response?.data?.benefits);
    }
  }

  const getProtectionPlan = async () => {
    try {
      const cookie_pp_id = getCookie('js_protection_plan_id');
      const res = await ApiServices.get(`${API_END_POINT.GET_PROTECTION_PLAN}/${cookie_pp_id}`);
      const data = res?.data;

      if (data?.status === "Success") {
        window.dataLayer.push(
          {
            event: "purchase",
            ecommerce: {
              transaction_id: data?.protection_plan?.protectionPlanId,
              value: data?.protection_plan?.netAmount,
              currency: 'INR',
            }
          }
        );

        window.dataLayer.push(
          {
            'event': 'PaymentSuccess_Thankyou_Success',
            'payment': {
              protectionPlanId: data?.protection_plan?.protectionPlanId,
              proposalId: data?.protection_plan?.proposalId,
              netAmount: data?.protection_plan?.netAmount,
              currency: 'INR',
            }
          }
        );
        window.webengage.track('PaymentSuccess_Thankyou_Success', {
          protectionPlanId: data?.protection_plan?.protectionPlanId,
          proposalId: data?.protection_plan?.proposalId,
          netAmount: data?.protection_plan?.netAmount,
          currency: 'INR',
        });
        window.webengage.user.setAttribute('PaymentSuccess_Thankyou_Success', {
          protectionPlanId: data?.protection_plan?.protectionPlanId,
          proposalId: data?.protection_plan?.proposalId,
          netAmount: data?.protection_plan?.netAmount,
          currency: 'INR',
        });
        APP_DATA_CONFIG.GENERATE_LEAD.request("PaymentSuccess_Thankyou_Success")
        setProtectionPlanDetails(data?.protection_plan);
        window.open(data?.protection_plan?.downloadProtectionPlanLink, "_self")
      }

    } catch (error) {
      const _error = error.response
      setSnackBarOpen(true);
      setSnackBarMessage(_error?.data?.message);
    } finally {
      refreshLeadID()
    }
  }

  const downloadPlan = () => {
    window.open(protectionPlanDetails?.downloadProtectionPlanLink, "_self");
  }


  const planBenefitsCircleTickDashView = (planBenefitArr = [], benefitId, benefitName) => {
    const result = planBenefitArr.includes(benefitId);
    if (result === true) {
      return (
        <p className="benefits-list subtitle1 font-family-medium black-color">
          <img src={CircleTick} className="benefits-list-img" alt="CircleDash"></img> {benefitName}
        </p>
      )
    } else {
      return null;
    }
  }

  const updateWebengageEvenetAndLead = (data) => {
    try {
      window.dataLayer.push(
        {
          event: "purchase",
          ecommerce: {
            transaction_id: data?.userPlanProposal?.plan_code,
            value: (data?.order?.amount_payable / 100),
            currency: 'INR',
          }
        }
      );

      window.dataLayer.push(
        {
          'event': 'PaymentSuccess_Thankyou_Success',
          'payment': {
            protectionPlanId: data?.order?.protection_plan_id,
            proposalId: data?.userPlanProposal?.plan_code,
            netAmount: (data?.order?.amount_payable / 100),
            currency: 'INR',
          }
        }
      );

      window.webengage.track('PaymentSuccess_Thankyou_Success', {
        protectionPlanId: data?.order?.protectionPlanId,
        proposalId: data?.userPlanProposal?.plan_code,
        netAmount: (data?.order?.amount_payable / 100),
        currency: 'INR',
      });

      window.webengage.user.setAttribute('PaymentSuccess_Thankyou_Success', {
        protectionPlanId: data?.order?.protectionPlanId,
        proposalId: data?.userPlanProposal?.plan_code,
        netAmount: (data?.order?.amount_payable / 100),
        currency: 'INR',
      });

      APP_DATA_CONFIG.GENERATE_LEAD.request("PaymentSuccess_Thankyou_Success")
    } catch (err) {
      console.log("updateWebengageEvenetAndLead error: ", err);
    } finally {
      refreshLeadID();
    }
  }

  const refreshLeadID = () => {
    try {

      const params = {
        getQuoteContext
      }

      const handleRefreshLeadSuccess = (params) => (results) => {
        const [refreshLeadPromise] = results;

        APP_DATA_CONFIG.REFRESH_LEAD.onSettled(params, refreshLeadPromise);
      }

      const handleRefreshLeadError = (_params) => (_err) => {
        return undefined;
      };

      const handleRefreshLeadFinally = (params) => () => { };

      Promise.allSettled([
        APP_DATA_CONFIG.REFRESH_LEAD.request()
      ])
        .then(handleRefreshLeadSuccess(params))
        .catch(handleRefreshLeadError(params))
        .finally(handleRefreshLeadFinally(params));
    } catch (err) {
      console.log("refreshLeadID Error: ", err);
    }
  }

  return (
    <React.Fragment>
      <QuoteHeader
        btn={
          <Btn
            className="btn-white font-family-regular"
            label="Back to Home"
            size="small"
            startIcon={<img alt="Home" src={Home} />}
            onClick={() => backToHome()}
          />
        }
      />
      <Loader
        open={isLoading}
        label="Validating your payment"
      />

      <Snackbar open={snackBarOpen && !!snackBarMessage} autoHideDuration={6000} onClose={handleSnackBar}>
        <Alert onClose={handleSnackBar} severity="error">
          {snackBarMessage}
        </Alert>
      </Snackbar>

      {
        !isLoading && (
          <div className="layout-conatiner">
            <div className="small-container thankyou-wrap">
              <div className="plan-think-wrap">
                <div className="plan-think-icon">
                  <img alt="Congratulations" src={Congratulations} />
                </div>
                <div className="text-wrap">
                  <p className="caption font-family-medium">Congratulations <span className="text-capitalize">{userDetails?.name}</span>! You have successfully purchased the plan.</p>
                </div>
              </div>
              <Card classes={"card card-shadow w-100"}>
                <div className="card-content-wrap">
                  <div className="thankyou-title-wrap">
                    <img alt="Thankyou" src={Thankyou} />
                  </div>
                  <div className="mb-2 text-wrap">
                    <h5 className="text-center black-color font-family-medium">Thank You for choosing UPSOLV 😊</h5>
                  </div>
                  <div className="thankyou-info-wrap">
                    <div className="text-wrap">
                      <p className="text-center subtitle1 font-family-thin black-color">Congratulations for taking the decision to prepare against layoffs with UPSOLV Subscription plans</p>
                    </div>
                    <div className="thankyou-plan-detail-wrap mt-4">
                      <div className={`plan-card-wrap plan-card-selection active`}>
                        <div className="plan-card-item">
                          <div className="plan-info-wrap">
                            <CapsuleTag
                              title="UP"
                              subtitle={planDetails?.name}
                              className={`${PLAN_COLOR[planDetails?.name?.toUpperCase()]}`}
                            />
                            <div className="text-wrap mt-2">
                              {/* <p className="subtitle1 font-family-medium black-color">{planDetails?.description}</p> */}
                              <p className="subtitle1 font-family-medium black-color">Get advantage from these benefits of your annual subscription</p>

                            </div>
                          </div>
                          <div className="benefits-info-wrap">
                            <div className="text-wrap">
                              {
                                planBenefits.map((item) => (
                                  planBenefitsCircleTickDashView(planDetails?.benefit_ids, item?.id, item?.name)
                                ))
                              }

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-wrap mt-4">
                      <p className="text-center subtitle font-family-thin black-color"><span className="font-family-medium cursor-pointer underline" onClick={() => backToHome()}>Click here</span> to access the plan</p>
                    </div>
                  </div>
                  {
                    planDetails?.name === "Iconic" && (
                      <div className="btn-wrap">
                        <Btn
                          label="Download Plan"
                          startIcon={<img alt="Download" src={Download} />}
                          className="btn btn-green"
                          size="large"
                          onClick={() => { downloadPlan() }}
                        />
                      </div>
                    )
                  }
                </div>
              </Card>
            </div>
          </div>
        )
      }
    </React.Fragment>
  )
}

export default PlanSuccess;