import React from "react";

import Nav from "../../layout/Nav";
import Body from "../../layout/Body";
import Footer from "../../layout/Footer";

import AboutUs from "../../modules/aboutUs";

const AboutUsPage = () => {
  return (
    <>
      <Nav />
      <Body>
        <AboutUs />
      </Body>
      <Footer />
    </>
  );
};

export default AboutUsPage;
