import React from "react";

import Heading from "../../../../components/atoms/heading";

import NotCoveredAsset1 from "../../../../assets/images/8.1_Not_Covered.svg";
import NotCoveredAsset4 from "../../../../assets/images/8.2_Not_Covered.svg";
import NotCoveredAsset3 from "../../../../assets/images/8.3_Not_Covered.svg";
import NotCoveredAsset2 from "../../../../assets/images/8.4_Not_Covered.svg";

import RightArrow from "../../../../assets/images/icons/rightArrow.png";
import Link from "../../../../shared/link";

import styles from "./whatNotCovered.module.scss";

const listItems = [
  
  {
    image_src: NotCoveredAsset2,
    heading: "Theft",
    description:"If you experience job loss because you’re stealing from your employer."
  },
  {
    image_src: NotCoveredAsset3,
    heading: "Fraud",
    description:"If your employer terminates you because you’ve committed fraud."
  },
  {
    image_src: NotCoveredAsset1,
    heading: "Performance issues",
    description:"If your employer has laid you off because of poor performance issues."
  },
  {
    image_src: NotCoveredAsset4,
    heading: "Waiting Period",
    description:"Job loss within the first 120 days of buying the plan."
  },
];
function WhatNotCovered(props) {
  return (
    <div className="bg-dark-grey-color">  
      <div className="body-container">
        <div className={styles.container}>
          <Heading text="What's not covered" className={styles.heading} />
          <div className={styles["list-items"]}>
            {listItems.map((item) => {
              const { image_src, heading, description } = item;
              return (
                <div className={styles.item}>
                  <div className={styles.cardImageContainer}>
                    <img alt="" src={image_src} />
                  </div>
                  <h3 className={styles.itemHeading}>{heading}</h3>
                  <p className={styles.itemSubheading}>{description}</p>
                </div>
              );
            })}
          </div>
          <Link to="/faq" onClick={()=>{
            window.dataLayer.push({'event': 'HomePage_Whatsnotcovered_KnowMore'});
            window.webengage.track("HomePage_Whatsnotcovered_KnowMore");
            }}
            className={styles.linkWrap}
            >
              <p>Know More</p>
              <img src={RightArrow} alt="learn-more" />
            </Link>
        </div>
      </div>
    </div>
  );
}

export default WhatNotCovered;
