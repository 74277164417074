import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import cx from "classnames";

import CloseIcon from "@mui/icons-material/Close";
import { Logo } from "../../assets/images";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from '@mui/material/styles';

import Badge from '@mui/material/Badge';

import linkedin from "../../assets/images/social/linkedin.svg";
import instagram from "../../assets/images/social/instagram.svg";
import youtube from "../../assets/images/social/youtube.svg";

import styles from "./nav.module.scss";
import useNavigateToGetQuote from "../../shared/hooks/useNavigateToGetQuote";
import Link from "../../shared/link";

import { GetQuoteContext, UserContext } from "../../modules/common-context";
import ApplicationContext from "../../shared/contexts/applicationContext";
import { EMPTY_OBJECT } from "../../shared/constants/app";
import useLogout from "../../shared/hooks/useLogout";
import socialHandleUrls from "../constants/socialHandlesUrl";
import PolicyWidget from "../../shared/components/policyWidget";
import MyReferral from "../../shared/components/myReferral";
import isUndefined from "../../shared/utils/isUndefined";

import POLICY_STATUS from "../../shared/components/policyWidget/constants/policyStatus";
import useNavigateToUrl from "../../shared/hooks/useNavigateToUrl";
import { getCookie, setCookie } from "../../shared/utils/cookie";
import { useNavigate } from "react-router-dom";

const currentAppState = {
  LOGIN: "Login",
  LOGOUT: "Logout",
};

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    right: -10,
    fontSize: '8px',
    backgroundColor: '#ff5722',
    color: '#fff',
    minWidth: '16px',
    height: '16px',
    borderRadius: '50%',
  },
}));

const Nav = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useContext(UserContext);
  const [loginTextState, setLoginTextState] = useState(() => {
    if (user?.id) return currentAppState.LOGOUT;
    return currentAppState.LOGIN;
  });
  const { dataPayload } = useContext(GetQuoteContext);
  const navigateToUrl = useNavigateToUrl();
  const navigate = useNavigate();

  const navigateToGetQuote = useNavigateToGetQuote();
  const searchParams = new URLSearchParams(window.location.search);

  const myReferralModalOpenStateRef = useRef(false);

  const handleMenuOpen = (intent) => {
    setAnchorEl((p) => {
      if (!isUndefined(intent)) return intent;
      return !p;
    });
  };

  const applicationContext = useContext(ApplicationContext);
  const { login = EMPTY_OBJECT, myReferral = EMPTY_OBJECT, policyWidget = EMPTY_OBJECT } =
    applicationContext;

  const { setShowLoginModal, showLoginModal } = login;

  const {
    showPolicyWidgetConfigurable,
    showPolicyWidget,
    handlePolicyWidgetUpdate,
    policyStatus
  } = policyWidget;

  const { setShowMyReferralModal, myReferrals } = myReferral;
  const logout = useLogout();

  // handles opening of referral modal after successful login, when users tries to open
  // MyReferrals when not logged in.
  if (myReferralModalOpenStateRef.current && currentAppState.LOGOUT === loginTextState) {
    myReferralModalOpenStateRef.current = false;
    setShowMyReferralModal(true);
  }

  const handleLoginAndLogout = () => {
    if (currentAppState.LOGIN === loginTextState) {
      setShowLoginModal(true);
      return;
    }
    if (currentAppState.LOGOUT === loginTextState) {
      handlePolicyWidgetUpdate({ showPolicyWidget: false });
      logout();      
      return;
    }
    setShowLoginModal(true);
    return;
  };

  const checkIsLogin = useCallback(() => {
    if (user?.id) {
      setLoginTextState(currentAppState.LOGOUT);
    } else {
      setLoginTextState(currentAppState.LOGIN);
    }
  }, [user?.id]);

  useEffect(() => {
    checkIsLogin();
  }, [user, checkIsLogin]);

  const handleGetQuoteCtaRedirect = (e) => {
    e?.stopPropagation();
    navigateToGetQuote();
    window.dataLayer.push({ event: "HomePage_Header_GetQuote" });
    window.webengage.track("Header_GetQuote");
  };

  const renderMyPolicy = () => {
    if (currentAppState.LOGOUT !== loginTextState || policyStatus !== POLICY_STATUS.PURCHASED) return;

    const handleMyPolicyClick = () => {
      window.scrollTo(0, 0);
      if (showPolicyWidgetConfigurable) {
        handlePolicyWidgetUpdate({ showPolicyWidget: !showPolicyWidget });
      }
      handleMenuOpen(false);
      window.webengage.track("Header_MyPolicy");
      window.dataLayer.push({ event: "HomePage_Header_MyPolicy" });
    };

    return (
      <span className={styles.link} onClick={handleMyPolicyClick}>
        My Plan
      </span>
    );
  };

  const handleRouteToNavigate = useCallback(() => {  
    // console.log(dataPayload, "handleRouteToNavigate") 
    if(dataPayload?.user_plan_status?.has_purchased) {
      // navigateToUrl("/dashboard");
      navigate({ pathname: '/dashboard' });
    } else {
      navigate({ pathname: '/' });
      // navigateToUrl("/");
    }
  }, [dataPayload?.user_plan_status]);

  useEffect(()=> {
    const _islogin = getCookie("js_login");
    if(!(_islogin === 'true' && dataPayload?.user_plan_status?.has_purchased)) {
      if(searchParams.get('login') == 'modal') {
        handleLoginAndLogout()
      }
    }
  },[dataPayload?.user_plan_status])

  const renderMyReferral = () => {

    const handleMyReferralClick = () => {
      const isLoggedOut = currentAppState.LOGOUT !== loginTextState;
      if (isLoggedOut) {
        myReferralModalOpenStateRef.current = true;
        setShowLoginModal(true);
        return;
      }
      setShowMyReferralModal(p => !p)
    }

    return (
      <span className={cx(styles.link, { 'mr-3': !!referralsCount })} onClick={handleMyReferralClick}>
        <StyledBadge badgeContent={referralsCount} color="success">
          <>My Referral</>
        </StyledBadge>
      </span>
    );
  }

  const referralsCount = myReferrals?.length || 0;

  return (
    <>
      <div className={styles.stripContainer}>
        <div className={styles.strip}>
          <div className={styles.stripItem}>Jobsurance is now Upsolv with extra benefits like placement support, anytime ITR filing, mental wellness and more. Check out our plans.</div>
        </div>
      </div>
      <div className={styles.container}>
        <div className={`body-container ${styles.navContainer}`}>
          <div className={styles.logoContainer} onClick={()=>{handleRouteToNavigate()}}>
            <img className={styles.logo} src={Logo} alt="" />
          </div>
          <div className={styles.navOptionsContainer}>
            <span className={styles.link}>
              <Link
                to="/about"
                onClick={() => {
                  window.webengage.track("Header_AboutUs");
                  window.dataLayer.push({ event: "HomePage_Header_AboutUs" });
                }}
              >
                About us
              </Link>
            </span>
            <span className={styles.link}>
              <Link
                to="/faq"
                onClick={() => {
                  window.webengage.track("Header_FAQs");
                  window.dataLayer.push({ event: "HomePage_Header_FAQs" });
                }}
              >
                FAQs
              </Link>
            </span>
            <span className={styles.link}>
              <Link
                to="/contact"
                onClick={() => {
                  window.webengage.track("Header_ContactUs");
                  window.dataLayer.push({ event: "HomePage_Header_ContactUs" });
                }}
              >
                Contact Us
              </Link>
            </span>
            {renderMyReferral()}
            {/* {renderMyPolicy()} */}
            {/* <span className={styles.link}>
            <Link to="/blogs">Blogs</Link>
          </span> */}
            <span className={styles.link} onClick={handleLoginAndLogout}>
              {loginTextState}
            </span>
            <div className="d-flex align-items-center">
              <button
                onClick={() => {
                  handleGetQuoteCtaRedirect();
                }}
                className={styles.getQuoteCta}
              >
                Get Quote
              </button>
              <div className={styles.hamburgerMenu}>
                <IconButton onClick={() => handleMenuOpen()}>
                  {anchorEl ? (
                    <CloseIcon className={styles.hamburgerIcon} />
                  ) : (
                    <MenuIcon className={styles.hamburgerIcon} />
                  )}
                </IconButton>
              </div>
            </div>
          </div>
          {anchorEl && (
            <div
              className={styles.mobileMenuItemContainer}
              onClick={() => handleMenuOpen()}
            >
              <div
                className={styles.mobileMenuItemContent}
                onClick={(e) => {
                  e?.stopPropagation();
                }}
              >
                <div className={styles.pageLinkHandles}>
                  <div className={styles.link}>
                    <Link
                      to="/about"
                      onClick={() => {
                        window.webengage.track("Header_AboutUs");
                        window.dataLayer.push({
                          event: "HomePage_Header_AboutUs",
                        });
                      }}
                    >
                      About us
                    </Link>
                  </div>
                  <div className={styles.link}>
                    <Link
                      to="/faq"
                      onClick={() => {
                        window.dataLayer.push({ event: "HomePage_Header_FAQs" });
                        window.webengage.track("Header_FAQs");
                      }}
                    >
                      FAQs
                    </Link>
                  </div>
                  <div className={styles.link}>
                    <Link
                      to="/contact"
                      onClick={() => {
                        window.webengage.track("Header_ContactUs");
                        window.dataLayer.push({
                          event: "HomePage_Header_ContactUs",
                        });
                      }}
                    >
                      Contact Us
                    </Link>
                  </div>
                  {/* <div className={styles.link}>
                  <Link to="/blogs">Blogs</Link>
                </div> */}
                  {renderMyReferral()}
                  {/* {renderMyPolicy()} */}
                  <div
                    className={cx("d-flex align-items-center", styles.loginCta)}
                    onClick={handleLoginAndLogout}
                  >
                    <div className={styles.loginIcon}>{loginTextState}</div>
                  </div>
                </div>
                <div className={styles.socialHandles}>
                  <Link
                    to={socialHandleUrls.linkedin}
                    target="_blank"
                    onClick={() => {
                      window.webengage.track("Header_Linkedin");
                    }}
                  >
                    <img
                      className={styles.socialHandle}
                      src={linkedin}
                      alt="linkedin"
                    />
                  </Link>
                  <Link
                    to={socialHandleUrls.youtube}
                    target="_blank"
                    onClick={() => {
                      window.webengage.track("Header_Youtube");
                    }}
                  >
                    <img
                      className={styles.socialHandle}
                      src={youtube}
                      alt="youtube"
                    />
                  </Link>
                  <Link
                    to={socialHandleUrls.instagram}
                    target="_blank"
                    onClick={() => {
                      window.webengage.track("Header_Instagram");
                    }}
                  >
                    <img
                      className={styles.socialHandle}
                      src={instagram}
                      alt="instagram"
                    />
                  </Link>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <PolicyWidget />
      <MyReferral />
    </>
  );
};

export default Nav;
