import React, { useCallback, useEffect, useState } from "react";
import "../index.scss";
import { PLAN_COLOR } from "../../../../constants/planColor";
import { Btn, CapsuleTag, Loader } from "../../../../components";
import ApiServices from "../../../../services/api";
import { API_END_POINT } from "../../../../components/getQuotes/constants/api-const";
import POLICY_STATUS from "../../../../shared/components/policyWidget/constants/policyStatus";
import { CircleTick, Download, InfoCircle, Mental_Plain, Allowance_Plain, Next, Dimond } from "../../../../assets/images";
import useDownloadPolicy from "../../../../shared/components/policyWidget/molecules/purchasedPolicy/hooks/useDownloadPolicy";
import PlanSlider from "../plan-slider";
import { BENEFITS_SLIDER } from "../../../../constants/benefits";
import { CalendarIcon } from "@mui/x-date-pickers";
import moment from "moment";
import { IconButton } from "@mui/material";
import PlanBreakupDialog from "../../../../components/getQuotes/plan-details-v2/plan-detail-breakup";
import amountWithCommas from "../../../../shared/utils/amount-seperator";

const ViewPlanV1 = (props) => {
    const [isLoading, setIsloading] = useState(true);
    const [showPriceBreakUpModal, priceBreakupToggleModal] = useState(false);
    const [planBenefits, setPlanBenefits] = useState([]);
    const [planSliderData, setPlanSliderData] = useState([]);
    const [policyStatus, setPolicyStatus] = useState("");
    const [purchasedPolicy, setPurchasedPolicy] = useState({});
    const [protectionPlan, setProtectionPlan] = useState({});

    const [planSelection, setPlanSelection] = useState("");
    const [priceBreakGrossAmount, setPriceBreakGrossAmount] = useState(null);
    const [priceBreakNetAmount, setPriceBreakNetAmount] = useState(null);
    const [priceBreakTax, setPriceBreakTax] = useState(null);
    const [extraBenefitsPrice, setExtraBenefitsPrice] = useState(null);
    const [priceGrossBenefits, setPriceGrossBenefits] = useState(null);
    const [protectionAmount, setProtectionAmount] = useState(null);
    const [isDownloadPolicyLink, handleDownloadPolicyLink] = useDownloadPolicy(purchasedPolicy);

    const handlePriceBreakupModal = () => {
        priceBreakupToggleModal(true);
    };

    const onClosePriceBreakupModal = () => {
        priceBreakupToggleModal(false);
    };

    const handlePriceBreakup = (data) => {
        try {
            setPlanSelection("Iconic");

            let priceBreakGrossAmount = data?.grossAmount;
            let priceBreakNetAmount = data?.netAmount;
            let priceBreakTax = data?.tax;
            let extraBenefitsCharge = 0;
            let priceGrossBenefits = 0;
            let protectionAmount = data?.protections[0]?.amount.toString().replace(/,/g, "") / 3;

            setPriceBreakGrossAmount(amountWithCommas(priceBreakGrossAmount));
            setPriceBreakNetAmount(amountWithCommas(priceBreakNetAmount));
            setPriceBreakTax(amountWithCommas(priceBreakTax));
            setExtraBenefitsPrice(amountWithCommas(extraBenefitsCharge));
            setPriceGrossBenefits(amountWithCommas(priceGrossBenefits));
            setProtectionAmount(amountWithCommas(protectionAmount));
        } catch (error) {
            // console.log("handlePriceBreakup Error:", error);
        }
    };

    const getProtectionPlan = useCallback(async (protection_plan_id) => {
        if (protection_plan_id === undefined) return;

        let response = await ApiServices.get(`${API_END_POINT.GET_PROTECTION_PLAN}/${protection_plan_id}`);
        if (response.status === 200) {
            console.log("getProtectionPlan >>>>", response.data);
            setProtectionPlan(response?.data?.protection_plan);
        }
        setIsloading(false);
    }, []);

    const getBenefit = async () => {
        try {
            let response = await ApiServices.get(API_END_POINT.GET_BENEFITS);
            if (response.status === 200) {
                let benefits = response?.data?.benefits;
                let filteredBenefits = benefits.filter((item) => item.ref_code === "BENE_3" || item.ref_code === "BENE_6");
                setPlanBenefits(filteredBenefits);
            } else {
                setPlanBenefits([]);
            }
        } catch (error) {
            console.log("getBenefit.Error", error);
        }
    };

    const formatPlanBenefitDataForPlanSlider = useCallback(() => {
        const formatedPlanBenefit = [];
        console.log("planBenefits", planBenefits);
        planBenefits.forEach((item) => {
            const icon = BENEFITS_SLIDER.find((obj) => obj.name === item.name);
            item["title"] = item?.name;
            item["image"] = icon?.image;

            formatedPlanBenefit.push(item);
        });

        setPlanSliderData(formatedPlanBenefit);
    }, [planBenefits]);

    useEffect(() => {
        getBenefit();
    }, []);

    useEffect(() => {
        formatPlanBenefitDataForPlanSlider();
    }, [formatPlanBenefitDataForPlanSlider, planBenefits]);

    useEffect(() => {
        setPolicyStatus(props.policyStatus);
        setPurchasedPolicy(props.purchasedPolicy);
    }, [props]);

    useEffect(() => {
        getProtectionPlan(purchasedPolicy?.protection_plan_id);
    }, [getProtectionPlan, purchasedPolicy]);

    useEffect(() => {
        handlePriceBreakup(protectionPlan);
    }, [protectionPlan]);

    return (
        <React.Fragment>
            <Loader open={isLoading} />
            <div className="dashboard-page-wrap existing-plan-item">
                <div className="text-wrap mb-4">
                    <p className="subtitle1 black-color font-family-medium">My Plan</p>
                </div>
                <div className="dashboard-upgarde-plan">
                    <div className="">
                        <div className="dash-plan-card">
                            <div className="plan-header-wrap">
                                <div className="plan-header-action-wrap">
                                    <CapsuleTag title="UP" subtitle={"Iconic"} className={`${PLAN_COLOR["ICONIC"]}`} />
                                    {policyStatus === POLICY_STATUS.PURCHASED && (
                                        <div className="text-wrap text-center">
                                            <Btn
                                                label="Download Plan"
                                                size="medium"
                                                className="btn btn-green font-family-medium btn-download-action"
                                                startIcon={<img src={Download} alt="download-plan"></img>}
                                                loading={isDownloadPolicyLink}
                                                onClick={handleDownloadPolicyLink}
                                            ></Btn>
                                            <p className="small-caption mt-2 btn-download-action">3 months unemployment allowance plan </p>
                                        </div>
                                    )}
                                </div>
                                {/* <div className="text-wrap mt-2">
                                    <p className="subtitle1 black-color font-family-regular"></p>
                                </div> */}
                            </div>

                            <div className="plan-body-footer-wrap">
                                <div className="plan-body-wrap plan-body-top-radius-wrap">
                                    <div className="row">
                                        <div className="col col-12 col-lg-6">
                                            <div className="benefits-info-wrap p-0">
                                                {planBenefits.map((item) => (
                                                    <div className="benefits-list text-wrap px-0">
                                                        <img src={CircleTick} alt="benefits-include" className="benefits-list-img"></img>
                                                        <p className="subtitle1 black-color font-familt-regular">
                                                            {item.name}
                                                            <br />

                                                            <span className="small-caption">
                                                                {item.ref_code === "BENE_6" ? `(For the first 3 months get a salary of ₹ ${protectionAmount} /month)` : null}
                                                            </span>
                                                        </p>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>

                                        <div className="col col-12 col-lg-6">
                                            <div className="plan-slider-wrap">
                                                <PlanSlider viewPlanVersion={props.viewPlanVersion} sliderData={planSliderData} protectionAmount={protectionAmount} />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="plan-footer-wrap">
                                    <div className="plan-footer-action-wrap">
                                        <div className="plan-date-details">
                                            <div className="text-wrap">
                                                <CalendarIcon />
                                                <p className="caption black-color font-family-regular">
                                                    <span>
                                                        Plan Starts on{" "}
                                                        <span className="font-family-medium text-nowrap">{moment.unix(protectionPlan.policyStartDate).format("Do MMM YYYY")}</span>{" "}
                                                    </span>
                                                    <span className=""> to Expire on</span>{" "}
                                                    <span className="font-family-medium text-nowrap">{moment.unix(protectionPlan.policyEndDate).format("Do MMM YYYY")}</span>
                                                    {/* <span className="d-block mt-2 "> waiting period end date <span className="font-family-medium d-block">{moment(purchasedPolicy.waiting_period_end_date).format("Do MMM YYYY")}</span></span> */}
                                                    <span className="d-block mt-2 ">
                                                        {" "}
                                                        Waiting period of 90 days starting from{" "}
                                                        <span className="font-family-medium text-nowrap">{moment.unix(protectionPlan.policyStartDate).format("Do MMM YYYY")}</span>
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="plan-price-details">
                                            <div className="text-wrap">
                                                <p className="caption font-family-medium black-color">
                                                    <span className="subtitle1 text-nowrap">₹ {protectionPlan.netAmount}</span> <br></br>
                                                    <span className="opacity-6">/ year</span>
                                                </p>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        handlePriceBreakupModal();
                                                    }}
                                                >
                                                    <img src={InfoCircle} alt="InfoCircle" className="info-icon" />
                                                </IconButton>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {policyStatus === POLICY_STATUS.PURCHASED && (
                            <div className="btn-wrap btn-download-action-mb">
                                <div className="text-wrap text-center">
                                    <Btn
                                        label="Download Plan"
                                        size="medium"
                                        className="btn btn-green font-family-medium"
                                        startIcon={<img src={Download} alt="download-plan"></img>}
                                        loading={isDownloadPolicyLink}
                                        onClick={handleDownloadPolicyLink}
                                    ></Btn>
                                    {/* <p className="small-caption mt-2">3 months unemployment allowance plan </p> */}
                                </div>
                            </div>
                        )}
                        <PlanBreakupDialog
                            planName={planSelection}
                            extraBenefitsPrice={extraBenefitsPrice}
                            priceBreakGrossAmount={priceBreakGrossAmount}
                            priceBreakTax={priceBreakTax}
                            priceGrossBenefits={priceGrossBenefits}
                            priceBreakNetAmount={priceBreakNetAmount}
                            isExistingUser={true}
                            onClose={(val) => {
                                onClosePriceBreakupModal(val);
                            }}
                            open={showPriceBreakUpModal}
                        />
                    </div>
                    {/* <div className="upgarde-plan-item">
                        <div className="upgarde-tag">
                            <p>Upgrade to</p>
                        </div>
                        <div className="dash-plan-card selected-card">
                            <div className="plan-header-wrap active">
                                <div className="plan-header-action-wrap">
                                    <CapsuleTag
                                        title="Job"
                                        subtitle={'Iconic'}
                                        className={`orange-color`}
                                    />
                                </div>
                                <div className="text-wrap mt-2">
                                    <p className="subtitle1 black-color font-family-regular"></p>
                                </div>
                            </div>
                            <div className="plan-body-footer-wrap">
                                <div className="plan-body-wrap plan-body-top-radius-wrap">
                                    <div className="text-wrap wrap-heading">
                                        <h6>Everything in Pro and…</h6>
                                    </div>
                                    <div className="row">
                                        <div className="col col-12 col-lg-6">
                                            <div className="benefits-info-wrap p-0">
                                                <div className="coloum-wise-list text-wrap px-0">
                                                    <img src={Mental_Plain} alt="benefits-include" className="illustration-img"></img>
                                                    <img src={CircleTick} alt="benefits-include" className="benefits-list-img"></img>
                                                    <p className="subtitle1 black-color font-familt-regular">Mental Wellness sessions with experts</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col col-12 col-lg-6">
                                            <div className="benefits-info-wrap p-0">
                                                <div className="coloum-wise-list text-wrap px-0">
                                                    <img src={Allowance_Plain} alt="benefits-include" className="illustration-img"></img>
                                                    <img src={CircleTick} alt="benefits-include" className="benefits-list-img"></img>
                                                    <p className="subtitle1 black-color font-familt-regular">3 month Unemployment allowance</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="plan-footer-wrap">
                                    <div className="plan-footer-action-wrap">
                                        <div className="plan-price-details">
                                            <div className="text-wrap">
                                                <p class="caption black-color opacity-5">Just pay additional</p>
                                                <IconButton
                                                    size="small"
                                                    onClick={() => { handlePriceBreakupModal() }}
                                                >
                                                    <img src={InfoCircle} alt="InfoCircle" className="info-icon" />
                                                </IconButton>
                                            </div>
                                            <div className="text-wrap">
                                                <p className="caption font-family-medium black-color">
                                                    <span className="subtitle1 text-nowrap">₹ {protectionPlan.netAmount}</span> <br></br>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="upgarde-item">
                                            <Btn
                                                label="Upgrade"
                                                size="medium"
                                                className="btn btn-upgrade font-family-medium"
                                                endIcon={<img src={Next} alt="download-plan"></img>}
                                                startIcon={<img src={Dimond} alt="download-plan"></img>}
                                                loading={isDownloadPolicyLink}
                                                onClick={handleDownloadPolicyLink}
                                            >
                                            </Btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <PlanBreakupDialog
                        planName={planSelection}
                        extraBenefitsPrice={extraBenefitsPrice}
                        priceBreakGrossAmount={priceBreakGrossAmount}
                        priceBreakTax={priceBreakTax}
                        priceGrossBenefits={priceGrossBenefits}
                        priceBreakNetAmount={priceBreakNetAmount}
                        isExistingUser={true}
                        onClose={(val) => { onClosePriceBreakupModal(val) }}
                        open={showPriceBreakUpModal}
                    />*/}
                </div>
            </div>
        </React.Fragment>
    );
};

export default ViewPlanV1;
