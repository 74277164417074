import ApiServices from "../../../services/api";
import { API_END_POINT } from "../../../components/getQuotes/constants/api-const";
import { useContext } from "react";
import { GetQuoteContext, GetQuoteViews, UserContext } from "../../../modules/common-context";
import ApplicationContext from "../../contexts/applicationContext/ApplicationContext";
import { handleApplicationContext } from "../../helpers/computeAndSetInitialData";
import updateReferralInApplicationContext from "../../helpers/updateReferralInApplicationContext";
import APP_DATA_CONFIG from "../../../helpers/app";
import { updateUserDashboardInApplicationContext } from "../../helpers/updateUserDashboardInApplicationContext";
import { setCookie } from "../../utils/cookie";
import { useNavigate } from "react-router-dom";


const useLogout = () => {
  const { setUserDetails } = useContext(UserContext);
  const { setDataPayload, setCurrView, dataPayload } = useContext(GetQuoteContext);
  const applicationContext = useContext(ApplicationContext);
  const navigate = useNavigate();

  const logout = async () => {
    try {
      await ApiServices.post(API_END_POINT.LOGOUT);
      setUserDetails();

      // get new unique-id (lead_id) when user gets loged out
      await APP_DATA_CONFIG.GENERATE_LEAD.request();

      // handle logout for getQuoteContext 
      // Retain the unique-id per page page view
      const leadId = dataPayload?.lead_id;

      // dataPayload.user_plan_status = null;
      // setDataPayload(dataPayload);
      setCookie("js_login", false);
      setDataPayload({ lead_id: leadId}, true);
      navigate({ 
        pathname: '/'
      });

      setCurrView(GetQuoteViews.LOGIN);

      // TO DO : refactor : clean application state for logout gracefully.
      handleApplicationContext({}, applicationContext);
      updateReferralInApplicationContext([], applicationContext);
      updateUserDashboardInApplicationContext([], applicationContext);
    } catch (err) {}
  };
  return logout;
};

export default useLogout;
