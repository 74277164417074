import React, { useEffect, useRef } from "react";
import { register } from "swiper/element/bundle";

import HighlightSectionName from "../../../../shared/components/highLightSectionName";
import Heading from "../../../../components/atoms/heading";
import TestiMonialCard from "./testimonialCard";

import rajatImage from "../../../../assets/images/testimonials/rajat.png";
import sunitDubeyImage from "../../../../assets/images/testimonials/sunitDubey.png";
import shahzebKhan from "../../../../assets/images/testimonials/shahzebKhan.jpeg";
import ratneshMishra from "../../../../assets/images/testimonials/ratneshMishra.jpeg"
import gauravGauba from "../../../../assets/images/testimonials/gauravGauba.jpeg";
import hiteshAggarwal from "../../../../assets/images/testimonials/hiteshAggarwal.jpeg";
import aditiArora from "../../../../assets/images/testimonials/aditiArora.jpeg";
import ujjwalRana from "../../../../assets/images/testimonials/ujjwalRana.jpeg";


import styles from "./peopleLoveUs.module.scss";

register();

const peopleLoveUsData = [
  {
    name: "Rohit",
    photo: rajatImage,
    text: "I'm truly impressed with Jobsurance's prompt claims resolution and exceptional customer service. Their team went above and beyond to ensure a smooth and efficient process during a challenging time. The speed and ease of filing a claim with Jobsurance were remarkable. They made a stressful situation much more manageable. Additionally, their customer support team was incredibly professional and supportive, offering guidance and assistance throughout the entire process. Just like how a term plan or life insurance provides financial security in case of unforeseen events, Jobsurance has offered an  invaluable protection for working professionals like me and it's a great product at a very nominal price. Thanks.",
    recommendedBy: "",
    footer: "MNC Employee (Benefits Availed)",
    likesCount: "",
    url: "",
    claimed: true,
  },
  {
    name: "Ayush",
    photo: rajatImage,
    text: "Gone are those days when working for a large corporation gave you job security. Although I was a good performer at my present company, there are a lot of reasons for job losses which are beyond your control. One of those reasons hit me because my company was acquired by another company and my role was made redundant. Because of the jobsurance, I didn't panic since I would still receive a salary from the insurance company. I would like to thank the jobsurance team for smoothly processing my claim. Hence getting yourself insured gives you a peace of mind that you deserve in the difficult time.",
    recommendedBy: "",
    footer: "MNC Employee (Benefits Availed)",
    likesCount: "",
    url: "",
    claimed: true,
  },
  {
    name: "Muktada",
    photo: rajatImage,
    text: "Layoffs are very common nowadays. I would recommend getting job loss plan by jobsurance. I got laid off recently and jobsurance supports me financially. I have got the first payout and am waiting for the next one . I would highly recommend this.",
    recommendedBy: "",
    footer: "Startup Employee (Benefits Availed)",
    likesCount: "",
    url: "",
    claimed: true,
  },
  {
    name: "Rajat Raj",
    photo: rajatImage,
    text: "Not every day, you come across a product that immediately catches your attention. When I saw Upsolv on Linkedin it clicked to me instantly amid the ongoing layoffs in India as well as globally. The 3 months' salary cover is enough to provide financial stability in case of Job loss.",
    recommendedBy: "Arjun",
    footer:"",
    likesCount: "34",
    url: "https://www.linkedin.com/search/results/content/?fromMember=%5B%22ACoAACYT1-kBaatqxEhX2VwbhLSVHg6_bsOKUEo%22%5D&heroEntityKey=urn%3Ali%3Afsd_profile%3AACoAACYT1-kBaatqxEhX2VwbhLSVHg6_bsOKUEo&keywords=rajat%20raj&position=0&searchId=a3769125-ea68-41a9-ae5d-abc59b8a7618&sid=7Fs&update=urn%3Ali%3Afs_updateV2%3A(urn%3Ali%3Aactivity%3A7066611472285523968%2CBLENDED_SEARCH_FEED%2CEMPTY%2CDEFAULT%2Cfalse)",
    claimed: false,
  },
  {
    name: "Sunil Dubey",
    photo: sunitDubeyImage,
    text: "The start-up Upsolv.in is giving a 3-month salary protection and an emergency fund kitty for another 3-months - what an incredible value proposition in this volatile world !! I registered on it, just out of curiosity and finding it one of the finest ideas in the recent times !",
    recommendedBy: "Avaneesh",
    footer:"",
    likesCount: "286",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7068126999017324544/",
    claimed: false,
  },
  {
    name: "Shahzeb Khan",
    photo: shahzebKhan,
    text: "Today, I want to share a wonderful experience that has provided me with peace of mind and a sense of security in my professional career as i purchased a Upsolv subscription by just one click and which will provide 3 months salary cover and provide emergency fund in case of job loss due to layoff .Kudos to Upsolv team for this wonderful initiative.",
    recommendedBy: "Mehndi",
    footer:"",
    likesCount: "46",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7078461423235776513/",
    claimed: false,
  },
  {
    name: "Ratnesh Mishra",
    photo: ratneshMishra,
    text: `Dear connections,

    Do check out and subscribe for Upsolv’s safety net to make these times a little easier!
    
    Good luck to Ankit & the team!`,
    recommendedBy: "Akash",
    footer:"",
    likesCount: "4",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7061388728975462400/",
    claimed: false,
  },
  {
    name: "Gaurav Gauba",
    photo: gauravGauba,
    text: `I wanted to take a moment to share my incredible experience with Upsolv This platform has an amazing income protection plan in case of Job loss in current market scenario.

    From the user-friendly interface and comprehensive coverage to their dedicated customer support team. With their innovative income protection offering, I now have a safety net that protects me in the event of a layoff, giving me the confidence to pursue my career ambitions.`,
    recommendedBy: "Saurav",
    footer:"",
    likesCount: "65",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7067893387453947907/",
    claimed: false,
  },
  {
    name: "Hitesh Aggarwal",
    photo: hiteshAggarwal,
    text: `Dear connections, my friend has launched this innovative product, addressing an important need in this uncertain world - Assuring monthly incomes!

    Do check it out.
    
    Congratulations on the launch`,
    recommendedBy: "Shivam",
    footer:"",
    likesCount: "19",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7061256880836280320/",
    claimed: false,
  },
  {
    name: "Aditi Arora",
    photo: aditiArora,
    text: `When I first heard about Upsolv, I knew this was a winner. It’s an extremely well-thought-out product that is relevant and appropriate considering the current wave of layoffs happening in many industries.

    The 3 months' salary cover will provide financial stability in case of Layoff.
    
    And the wonderful team and supportive customer service only further enhanced the overall experience and satisfaction.`,
    recommendedBy: "Suraj",
    footer:"",
    likesCount: "51",
    url: "https://www.linkedin.com/feed/update/urn:li:activity:7080886454842765312/",
    claimed: false,
  },
  {
    name: "Ujjwal Rana",
    photo: ujjwalRana,
    text: "#Upsolv is a great product offering enough peace of mind with its 3 month income protection cover against layoffs. I wish the team best of luck in building a great product for every employee.",
    recommendedBy: "Abhishek",
    footer:"",
    likesCount: "39",
    url: "https://www.linkedin.com/posts/ujjwalrana_Upsolv-activity-7080971035033956352-mH3j?utm_source=share&utm_medium=member_ios",
    claimed: false,
  },
];

const PeopleLoveUs = () => {
  const swiperRef = useRef(null);

  useEffect(() => {
    const swiperContainer = swiperRef.current;
    const params = {
      navigation: true,
      pagination: true,
      breakpoints: {
        300: {
          slidesPerView: 1,
          spaceBetween: 80,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1280: {
          slidesPerView: 2,
          spaceBetween: -60,
        },
      },
      injectStyles: [
        `
          .swiper-wrapper {
            padding-bottom: 40px;
          }
          .swiper-button-next{
            color: lightGrey;
            height: 40px;
            z-index: 1;
            @media (max-width: 1279px) {
              display: none;
            }
          }
          .swiper-button-prev{
            color: lightGrey;
            height: 40px;
            z-index: 1;
            @media (max-width: 1279px) {
              display: none;
            }
          }
          .swiper-pagination-bullet{
            width: 10px;
            height: 10px;
            background-color: #fd930d;
          }
          .swiper-pagination-bullets {
            margin-bottom: -10px;
          }
      `,
      ],
    };

    Object.assign(swiperContainer, params);
    swiperContainer.initialize();
  }, []);

  return (
    <div className="body-container text-center">
      <center>
        <div className={styles.container}>
          <HighlightSectionName text="PEOPLE LOVE US" />
          <Heading text="Real Customer's Speak" className={styles.heading} />
          <div className={styles.swiperContainer}>
            <swiper-container ref={swiperRef} init="false">
              {peopleLoveUsData?.map((peopleLoveUs) => {
                const { name, photo, text, recommendedBy, likesCount, url, claimed, footer } =
                  peopleLoveUs;
                    return (
                      <swiper-slide>
                        <TestiMonialCard
                          name={name}
                          photo={photo}
                          text={text}
                          recommendedBy={recommendedBy}
                          likesCount={likesCount}
                          url={url}
                          claimed={claimed}
                          footer={footer}
                        />
                      </swiper-slide>
                    );
                  })}
            </swiper-container>
          </div>
      </div>
      </center>
    </div>
  );
};

export default PeopleLoveUs;
