import React, { useCallback, useContext, useState } from "react";


import { formatSearchInput } from "./helpers/searchBar.general";

import styles from "./searchBar.module.scss";

import { GetQuoteContext } from "../../../../common-context";
import useNavigateToGetQuote from "../../../../../shared/hooks/useNavigateToGetQuote";

const SearchBar = (props) => {
  const {setDataPayload, dataPayload} = useContext(GetQuoteContext);
  const [searchInput, setSearchInput] = useState("");
  const navigateToGetQuote = useNavigateToGetQuote();

  const handleChange = useCallback((e) => {
    const userInput = e?.target?.value;
    const formattedUserInput = formatSearchInput(userInput);
    setSearchInput(formattedUserInput);
  }, []);

  const getQuoteRedirect = () => {

    const _searchInput = searchInput.replace(/[,₹]/g,'').trim()
    
    if(_searchInput.length > 0) {
      // setCookie('js_salary_quote', _searchInput);
      setDataPayload({ ...dataPayload, salary: _searchInput})
      navigateToGetQuote();
      window.dataLayer.push({'event': 'HomePage_1stSection_GetQuote'});
      window.webengage.track("HomePage_1stSection_GetQuote");
    } 

  }

  return (
    <div className={styles.container}>
      <input
        inputMode="numeric"
        value={searchInput}
        className={styles.input}
        onChange={handleChange}
        placeholder={props.placeholder}
        required
      />
      <button 
        className={styles.cta}
        onClick={()=>{getQuoteRedirect()}}
      >{props.btnLabel}</button>
    </div>
  );
};

export default SearchBar;
