import React from "react";

import DialogBox from "../../dialog-box";
import CapsuleTag from "../../capsule-tag";
import { PLAN_COLOR } from "../../../constants/planColor";

const PlanBreakupDialog = (props) => {
  return (
    <DialogBox
      customStyle="dialog-header-border"
      title={
        <div className="text-wrap">
          <CapsuleTag
            title="UP"
            subtitle={props.planName}
            className={`${PLAN_COLOR[props.planName?.toUpperCase()]}`}
          />
          <p className="subtitle1 font-family-medium plale-black-color mt-2">Up {props.planName} Protection Plan Cost breakdown per year</p>
        </div>
      }
      open={props.open}
      onClose={(val) => { props.onClose(val) }}
      content={
        <div className="card-content-wrap">
          {
            props?.isExistingUser === true ? (
              <div className="text-wrap">
                <p className="subtitle font-family-medium plale-black-color opacity-6 mb-2">
                  The cost of the {props.planName} Protection Plan is ₹{props.priceBreakNetAmount}/-
                </p>
                <p className="subtitle font-family-medium plale-black-color opacity-6 mb-2">
                  Your GST for the {props.planName} Protection plan is ₹{props.priceBreakTax}/-
                </p>
              </div>
            ) : (
              <div className="text-wrap">
                {
                  props.planName === "Iconic" && (
                    <React.Fragment>
                      <p className="subtitle font-family-medium plale-black-color opacity-6 mb-2">The cost of Unemployment allowance is ₹{props.extraBenefitsPrice}/-</p>
                      <p className="subtitle font-family-medium plale-black-color opacity-6 mb-2">The cost of the other benefits is ₹{props.priceGrossBenefits}/-</p>
                    </React.Fragment>
                  )
                }
                <p className="subtitle font-family-medium plale-black-color opacity-6 mb-2">
                  The {props.planName === "Iconic" && 'total'} cost of the {props.planName} Protection plan is ₹{props.priceBreakNetAmount}/-</p>
                {/* <p className="subtitle font-family-medium plale-black-color opacity-6 mb-2">Your GST for the {props.planName} Protection plan is ₹{props.priceBreakTax}/-</p> */}
                {/* <p className="subtitle font-family-medium plale-black-color opacity-6">Yearly amount payable ₹{priceBreakNetAmount}</p> */}
                {
                  props?.planUpgrade === true && (
                    <React.Fragment>
                      <p className="subtitle font-family-medium plale-black-color opacity-6 mb-2">Paid previously is ₹{props?.paidPreviously}/-</p>
                      <p className="subtitle font-family-medium plale-black-color opacity-6">Net pay is ₹{props?.planUpgradeAmount}/-</p>
                    </React.Fragment>
                  )
                }
                <p className="subtitle font-family-medium plale-black-color opacity-6 mt-2">All prices includes 18% GST</p>
              </div>
            )
          }

        </div>
      }
    />
  );
};

export default PlanBreakupDialog;
