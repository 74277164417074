import { Alert, Snackbar } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import OTPInput from "react-otp-input";

import ValidHelper from "../../../../../components/getQuotes/otp/molecules/validHelper/ValidHelper";
import InvalidHelper from "../../../../../components/getQuotes/otp/molecules/invalidHelper/InvalidHelper";
import { validateOtpLength } from "../../../../../components/getQuotes/otp/helpers/otp.validator";
import { Btn } from "../../../../../components";
import { Edit } from "../../../../../assets/images";
import {
  GetQuoteContext,
  UserContext,
} from "../../../../../modules/common-context";
import ApiServices from "../../../../../services/api";
import { API_END_POINT } from "../../../../../components/getQuotes/constants/api-const";
import getDataFromResponse from "../../../../utils/getDataFromResponse";
import phoneNumberMask from "../../../../utils/phone-number-mask";
import LOGIN_MODAL_VIEWS from "../../constants/loginModal.views";

import styles from "./otp.module.scss";
import { handleApplicationContext } from "../../../../helpers/computeAndSetInitialData";
import ApplicationContext from "../../../../contexts/applicationContext/ApplicationContext";
import { setCookie } from "../../../../utils/cookie";

const resendOtpCounter = 30;

const Otp = (props) => {
  const { otpToken, onSuccess, updateCurrentView, phoneNumber, setOtpToken, name } =
    props;
  const { dataPayload, setDataPayload } = useContext(GetQuoteContext);
  const { setUserDetails } = useContext(UserContext);
  const applicationContext = useContext(ApplicationContext);

  const [otp, setOtp] = useState("");
  const [isOtpValid, setIsOtpValid] = useState(undefined);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [isResendingEnabled, setIsResendingEnabled] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [timer, setTimer] = useState(resendOtpCounter);
  const [isResendingOtp, setIsResendingOtp] = useState(false);

  const handleSnackBar = () => {
    setSnackBarOpen(false);
  };

  useEffect(() => {
    let intervalId;
    if (timer > 0) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    if (timer === 0) {
      setIsResendingEnabled(true);
    }

    return () => clearInterval(intervalId); // Clean up the interval on unmount
  }, [timer]);

  const verifyUserOtp = async (otp) => {
    try {
      const payload = {
        otp,
        token: otpToken,
        lead_id: dataPayload?.lead_id,
      };
      const response = await ApiServices.post(
        API_END_POINT.VERIFY_OTP,
        payload
      );
      const verifyOtpResponse = getDataFromResponse(response);
      window.webengage.user.login(verifyOtpResponse?.user?.id);
      window.webengage.user.setAttribute("we_otp", {
        "otp": otp
      });
      window.webengage.user.setAttribute("we_sms_opt_in", true);
      window.webengage.user.setAttribute("we_whatsapp_opt_in", true);
      window.webengage.user.setAttribute("we_email_opt_in", true);

      window.webengage.user.setAttribute("we_first_name", verifyOtpResponse?.user?.name);
      window.webengage.user.setAttribute("we_phone", verifyOtpResponse?.user?.phone_number);

      
      setUserDetails(verifyOtpResponse?.user || {});
      handleApplicationContext(verifyOtpResponse?.user, applicationContext);
      setDataPayload({
        ...dataPayload,
        ...(verifyOtpResponse?.user || {}),
      });
      
      getUserDashboard(dataPayload);
      
      setCookie('js_login', true);

      window.dataLayer.push({'event':'get-quote_OTPVerification_Success'});
      window.webengage.track("get-quote_OTPVerification_Success", {
        otp: otp
      });
      window.webengage.user.setAttribute("get-quote_OTPVerification_Success", {
        otp: otp
      });
      onSuccess();
    } catch (err) {
      const _error = err.response;
      setSnackBarOpen(true);
      setSnackBarMessage(_error?.data?.message);
    }
  };

  const getUserDashboard = async(data) => {
    const userDashboardData = await ApiServices.get(API_END_POINT.USER_DASHBOARD);

    const userPlanStatus = {
      user_plan_status : userDashboardData?.data?.user_plan_status
    }

    const _dataPayload = {
      ...data,
      ...userPlanStatus
    }

    setDataPayload(_dataPayload);
  }

  const handleOtpChange = useCallback((otp) => {
    setOtp(otp);
    const hasValidOtpLength = validateOtpLength(otp);
    setIsOtpValid("");
    if (!hasValidOtpLength) return;
    verifyUserOtp(otp);
  }, [otpToken]);

  const displayTime = () => {
    const minutes = Math.floor(timer / 60);
    const seconds = timer % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const handleResendOtp = async () => {
    try {
      setIsResendingOtp(true);
      const resendOtpPayload = {
        phone_number: phoneNumber.value,
        name: name.value,
        lead_id: dataPayload?.lead_id,
      };
      const response = await ApiServices.post(
        API_END_POINT.SEND_OTP,
        resendOtpPayload
      );
      const sendOtpResponse = getDataFromResponse(response);
      const token = sendOtpResponse?.token;
      setOtpToken(token);
    } catch (err) {
    } finally {
      setIsResendingOtp(false);
      setIsResendingEnabled(false);
      setTimer(resendOtpCounter);
    }
  };

  const handleEdit = () => {
    updateCurrentView(LOGIN_MODAL_VIEWS.LOGIN);
  };

  return (
    <div className={styles.container}>
      <Snackbar
        open={snackBarOpen && !!snackBarMessage}
        autoHideDuration={6000}
        onClose={handleSnackBar}
      >
        <Alert onClose={handleSnackBar} severity="error">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <div className={styles.cardHeaderWrap}>
        <div className="mt-2 mb-2 text-wrap">
          <h1 className="font-family-bold black-color">One Time Password</h1>
        </div>
        <div className="auth-timer-wrap">
          <div className="auth-mobile-edit">
            <div className="text-wrap">
              <p className="black-color font-family-medium">
                +91-{phoneNumberMask(dataPayload?.phone_number)}
              </p>
            </div>
            <div className="btn-wrap">
              <Btn
                label="Edit"
                variant="text"
                className="btn btn-black font-family-medium"
                size="medium"
                startIcon={<img src={Edit} alt="edit" />}
                onClick={handleEdit}
              />
            </div>
          </div>
          <div className="text-wrap">
            <p className="caption black-color font-family-light">
              {displayTime()}
            </p>
          </div>
        </div>
      </div>
      <div className="form-row">
        <div className={`${isOtpValid} otp-input-wrap`}>
          <OTPInput
            value={otp}
            onChange={handleOtpChange}
            numInputs={4}
            renderInput={(props) => <input {...props} />}
            inputType="tel"
          />
        </div>
        {isOtpValid === "valid" && <ValidHelper />}
        {isOtpValid === "invalid" && <InvalidHelper />}
      </div>
      <div className="card-footer-wrap dotted-border">
        <div className="auth-footer-wrap">
          <div className="text-wrap">
            <p className="caption dark-grey-color font-family-medium opacity-5">
              Didn’t receive the verification code?
            </p>
          </div>
          <div className="btn-wrap">
            <Btn
              label="Resend"
              variant="text"
              className="btn btn-black font-family-medium"
              size="medium"
              disabled={!isResendingEnabled}
              onClick={handleResendOtp}
              loading={isResendingOtp}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Otp;
