
import React, { useContext, useEffect, useState } from "react";

import {useLocation} from 'react-router-dom';

import { GetQuoteContext } from "../../../modules/common-context";

import { Btn } from "../../../components/index"
import { Back } from "../../../assets/images/index";

import QuoteHeader from "../quote-header";
import EmployeeDetails from "./employment-details";
import KycDetails from "./kyc-details";
import PersonalDetails from "./personal-details";
import CommunicationDetails from "./communication-details";
import PlanCostDetails from "./plan-cost-details";
import StickyCTA from "../../../shared/components/stickyCTA/StickyCTA";
import useNavigateToUrl from "../../../shared/hooks/useNavigateToUrl";

const PlanConfirmation = () => {

  const location = useLocation();
  const navigateToUrl = useNavigateToUrl();
  const {setCurrView, dataPayload} = useContext(GetQuoteContext);
  const [userDetails, setUserDetails] = useState(dataPayload);

  const backTo = () => {
    if(location.state !== null){
      navigateToUrl(location.state.backTo);
      setCurrView("EMPLOYMENT_DETAILS");
    }else{
      setCurrView("PLAN_DETAILS")
    }
  }

  useEffect(()=> {
    window.scrollTo(0, 0);
  }, [])

  useEffect(()=> {
    const _storeUserDetails = sessionStorage.getItem("userDetails");
    const storeUserDetails = JSON.parse(_storeUserDetails);
    setUserDetails(storeUserDetails);
  }, [])

  return (
    <React.Fragment>
      <QuoteHeader
        btn={
          <Btn
            className="btn-white font-family-regular"
            label="Back"
            size="small"
            startIcon={<img src={Back} alt="Back" />}
            onClick={() => {backTo()}}
          />
        }
      />
      <StickyCTA 
        onClick={()=>{
          window.dataLayer.push({'event': 'get-quote_ProposalPage_Whatsapp'});
          window.webengage.track("get-quote_ProposalPage_Whatsapp");
        }}
      />
      <div className="layout-conatiner align-items-start plan-confirmation-layout"> 
        <div className="large-container plan-confirmation-wrap">
          <div className="plan-confirm-info-wrap">
            <EmployeeDetails
              userDetails={userDetails}
            />
            <KycDetails/>
            <PersonalDetails/>
            <CommunicationDetails/>
          </div>
          <div className="plan-cost-info-wrap">
            <PlanCostDetails/>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PlanConfirmation;
