import React, { useContext } from "react";

import ApplicationContext from "../../contexts/applicationContext/ApplicationContext";
import { EMPTY_OBJECT } from "../../constants/app";
import POLICY_STATUS_VS_COMPONENT from "./helpers/policyWidget.component";

import styles from "./policyWidget.module.scss";
import { UserContext } from "../../../modules/common-context";

const PolicyWidget = () => {
  const { policyWidget = EMPTY_OBJECT } = useContext(ApplicationContext);
  const { user } = useContext(UserContext);
  
  if(!user) return null;

  const { showPolicyWidget, order, policyStatus } = policyWidget;
  if (!showPolicyWidget) return null;

  const Component = POLICY_STATUS_VS_COMPONENT[policyStatus] || null;

  if (!Component) return null;

  return (
    <div className={styles.container}>
      <Component order={order} />
    </div>
  );
};

export default PolicyWidget;
