import React from "react";

import Heading from "../../../../components/atoms/heading/Heading";
import HighLightSectionName from "../../../../shared/components/highLightSectionName/HighLightSectionName";
import Plans from "./molecules/plans";
import CareerSvg from "../../../../assets/images/Career.svg";

import Link from "../../../../shared/link";

import styles from "./joinOurTeam.module.scss";

const JoinOurTeam = () => {
  return (
    <section className={styles.container}>
      <div className={styles.left}>
        <HighLightSectionName text="JOIN THE TEAM!" />
        <Heading
          text="We've got big plans and they include you!"
          className={styles.heading}
        />
        <Plans />
        <Link to="https://www.linkedin.com/company/jobsurance/jobs">
          <button className={styles.cta}>Join Us</button>
        </Link>
      </div>
      <div className={styles.right}>
        <img className={styles.image} src={CareerSvg} alt="" />
      </div>
    </section>
  );
};

export default JoinOurTeam;
