export const CONTENT_SECTIONS = [
  {
    sectionHeading: "SCOPE",
    description: [
      {
        content:
          "This Privacy Policy applies to the Services or Website or any associated Upsolv App or other website or page or service or online form that refers to or links to this Privacy Policy. This Privacy Policy applies regardless of whether you have accessed or used the Services or Website from your mobile telephone or handheld device or any other computer resource or any electronic device of any kind. ",
      },
      {
        content:
          "You acknowledge and agree that by accessing our Website or subscribing to our Service, you are authorizing and advising us to use your information and disclose it to other third parties as described in this Privacy Policy.",
      },
    ],
    footer: "",
  },

  {
    sectionHeading: "INFORMATION WE COLLECT",
    description: [
      {
        content:
          "**At the time of registration on our Website for a call back or waitlist or to get additional information:** When you choose to register on our Website for any of these purposes, we will mandatorily collect Mobile Number & Email address in order to communicate with you, understand your requirements and provide you with information. At our discretion, in order to provide you with adequate communication and information we may share your Mobile Number and Email address with our partners or service providers or vendors.",
      },
      {
        content:
          "**At the time of purchasing our products & Services:** When you create an account on our Website and/or purchase our Products & Services we will collect ",
      },
      {
        content:
          "(i) Mandatory information in order to provide you our Services or information about our Services, ",
        noSpacing: true,
      },
      {
        content: "(ii) Optional information only when you consent to the same,",
        noSpacing: true,
      },
      {
        content:
          "(iii) Information derived from your use of our Services or Website.",
        noSpacing: true,
      },
    ],
    items: [
      {
        content: "**The information which is mandatorily provided to us is:**",
        orderType: 'a',
        items: [
          {
            content:
              "**Mobile number and/or E-mail address:** For creation of a unique profile, identification, authentication via OTP and any communications from us or on our behalf or by any third parties engaged by us to provide Services",
          },
          {
            content:
              "**Username or e-mail address or profile photo:** If you sign up/sign in to your Upsolv account using a One-Tap/Single Sign On (SSO) from any supported third party service which allows authentication of your identity and allows you to share personal information with us (such as your name or email or profile photo) to pre-populate signup/sign into the Upsolv platform.",
          },
          {
            content: "**Name:** for purposes of displaying on your profile;",
          },
          {
            content:
              "**Date of Birth:** for the purposes of identification and/or understanding your eligibility for Services",
          },
          {
            content:
              "**Gender:** for the purposes of identification and/or customising scope of Services offered to you",
          },
          {
            content:
              "**Profile Picture:** for the purposes of displaying on your profile;",
          },
          {
            content:
              "**Location:** for the purposes of customising your experience on the Application",
          },

          {
            content:
              "**Professional details:** for the purposes of displaying on your profile and/or evaluating your eligibility and/or for customisation of products & Services offered to you. Such information includes, name and address of current or past employers, position, current employment status, location, tenure of service, name of manager, letter of appointment or termination or salary or other information as may be required",
          },
          {
            content:
              "**Description of Self:** for the purposes of displaying on your profile and/or customising your experience on the Website and/or customising scope of Services offered to you",
          },
          {
            content:
              "**Relationship Status:** this includes married or unmarried or divorced or other status for the purposes of evaluating your your eligibility and/or customising scope of Services offered to you",
          },

          {
            content:
              "**Aadhaar Number:** for the purposes of confirmation of identity",
          },
          {
            content:
              "**PAN Number:** for purposes of confirmation of identity and/or evaluation of eligibility and/or conducting KYC",
          },
          {
            content:
              "**UAN number:** for the purposes of confirmation of identity and/or evaluation of eligibility and/or conducting KYC",
          },

          {
            content:
              "**Bank account or credit card or debit card or UPI id or other banking information:** for the purposes of receiving or disbursing funds from and to you and/or KYC and/or conducting any financial transactions on or relating to the Upsolv platform",
          },
        ],
      },
      {
        orderType: 'a',
        content:
          "**The information that you provide by consent or optionally is:**",
        items: [
          {
            content:
              "**Access to information on your device:** to optimize your use and access of the Services or other content or materials provided by us. You can restrict the access from the device settings. No device permission will be taken from you without your explicit consent.",
          },
          {
            content:
              "**Information to Third Party Service:** In addition, if you authorise us to do so, we may grant/link third party services with Upsolv (eg. Facebook, Twitter, Instagram, UPI, Google, Gpay, LinkedIn, Naukri etc.). On your authorization, we may grant such third parties access to some or all (depending on the permission you give) of your information through our own API for use in connection with their services. You have the ability to control what information you share with these third parties through our API, but please note that any information you share with third parties will be governed by their privacy policies and we do not assume any responsibility for such third party's use of your information.",
          },

          {
            content:
              "**Surveys:** We may collect additional information at other times, including but not limited to, when you provide feedback(s), modify your content or email preferences, respond to surveys, provide comments, or communicate with us. This information may include your name, e-mail id, mobile number, location, etc. and only such information that you may choose to specifically provide to us.",
          },
        ],
      },
      {
        orderType: 'a',
        content:
          "**Information derived from your use of our Website or Services:** When you use our Website or Services, we may collect the following information automatically:",
        items: [
          {
            content:
              "Information on your browser’s user agent string and server logs, including your IP address, information in the cookies and activity on our Website",
          },
          {
            orderType: 'a',
            content:
              "URL information, time stamp, visit information, your browsing history on our Website",
          },
          {
            orderType: 'a',
            content:
              "Device information, such as make, model and operating system of the device used for browsing the Website",
            items: [
              {
                noSpacing: true,
                content:
                  "Events related to your actions (purchase, cancellations, time spent, how often and when you visit the Website, etc);",
              },

              {
                noSpacing: true,
                content: "Language.",
              },
              {
                noSpacing: true,
                content:
                  "Locational information as permitted by you in your mobile device settings.We do not collect any further information which is not specifically communicated to you as per this Privacy Policy.",
              },
            ],
          },
          {
            content:
              "You have the ability and choice of restricting certain kinds of information from being collected by adjusting your phone or device or browser settings. Please refer to your phone and device user manuals for more information.",
          },
        ],
      },
    ],
    footer: "",
  },
  {
    sectionHeading: "USE OF COOKIES",
    description: [
      {
        content:
          "We use cookies and similar technologies which are necessary to its functioning. Some of these cookies are essential for us to provide you with the Services. We, or our third-party service providers, may use cookies, mobile app analytics and similar technologies to track visitor activity and collect data on the App. We may combine this data with other personal data we have collected from users.",
      },
    ],
    footer: "",
  },
  {
    sectionHeading: "WHY WE COLLECT THIS INFORMATION",
    description: [
      {
        content:
          "We collect information to provide the Services and for the purposes as outlined below:",
      },
    ],

    items: [
      {
        noSpacing: true,
        content:
          "To help us identify you when you log onto the Website and when you register an account with us, and, to validate, authorize and map a specific profile with an authorised user",
      },
      {
        noSpacing: true,
        content:
          "To enhance the quality of the Services that we provide and improve your experience during browsing",
      },

      {
        noSpacing: true,
        content:
          "To evaluate eligibility and to provide you with Services as requested by you",
      },
      {
        noSpacing: true,
        content:
          "For providing location-based services, as and where requested by you",
      },
      {
        noSpacing: true,
        content:
          "For the performance of legal obligations under the Terms of Service",
      },

      {
        noSpacing: true,
        content: "To communicate with you",
      },
      {
        noSpacing: true,
        content:
          "To provide you with news, special offers, general information about other products and services along with marketing information and surveys, as agreed to by you;",
      },
      {
        noSpacing: true,
        content: "To provide and process service requests initiated by you.",
      },
    ],
    footer: "",
  },
  {
    sectionHeading: "WHEN WE SHARE YOUR INFORMATION",
    description: [
      {
        content:
          "We may share any information with our trusted partners or third parties who provide us with support services or infrastructure, for meeting the obligations agreed to between you and us.",
      },
      {
        content:
          "We may also share aggregated, non-personally identifiable information publicly and with our partners, like publishers, advertisers or connected sites to show trends about the general use of our Services. We will seek your consent prior to using or sharing your personal information for any other purpose, if so, identified at a later stage.",
      },
      {
        content:
          "We may also use your information to perform analytics and conduct customer research, to determine your interest, for identifying content that generates sales and to analyse traffic patterns.",
      },

      {
        content:
          "We also use your information to market to you as per the laws of your country, and to the extent permissible.",
      },
      {
        content:
          "You have the ability to allow us to share your information to third parties so that you can avail their services. You may disable or limit such sharing at any time.",
      },
    ],
    footer: "",
  },
  {
    sectionHeading: "DISCLOSURE OF INFORMATION",
    description: [
      {
        content: "We may also disclose your Personal Information only:",
      },
    ],

    items: [
      {
        content:
          "As required by law, such as to comply with a judicial order, executive orders, requirement by a law enforcement authority, or by other legal processes.",
      },
      {
        content:
          "When your desired products and services can only be provided if your personal information is disclosed.",
      },

      {
        content:
          "When we believe, in good faith, that disclosure is necessary to protect our rights, protect your safety or the safety of others, or, investigate fraud or crime;",
      },
      {
        content:
          "If we (or our affiliates) are involved in a merger, acquisition, or sale of all or substantially all of its assets or equity. Such information shall be disaggregated to the extent possible and shall be subject to execution of appropriate non disclosure agreements and binding privacy undertakings.",
      },
    ],
    footer: "We will never rent or sell your personally identifiable information to others.",
  },
  {
    sectionHeading: "YOUR INFORMATION RIGHTS",
    description: [
      {
        content:
          "We want to ensure that you are fully empowered and are completely aware of the rights that you are entitled to in the course of using our Services. You have a number of rights in relation to the information we hold about you.",
      },
    ],
    items: [
      {
        content:
          "**Access:** The right to access the information we hold about you, and to obtain details of the processing. You also have the right to access a list of all the third parties that have your personal information through us. ",
      },
      {
        content:
          "**Rectification:** The right to seek, correct, update and modify the information available with us, to ensure accuracy.",
      },

      {
        content:
          "**Cancellation:** The right to seek to cancel or erase, free of charge, your personal data when it is inadequate, excessive, or unnecessary. This shall be subject to lawful processing measures and any legal prescriptions. Please note that any discontinuation and cancellation may adversely restrict our performance of the Services availed by you.",
      },
      {
        content:
          "**Objection:** The right to withdraw your consent to our continued processing of the information, at any point of time, subject only to any legitimate reason for continued processing in certain circumstances. Please note that any objection may adversely restrict our performance of the Services availed by you. ",
      },
      {
        content:
          "**Portability:** The right to seek from us your personal data to be provided to another service provider, in a machine-readable format that we use at the time the request was made.",
      },
    ],
    footer: "You may exercise any of these rights by writing to **data.protection@Upsolv.in** Exercise of such rights shall be subject to the legal requirements, and our internal procedure.",
  },
  {
    sectionHeading:
      "HOW LONG WILL YOUR PERSONAL INFORMATION BE STORED WITH US?",
    description: [
      {
        content:
          "In relation to personal information, we store them for certain predetermined periods on the basis of (i) statutory and legal requirements; (ii) industry guidelines, (iii) de-identified or pseudonymised data sets to be used in an aggregated format for scientific, statistical or historical purposes.",
      },
      {
        content:
          "We retain the information which we have collected for a period no longer than is necessary, and as may be required in law. If we need to retain your personal information for a longer period, we will inform you prior to the extension of the storage period and seek your explicit consent to extend the retention period. We will delete your information whenever you request us to do so. However, we may archive and/or retain some information for legal purposes. Any other information which is processed by us for analytical purposes, will only be processed in an aggregated or non-identifiable basis.",
      },
    ],
    footer: "",
  },
  {
    sectionHeading: "OPTING OUT",
    description: [
      {
        content:
          "You can always opt out of our Services or choose not to disclose information to us at any time. However, keep in mind that some information may be needed to register with us or to take advantage of some of our features. By providing limited information, or by availing the opt-out provision, you may not be able to access full functionalities of our Services, and some features may be disabled for your access. We reserve the right to continue to keep a copy of any of your Personal Information if required by law. We may use any aggregated/anonymised data derived from your account, in a matter which shall not infringe upon your privacy.",
      },
    ],

    footer: "",
  },
  {
    sectionHeading: "SECURITY OF YOUR INFORMATION",
    description: [
      {
        content:
          "Your personal data is maintained by us in electronic form and is encrypted as per industry standards. We shall take all necessary precautions to protect your personal data and implement reasonable security practices and measures including certain managerial, technical, operational and physical security control measures that are commensurate with respect to the information being collected and the nature of our business.",
      },
      {
        content:
          "We restrict access to personal information to Company employees, contractors, and agents who need that information in order to process it. Anyone with this access is subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.",
      },
    ],
    footer: "",
  },
  {
    sectionHeading: "OVERSEAS TRANSFER",
    description: [
      {
        content:
          "We make every effort to store your data within the territorial jurisdiction of India. However, based on our cloud system provider your information may be transferred to and stored in locations outside India. We will do this only in compliance with requirements of Indian law and when the destination jurisdiction has an adequate and appropriate level of protection and where the transfer is lawful, and only when the same is required for us to meet our contractual and statutory obligations.",
      },
      {
        content:
          "When we transfer your personal data we will comply with our legal and regulatory obligations in relation to your personal data, including having a lawful basis for transferring personal data and putting appropriate safeguards in place to ensure an adequate level of protection for the personal data. We will also ensure that the recipient is obliged to protect your personal data at a standard of protection comparable to the protection under Indian laws.",
      },
    ],
    footer: "",
  },
  {
    sectionHeading: "PERSONAL DATA OF OTHERS",
    description: [
      {
        content:
          "In some situations, you may provide personal data of other individuals (family, friends, likewise) to us. If you provide us with such personal data, you represent and warrant that you have obtained their consent for their personal information to be collected, used and disclosed as set out in this Privacy Policy.",
      },
    ],
    footer: "",
  },
  {
    sectionHeading: "CHANGES TO THIS PRIVACY POLICY",
    description: [
      {
        content:
          "We make periodical changes to the Privacy Policy. Any significant changes we may make to our Privacy Policy in the future will be promptly notified to Users by posting the relevant terms in a prominent position on the webpage. The new terms may be displayed on the webpage, and you will be required to read and accept them to continue your use of the Services.",
      },
    ],
    footer: "",
  },
  {
    sectionHeading: "GRIEVANCE REDRESSAL MECHANISM",
    description: [
      {
        content:
          "Any discrepancies or grievances with regard to content and or comment or breach of this Agreement shall be taken up with our Grievance Officer as mentioned below via email at **data.protection@Upsolv.in.**",
      },
    ],
    footer: "",
  },
  {
    sectionHeading: "OUR CONTACT DETAILS",
    description: [
      {
        content:
          "**Vicinal Jobsurance Private Limited,** Workflo by OYO, 3rd Floor, Plot No. 224, Ranka Junction, Near Tin Factory, Bengaluru - 560036. **Phone Number:** +91-7795178080",
      },
    ],
    footer: "",
  },
];
