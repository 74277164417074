import callUs from "../../../assets/images/icons/call.png";
import emailUs from "../../../assets/images/icons/email.png";
import location from "../../../assets/images/icons/location.png";

export const CONTACT_ADDRESS = [
  {
    label: "Registered Office",
    text1:
      "Vicinal Jobsurance Private Limited, Workflow by OYO, Ranka Junction, Near Tin Factory, Bangalore",
    icon: location,
    link: "https://maps.app.goo.gl/7uioFamiqsJcxedA6?g_st=iw",
  },
  {
    label: "Corporate Office",
    text1:
      "UPSOLV (Formerly Jobsurance), 3rd Floor, HustleHub H204, 4th block, Near Sony Signal, Koramangala, Bengaluru, Karnataka 560034",
    icon: location,
    link: "https://maps.app.goo.gl/Yp6rJt14hiYP8dyN6",
  },
  {
    label: "Phone & Whatsapp",
    text1: "+91 77951 78080",
    icon: callUs,
    link: "tel:+917795178080",
  },
  {
    label: "Email",
    text1: "support@upsolv.in",
    icon: emailUs,
    link: "mailto:support@upsolv.in",
  },
];
