import React from "react";
import ApplicationContext from "./ApplicationContext";
import usePolicyInitiateState from "./hooks/usePolicyInitiateState";
import useLoginInitiateState from "./hooks/useLoginInitiateState";
import useMyReferralInitialState from "./hooks/useMyReferralInitialState";
import useDashboardInitialState from "./hooks/useDashboardInitialState";

const ApplicationContextWrapper = ({ children }) => {
  const loginModalInitialState = useLoginInitiateState();
  const policyWidgetInitialState = usePolicyInitiateState();
  const myReferralInitialState = useMyReferralInitialState();
  const dashboardInitialState = useDashboardInitialState();

  const value = {
    login: loginModalInitialState,
    policyWidget: policyWidgetInitialState,
    myReferral: myReferralInitialState,
    dashboard: dashboardInitialState,
  };

  return (
    <ApplicationContext.Provider value={value}>
      {children}
    </ApplicationContext.Provider>
  );
};

export default ApplicationContextWrapper;
