import React from "react";
import { LinkedInWhite } from "../../assets/images";

const MentorCard = (props) => {
  return (
    <div className={`mentor-wrap ${props.data.style}`}>
      <div className={`mentor-item`}>
        <div className="mentor-image-wrap">
          <img src={props.data.image} alt={props.data.name}></img>
        </div>
        <div className="mentor-info-wrap">
          <h6 className="mentor-title mb-2">{props.data.name}</h6>
          <p className="mentor-subtitle mb-2">{props.data.desgination} <br></br>({props.data.companies})</p>
          <h6 className="mentor-caption">{props.data.domain}</h6>
          <h6 className="mentor-experience">{props.data.experience}</h6>
          <h6 className="mentor-social">
            <a href={props.data.linkedin} target="_blank">
              <img src={LinkedInWhite} alt="linkdein"></img>
            </a>
          </h6>
        </div>
      </div>
    </div>
  );
};


export default MentorCard;
