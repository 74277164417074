import React from "react";

import ProtectedBuildingSvg from "../../../../assets/images/protectedBuilding.svg";

import styles from "./heroSection.module.scss";

const HeroSection = () => {
  return (
    <section className={styles.container}>
      <div className={styles.contentContainer}>
        <div className={styles.header}>
          Revolutionising job security in India
        </div>
        <p className={styles.subHeader}>
          Personalised solutions to prepare you against layoffs
        </p>
      </div>
      <div className={styles.protectedBuildingContainer}>
        <img src={ProtectedBuildingSvg} alt="" height="100%" />
      </div>
    </section>
  );
};

export default HeroSection;
