import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./image.module.scss";

const Image = ({ src, alt, className, children }) => {
  if (!children) {
    return (
      <div className={styles.container}>
        <img className={cx(styles.img, className)} src={src} alt={alt} />
      </div>
    );
  }

  return children;
};

Image.propTypes = {
  src: "",
  alt: "",
  className: "",
  children: null,
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.element,
};

export default Image;
