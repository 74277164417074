import React from "react";

import HeroSection from "./components/heroSection";
import WrappingUp from "./components/wrappingUp";
import ExploreWhy from "./components/exploreWhy";
import Benefits from "./components/benefits";
import LayoffAllAround from "./components/layoffAllAround";
import WhatIsCovered from "./components/whatIsCovered";
import HowItWorks from "./components/howItWorks";
import InstantSupport from "./components/instantSupport";
import PeopleLoveUs from "./components/peopleLoveUs";
import WhatsNotCovered from "./components/whatsNotCovered";
import FAQs from "./components/FAQs";
import StickyCTA from "../../shared/components/stickyCTA";
import OurPlans from "./components/ourPlans";
import Mentors from "./components/mentors";
import TrustedBy from "./components/trustedBy";
import MentalHealth from "./components/mentalHealth";


const Home = (props) => {

  
  return (
    <div>
      <HeroSection />
      <TrustedBy/>
      <PeopleLoveUs />
      <LayoffAllAround />
      <OurPlans/>
      <HowItWorks />
      
      {/* <Benefits /> */}
      <MentalHealth/>
      <Mentors/>
      <WhatIsCovered />
      <WhatsNotCovered />
      
      
      <InstantSupport />
      
      <FAQs />
      {/* <ExploreWhy /> */}
      <WrappingUp />
      <StickyCTA />
    </div>
  );
};

export default Home;
