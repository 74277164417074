import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./text.module.scss";

const Text = ({ text, className }) => {
  return <p className={cx(styles.text, className)}>{text}</p>;
};

Text.propTypes = {
  text: null,
  className: "",
};

Text.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export default Text;
