import React from "react";
import HeroSection from "./molecules/heroSection/HeroSection";
import ContentSection from "./molecules/contentSection/ContentSection";
import StickyCTA from "../../shared/components/stickyCTA/StickyCTA";

const TermsAndConditions = () => {
  return (
    <div>
      <HeroSection />
      <ContentSection />
      <StickyCTA />
    </div>
  );
};

export default TermsAndConditions;
