import React from "react";

import Accordion from "../../shared/components/accordion";
import HighLightSectionName from "../../shared/components/highLightSectionName";
import Heading from "../../components/atoms/heading";
import StickyCTA from "../../shared/components/stickyCTA/StickyCTA";

import faqData from "./faqs.constants";

import styles from "./faqs.module.scss";

const Faqs = () => {
  return (
    <div className="body-container">
      <div className={styles.container}>
        <HighLightSectionName text="UPSOLV FAQs" />
        <Heading text="Answers to your questions" className={styles.heading} />
        <Accordion items={faqData} allOpen />
        <StickyCTA />
      </div>
    </div>
  );
};

export default Faqs;
