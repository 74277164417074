import React from "react";
import styles from "./blogCards.module.scss";
import BlogCard from "../BlogCard";

const getCardRowType = (index) => {
  const startOfRowIndex = [0, 5, 9, 14];
  const mod = index % 14;
  let isStartOfRow = startOfRowIndex.includes(mod);
  let type;
  if (mod < 3) {
    type = 1;
  } else if (mod >= 9 && mod < 14) {
    type = 2;
  } else type = 3;

  return {
    type,
    isStartOfRow,
  };
};

const Cards = ({ cards = [], cardAlignment, rowType }) => {
  return (cards || []).map((card, idx) => {
    return (
      <BlogCard
        cardData={card}
        cardAlignment={cardAlignment}
        rowType={rowType}
        key={`cards-${idx}`}
      />
    );
  });
};

const Row1 = ({ horizontalStack, verticalStack }) => {
  return (
    <div className={styles.row}>
      <div className={styles.row1Left}>
        <Cards cards={horizontalStack} cardAlignment={"vertical"} />
      </div>
      <div className={styles.row1Right}>
        <Cards cards={verticalStack} cardAlignment={"horizontal"} />
      </div>
    </div>
  );
};

const Row2 = ({ horizontalStack, verticalStack }) => {
  return (
    <div className={styles.row}>
      <div className={styles.row2Left}>
        <Cards cards={verticalStack} cardAlignment={"horizontal"} />
      </div>
      <div className={styles.row2Right}>
        <Cards cards={horizontalStack} cardAlignment={"vertical"} />
      </div>
    </div>
  );
};

const Row3 = ({ horizontalStack }) => {
  return (
    <div className={styles.row}>
      <div className={styles.row3}>
        <Cards cards={horizontalStack} cardAlignment={"vertical"} rowType={3} />
      </div>
    </div>
  );
};

const BlogCards = ({ blogCards }) => {
  return (
    <div className={styles.container}>
      <div className={styles.dwebContainer}>
        {blogCards.map((card, index, cards) => {
          const { type, isStartOfRow } = getCardRowType(index);
          if (!isStartOfRow) {
            return null;
          }
          switch (type) {
            case 1: {
              const horizontalStack = cards.slice(index, index + 2);
              const verticalStack = cards.slice(index + 2, index + 5);
              return (
                <Row1
                  horizontalStack={horizontalStack}
                  verticalStack={verticalStack}
                />
              );
            }
            case 2: {
              const verticalStack = cards.slice(index, index + 3);
              const horizontalStack = cards.slice(index + 3, index + 5);
              return (
                <Row2
                  verticalStack={verticalStack}
                  horizontalStack={horizontalStack}
                />
              );
            }
            case 3: {
              const horizontalStack = cards.slice(index, index + 4);
              return <Row3 horizontalStack={horizontalStack} />;
            }
            default:
              return null;
          }
        })}
      </div>
      <div className={styles.mwebContainer}>
        {blogCards.map((card, idx) => {
          return <BlogCard cardData={card} key={`blogCards-${idx}`} />;
        })}
      </div>
    </div>
  );
};

export default BlogCards;
