import React, { useContext, useEffect, useState } from "react";

import Claims from "./claims";
import EditPlans from "./edit-plans";
import ViewPlans from "./view-plans";
import { NAVIGATION_CONST } from "../sidenav/sidenav.constant";
import { Bulb, Clapping } from "../../assets/images";

import "./index.scss";
import { GetQuoteContext } from "../../modules/common-context";

const DashboardPages = (props) => {
  
  const {dataPayload} = useContext(GetQuoteContext);
  const [activeView, setActiveView] = useState(props.activeView);

  const PAGE_VIEW = {
    VIEW_PLANS: NAVIGATION_CONST.VIEW_PLANS,
    CLAIMS: NAVIGATION_CONST.CLAIMS,
    EDIT_PLAN: NAVIGATION_CONST.EDIT_PLAN,
  }

  useEffect(() => {
    if(
      PAGE_VIEW.VIEW_PLANS == props.activeView ||
      PAGE_VIEW.CLAIMS == props.activeView ||
      PAGE_VIEW.EDIT_PLAN == props.activeView
    ) {
      setActiveView(props.activeView);
    }
  }, [props.activeView])


  return (
    <div className="dashboard-content-wrap">
      <div className="dash-content-header">
        <div className="text-wrap d-flex align-items-center">
          <h5 className=" black-color font-family-thin me-2">Hi <span className="text-capitalize">{dataPayload?.name}</span>, </h5>
          <img src={Clapping} alt="welcome"></img>
        </div>
      </div>
      {PAGE_VIEW.VIEW_PLANS == activeView && <ViewPlans></ViewPlans>}
      {PAGE_VIEW.CLAIMS == activeView && <Claims></Claims>}
      {PAGE_VIEW.EDIT_PLAN == activeView && <EditPlans></EditPlans>}
    </div>
  );
};

export default DashboardPages;
