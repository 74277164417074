
import React, { useContext, useState } from "react";
import { Alert, IconButton, Snackbar } from "@mui/material";


import Card from "../../Card";
import { GetQuoteContext } from "../../../../modules/common-context";

import { Btn, ItemList } from "../../../../components/index"
import { AvtarMale, Cancel, Valid, AvtarFemale, AvtarOther } from "../../../../assets/images/index";
import ApiServices from "../../../../services/api";
import { API_END_POINT } from "../../constants/api-const";
import APP_DATA_CONFIG from "../../../../helpers/app";




const KycDetailsVerify = (props) => {

  const { setDataPayload, dataPayload } = useContext(GetQuoteContext);
  const { panResponse } = props;
  const panSecure = panResponse?.panNo.slice(0, 5)
  const [pannumberSecure, setPannumberSecure] = useState(panSecure);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [isFormSubmit, setIsFormSubmit] = useState(false);

  const panNumberNotMatch = () => {
    props.isVerify(false);
    props.onClose();
  }

  // handleSnackBar
  const handleSnackBar = () => {
    setSnackBarOpen(false);
  }

  const patchUserData = async (payload) => {
    try {
      const res = await ApiServices.patch(API_END_POINT.USER_SELF, payload);
      if(res.status === 200){
        props.isVerify(true);
        props.onClose();
        setIsFormSubmit(false);
      }

    } catch (error) {
      const _error = error.response
      setSnackBarOpen(true);
      setSnackBarMessage(_error?.data?.message);
      setIsFormSubmit(false);
    }
  }

  const panNumberMatch = () => {
    setIsFormSubmit(true);
    const patchUserPayload = {
      lead_id: dataPayload?.lead_id,
      pan: panResponse?.panNo
    };
    
    patchUserData(patchUserPayload)
    window.dataLayer.push({'event':'get-quote_ProposalPage_KycDetailsSave'});
    window.webengage.track("get-quote_ProposalPage_KycDetailsSave", patchUserPayload);
    window.webengage.user.setAttribute("get-quote_ProposalPage_KycDetailsSave", patchUserPayload);
    setDataPayload({...dataPayload, pan: panResponse?.panNo})
    APP_DATA_CONFIG.GENERATE_LEAD.request("get-quote_ProposalPage_KycDetailsSave", dataPayload?.lead_id)
  }

  const genderType = (val) => {
    switch(val) {
      case 'Male': return AvtarMale;
      case 'Female': return AvtarFemale;
      case 'Others': return AvtarOther;
      case null || '' || undefined : return AvtarOther;
      default: return
    }
  }

  return (
    <React.Fragment>
      <Snackbar open={snackBarOpen && !!snackBarMessage} autoHideDuration={6000} onClose={handleSnackBar}>
        <Alert onClose={handleSnackBar} severity="error">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Card classes={"card card-shadow"}>
        <div className="card-header-wrap d-flex">
          <div className="text-wrap">
            <h3 className="font-family-bold black-color">KYC Details</h3>
            <div className="d-flex align-items-center">
              <img alt="Valid" src={Valid} width={16} />
              <p className="caption font-family-regular black-color opacity-6 ml-2">We found your Details!!</p>
            </div>
          </div>
          <div className="btn-wrap">
            <IconButton
              size="small"
              onClick={props.onClose}
            >
              <img alt="Cancel" src={Cancel} />
            </IconButton>
          </div>
        </div>
        <div className="card-content-wrap">
          <div className="form-wrap">
            <div className="form-row">
              <div className="card-highlight-wrap card-highlight-left p-0">
                <ItemList
                  className=""
                  primary={
                    <span className="text-wrap">
                      <span className="subtitle black-color font-family-bold">{panResponse.name}</span>
                    </span>
                  }
                  secondary={
                    <span className="text-wrap">
                      <span className="caption black-color font-family-medium">PAN: {pannumberSecure}XXXXX</span>
                    </span>
                  }
                  avatar={
                    <img alt="genderType" src={genderType(panResponse.gender)} />
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="card-footer-wrap card-btn-end-wrap">
          <div className="btn-wrap">
            <Btn
              label="Nope"
              variant="text"
              className="btn btn-black font-family-medium"
              size="large"
              onClick={()=> {panNumberNotMatch()}}
            />
            <Btn
              label="Yup, That's me"
              variant="contained"
              className="btn btn-primary font-family-medium"
              size="large"
              onClick={() => {panNumberMatch()}}
              disabled={isFormSubmit}
              loading={isFormSubmit}
            />
          </div>
        </div>
      </Card>
    </React.Fragment>
  )
}

export default KycDetailsVerify;
