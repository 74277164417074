import React from "react";

import styles from "./whatIsCovered.module.scss";

const CoveredItem = (props) => {
  const {
    logo,
    heading,
    subheading
  } = props;

  return (
    <div className={styles.coveredItem}>
      <img className={styles.logo} src={logo} alt="What is covered item" />
      <h2 className={styles.itemHeading}>{ heading }</h2>
      <p className={styles.itemSubheading}>{ subheading }</p>
    </div>
  );
};

export default CoveredItem;
