import React from "react";

import './index.scss';

const CapsuleTag = (props) => {
  return (
    <div className={`capsule-wrap ${props.className}`}>
      <div className="capsule-title">
        {props.title}
      </div>
      <div className="capsule-subtitle">
        {props.subtitle}
      </div>
    </div>
  );
};

CapsuleTag.defaultProps = {
  
}

export default CapsuleTag;
