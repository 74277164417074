import React from "react";
import HeroSection from "./components/heroSection/HeroSection";
import Benefits from "./components/benefits";
import ExploreWhy from "./components/trustedBy";
import MentorsCategory from "./components/mentors-category/mentorsCategory";


const Mentors = (props) => {

  
  return (
    <div>
      <HeroSection></HeroSection>
      <Benefits></Benefits>
      <MentorsCategory></MentorsCategory>
      <ExploreWhy></ExploreWhy>
    </div>
  );
};

export default Mentors;
