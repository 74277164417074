import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./heading.module.scss";

const Heading = ({ text, className }) => {
  return <p className={cx(styles.heading, className)}>{text}</p>;
};

Heading.propTypes = {
  text: PropTypes.string,
};

Heading.defaultProps = {
  text: "",
};

export default Heading;
