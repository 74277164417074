import React, { useEffect, useRef } from "react";

import "./heroSection.scss";
import SearchBar from "../../../Home/components/heroSection/searchBar";
import { MentorHeader } from "../../../../assets/images";

const HeroSection = (props) => {

  return (
    <div className="hero-container mentor-hero-container">
      <div className="body-container">
        <div className="hero-wrap">
          <div className="hero-left-wrap">
            <div className="text-wrap">
              <h1 className="white-color font-family-bold mb-2">
                <span className="">Get interview prep</span>
                <span className="d-block primary-color">From top specialists</span>
              </h1>
              <p className="subtitle1 white-color font-family-medium black-color">Ex-founders, Head of Business, Marketing, Tech will help you crack interviews</p>
            </div>
            <div className="search-wrap mt-3">
              <SearchBar 
                placeholder="Enter your salary"
                btnLabel="Check Price!"
              />
            </div>
          </div>
          <div className="hero-right-wrap">
            <div className="hero-illust my-4">
              <img src={MentorHeader} alt="mentor"></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
