import React from "react";

import { Dialog, IconButton } from "@mui/material";

import Card from "../getQuotes/Card";
import { Cancel } from "../../assets/images";

// style

const DialogBox = (props) => {
  return (
    <Dialog onClose={props.onClose} open={props.open} className={`dialog-wrap ${props.customStyle}`}>
      <Card classes={"card card-shadow"}>
        <div className="card-header-wrap d-flex">
          { props.title }
          <div className="btn-wrap">
            <IconButton
              size="small"
              onClick={props.onClose}
            >
              <img src={Cancel} alt="Cancel"/>
            </IconButton>
          </div>
        </div>
        <div className="card-content-wrap">
          {props.content}
        </div>
        {
          props.footer && (
            <div className="card-footer-wrap card-btn-end-wrap">
              {props.footer}
            </div>
          )
        }
      </Card>
    </Dialog>
  );
};

DialogBox.defaultProps = {
  onClose: null,
  open: false
}

export default DialogBox;
