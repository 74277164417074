import React from "react";
import Heading from "../../../../components/atoms/heading";
import Card from "../../../../components/molecules/Card";
import HighlightSectionName from "../../../../shared/components/highLightSectionName";

import styles from "./trustedBy.module.scss";
import { Corporates, ListedCompanies, Unicorns } from "../../../../assets/images";

const ExploreWhy = () => {
  return (
    <div className="bg-white-color py-3">
      <div className="body-container">
        <div className={styles.container}>
          <HighlightSectionName text="TRUSTED BY" />

          {/* MWEB HEADING */}
          <div className={styles.headingContainer}>
            <Heading text="Employees from leading corporates have trusted" className={`d-inline ${styles.headingOne}`} />
            <Heading text=" UPSOLV" className={`d-inline ${styles.headingTwo}`} />
          </div>

          <div className={styles.exploreWhyItems}>
            <Card>
              <Card.Image className={styles.cardImage} src={Corporates} />
              <div className={styles.exploreWhyCardInfo}>
                <Card.Heading
                  className={styles.cardHeading}
                  text="1000+"
                />
                <Card.Text
                  className={`${styles.cardText} mt-0`}
                  text="Corporates"
                />
              </div>
            </Card>
            <Card>
              <Card.Image className={styles.cardImage} src={Unicorns} />
              <div className={styles.exploreWhyCardInfo}>
                <Card.Heading
                  className={styles.cardHeading}
                  text="30+"
                />
                <Card.Text
                  className={`${styles.cardText} mt-0`}
                  text="Unicorns"
                />
              </div>
            </Card>
            <Card>
              <Card.Image className={styles.cardImage} src={ListedCompanies} />
              <div className={styles.exploreWhyCardInfo}>
                <Card.Heading
                  className={styles.cardHeading}
                  text="100+"
                />
                <Card.Text
                  className={`${styles.cardText} mt-0`}
                  text="MNCs"
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExploreWhy;
