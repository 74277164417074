import { EMPTY_ARRAY, EMPTY_OBJECT } from "../../../../shared/constants/app";

const makeCompanyOption = (company = EMPTY_OBJECT) => {
  const { name, id, status, pincode } = company;
  return {
    id,
    label: name,
    status,
    pincode
  };
};

export const makeCompanyOptions = (companies = EMPTY_ARRAY) => {
  const companyAsOptions = companies?.map(makeCompanyOption);
  return companyAsOptions;
};
