export const formatSearchInput = (searchInput) => {
  if (!searchInput) return "";
  
  const parsedSalary = parseInt(
    searchInput.replace(/₹ /g, "").replace(/[^0-9.-]+/g, "")
  );
  
  const salary = isNaN(parsedSalary) ? "" : parsedSalary;
  if(!salary) return '';

  const formattedSalary = salary.toLocaleString("en-IN");
  if (!formattedSalary) return "";

  const formattedSalaryWithCurrency = `₹ ${formattedSalary}`;

  return formattedSalaryWithCurrency;
};
