import React from "react";

import Nav from "../../layout/Nav/Nav";
import Body from "../../layout/Body/Body";
import Footer from "../../layout/Footer/Footer";

import ContactUs from "../../modules/contactUs/ContactUs";

const ContactUsPage = () => {
  return (
    <>
      <Nav />
      <Body>
        <ContactUs />
      </Body>
      <Footer />
    </>
  );
};

export default ContactUsPage;
