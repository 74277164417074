import React, { useContext, useState } from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Btn from "../../../../../components/buttons";
import { India } from "../../../../../assets/images";

import validatePhoneNumber from "./helpers/phoneNumber.validators";
import validateName from "./helpers/name.validators";
import ApiServices from "../../../../../services/api";
import { API_END_POINT } from "../../../../../components/getQuotes/constants/api-const";
import { GetQuoteContext } from "../../../../../modules/common-context";
import getDataFromResponse from "../../../../utils/getDataFromResponse";
import LOGIN_MODAL_VIEWS from "../../constants/loginModal.views";

import styles from "./loginForm.module.scss";

const LoginForm = ({
  updateCurrentView,
  setOtpToken,
  phoneNumber,
  setPhoneNumber,
  name,
  setName,
}) => {
  const { dataPayload } = useContext(GetQuoteContext);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [isRequestingOtp, setIsRequestingOtp] = useState(false);

  const handlePhoneChange = (e) => {
    const phNumber = e?.target?.value;
    setPhoneNumber((prev) => {
      return {
        ...prev,
        value: phNumber,
        err: "",
      };
    });
  };
  const handlePhoneFieldBlur = () => {
    const err = validatePhoneNumber(phoneNumber.value);
    if (!err) {
      return;
    }
    setPhoneNumber((prev) => ({
      ...prev,
      err,
    }));
  };
  const phoneFieldHasError = !!phoneNumber.err;
  const phoneFieldHelperText = phoneNumber.err;

  const handleNameChange = (e) => {
    const nameVal = e?.target?.value;
    setName((prev) => {
      return {
        ...prev,
        value: nameVal,
        err: "",
      };
    });
  };
  const handleNameFieldBlur = () => {
    const err = validateName(name.value);
    if (!err) {
      return;
    }
    setName((prev) => ({
      ...prev,
      err,
    }));
  };
  const nameFieldHasError = !!name.err;
  const nameFieldHelperText = name.err;

  const handleSnackBar = () => {
    setSnackBarOpen(false);
  };

  const handleRequestOtp = async () => {
    handleNameFieldBlur();
    handlePhoneFieldBlur();
    if (nameFieldHasError || phoneFieldHasError) return;
    try {
      const payload = {
        phone_number: phoneNumber.value,
        name: name.value,
        lead_id: dataPayload?.lead_id,
      };
      setIsRequestingOtp(true);
      const response = await ApiServices.post(API_END_POINT.SEND_OTP, payload);
      const sendOtpResponse = getDataFromResponse(response);
      const token = sendOtpResponse?.token;
      setOtpToken(token);
      updateCurrentView(LOGIN_MODAL_VIEWS.OTP);

      // tracking
      window.dataLayer.push({'event':'get-quote_NameMobile_Continue'});
      window.webengage.track("get-quote_NameMobile_Continue", payload);

      window.webengage.user.setAttribute("get-quote_NameMobile_Continue", payload);
      window.webengage.user.setAttribute("we_first_name", name.value);
      window.webengage.user.setAttribute("we_phone", phoneNumber.value);

    } catch (err) {
      const error = err?.response;
      setSnackBarOpen(true);
      setSnackBarMessage(error?.data?.message);
    } finally {
      setIsRequestingOtp(false);
    }
  };

  return (
    <div className={styles.container}>
      <Snackbar
        open={snackBarOpen && !!snackBarMessage}
        autoHideDuration={6000}
        onClose={handleSnackBar}
      >
        <Alert onClose={handleSnackBar} severity="error">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <div className={styles.heading}>
        <p>Let's get started</p>
      </div>
      <div className={styles.formWrap}>
        <div className={styles.formRow}>
          <TextField
            id="name"
            label="Name"
            variant="outlined"
            required={true}
            size="large"
            value={name.value}
            onChange={handleNameChange}
            onBlur={handleNameFieldBlur}
            error={nameFieldHasError}
            helperText={nameFieldHelperText}
          />
        </div>
        <div className={styles.formRow}>
          <TextField
            label="Mobile Number"
            variant="outlined"
            required={true}
            size="large"
            value={phoneNumber.value}
            type="tel"
            inputProps={{
              maxLength: 10,
            }}
            InputProps={{
              startAdornment: (
                <div className="mobile-country-wrap">
                  <img src={India} alt="india-phone-number" />
                  <span>+91</span> <KeyboardArrowDownIcon />
                </div>
              ),
            }}
            onChange={handlePhoneChange}
            onBlur={handlePhoneFieldBlur}
            error={phoneFieldHasError}
            helperText={phoneFieldHelperText}
          />
        </div>
        <div className={styles.formRow}>
          <div className={styles.btnContainer}>
            <Btn
              label="Request OTP"
              className={`btn-primary font-family-medium full-width ${styles.btn}`}
              size="large"
              type="submit"
              onClick={handleRequestOtp}
              loading={isRequestingOtp}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
