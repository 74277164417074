import { Allowance_Plain, Mental_Plain, ResumeBuilding_Plain, Session1_1to1_Plain, TAPortal_Plain, Exclusive_Support } from "../assets/images";


const BENEFITS_SLIDER = [
    {
        name: "TA Portal Subscription",
        image: TAPortal_Plain,
        ref_code: "BENE_1"
    },
    {
        name: "Anytime ITR filing",
        image: ResumeBuilding_Plain,
        ref_code: "BENE_2"
    },
    {
        name: "Exclusive Placement Support",
        image: Exclusive_Support,
        ref_code: "BENE_3"
    },
    {
        name: "1:1 session with industry domain specialist",
        image: Session1_1to1_Plain,
        ref_code: "BENE_4"
    },
    {
        name: "1:1 Support with industry domain specialist",
        image: Session1_1to1_Plain,
        ref_code: "BENE_4"
    },
    {
        name: "Mental Wellness sessions with experts",
        image: Mental_Plain,
        ref_code: "BENE_5"
    },
    {
        name: "Unemployment Allowance",
        image: Allowance_Plain,
        ref_code: "BENE_6"
    },
];

export {BENEFITS_SLIDER}