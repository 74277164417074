import React, { useEffect, useRef } from "react";
import { register } from "swiper/element/bundle";

import HighLightSectionName from "../../../../shared/components/highLightSectionName";
import Heading from "../../../../components/atoms/heading";
import Step from "./Step";
import howItWorksGif from "../../../../assets/images/howItWorks.gif";
import useNavigateToGetQuote from "../../../../shared/hooks/useNavigateToGetQuote";

import styles from "./howItWorks.module.scss";

register();

const HowItWorks = () => {
  const swiperRef = useRef(null);

  const navigateToGetQuote = useNavigateToGetQuote();

  const handleGetQuoteClick = (e) => {
    window.dataLayer.push({'event': 'HomePage_HowitWorks_GetQuote'});
    window.webengage.track("HomePage_HowitWorks_GetQuote");
    e?.stopPropagation();
    navigateToGetQuote();
  }

  useEffect(() => {
    const swiperContainer = swiperRef.current;
    const params = {
      navigation: true,
      pagination: true,
      injectStyles: [
        `
          .swiper-wrapper {
            padding-bottom: 20px;
            width: calc(100vw - 15px);
          }
          .swiper-button-next{
            z-index: 1;
            display: none;
          }
          .swiper-button-prev{
            z-index: 1;
            display: none;
          }
          .swiper-pagination-bullet{
            width: 10px;
            height: 10px;
            background-color: #fd930d;
          }
          .swiper-pagination-bullets {
          }
      `,
      ],
    };

    Object.assign(swiperContainer, params);
    swiperContainer.initialize();
  }, []);

  return (
    <div className="body-container">
      <div className={styles.container}>
        <div className={styles.howItWorksImageContainer}>
          <img src={howItWorksGif} alt="How It works" loading="lazy" />
        </div>
        <div className={styles.rightSection}>
          <div className={styles.rightInnerSection}>
            <HighLightSectionName text="HOW IT WORKS" />
            <Heading
              text="Get your UPSOLV subscription in 3 simple steps"
              className={styles.stepsHeadingText}
            />

            <div className={styles.howItWorksImageContainerRightSection}>
              <img src={howItWorksGif} alt="How It works" loading="lazy" />
            </div>

            <div className={styles.dwebRightSection}>
              <Step
                stepNo="1"
                heading="Fill in Your Details"
                subheading="Enter your mobile number and monthly salary to get started"
              />
              <Step
                stepNo="2"
                heading="Compare Plans"
                subheading="Starting as low as ₹999 per year"
              />
              <Step
                stepNo="3"
                heading="Make Your Payment"
                subheading="With Hassle-free online payments"
              />
                <div className={styles.getQuote} onClick={()=>{handleGetQuoteClick();}} >Check Price!</div>
            </div>

            {/* MWEB RIGHT SECTION */}
            <div className={styles.mwebRightSection}>
              <swiper-container ref={swiperRef} init="false">
                <swiper-slide>
                  <Step
                    stepNo="1"
                    heading="Fill in Your Details"
                    subheading="Enter your mobile number and your current salary details to get started."
                  />
                </swiper-slide>
                <swiper-slide>
                  <Step
                    stepNo="2"
                    heading="Compare Plans"
                    subheading="Starting as low as ₹999 per year."
                  />
                </swiper-slide>
                <swiper-slide>
                  <Step
                    stepNo="3"
                    heading="Make Your Payment"
                    subheading="With Hassle-free online payments"
                  />
                </swiper-slide>
              </swiper-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
