import { API_END_POINT } from '../components/getQuotes/constants/api-const';
import ApiServices from '../services/api';
import {updateUserDashboardInApplicationContext, handleUserContext, handleGetQuoteContext} from '../shared/helpers/updateUserDashboardInApplicationContext';
import getDataFromResponse from '../shared/utils/getDataFromResponse';



const fetchUserDashboard = async () => ApiServices.get(API_END_POINT.USER_DASHBOARD);

const onSuccess = (params, userDashboardResponse) => {
  const { applicationContext } = params;
  const userDashboard = getDataFromResponse(userDashboardResponse) || [];

  // handleUserContext(userDashboard, userContext)
  // handleGetQuoteContext(userDashboard, getQuoteContext)
  updateUserDashboardInApplicationContext(userDashboard, applicationContext);
};

const onFailure = (_params, _err) => {
  // DO NOTHING
  return undefined;
};

const onSettled = (params, settledPromise) => {
  const {
    status,
    value,
    reason
  } = settledPromise;
  if (status === 'fulfilled') return onSuccess(params, value);
  if (status === 'rejected') return onFailure(params, reason);
  return undefined;
}

const getDataOnSettled = (settledPromise) => {
  const {
    status,
    value
  } = settledPromise;

  if (status === 'fulfilled') return getDataFromResponse(value);
  if (status === 'rejected') return undefined;
  return undefined;
}

const userDashboardConfig = {
  request: fetchUserDashboard,
  onSettled,
  getDataOnSettled
};

export default userDashboardConfig;
