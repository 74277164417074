import { useState } from "react";

import ApiServices from "../../../../../../services/api";
import getDataFromResponse from "../../../../../utils/getDataFromResponse";
import { API_END_POINT } from "../../../../../../components/getQuotes/constants/api-const";

const useDownloadPolicy = (order) => {
  const protectionPlanId = order?.protection_plan_id;
  const [isDownloadPolicyLink, setIsDownloadPolicyLink] = useState(false);

  const handleDownloadPolicyLink = async () => {
    try {
      const protectionPlanResponse = await ApiServices.get(
        `${API_END_POINT.GET_PROTECTION_PLAN}/${protectionPlanId}`
      );
      const protectionPlan = getDataFromResponse(protectionPlanResponse);
      const protectionPlanDownloadLink =
        protectionPlan?.protection_plan?.downloadProtectionPlanLink;
      window.open(protectionPlanDownloadLink, "_self");
    } catch (err) {
    } finally {
      setIsDownloadPolicyLink(false);
    }
  };

  return [isDownloadPolicyLink, handleDownloadPolicyLink];
};

export default useDownloadPolicy;
