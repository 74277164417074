import React from "react";

import { ListItem, ListItemAvatar, ListItemText } from "@mui/material";

// style
import './index.scss';

const ItemList = ({avatar,className, secondaryAction, primary, secondary}) => {
  return (
    <ListItem
      className={`${className} item-list`}
      secondaryAction={
        secondaryAction
      }
    >
      {avatar && (
        <ListItemAvatar>
          {avatar}
        </ListItemAvatar>
      )}
      <ListItemText
        primary={primary}
        secondary={secondary}
      />
    </ListItem>
  );
};

ItemList.defaultProps = {

}

export default ItemList;
