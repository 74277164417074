import { useContext, useEffect, useState } from "react";
import useNavigateToUrl from "../../../../../hooks/useNavigateToUrl";
import {
  GetQuoteContext,
  GetQuoteViews,
  UserContext,
} from "../../../../../../modules/common-context";
import ApiServices from "../../../../../../services/api";
import { API_END_POINT } from "../../../../../../components/getQuotes/constants/api-const";
import getQuoteBasedOnSalary from "../../../helpers/getQuoteBasedOnSalary";

const useRetryPaymentForContinuePurchase = (order) => {
  const { setCurrView, dataPayload, setDataPayload } =
    useContext(GetQuoteContext);
  const { user } = useContext(UserContext);
  const navigateToUrl = useNavigateToUrl();
  const [isRetryingPayment, setIsRetryingPayment] = useState(true);

  const updateUserQuote = async () => {
    try {
      const payload = {
        name: user?.name,
        salary: user?.salary,
        mobile: user?.phone_number,
        lead_id: dataPayload?.lead_id,
      };
      const res = await ApiServices.post(API_END_POINT.GET_OUR_PROTECTION_PLAN, payload);
      const quoteData = res?.data?.quotes;
      const quoteBasedOnSalary = getQuoteBasedOnSalary(order?.amount_payable, quoteData)

      const _employee = {
        company_id: user?.company_id,
        company_name: user?.company_name,
        salary: user?.salary,
        company_pincode: user?.pincode,
        company_details: {
          id: user?.company_id,
          label: user?.company_name,
          pincode: user?.pincode,
        },
        employee_type: "Full Time",
        quoteData: quoteData,
        selected_quote: quoteBasedOnSalary,
        selected_quote_planName: quoteBasedOnSalary.planName,
      };
      const updatedDataPayload = {
        ...dataPayload,
        ..._employee,
      };
      setDataPayload(updatedDataPayload);
    } catch (err) {
    } finally {
      setIsRetryingPayment(false);
    }
  };

  useEffect(() => {
    updateUserQuote();
  }, []);

  const onRetryPayment = () => {
    setCurrView(GetQuoteViews.PLAN_CONFIRMATION);
    navigateToUrl("/get-quote");
  };

  return [isRetryingPayment, onRetryPayment];
};

export default useRetryPaymentForContinuePurchase;
