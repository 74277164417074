import React, { useContext, useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";

import LoginForm from "./molecules/loginForm";
import Otp from "./molecules/otp";

import ApplicationContext from "../../contexts/applicationContext/ApplicationContext";

import { EMPTY_OBJECT } from "../../constants/app";
import LOGIN_MODAL_VIEWS from "./constants/loginModal.views";
import fetchReferrals from "../../../components/getQuotes/otp/fetchReferrals";

const LoginModal = () => {
  const applicationContext = useContext(ApplicationContext);
  const { showLoginModal, setShowLoginModal } =
    applicationContext?.login || EMPTY_OBJECT;

  const handleClose = () => {
    setShowLoginModal(false);
    postCloseModal();
  };

  const [phoneNumber, setPhoneNumber] = useState({
    value: "",
    err: "",
  });

  const [name, setName] = useState({
    value: "",
    err: "",
  });

  const [currentView, setCurrentView] = useState(LOGIN_MODAL_VIEWS.LOGIN);
  const [otpToken, setOtpToken] = useState("");

  const postCloseModal = () => {
    setOtpToken("");
    setCurrentView(LOGIN_MODAL_VIEWS.LOGIN);
    setPhoneNumber({
      value: "",
      err: "",
    })
    setName({
      value: "",
      err: "",
    })
  }

  const handleOtpSuccess = () => {
    handleClose();
    postCloseModal();
    fetchReferrals(applicationContext);
  };

  useEffect(() => {
    return () => {
      handleOtpSuccess();
    };
  }, []);

  const renderCurrentView = () => {
    switch (currentView) {
      case LOGIN_MODAL_VIEWS.OTP: {
        return (
          <Otp
            otpToken={otpToken}
            onSuccess={handleOtpSuccess}
            updateCurrentView={setCurrentView}
            setOtpToken={setOtpToken}
            phoneNumber={phoneNumber}
            name={name}
          />
        );
      }
      case LOGIN_MODAL_VIEWS.LOGIN:
      default: {
        return (
          <LoginForm
            updateCurrentView={setCurrentView}
            setOtpToken={setOtpToken}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            name={name}
            setName={setName}
          />
        );
      }
    }
  };

  return (
    <Dialog onClose={handleClose} open={showLoginModal} className="dialog-wrap">
      {renderCurrentView()}
    </Dialog>
  );
};

export default LoginModal;
