import React, { useCallback, useContext, useMemo } from 'react'

import ApplicationContext from '../../contexts/applicationContext'
import { UserContext } from '../../../modules/common-context'

import Dialog from "@mui/material/Dialog";

import GiftCardIcon from '@mui/icons-material/CardGiftcard';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';

import detectMobile from '../../utils/detectMobile';
import getReferralRewards from './helpers/getReferralRewards';

import styles from './myReferral.module.scss';

import { EMPTY_OBJECT } from '../../constants/app'

import ClipBoard from '../clipBoard';
import getHost from '../../utils/getHost';
import ReactRouteDomLink from '../../link/Link';

import { Cancel } from "../../../assets/images";


const MyReferral = () => {

  const applicationContext = useContext(ApplicationContext);
  const { user } = useContext(UserContext);
  const { myReferral } = applicationContext;

  const { showMyReferralModal, setShowMyReferralModal, myReferrals } = myReferral || EMPTY_OBJECT;

  const handleClose = useCallback(() => {
    setShowMyReferralModal(false);
  }, []);


  const { referral_code = '' } = user || EMPTY_OBJECT;

  const referral_link = `${getHost()}?utm_referral=${referral_code}`;

  const referralCodeShareData = useMemo(() => ({
    text: `Hi,\nGet your salary secured with Upsolv. Earn upto ₹500 amazon voucher on your subscription.\nTo purchase the subscription use my referral code ${referral_code} or `,
    url: referral_link,
  }), [referral_code, referral_link]);

  const referralLinkShareData = useMemo(() => ({
    text: `Hi,\nGet your salary secured with Upsolv. Earn upto ₹500 amazon voucher on your subscription.\nTo purchase the subscription use my referral code ${referral_code} or `,
    url: referral_link,
  }), [referral_code, referral_link]);

  const isMobile = useMemo(() => detectMobile(), []);

  const maxWords = isMobile ? 30 : 60;

  const {
    showReferralRewards,
    referralRewardText
  } = getReferralRewards(myReferrals);

  return (
    <Dialog onClose={handleClose} open={showMyReferralModal} className="dialog-wrap">
      <div className={styles.container}>

        <div className={styles.heading}>
          <p>Refer and earn upto ₹25,000</p>

          <div>
              <img  onClick={handleClose} src={Cancel} alt="cancel" />
          </div>
        </div>

        <div className={styles.referralInfo}>
          <ClipBoard heading="Your Referral Code" text={referral_code} maxWords={maxWords} enableShareForMweb nativeShareOptions={referralCodeShareData} />
          <div className={styles.divider}>
            <hr className={styles.dash} />
            <span>or</span>
            <hr className={styles.dash} />

          </div>
          <ClipBoard heading="Your Link" text={referral_link} maxWords={maxWords} enableShareForMweb nativeShareOptions={referralLinkShareData} />
        </div>

        <div className={styles.benefits}>
          <div className={styles.benefit}>
            <div className={styles.left}>
              <div className={styles.leftContainer}>
                <GiftCardIcon />
              </div>
            </div>
            <div className={styles.right}>
              <p className={styles.highlight}>Earn Rewards:</p>
              <div className={styles.highlightInfo}>
                <ol className={styles.highlightInfoList}>
                  <li>You &amp; Your friend both will get up to ₹500 rupees Amazon gift card individually for each successful referral</li>
                  <li>You can earn rewards without you being a Upsolv customer by sharing your link/code with your friends</li>
                  <li>Accumulate your rewards for every successful referral and your accumulated rewards can be redeemed when you become a Upsolv customer</li>
                  <li>Copy the code &amp; refer your friends today</li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        {
          showReferralRewards && (
            <div className={styles.rewards}>
              <div className={styles.rewardIcon}>
                <EmojiEventsOutlinedIcon />
              </div>
              <p className={styles.rewardText}>{referralRewardText}</p>
            </div>
          )
        }

        <div className={styles.tnc}>
          <ReactRouteDomLink to='/terms' onClick={handleClose} >
            *T&C apply
          </ReactRouteDomLink>
        </div>

      </div>
    </Dialog>
  )
}

export default MyReferral
