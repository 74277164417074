import React from "react";
import Heading from "../../../../components/atoms/heading";
import Card from "../../../../components/molecules/Card";
import HighlightSectionName from "../../../../shared/components/highLightSectionName";

import styles from "./trustedBy.module.scss";
import { Corporates, ListedCompanies, Unicorns, standoutHassleFree, standoutInstant, standoutSavings } from "../../../../assets/images";
import useNavigateToGetQuote from "../../../../shared/hooks/useNavigateToGetQuote";

const ExploreWhy = () => {

  const navigateToGetQuote = useNavigateToGetQuote();

  const handleGetQuoteCta = (e) => {
    window.dataLayer.push({'event': 'MentorPag_WhyJobsurance_CheckPrice'});
    window.webengage.track("MentorPag_WhyJobsurance_CheckPrice");
    e?.stopPropagation();
    navigateToGetQuote();
  }

  return (
    <div className="bg-white-color py-3">
      <div className="body-container">
        <div className={styles.container}>
          <HighlightSectionName text="WHY UPSOLV" />

          {/* MWEB HEADING */}
          <div className={styles.headingContainer}>
            <Heading text="Level UP! your career & salary" className={`d-inline ${styles.headingOne}`} />
            <Heading text=" Only with UPSOLV" className={`${styles.headingTwo}`} />
          </div>

          <div className={styles.exploreWhyItems}>
            <Card>
              <Card.Image className={styles.cardImage} src={standoutInstant} />
              <div className={styles.exploreWhyCardInfo}>
                <Card.Heading
                  className={styles.cardHeading}
                  text="Low subscription pricing"
                />
                <Card.Text
                  className={`${styles.cardText}`}
                  text="Starting as low as ₹999 per year"
                />
              </div>
            </Card>
            <Card>
              <Card.Image className={styles.cardImage} src={standoutHassleFree} />
              <div className={styles.exploreWhyCardInfo}>
                <Card.Heading
                  className={styles.cardHeading}
                  text="Superquick and easy"
                />
                <Card.Text
                  className={`${styles.cardText}`}
                  text="Sign up in 3 simple steps"
                />
              </div>
            </Card>
            <Card>
              <Card.Image className={styles.cardImage} src={standoutSavings} />
              <div className={styles.exploreWhyCardInfo}>
                <Card.Heading
                  className={styles.cardHeading}
                  text="Hassle free experience"
                />
                <Card.Text
                  className={`${styles.cardText}`}
                  text="With 24x7 dedicated customer support"
                />
              </div>
            </Card>
          </div>

          <button className={"btn btn-primary btn-medium mt-3"} onClick={()=>{handleGetQuoteCta();}}>Check Price!</button>
        </div>
      </div>
    </div>
  );
};

export default ExploreWhy;
