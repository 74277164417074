// import React, { useRef, useState } from 'react';

// import Button from '@mui/material/Button';
// import Snackbar from "@mui/material/Snackbar";

// import styles from './clipBoard.module.scss';
// import detectMobile from '../../utils/detectMobile';
// import nativeShare from '../../utils/share';
// import EndIcon from './atom/EndIcon';

// window.Clipboard = (function(window, document, navigator) {
//     var textArea,
//         copy;

//     function isOS() {
//         return navigator.userAgent.match(/ipad|iphone/i);
//     }

//     function createTextArea(text) {
//         textArea = document.createElement('textArea');
//         textArea.value = text;
//         document.body.appendChild(textArea);
//     }

//     function selectText() {
//         var range,
//             selection;

//         if (isOS()) {
//             range = document.createRange();
//             range.selectNodeContents(textArea);
//             selection = window.getSelection();
//             selection.removeAllRanges();
//             selection.addRange(range);
//             textArea.setSelectionRange(0, 999999);
//         } else {
//             textArea.select();
//         }
//     }

//     function copyToClipboard() {        
//         document.execCommand('copy');
//         document.body.removeChild(textArea);
//     }

//     copy = function(text) {
//         createTextArea(text);
//         selectText();
//         copyToClipboard();
//     };

//     return {
//         copy: copy
//     };
// })(window, document, navigator);


// const ClipBoard = (props) => {

//   const {
//     heading = "",
//     text = "",
//     maxWords = 900,
//     enableShareForMweb = false,
//     nativeShareOptions = {},
//   } = props;


//   const snackbarMessageRef = useRef("Copied to clipboard")

//   const [open, setOpen] = useState(false)

//   const handleClick = async (ev) => {
//     try {
//       ev?.stopPropagation();
//       ev?.preventDefault();

//       snackbarMessageRef.current = 'Copied to clipboard';

//       const isMobile = detectMobile();
//       const nativeShareAvailable = false;

//       if (enableShareForMweb && isMobile && nativeShareAvailable) {
//         nativeShare(nativeShareOptions)
//         return;
//       }
//       setOpen(true)
//       ev.target.focus();
//       window.Clipboard.copy(text)
//       return;
//     }
//     catch (err) {
//       console.log(err);
//       debugger
//       snackbarMessageRef.current = 'Some issue occurred';
//       setOpen(true)
//     }
//   }

//   const words = text.split('');
//   let truncatedText = words.slice(0, maxWords).join('');

//   if (words.length > maxWords) {
//     truncatedText = truncatedText + "...";
//   }

//   return (
//     <div>
//       <p className={styles.heading}>{heading}</p>
//       <div>
//         <Button className={styles.button} onClick={handleClick} endIcon={<EndIcon />}>{truncatedText}</Button>
//         <Snackbar
//           open={open}
//           onClose={() => setOpen(false)}
//           autoHideDuration={2000}
//           message={snackbarMessageRef.current}
//         />
//       </div>
//     </div>
//   )
// }

// export default ClipBoard


import React, { useRef, useState } from 'react';

import Button from '@mui/material/Button';
import Snackbar from "@mui/material/Snackbar";

import styles from './clipBoard.module.scss';
import detectMobile from '../../utils/detectMobile';
import nativeShare from '../../utils/share';
import EndIcon from './atom/EndIcon';
import { useClipboard } from './hooks/useClipboard';

const ClipBoard = (props) => {

  const {
    heading = "",
    text = "",
    maxWords = 900,
    enableShareForMweb = false,
    nativeShareOptions = {},
  } = props;

  const { copy } = useClipboard();

  const snackbarMessageRef = useRef("Copied to clipboard")

  const [open, setOpen] = useState(false)

  const handleClick = async (ev) => {
    try {
      ev?.stopPropagation();
      ev?.preventDefault();

      snackbarMessageRef.current = 'Copied to clipboard';

      const isMobile = detectMobile();
      const nativeShareAvailable = navigator?.canShare?.(nativeShareOptions);

      if (enableShareForMweb && isMobile && nativeShareAvailable) {
        nativeShare(nativeShareOptions)
        return;
      }
      setOpen(true)
      ev.target.focus();
      const didCopy = await copy(text);
      if(!didCopy) throw new Error("Some issue occurred");
    }
    catch (err) {
      // console.log(err);
      snackbarMessageRef.current = 'Some issue occurred';
      setOpen(true)
    }
  }

  const words = text.split('');
  let truncatedText = words.slice(0, maxWords).join('');

  if (words.length > maxWords) {
    truncatedText = truncatedText + "...";
  }

  return (
    <div>
      <p className={styles.heading}>{heading}</p>
      <div>
        <Button className={styles.button} onClick={handleClick} endIcon={<EndIcon />}>{truncatedText}</Button>
        <Snackbar
          open={open}
          onClose={() => setOpen(false)}
          autoHideDuration={2000}
          message={snackbarMessageRef.current}
        />
      </div>
    </div>
  )
}

export default ClipBoard;
