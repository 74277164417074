import React, { useMemo, useState, useEffect } from "react";
import cx from "classnames";

import styles from "./accordion.module.scss";

const Accordion = ({ items, limit, allOpen }) => {
  const [activeIndices, setActiveIndices] = useState([]);

  useEffect(() => {
    if (allOpen) {
      setActiveIndices(items.map((_, index) => index));
    } else {
      setActiveIndices([0]);
    }
  }, [allOpen, items]);

  const isShowMoreCtaVisible = useMemo(
    () => items?.length > limit,
    [items, limit]
  );

  const handleClick = (index) => {
    setActiveIndices((prevIndices) => {
      if (prevIndices.includes(index)) {
        return prevIndices.filter((idx) => idx !== index);
      } else {
        return [...prevIndices, index];
      }
    });
  };

  const handleShowMore = () => {
    if (allOpen) {
      setActiveIndices([]);
    } else {
      setActiveIndices(items.map((_, index) => index));
    }
  };

  const BulletList = ({ bullets }) => {
    if (!bullets) {
      return null;
    }

    return (
      <ul>
        {bullets.map((bullet, index) => (
          <>
            <li className="sd" key={index}>
              {bullet.text}
            </li>
            {bullet.bullets && <BulletList bullets={bullet.bullets} />}
          </>
        ))}
      </ul>
    );
  };

  const renderContent = (content) => {
    if (!content || !content.descriptions) {
      return null;
    }

    return (
      <div className={styles["accordion-content"]}>
        {content.descriptions.map((description, descIndex) => (
          <div key={descIndex}>
            <p>{description.text}</p>
            <BulletList bullets={description.bullets} />
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className={styles.accordion}>
      {items.slice(0, allOpen ? items.length : limit).map((item, index) => (
        <div className={styles["accordion-item"]} key={index}>
          <div
            className={styles["accordion-title-container"]}
            onClick={() => handleClick(index)}
          >
            <div
              className={cx(styles["accordion-title"], {
                [styles.active]: activeIndices.includes(index),
              })}
            >
              {item.title}
            </div>
            <span className={styles["accordion-icon"]}>
              {activeIndices.includes(index) ? "-" : "+"}
            </span>
          </div>
          {activeIndices.includes(index) && renderContent(item.content)}
        </div>
      ))}
      {isShowMoreCtaVisible && (
        <button className={styles["show-more-cta"]} onClick={handleShowMore}>
          Show More
        </button>
      )}
    </div>
  );
};

export default Accordion;
