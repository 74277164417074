import React from "react";

import { Verified } from "../../../../../assets/images";


const ValidHelper = () => {
  return (
    <div className="helper-text text-wrap mt-1">
      <p className="caption font-family-regular">
        <img src={Verified} alt="verified-otp" /> Verified
      </p>
    </div>
  );
};

export default ValidHelper;
