const faqData = [
  {
    title: "What is UPSOLV?",
    content: {
      descriptions: [
        {
          text: "Upsolv is a provider of career planning subscription plans (UPSOLV Plus-Iconic) with an additional option for its members to enrol for a group insurance cover with an IRDAI-licensed General Insurance Company. Upsolv subscription plans help working professionals grow in their careers without any hiccups from unexpected job loss. Our plans include a multitude of services starting from anytime ITR filing, interview preparation, access to premium plans of Talent Acquisition portals, access to job openings through our recruiter network, mental health sessions, and an unemployment allowance.",
        },
      ],
    },
  },
  {
    title: "Is UPSOLV an insurance policy?",
    content: {
      descriptions: [
        {
          text: "No, Upsolv is not an insurance policy, it is instead a career management services provider that works with its subscribers to help them with their employment and career planning. Our subscribers can optionally choose to enroll in a group insurance cover with an IRDAI-licensed general insurance company to get access to benefits of unemployment allowance up to limits prescribed at respective salary levels by the insurance provider. These insurance benefits are available only to Upsolv members who opt for the Upsolv Iconic plans.",
        },
      ],
    },
  },
  {
    title: "Who is eligible to buy?",
    content: {
      descriptions: [
        {
          text: "You are eligible to buy the subscription plan if you are:",
          bullets: [
            {
              text: "Resident Indian Citizen;",
            },
            {
              text: "competent to contract and purchase the Subscription under applicable law, including but not limited to the Indian Contract Act, 1872;",
            },
            {
              text: "employed within the territory and jurisdiction of India, by an employer registered in India and having its primary place of business in India;",
            },
            {
              text: "employed in a permanent and full-time capacity, and not on the basis of advisory or consultancy services, part-time employment, contract or gig-work, temporary or seasonal, articleships or internships, assessment or probation, self-employed, retainership, or otherwise in any manner other than as a permanent and full-time employee;",
            },
            {
              text: "should have at least 6 (six) months of employment remaining before official retirement/ superannuation date.",
            },
          ],
        },
      ],
    },
  },
  {
    title: "Who cannot purchase the plan?",
    content: {
      descriptions: [
        {
          text: "You cannot purchase the Subscription if you are:",
          bullets: [
            {
              text: "a business owner, self-employed professional, or able to influence your own termination (including without limitation through a relative or any other manner of control);",
            },
            {
              text: "an affiliate, agent, officer, director, partner, member, representative and/or employee of the Provider, or an employee or relative of an affiliate, agent, officer, director, partner, member, or representative of the Provider;",
            },
            {
              text: "Key managerial personnel of the employer as defined in the Companies Act or any similar enactment; and/or",
            },
            {
              text: "serving your probation period, assessment period, notice period, performance improvement period, etc., or aware, anticipate or under the apprehension that you may be terminated or be required to serve probation period, assessment period, notice period, performance improvement period, etc.",
            },
          ],
        },
      ],
    },
  },
  {
    title: "What if I purchase the plan even if I am not eligible?",
    content: {
      descriptions: [
        {
          bullets: [
            {
              text: "In case you are ineligible to buy the plan, you can seek a refund within a period of 7 days from the date of purchase of the plan.",
            },
            {
              text: "Please note, any person ineligible to purchase the plan remains ineligible to claim any benefit under the plan.",
            },
          ],
        },
      ],
    },
  },
  {
    title: "Who is eligible to claim benefits?",
    content: {
      descriptions: [
        {
          text: "You will be eligible to claim and avail benefits, if you:",
          bullets: [
            {
              text: "fulfil the criteria of eligibility of buying the plan and are not declared or found to be ineligible, in our reasonable determination;",
            },
            {
              text: "are employed for a continuous period of 6 (six) months at the time of making a claim for benefits; and",
            },
            {
              text: "have been terminated from your employment without cause, or you have been given notice of termination of your employment without cause, at any time after the Waiting Period",
            },
          ],
        },
      ],
    },
  },
  {
    title: "Is there any waiting or cool-off period?",
    content: {
      descriptions: [
        {
          text: "Yes, there is a waiting period of 120 days from the date of purchase of the subscription plan. NO BENEFITS CAN BE AVAILED BY THE SUBSCRIBER IF THE SUBSCRIBER IS TERMINATED FROM (OR NOTIFIED OF TERMINATION OF) THEIR EMPLOYMENT WITHIN THE WAITING PERIOD.",
        },
      ],
    },
  },
  {
    title: "Who is ineligible to claim benefits? What is not covered?",
    content: {
      descriptions: [
        {
          text: "You are NOT eligible to claim benefits, if your employment has been terminated for the following reasons:",
          bullets: [
            {
              text: "Superannuation or retirement;",
            },
            {
              text: "Voluntary resignation, provided however, in case of involuntary or forced resignation at the direction of the employer where you have received a severance amount and a waiver of notice period, you can reach out to us to state your case and seek benefits under the Plan;",
            },
            {
              text: "Non-confirmation of your role or any other termination, suspension, or cessation during a probationary or similar period;",
            },
            {
              text: "Violation of, or failure to adhere to, any terms of employment, including but not limited to the employer’s code of conduct, ethics policies, leave policies, or any other policies applicable to the Subscriber as an employee (including termination resulting from a breach of policies of a customer, partner, vendor, supplier, regulator, etc.);",
            },
            {
              text: "Termination resulting from a breach by the Subscriber of their employment contract or any other terms of employment",
            },
            {
              text: "Commission, allegation or threat of any illegal, criminal, tortuous, or violent acts;",
            },
            {
              text: "Breach of any fiduciary duties or disclosure confidential or proprietary information, including but not limited to any fraudulent acts, misconduct, negligence, acts of moral turpitude, theft, misrepresentation, falsification of any records or information, or any other acts of a similar nature, whether or not resulting in a criminal offense or misdemeanour;",
            },
            {
              text: "Cessation of employment, during the term of which, no salary and/or remuneration was provided to the Subscriber;",
            },
            {
              text: "Cessation of employment on account of any pending enquiry or initiation of enquiry of any nature, by the employer or a competent authority;",
            },
            {
              text: "Occurrence of an ‘acqui-hire’ or any corporate action or transaction of a similar nature, resulting in termination of your employment with one entity and commencement of employment (or any engagement similar to employment) in a resulting entity or any other entity;",
            },
             {
              text: "Cessation of employment due to non-extension of maternity/paternity leave, either as per the Maternity Benefit Act 1961, as amended from time to time, or as per the employer’s internal regulation/policy in force at the time of cessation;",
            },
            {
              text: "any allegation, findings and/or conviction for complaints relating to harassment or sexual harassment, whether or not in terms of the Sexual Harassment of Women at Work Place (Prevention, Prohibition and Redressal) Act, 2013;",
            },
            {
              text: "Failing alcohol, drug and/or substance abuse tests;",
            },
            {
              text: "Breach of any policies or statutes relating to anti-bribery, anti-corruption, or other rules and/or regulations of a similar nature;",
            },
            {
              text: "Any allegation, framing of charges and/or conviction for any crime falling within the ambit of the Indian Code of Criminal Procedural, 1973, the Indian Penal Code, 1860, or any similar statute, regulation, notification, ordinance, direction, or order having the authority of a legislative, judicial or quasi-judicial authority, whether within the jurisdiction or territory of India or otherwise; or",
            },
            {
              text: "Occurrence of any events in the nature of force majeure (including but not limited to war, civil unrest, labour disputes, pandemics, epidemics, natural disasters, and events of a similar nature).",
            },
          ],
        },
      ],
    },
  },
  {
    title: "What is the duration of the subscription?",
    content: {
      descriptions: [
        {
          text: "It is 365 days from the date of purchase of the subscription",
        },
      ],
    },
  },
  {
    title: "What are the benefits of the subscription?",
    content: {
      descriptions: [
        {
          text: "There are 3 subscription plans with different benefits. You can only purchase one of the three subscription plans. Below are the benefits and associated terms & conditions of each benefit:",
        },
        {
          text: "",
          bullets: [
            {
              text: "Premium Subscription of Talent Acquisition Portal",
              bullets: [
                {
                  text: "Upon loss of job after the completion of a waiting period of 120 days, the plan subscriber shall receive the benefit of access to a paid subscription of premium talent acquisition portals of their choice.",
                },
                {
                  text: "The subscriber can choose from any premium subscription upto a maximum of 90 days from the initmation of loss of job, upto a maximum amount of Rs. 5,000.",
                },
                {
                  text: "Such provision shall be made only a reimbursement basis",                 
                  bullets: [
                    {
                      text: "the plan subscriber shall first seek a confirmation from support@upsolv.in to buy a plan",
                    },
                    {
                      text: "after the purchase, the plan subscriber shall share the receipts/invoice of such premium subscription on support@upsolv.in, which shall be reimbursed by Upsolv on actuals subject to a maximum amount of Rs. 5,000.",
                    },
                  ],
                },
              ],
            },
            {
              text: "Placement Support",
              bullets: [
                {
                  text: "Upon loss of job after the completion of waiting period of 120 days, the plan subscriber shall be put on placement support by Upsolv recruitment team through its network of recruiters and consultants",
                },
                {
                  text: "The recruiters & consultants will share relevant job openings with the plan subscriber as per the role, interests, salary expectations, type of companies.",
                },
                {
                  text: "The plan subscriber will apply to these opportunities and receive guidance throughout the process from these recruiters and recruitment consultants",
                },
              ],
            },
            {
              text: "Interview Support from Mentors",
              bullets: [
                {
                  text: "The plan subscriber shall receive interview preparation support from industry specialists with more than 10 years plus experience in various fields including digital marketing, marketing, B2C sales, B2B sales, accounting, corporate finance, software development, management consulting, entrepreneurship, etc.",
                },
                {
                  text: "Mentor panel can be accessed on https://upsolv.in/mentors",
                },
              ],

            },
            {
              text: "Mental Health Support",
              bullets: [
                {
                  text: "Upon loss of job after the completion of waiting period of 120 days, the plan subscriber shall receive a maximum of 2 sessions with mental health counsellor empanelled with our partner https://www.jumpingminds.ai/",
                },
                {
                  text: "The plan subscriber shall confirm the availing of these sessions with our support team on support@upsolv.in and shall share the receipt of the payment made to JumpingMinds; this receipt shall be used to reimburse the amount on actuals subject to a maximum amount of Rs. 1,000 for a maximum of 2 sessions. ",
                },
              ],
            },
            {
              text: "Income Tax filing - The income tax filing at any time during the financial year through a professional chartered accountant registered and quailified for filing taxes for individuals and corporates.",
            },
            {
              text: "Unemployment Allowance",
              bullets:[
                {
                  text:"It is only provided to the Upsolv subscribers who choose to opt for the Upsolv Iconic plan. The benefit amount and payout details are provided below."
                }
              ]
            },
          ],
        },
      ],
    },
  },
  {
    title: "If I have opted for the group insurance policy plan under the Upsolv Iconic plan, how can I avail my benefits?",
    content: {
      descriptions: [
        {
          text:"In case you have opted for the group insurance policy , you will be offered unemployment allowance",
          bullets: [
            {
              text: "Until the date on which you are employed or engaged by a new employer or organisation, and as an add-on to the benefits under the Plan, you can claim unemployment allowance payments twice, each after a period of 45 (forty-five) days of unemployment.",
            },
            {
              text: "The amount of benefits paid will be the lower of (i) 50% (fifty percent) of the limits specified (such limits are available to check when you enter your salary) against the Subscription purchased or (ii) 70% (seventy percent) of the take-home salary per month.",
            },
            {
              text: "To calculate the ‘take-home’ salary, the salary will be calculated as the average of the sum of the components of the payslip which go towards the fixed component of the cost-to-company/employer during the preceding 3 (three) monthly payment cycles, prior to any deduction in taxes. It is clarified that variable components (such as performance bonuses, variable pay, referral bonuses, leave encashment, insurance benefits, wellness benefits, allowances, stock options, etc.) will not be considered as part of the ‘take-home’ salary.",
            },
            {
              text: "For instance, your gross salary is Rs. 1,00,000 per month out of which Rs. 20,000 is incentives, then Rs. 80,000 is your take-home salary. Against this salary of Rs. 80,000, you can claim an unemployment allowance of a maximum of 70% or Rs. 56,000 for every 45 days of unemployment twice only (total of Rs. 1,12,000), provided that the plan chosen has a limit of more than Rs. 1,12,000",
            },
          ],
        },
      ],
    },
  },
  {
    title: "How can I avail my benefits?",
    content: {
      descriptions: [
        {
          text: "Upon loss of employment after the Waiting Period, you can initiate a request within 14 (fourteen) days from the date of intimation of loss of employment (oral or written) or the last working day, through any of the following methods:",
          bullets: [
            {
              text:"Call us on +91-7795178080"
            },
            {
              text:"Mail us at support@upsolv.in"
            }
          ]
        },
        {
          text: "Please note date of intimation of loss of job is different from last working day. You can be intimated verbally for a layoff and your last working day can be 1 month from that day.",
        },
      ],
    },
  },
  {
    title: "What are the documents required to be submitted to get my benefits?",
    content: {
      descriptions: [
        {
          text: "The Subscriber will be required to provide the following supporting documents for the purposes of verification of claim for benefits:",
          bullets: [
            {
              text:"Joining letter as provided by the employer;"
            },
            {
              text:"Details of the reporting manager of the Subscriber prior to termination / unemployment, including name, designation, email address, and contact / mobile number;"
            },
            {
              text:"Bank statement of the salary account and pay slips for the three (3) months preceding the date of such claim;"
            },
            {
              text:"In case of involuntary or forced resignation, confirmation of severance paid during the final settlement and the documents evidencing and waiver of notice period, i.e., the last date of employment and date of resignation are falling on the same day."
            },
            {
              text:"Such other documents, as may be required to complete the verification."
            }
          ]
        },
      ],
    },
  },
  {
    title: "What if I am forced to resign?",
    content: {
      descriptions: [
        {
          text: "In case of involuntary or forced resignation, confirmation of severance paid during the final settlement and the documents evidencing and waiver of notice period, i.e., the last date of employment and date of resignation are falling on the same day.",
        },
      ],
    },
  },
  {
    title:"How many times can I avail the benefits in the subscription duration?",
    content: {
      descriptions: [
        {
          text: "You can avail the benefits only once in a period of 365 days from the date of your purchase.",
        },
      ],
    },
  },
  {
    title: "Are the benefits offered over and above my company’s benefits?",
    content: {
      descriptions: [
        {
          text: "Yes, the benefits of the subscription are over and above the benefits offered by your company.",
        },
      ],
    },
  },
  {
    title: "Can I seek a refund after purchase?",
    content: {
      descriptions: [
        {
          text: "Yes, you can apply for a 100% refund within 7 days from the date of purchase of the plan.",
        },
      ],
    },
  },
  {
    title: "Can I pay the subscription charges on a monthly basis?",
    content: {
      descriptions: [
        {
          text: "For now, it is an annual payment for the annual subscription to be paid at once at the time of buying the plan.",
        },
      ],
    },
  },
  {
    title: "What is the monthly salary amount I should enter?",
    content: {
      descriptions: [
        {
          text: "You should enter the gross monthly salary excluding any variable component in your Cost to Company (CTC).",
        },
        {
          text: "For instance, if your CTC is Rs. 8 lacs per annum which includes Rs. 2 lacs variable component, then your fixed CTC is 6 lac; therefore, you should enter Rs. 50,000 (Rs. 6 lac/12) as your monthly gross salary.",
        },
      ],
    },
  },
  {
    title: "What will happen to my plan if I change my job during the plan duration of 365 days?",
    content: {
      descriptions: [
        {
          text: "In case of voluntary resignation during the plan duration of 365 days and commencement of employment with a different employer, you may transfer or roll-over the Subscription provided that your new employer is one of the entities available in our Master Database.",
        },
        {
          text: "You can inform us of this change by mailing us on support@upsolv.in within 30 (thirty) days of the date of resignation at your old employer. ",
        },
        {
          text: "In such a case, the Subscription will be subject to a renewed Waiting Period of 120 days from the date of commencement of employment with your new employer.",
        },
      ],
    },
  },
  {
    title: "If I get a job in 20 days, will I get paid for 20 days?",
    content: {
      descriptions: [
        {
          text: "No, there will be no prorated pay-outs. You can get unemployment allowance benefits until the date on which you are employed or engaged by a new employer or organisation, only twice, each after a period of 45 (forty-five) days of unemployment.",
        },
      ],
    },
  },
];

export default faqData;
