import ApiServices from "../../../../services/api";
import getDataFromResponse from "../../../../shared/utils/getDataFromResponse";
import { API_END_POINT } from "../../constants/api-const";

export const fetchWorkplaceLocation = async (pincode) => {
  try {
    const requestConfig = pincode ? { params: { pincode } } : {};
    const getPincode = requestConfig.params.pincode;


    const response = await ApiServices.get(
      `${API_END_POINT.SEARCH_PINCODE}?pincode=${getPincode}`,
    );
    const workplaceLocationResponse = getDataFromResponse(response);
    const locations = workplaceLocationResponse?.pincodes;
    return locations;
  } catch (err) {
    return [];
  }
};
