export const CONTENT_SECTION = [
  {
    sectionHeading: "ACCESS TO AND CONTINUED USE OF SERVICES",
    items: [
      {
        content:
          "You should have attained the age of majority in your jurisdiction to be able to use and access our Website. Company has the right to terminate an account, wherein a User is found to be defaulting on this condition, or where we find that you are barred in law from access to our Website.",
      },
      {
        content:
          "You are responsible for your use of our Website, and undertake to comply with applicable laws, and act in strict adherence to these ToS and Privacy Policy, and Upsolv Policy Terms. (https://Upsolv.in/privacy/)",
      },
      {
        content:
          "Unless indicated otherwise You may access our Website free of charge, or in any such manner which is determined by the Company and is conveyed to you from time to time.",
      },
      {
        content:
          "You are responsible for safeguarding your account and you agree not to disclose your password to any third party. You agree that you will be solely responsible for any activities or actions taken under your account or handle name or password, irrespective of whether you have authorized such activities or actions. You will immediately notify the Company of any unauthorized use of your password or your account, upon becoming aware of any such discrepancy.",
      },
      {
        content:
          "The Company is committed to safeguarding the efficiency of the Website. For that reason, we reserve the right to make changes to the Website for maintenance at any time. If such situations cause an interruption for a reasonable duration, we shall not bear any liability to you and/or to any third parties. We will make attempts on a best-efforts basis to let you beware of any scheduled maintenance to such extent as is possible.",
      },
      {
        content:
          "You agree not to: circumvent, remove, degrade, deactivate, or thwart any of the Contents of our Website; use any robot, spider, scraper, or other means to access our Website. You also agree not to decompile, reverse engineer, and disassemble any software or other products or processes accessible through our Website. Additionally, you agree not to upload, post, e-mail or otherwise send or transmit any material designed to interrupt, destroy, or limit the functionality of our Website.We may terminate or restrict your use of our Website if you violate these ToS or are engaged in any illegal or fraudulent or unethical or unwarranted or otherwise malicious use of our Website.",
      },
      {
        content:
          "We reserve the right to update, modify, alter, amend, these ToS at any time, at our sole discretion.",
      },
      {
        content:
          "We do not endorse, support, represent, authorize the circulation of all Content published on our Website, and we do not further attest to the accuracy, originality, reliability, legitimacy, completeness, of such Content, as available on our Website.",
      },
      {
        content:
          "All the Content that is available on the Website is without any responsibility or liability. Your use or reliance on any Content while using the Website or availing any products or services is solely at your own risk.",
      },
      {
        content:
          "As a User, you may come across any service on Content on the Website which you may find to be harmful, misleading, inaccurate, improper or inappropriate. We impress upon you to make reasonable efforts and seek professional guidance to investigate whether any Content or product or services are useful or beneficial to you. We do not take any responsibility for your purchase of any products or services.",
      },
      {
        content:
          "The Website made available to you is protected by copyright, trademark, and other laws. Nothing in these ToS gives you the right to use our trademarks, logos, domain names, other distinctive brand features, and other proprietary rights. All right, title, and interest in and to the Website are and will remain the exclusive property of the Company and its licensors.",
      }
    ],
  },
  {
    sectionHeading: "SERVICES",
    description: "The Website provides you with the ability to:",
    items: [
      {
        content:
          "Create and maintain your own account and if applicable, your own profile, on the Website after registration.",
      },
      {
        content:
          "Control your own privacy, as per the Privacy Policy (https://Upsolv.in/privacy/) of the Company",
      },
      {
        content:
          "Review and purchase any products and services offered by the Company",
      },
    ],
  },
  {
    sectionHeading: "REGISTRATION AND ACCOUNT INTEGRITY",
    items: [
      {
        content:
          "We provide you with a free account, however, you are required to be registered with us, to avail full functionalities of our Website including research, purchase or use any products and services offered by us.",
      },
      {
        content:
          "As part of the registration process for creating your account, you will need to provide us with your phone number and/or email address, (which will be verified via a one-time password verification mechanism). You can then create an account username/handle and password for yourself.",
      },
      {
        content:
          "You undertake that the information you provide to us is accurate, secure, and is not misleading.",
      },
      {
        content:
          "assword verification mechanism). You can then create an account username/handle and password for yourself. You undertake that the information you provide to us is accurate, secure, and is not misleading. We reserve the right to suspend or terminate or remove any account or take any other actions we deem fit with or without notice, if you are in breach of these ToS.",
      },
    ],
  },
  {
    sectionHeading: "THIRD PARTY SERVICES",
    items: [
      {
        content:
          "You agree that during your usage of our Website, Company can place advertisements or other types of commercial information. You also agree to receive advertisement or other relevant commercial information from us via e-mail or other authorized means. The Company may provide its Users with links to, or contact information for, third-party sites or services. Please note that we do not control such third-party websites, and their interactions with you. We have not reviewed, and do not review, all the material, including goods or services, made available through third-party sites. We therefore urge you to kindly make an informed choice in interacting with such third-party sites and ensure that you are familiar with the policies of such sites before you proceed with engaging, interacting, with such third-party sites.",
      },
      {
        content:
          "The Company is not responsible for, and does not endorse, any third-party content, sites, or services mentioned on the Application. Third party materials accessed through or used by means of the third-party sites may also be protected by copyright and other intellectual property laws.",
      },
    ],
  },
  {
    sectionHeading: "SUPPORT",
    items: [
      {
        content:
          "The Company offers email based, and online support tools. You may access or contact our support by emailing at support@upsolv.in. Under certain exceptional circumstances, we may also request you to reach out to other authorized, appointed contact persons for resolution of your queries or support requests.",
      },
      {
        content:
          "The Company does not make any promises regarding how quickly we will respond to your request for support, or that we will be able to fix any problems you may be having. Any suggestions by the Company regarding use of the Website shall not be construed as a warranty.",
      },
      {
        content:
          "Upsolv is a technology intermediary engaged in the business of marketing, listing, communicating and sale of value added lifestyle support products and services. Upsolv has curated a bouquet of services from other service providers and partners that provide paying subscriber(s) with a limited period lifestyle and reemployment support. Upsolv is only an intermediary and is neither responsible for performance nor delivery of these services. Upsolv’s sole responsibility is connect users of such services to the providers of such services.",
      },
    ],
  },
  {
    sectionHeading: "TERMINATION",
    items: [
      {
        content: "The Company reserves the right to suspend or terminate your access to the Website with or without notice and to exercise any other remedy available under law, in cases where:",
        items: [
          {
            content:
              "You are in breach of any terms and conditions of these ToS;",
          },
          {
            content:
              "The Company is unable to verify or authenticate any information provided to Company by you;",
          },
          {
            content:
              "The Company has reasonable grounds for suspecting any illegal, fraudulent, spammy or abusive activity on your part;",
          },
          {
            content:
              "The Company believes in its sole discretion that your actions may cause legal liability for you, other Users or for the Company, or are contrary to the interests of the Company; or",
          },
          { content: "It is directed by law enforcement." },
        ],
      },
      {
        content:
          "All provisions of these ToS, which by their nature should survive termination, shall survive termination, including, without limitation, disclaimers, indemnity, and limitations of liability.",
      },
    ],
  },
  {
    sectionHeading: "DISCLAIMER",
    description:
      "THE WEBSITE AND ANY SERVICES ON THE WEBSITE (INCLUDING, WITHOUT LIMITATION, ANY CONTENT) IS PROVIDED “AS IS” AND “AS AVAILABLE” AND IS WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF TITLE, NON-INFRINGEMENT, MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE, AND ANY WARRANTIES IMPLIED BY ANY COURSE OF PERFORMANCE OR USAGE OF TRADE, ALL OF WHICH ARE EXPRESSLY DISCLAIMED. YOUR USE OF THE WEBSITE AND SERVICES PROVIDED VIA THE WEBSITE ARE SOLELY AT YOUR OWN RISK. THE COMPANY AND ITS DIRECTORS, EMPLOYEES, AGENTS, AND PARTNERS DO NOT WARRANT THAT:",
    items: [
      {
        content:
          "THE SERVICES WILL BE SECURE OR AVAILABLE AT ANY PARTICULAR TIME OR LOCATION; OR, ",
      },
      {
        content: "ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR,",
      },
      {
        content:
          "ANY CONTENT OR SOFTWARE AVAILABLE AT OR THROUGH THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS; OR,",
      },
      {
        content:
          "THE RESULTS OF USING THE SERVICE WILL MEET YOUR REQUIREMENTS.",
      },
      {
        content:
          "THE SERVICES ARE FIT FOR YOUR CIRCUMSTANCES OR LIFE STAGE IN ANY MANNER",
      },
    ],
    footer:
      "DO NOT USE ANY SOFTWARE, DEVICE, SCRIPTS, BOTS OR OTHER MEANS TO ACCESS, SCRAPE, CRAWL OR SPIDER THE APPLICATION FOR ANY TYPE OF PUBLIC AND PRIVATE DATA OR INFORMATION.",
  },
  {
    sectionHeading: "INDEMNITY",
    description:
      "You shall defend, indemnify, and hold harmless the Company, its affiliates, subsidiaries, joint venture partners and each of its, and its affiliates, subsidiaries, join venture partners’ employees, contractors, directors, suppliers and representatives from all liabilities, losses, claims, and expenses, including reasonable attorneys’ fees, that arise from or relate to:",
    items: [
      {
        content: "Your use or misuse of, or access to, the Website; or, ",
      },
      {
        content:
          "Your violation of the ToS of Website or any applicable law, contract, policy, regulation or other obligation. We reserve the right to assume the exclusive defense and control of any matter otherwise subject to indemnification by You, in which event You will assist and cooperate with us in connection therewith.",
      },
    ],
  },
  {
    sectionHeading: "LIMITATION OF LIABILITY",
    description:
      "TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL COMPANY (NOR ITS DIRECTORS, EMPLOYEES, AGENTS, SPONSORS, PARTNERS, SUPPLIERS, CONTENT PROVIDERS, LICENSORS OR RESELLERS,) BE LIABLE UNDER CONTRACT, TORT, STRICT LIABILITY, NEGLIGENCE OR ANY OTHER LEGAL OR EQUITABLE THEORY WITH RESPECT TO THE SERVICE:",
    items: [
      {
        content:
          "FOR ANY LOST PROFITS, DATA LOSS, LOSS OF GOODWILL OR OPPORTUNITY, OR SPECIAL, INDIRECT, INCIDENTAL, PUNITIVE, OR CONSEQUENTIAL DAMAGES OF ANY KIND WHATSOEVER;",
      },
      {
        content: "FOR YOUR RELIANCE ON THE SERVICE;",
      },
      {
        content:
          "FOR ANY DIRECT DAMAGES IN EXCESS (IN THE AGGREGATE) OF THE INR 10,000/-",
      },
      {
        content:
          "FOR ANY MATTER BEYOND ITS OR THEIR REASONABLE CONTROL, EVEN IF COMPANY HAS BEEN ADVISED OF THE POSSIBILITY OF ANY OF THE AFOREMENTIONED DAMAGES.",
      },
    ],
  },
  {
    sectionHeading: "GOVERNING LAW",
    description:
      "This Agreement shall be governed by and construed in accordance with the laws of India. All claims, differences and disputes arising under or in connection with or in relation to the Website, the ToS or any transactions entered into on or through the Website shall be subject to the exclusive jurisdiction of the courts at Bengaluru, India and You hereby accede to and accept the jurisdiction of such courts.",
  },
  {
    sectionHeading: "MISCELLANEOUS",
    items: [
      {
        content:
          "In the event that any provision of these ToS is held to be invalid or unenforceable, then that provision will be limited or eliminated to the minimum extent necessary, and the validity, legality, and enforceability of the remaining provisions shall remain in full force and effect. ",
      },
      {
        content:
          "These ToS form a valid, enforceable agreement between you and Vicinal Jobsurance Private Limited, a company incorporated under the Companies Act, 2013 with our registered office at Workflo by OYO, 3rd Floor, Plot No. 224, Ranka Junction, Near Tin Factory, Bengaluru - 560036.",
      },
    ],
  },
  {
    sectionHeading: "GRIEVANCE REDRESSAL MECHANISM",
    description:
      "Any discrepancies or grievances with regard to this Website may be referred to the Grievance Redressal Officer at legal@Upsolv.in. The Grievance Officer shall endeavor to resolve the same in an expeditious manner.",
  },
  {
    sectionHeading: "REFERENCE",
    items: [
      {
        content:
          "If you have any questions regarding the Website, please contact us at Workflo by OYO, 3rd Floor, Plot No. 224, Ranka Junction, Near Tin Factory, Bengaluru - 560036",
      },
      {
        content:
          "Please note that for the purpose of validation, you shall be required to provide information (including, but not limited to your email address, contact number, or, registered mobile number, etc.) for the purpose of sufficient identification, and authentication, and taking Your service request. Please refer to our Privacy Policy (https://Upsolv.in/privacy/) on how we deal with information.",
      },
    ],
  },
  {
    sectionHeading: "CHANGES",
    description:
      "We may from time to time change these ToS. We may assign or transfer our agreement with you including our associated rights and obligations at any time and you agree to cooperate with us in connection with such an assignment or transfer. We recommend that you periodically check this page for any revised terms. Your continued use of the Website will be deemed to constitute your acceptance of all such revised terms.",
    items: [],
  },
  {
    sectionHeading: "REWARDS with REFERRAL CODES",
    description: "Upsolv Referral programme has the below terms & conditions:",
    tabularData: {
      "table": {
        "heading": "Refer and earn along with your friends through the Upsolv Referral programme.",
        "subHeading": "Explore the rewards below for every successful referral only.",
        "columns": [
          {
            "header": "Purchase Amount",
            "key": "orderAmountRange"
          },
          {
            "header": "Referral Earned by You",
            "key": "giftCardValue"
          }
        ],
        "rows": [
          {
            "orderAmountRange": "₹1,999",
            "giftCardValue": "₹100"
          },
          {
            "orderAmountRange": "₹3,999",
            "giftCardValue": "₹300"
          },
          {
            "orderAmountRange": "₹5,499",
            "giftCardValue": "₹300"
          },
          {
            "orderAmountRange": "₹6,999",
            "giftCardValue": "₹500"
          },
          {
            "orderAmountRange": "₹8,999",
            "giftCardValue": "₹500"
          },
          {
            "orderAmountRange": "₹13,499",
            "giftCardValue": "₹500"
          }
        ]
      }
    },
    items: [
      {
        "content": "The rewards are shared in the form of Amazon gift vouchers only"
      },
      {
        "content": "These vouchers are sent to the Referrer and Referee only via email to their registered email address"
      },
      {
        "content": "The Referee shall receive the Amazon vouchers after completion of the 15 calendar days from the date of purchase of subscription provided that the Referee has not applied for cancellation of subscription"
      },
      {
        "content": "The Referrer shall receive the Amazon vouchers only after the Referrer has become a Upsolv customer; in the case of the Referrer not being a Upsolv customer, the rewards shall continue to accumulate till the Referrer becomes a customer of Upsolv"
      },
      {
        "content": "A successful referral is defined as a purchase of Upsolv subscription in which a referral code is applied"
      },
      {
        "content": "The Referrer and Referee shall receive these rewards only once for every successful referral, provided that the Referee has not applied for cancellation of subscription"
      },
      {
        "content": "Upsolv solely reserves the right to continue/modify/amend this Referral programme including its constituents such as eligibility, form of rewards, and amount of rewards"
      },
      {
        "content": "All correspondence, communication, queries and requests from either the Referrer and Referee regarding the Referral programme and its constituents shall be handled through emails only via the email address - support@upsolv.in"
      },
    ],
  },
];
