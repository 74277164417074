
import React, { useCallback, useContext } from "react";
import { Chip } from "@mui/material";

import Card from "../Card";

import { Btn } from "../../../components/index"
import { Home } from "../../../assets/images/index";

import QuoteHeader from "../quote-header";
import EmploymentDetailsForm from "./employment-details-form";
import StickyCTA from "../../../shared/components/stickyCTA/StickyCTA";
import useNavigateToUrl from "../../../shared/hooks/useNavigateToUrl";
import { GetQuoteContext } from "../../../modules/common-context";

const EmploymentDetails = () => {

  const navigateToUrl = useNavigateToUrl();
  const { dataPayload } = useContext(GetQuoteContext);

 /*  const backTo = () => {
    navigateToUrl("/");
    window.dataLayer.push({'event': 'get-quote_back_to_home'});
    window.webengage.track("get-quote_back_to_home");
  } */

  const backTo = useCallback(() => {   
    window.dataLayer.push({'event': 'login_back_to_home'});
    window.webengage.track("login_back_to_home");
    
    if(dataPayload?.user_plan_status?.has_purchased) {
      navigateToUrl("/dashboard");
    } else {
      navigateToUrl("/");
    }
  }, [dataPayload?.user_plan_status]);

  return (
    <React.Fragment>
      <QuoteHeader
        btn={
          <Btn
            className="btn-white font-family-regular"
            label="Back to Home"
            size="small"
            startIcon={<img src={Home} alt="Home"/>}
            onClick={()=>backTo()}
          />
        }
      />
      <div className="layout-conatiner employment-layout-container"> 
        <div className="medium-container employment-detail-wrap ">
          <Card classes={"card card-large card-shadow"}>
            <div className="card-header-wrap">
              <Chip 
                label="EMPLOYMENT DETAILS"
                className="chip chip-orange letter-space-2 font-family-regular text-uppercase"
                size="medium"
              ></Chip>
              <div className="text-wrap mt-2">
                <h1 className="font-family-bold black-color">Let's find the right plan for you</h1>
              </div>
            </div>
            <div className="card-content-wrap">
              <EmploymentDetailsForm></EmploymentDetailsForm>
            </div>
          </Card>
        </div>
      </div>
      <StickyCTA 
        onClick={()=>{
          window.dataLayer.push({'event': 'get-quote_CompanySalary_Whatsapp'});
          window.webengage.track("get-quote_CompanySalary_Whatsapp");
        }}
      />
    </React.Fragment>
  )
}

export default EmploymentDetails;
