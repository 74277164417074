import { API_END_POINT } from '../components/getQuotes/constants/api-const';
import ApiServices from '../services/api';
import getUrlParams from '../shared/utils/get-url-param';
import getDataFromResponse from '../shared/utils/getDataFromResponse';

const generateLead = (status = 'homePage', leadId, extraParams = {}) => {
  const payload = {
    status,
    "utm_medium": getUrlParams('utm_medium'),
    "utm_campaign": getUrlParams('utm_campaign'),
    "utm_id": getUrlParams('utm_id'),
    ...extraParams,
    fieldsToUpdate: Object.keys(extraParams)
  }

  const utm_referral = getUrlParams('utm_referral');

  if(utm_referral){
    payload.utm_referral = utm_referral;
  }

  return ApiServices.patch(API_END_POINT.GENERATE_LEAD, payload);    
};

const onSuccess = (params, generateLeadResponse) => {
  const { getQuoteContext } = params;
  const generatedLead = getDataFromResponse(generateLeadResponse);
  const leadId = generatedLead?.lead_id; 
  const { setDataPayload, dataPayload } = getQuoteContext;
  setDataPayload({
    ...(dataPayload || {}),
    lead_id: leadId,
  });
};

const onFailure = (_params, _err) => {
  // DO NOTHING
  return undefined;
};

const onSettled = (params, settledPromise) => {
  const {
    status,
    value,
    reason
  } = settledPromise;
  if (status === 'fulfilled') return onSuccess(params, value);
  if (status === 'rejected') return onFailure(params, reason);
  return undefined;
}
const generateLeadConfig = {
  request: generateLead,
  onSettled,
};


export default generateLeadConfig;
