
import React, { useCallback, useContext, useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import QuoteHeader from "../quote-header";
import StickyCTA from "../../../shared/components/stickyCTA/StickyCTA";
import { Back, CircleDash, CircleTick, InfoCircle, Next } from "../../../assets/images";
import Btn from "../../buttons";

import { GetQuoteContext } from "../../../modules/common-context";
import CapsuleTag from "../../capsule-tag";
import { PLAN_COLOR } from "../../../constants/planColor";
import ApiServices from "../../../services/api";
import { API_END_POINT } from "../constants/api-const";
import PlanDetailAccordion from "./plan-detail-accordion";
import { ICON_MAPPING } from "../constants/getQuotes-plan-const";
import amountWithCommas from "../../../shared/utils/amount-seperator";
import { IconButton } from "@mui/material";
import DialogBox from "../../dialog-box";
import APP_DATA_CONFIG from "../../../helpers/app";
import Loader from "../../loader";
import PlanBreakupDialog from "./plan-detail-breakup";
import { PAISE } from "../../../shared/constants/app";

const PlanDetailsV2 = () => {

  const { setCurrView, setDataPayload, dataPayload } = useContext(GetQuoteContext);
  const paise = 100;
  const [userDetails, setUserDetails] = useState({});
  const [planQuotes, setPlanQuotes] = useState([]);
  const [planBenefits, setPlanBenefits] = useState([]);
  const [planWithBenefits, setPlanWithBenefits] = useState([]);
  const [excludedList, setExcludedList] = useState([]);
  const [planSelection, setPlanSelection] = useState('');
  const [netPremiumAmount, setNetPremiumAmount] = useState(null);
  const [priceBreakGrossAmount, setPriceBreakGrossAmount] = useState(null);
  const [priceBreakNetAmount, setPriceBreakNetAmount] = useState(null);
  const [priceBreakTax, setPriceBreakTax] = useState(null);
  const [extraBenefitsPrice, setExtraBenefitsPrice] = useState(null);
  const [priceGrossBenefits, setPriceGrossBenefits] = useState(null);
  const [showPriceBreakUpModal, priceBreakupToggleModal] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [accordionToggle, setAccordionToggle] = useState(false);

  const iconMapping = ICON_MAPPING;

    // set exclusion plan
    const setPlanExclusion = useCallback((data) => {
      let IPSALArr = data;
      IPSALArr.forEach((element) => {
        element.icon = iconMapping[element.image]
      });
      setExcludedList(IPSALArr);
    }, [iconMapping]);

  const getPlanQuotes = useCallback(async () => {
    try {
      const payload = {
        name: dataPayload.name,
        mobile: dataPayload.phone_number,
        salary: dataPayload.salary
      }
      return await ApiServices.post(API_END_POINT.GET_OUR_PROTECTION_PLAN, payload);

    } catch (error) {
      
    }
  }, [dataPayload.name, dataPayload.phone_number, dataPayload.salary]);

  const getPlanBenefits = async () => {
    try {
      return await ApiServices.get(API_END_POINT.GET_BENEFITS);
    } catch (error) {
      console.log("getPlanBenefits_Error :", error);
    }
  }

  const getPlanQuotesAndBenefitsData = useCallback(() => {
    setIsloading(true)
    const getPlanQuotesFunction = getPlanQuotes();
    const getPlanBenefitsFunction = getPlanBenefits();
    const listOfFunctions = [getPlanQuotesFunction, getPlanBenefitsFunction];
    Promise.all(listOfFunctions)
      .then((values) => {

        const planQuotesRes = values[0];
        const planBenefitsRes = values[1];
        const planBenefitsResAccord = values[1];


        if (planQuotesRes.status === 200) {
          setPlanQuotes(planQuotesRes.data.quotes);
          setIsloading(false);
        }

        if (planBenefitsRes.status === 200) {

          console.log(planBenefitsResAccord.data.benefits,"planBenefitsResAccord")
          console.log(planBenefitsResAccord.data.benefits.slice(1,-1),"planBenefitsResAccord")

          setPlanBenefits(planBenefitsRes.data.benefits);

          setPlanExclusion(planBenefitsRes.data.exclusions);

          setIsloading(false);
        }

      })
      .catch((error) => {

      })
  }, [getPlanQuotes, setPlanExclusion])

  const setStoreUserDetails = useCallback((data, userDetails) => {

    userDetails.selected_quote = data;
    userDetails.selected_quote_planName = data.name;
    setUserDetails(userDetails);

    setDataPayload({ ...dataPayload, ...userDetails });

  }, [dataPayload, setDataPayload]);

  // set default plan on laod
  const setDefaultPlan = useCallback(() => {
    const storeUserDetails = dataPayload;
    
    if(storeUserDetails?.selected_quote_planName === undefined) {
      setPlanSelection(storeUserDetails?.quoteData[0]?.name);
      setStoreUserDetails(storeUserDetails?.quoteData[0], storeUserDetails);
    }else{
      setPlanSelection(storeUserDetails?.selected_quote?.name);
      setStoreUserDetails(storeUserDetails?.selected_quote, storeUserDetails);
    }

    let netPremiumAmountTotal = (storeUserDetails?.selected_quote?.premium?.netAmount / paise).toFixed(0);
    setNetPremiumAmount(amountWithCommas((netPremiumAmountTotal)));

  }, [dataPayload, setStoreUserDetails]);

  const formatPlanAndBenefitData = useCallback(() => {
    try {

      if (planQuotes.length > 0) {
        let planQuotesResult = [];
        planQuotes.forEach((item, index) => {
          const filteredBenefitArr = planBenefits.filter(obj => item.benefit_ids.includes(obj.id));
          item['benefits'] = filteredBenefitArr;
          planQuotesResult.push(item);
        });

        setPlanWithBenefits(planQuotesResult);
      }

    } catch (error) {

    }
  }, [planBenefits, planQuotes]);


  const planBenefitsCircleTickDashView = (planBenefitArr, benefitId, benefitName, planName) => {
    const result = planBenefitArr.includes(benefitId);
    const protectionAmount = Math.round(planWithBenefits[0]?.premium?.protectionAmount)
    return (
      <>  
        {
          result && (
            <p className="benefits-list subtitle1 font-family-medium black-color">
              <img src={result === true ? CircleTick : CircleDash} className="benefits-list-img" alt="CircleDash"></img> {benefitName !== 'Unemployment Allowance'? benefitName :  
              <span className="benefits-top-priority">
                Max unemployment allowance <br/> 
                <span className={`total-price ${planName === 'Iconic' ? 'green-color' : 'dark-grey-color opacity-6'}`}>₹{amountWithCommas(((protectionAmount / PAISE)))}</span>
                <span className="benefits-list-breakout mt-2">
                  <span>
                    <span className="bullet">1</span> <span>50% on 45 days of unemployment</span>
                  </span>
                  <span>
                    <span className="bullet">2</span> <span>50% on 90 days of unemployment</span>
                  </span>
                </span>
              </span>
            }
            </p>
          )
        }
      </>
      
    )
  }

  // handel plan selection
  const handlePlanSelection = (data) => {

    setPlanSelection(data.name);

    window.dataLayer.push({ 'event': `${'get-quote_PlanSelection_' + data.name}` });
    window.webengage.track(`${'get-quote_PlanSelection_' + data.name}`, {
      planName: data.name
    });
    window.webengage.user.setAttribute(`${'get-quote_PlanSelection'}`, {
      planName: data.name
    });

    let netPremiumAmountTotal = (data?.premium?.netAmount / paise).toFixed(0);
    setNetPremiumAmount(amountWithCommas((netPremiumAmountTotal / 12).toFixed(0)));

    let result = planQuotes.find(obj => {
      return obj.name === data.name
    });

    // set selected quote
    setStoreUserDetails(result, userDetails)
  };

  // handle price breakup
  const handlePriceBreakup = (data) => {
    setPlanSelection(data.name);
    priceBreakupToggleModal(true);


    let priceBreakGrossAmount = (data?.premium?.grossAmount / paise).toFixed(0);
    let priceBreakNetAmount = (data?.premium?.netAmount / paise).toFixed(0);
    let priceBreakTax = (data?.premium?.tax / paise).toFixed(0);
    let extraBenefitsCharge = (data?.premium?.extraBenefitsCharge / paise).toFixed(0);
    let priceGrossBenefits = ((data?.premium?.netAmount - data?.premium?.extraBenefitsCharge) / paise).toFixed(0);


    setPriceBreakGrossAmount(amountWithCommas(priceBreakGrossAmount));
    setPriceBreakNetAmount(amountWithCommas(priceBreakNetAmount));
    setPriceBreakTax(amountWithCommas(priceBreakTax));
    setExtraBenefitsPrice(amountWithCommas(extraBenefitsCharge));
    setPriceGrossBenefits(amountWithCommas(priceGrossBenefits));

  }

  // handle price breakup
  const onClosePriceBreakupModal = () => {
    priceBreakupToggleModal(false)
  }

  useEffect(() => {
    getPlanQuotesAndBenefitsData();
  }, [getPlanQuotesAndBenefitsData]);

  useEffect(() => {
    formatPlanAndBenefitData()
  }, [planQuotes, planBenefits, formatPlanAndBenefitData])

  useEffect(()=>{
    // set default plan on load
    setDefaultPlan();
  }, [setDefaultPlan])

  // back to previous page
  const backTo = () => {
    setCurrView("EMPLOYMENT_DETAILS")
  }

  const submitHandle = () => {
    setCurrView("PLAN_CONFIRMATION");
    window.dataLayer.push({'event':'get-quote_PlanSelection_Continue'});
    window.webengage.track('get-quote_PlanSelection_Continue');
    const result = planQuotes.find(obj => {
      return obj.name === planSelection
    })
    APP_DATA_CONFIG.GENERATE_LEAD.request('get-quote_PlanSelection_Continue', dataPayload?.lead_id, {
      plan_type: result.name,
      plan_cost: (result?.premium?.netAmount / paise),
      plan_id: result?.id
    })
  }

  const accordionToggleByView = (item) => {
    if(item === "Iconic") {
      setAccordionToggle(!accordionToggle);
    } else if(item === "Pro") {
      setAccordionToggle(!accordionToggle);
    } else if(item === "Plus") {
      setAccordionToggle(!accordionToggle);
    }
  }


  return (
    <React.Fragment>
      <QuoteHeader
        btn={
          <Btn
            className="btn-white font-family-regular"
            label="Back"
            size="small"
            startIcon={<img alt="Back" src={Back} />}
            onClick={() => { backTo() }}
          />
        }
      />
      <StickyCTA
        onClick={() => {
          window.dataLayer.push({ 'event': 'get-quote_PlanSelection_Whatsapp' });
          window.webengage.track('get-quote_PlanSelection_Whatsapp');
        }}
      />
      <div className="layout-conatiner d-flex flex-wrap align-items-start plandetails-layout-container">
        <div className="large-container auth-wrap">
          <Loader
            open={isLoading}
            label="Loading..."
            customStyle={"bg-transparent"}
          />
          {
            !isLoading && (
              <React.Fragment>
                <div className="plan-tab-wrap">
                  {
                    planWithBenefits.map((item, index) => {
                      return (
                        <React.Fragment>
                        {
                          item.name != "Plus" && (
                            <div className={`plan-tab-item ${item.name === planSelection && 'active'}`} onClick={() => (handlePlanSelection(item))}>
                              <CapsuleTag
                                title="UP"
                                subtitle={item.name}
                                className={`${PLAN_COLOR[item.name?.toUpperCase()]}`}
                              />
                            </div>
                          )
                        }
                        </React.Fragment>
                      )
                    })
                  }
                </div>
                <div className={`plan-details-card-wrap plan-details-card-${planWithBenefits.length - 1}`}>
                  {
                    planWithBenefits.map((item, index) => {
                      const roundedPrice = Math.ceil(((((item.price) / 12) / paise)).toFixed(2));
                      return (
                        <React.Fragment>
                          {
                            item.name != "Plus" && (
                              <div className={
                                  `plan-card-wrap plan-card-selection 
                                  ${item.name === planSelection && 'active'}  
                                  ${item.name !== 'Iconic' && 'low-box-shadow'}`
                                } 
                                key={index} onClick={() => (handlePlanSelection(item))}>
                                {item.ref_code === 'PLAN_1' && (
                                  <div className="upgarde-tag">Recommended</div>
                                )}
                                <div className="plan-card-item">
                                  {/* {
                                    item.name === planSelection && (
                                      <div className="plan-selection-indicator">
                                        <img src={CircleTick} alt="CircleDash"></img>
                                      </div>
                                    )
                                  } */}
                                  <div className="plan-info-wrap">
                                    <CapsuleTag
                                      title="UP"
                                      subtitle={item.name}
                                      className={`${PLAN_COLOR[item.name?.toUpperCase()]} plan-capsule-mob-hide`}
                                    />
                                    {/* <div className="text-wrap mt-3">
                                      <p className="subtitle1 font-family-medium black-color">{item.description}</p>
                                    </div> */}
                                  </div>
                                  <div className="benefits-info-wrap">
                                    <div className="text-wrap">
                                      {
                                        planBenefits.map((planBenefititem, index) => {
                                          if(planBenefits.length - 1 == index && item.name === "Iconic") {
                                            return planBenefitsCircleTickDashView(item.benefit_ids, planBenefititem.id, planBenefititem.name, item.name)
                                          }
                                        })
                                      }
                                      {
                                        planBenefits.map((planBenefititem, index) => {
                                          if(index == 0) {
                                            return planBenefitsCircleTickDashView(item.benefit_ids, planBenefititem.id, planBenefititem.name)
                                          }
                                        })
                                      }
                                      {
                                        planBenefits.slice(1, -1).map((planBenefititem, index) => {
                                          return planBenefitsCircleTickDashView(item.benefit_ids, planBenefititem.id, planBenefititem.name)
                                        })
                                      }
                                      {/* <div className="benefits-accordion-wrap">
                                        <div 
                                          className={`benefits-accordion-header ${(accordionToggle) && 'active'}`} 
                                          onClick={() => {accordionToggleByView(item.name)}}
                                        >
                                          <p className="benefits-list benefits-list subtitle1 font-family-medium black-color mb-0">
                                            <img src={ item.name !== "Plus" ? CircleTick : CircleDash} className="benefits-list-img" alt="CircleDash"></img>
                                            Placement Benefits
                                          </p>
                                          <span className="accord-arrow-wrap">
                                            <ExpandMoreIcon />
                                          </span>
                                        </div>
                                        <div className={`benefits-accordion-body ${(accordionToggle) && 'active'}`}>
                                          {
                                            planBenefits.slice(1, -1).map((planBenefititem, index) => {
                                              return planBenefitsCircleTickDashView(item.benefit_ids, planBenefititem.id, planBenefititem.name)
                                            })
                                          }
                                        </div>
                                      </div> */}
                                    </div>
                                  </div>
                                  <div className="plan-price-wrap">
                                    <div className="text-wrap">
                                      <div className="plan-price-action-wrap">
                                        <p className="caption black-color opacity-5">As low as</p>
                                        <IconButton
                                          size="small"
                                          onClick={() => { handlePriceBreakup(item) }}
                                        >
                                          <img src={InfoCircle} alt="InfoCircle" className="info-icon" />
                                        </IconButton>
                                      </div>
                                      <h5 className="black-color font-family-bold">₹{amountWithCommas(roundedPrice)}</h5>
                                      <p className="caption black-color">/month</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )
                          }
                        </React.Fragment>
                      )
                    })
                  }
                </div>
                <div className="accordion-wrap mt-4">
                  <PlanDetailAccordion
                    excludedList={excludedList}
                  />
                </div>
              </React.Fragment>
            )
          }
        </div>
        {
          !isLoading && netPremiumAmount != null && (
            <div className="plan-fixed-footer-wrap">
              <div className="plan-details-continue-wrap plan-details-noPrice-wrap">
                {/* <div className="text-wrap">
                  <h5 className="primary-color font-family-medium">₹{netPremiumAmount}/- <span className="caption black-color font-family-thin">/year</span></h5>
                  <p className="caption black-color font-family-thin">when paid annually</p>
                </div> */}
                <div className="btn-wrap">
                  <Btn
                    label="Continue"
                    className="btn btn-primary font-family-medium"
                    endIcon={<img alt="Continue" src={Next} />}
                    onClick={() => { submitHandle() }}
                  />
                </div>
              </div>
            </div>
          )
        }
        <PlanBreakupDialog
          planName={planSelection}
          extraBenefitsPrice={extraBenefitsPrice}
          priceBreakGrossAmount={priceBreakGrossAmount}
          priceBreakNetAmount={priceBreakNetAmount}
          priceBreakTax={priceBreakTax}
          priceGrossBenefits={priceGrossBenefits}
          onClose={(val) => {onClosePriceBreakupModal(val)}}
          open={showPriceBreakUpModal}
        />
      </div>
    </React.Fragment>
  )
}

export default PlanDetailsV2;
