import { CircularProgress } from "@mui/material";
import React from "react";

// style
import './index.scss';

const Loader = (props) => {
  return (
    <React.Fragment>
      {
        props.open && (
          <div className={`loader-wrap ${props.customStyle}`}>
            <CircularProgress color="primary"/>
            <div className="text-wrap mt-3">
              <p className="caption font-family-regular black-color">{props.label}</p>
            </div>
          </div>
        )
      }
    </React.Fragment>
  );
};

Loader.defaultProps = {
  label: ""
}

export default Loader;
