
import React, { useContext, useState, useEffect } from "react";
import { Alert, Autocomplete, Chip, IconButton, Snackbar, TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { Formik } from "formik";

import Card from "../../Card";
import { GetQuoteContext } from "../../../../modules/common-context";

import { Btn } from "../../../../components/index"
import { Cancel } from "../../../../assets/images/index";
import useFetchWorkLocation from "./hooks/useFetchWorkLocation";
import useCompanies from "../../hooks/useCompanies";
import dayjs from "dayjs";
import { API_END_POINT } from "../../constants/api-const";
import ApiServices from "../../../../services/api";
import APP_DATA_CONFIG from "../../../../helpers/app";

const EmployeeDetailsForm = (props) => {

  const {setDataPayload, dataPayload} = useContext(GetQuoteContext);
  const [userDetails, setUserDetails] = useState(props.data);
  const [workSpaceLocation, setWorkSpaceLocation] = useState();
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const maxDate = dayjs(new Date());

  const {
    locations,
    isFetchingLocations ,
    onLocationInputChange,
    onLocationOptionSelect,
  } = useFetchWorkLocation();
  const {
    companies,
    isFetchingCompanies,
    onCompanyInputChange,
    onCompanySelect,
  } = useCompanies(dataPayload);

  // set formik field
  let setFieldValueFunction = null;

  const handleSelectFieldObject = (setFieldValue) => {
    setFieldValueFunction = setFieldValue
  }

  // handleSnackBar
  const handleSnackBar = () => {
    setSnackBarOpen(false);
  }

  const companyChangeValue = (data) => {
    setFieldValueFunction("company_details", data);
    window.webengage.user.setAttribute("we_company", data?.label);
  }
  const locationChangeValue = (data) => {
    setFieldValueFunction("workspace_location", data);
    setWorkSpaceLocation(data)
  }

  const patchUserData = async (payload) => {
    try {
      const res = await ApiServices.patch(API_END_POINT.USER_SELF, payload);
      if(res.status === 200){
        props.onClose();
        props.onChange(new Date());
        setIsFormSubmit(false);
      }

    } catch (error) {
      const _error = error.response;
      setIsFormSubmit(false);
      setSnackBarOpen(true);
      setSnackBarMessage(_error?.data?.message);
    }
  }
  
  // form submit
  const submitHandle = (data) => {
    userDetails.company_id = data.company_details.id;
    setIsFormSubmit(true);

    const leadId = dataPayload?.lead_id;

    const _employeeDetails = {
      company_details: data.company_details,
      company_name: data.company_details.label,
      date_of_joining: dayjs(data.date_of_joining).format("YYYY-MM"),
      workspace_location: data.workspace_location,
      company_pincode: data.workspace_location.pincode,
      company_pincode_id : data.workspace_location.id,
    }

    let patchPayload = {
      company_name: data.company_details.label,
      date_of_joining: dayjs(data.date_of_joining).format("YYYY-MM"),
      company_pincode: data.workspace_location.pincode,
      company_pincode_id : data.workspace_location.id,
      lead_id: leadId,
    }

    window.dataLayer.push({'event':'get-quote_ProposalPage_EmploymentDetailsSave'});
    window.webengage.track("get-quote_ProposalPage_EmploymentDetailsSave", {
      company_name: data.company_details.label,
      date_of_joining: dayjs(data.date_of_joining).format("YYYY-MM"),
      company_pincode: data.workspace_location.pincode,
      company_pincode_id : data.workspace_location.id,
      lead_id: leadId,
    });

    window.webengage.user.setAttribute("get-quote_ProposalPage_EmploymentDetailsSave", {
      company_name: data.company_details.label,
      date_of_joining: dayjs(data.date_of_joining).format("YYYY-MM"),
      company_pincode: data.workspace_location.pincode,
      company_pincode_id : data.workspace_location.id,
      lead_id: leadId,
    });
    APP_DATA_CONFIG.GENERATE_LEAD.request("get-quote_ProposalPage_EmploymentDetailsSave", leadId)
    patchUserData(patchPayload);
    setDataPayload({ ...dataPayload, ...userDetails, ..._employeeDetails })
  }

  // on pincode location
  const onPincodeChange = async (val) => {

    if(val != null && val.toString().length > 5 ) {
      try {
        const res = await ApiServices.get(`${API_END_POINT.SEARCH_PINCODE}?pincode=${val}`);

        if(res.data.pincodes.length > 0) {

          const pincodeList = res.data.pincodes;

          var result = pincodeList.find(obj => {
            return obj.id.toString() === dataPayload?.company_pincode_id.toString()
          });
          
          if(result === undefined) {
            setSnackBarOpen(true);
            setSnackBarMessage("Workspace Pincode Not Matched");
          } else {

            const _workspace = {
              id: result.id,
              district: result.district,
              city: result.city,
              label: result.locality,
              pincode: result.pincode,
              stateId: result.stateId,
              state: result.state,
              stateCode: result.stateCode
            }
  
            setFieldValueFunction("workspace_location",_workspace);
          }

        } else {
          setSnackBarOpen(true);
          setSnackBarMessage("No Pincode found");
        }

        
      } catch (error) {
        const _error = error.response;
        setSnackBarOpen(true);
        setSnackBarMessage(_error?.data?.message);
      }
    }
  }

  useEffect(() => {
    
    const storeUserDetails = dataPayload;

    // check if userDetails is present in sessionStorage
    if (storeUserDetails.company_id != null && storeUserDetails.company_name != null) {
      const sessionUserDetails = storeUserDetails;
      setFieldValueFunction("company_details", sessionUserDetails.company_details)
      setFieldValueFunction("workspace_location", storeUserDetails.workspace_location != null ? storeUserDetails.workspace_location : "");
      setFieldValueFunction("date_of_joining", storeUserDetails.date_of_joining != null ? dayjs(storeUserDetails.date_of_joining) : "") 

    } else if (storeUserDetails.company_id == null && storeUserDetails.company_name == null && storeUserDetails.date_of_joining == null && storeUserDetails.workspace_location == null) {
      setFieldValueFunction("company_details", '');
      setFieldValueFunction("workspace_location", '');
      setFieldValueFunction("date_of_joining", '');
    }
  }, [])

  useEffect(()=> {
    // check for workspace loaction on behalf of company pincode and filter out with compnay_pincode_id
    if(dataPayload?.company_pincode_id !== undefined) {
      onPincodeChange(dataPayload?.company_pincode);
    }

  }, []);

  return (
    <React.Fragment>
      <Snackbar open={snackBarOpen && !!snackBarMessage} autoHideDuration={6000} onClose={handleSnackBar}>
        <Alert onClose={handleSnackBar} severity="error">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Card classes={"card card-shadow"}>
        <div className="card-header-wrap d-flex">
          <div className="text-wrap">
            <h3 className="font-family-bold black-color">Employment Details</h3>
          </div>
          <div className="btn-wrap">
            <IconButton
              size="small"
              onClick={props.onClose}
            >
              <img src={Cancel} alt="Cancel" />
            </IconButton>
          </div>
        </div>
        <Formik
        initialValues={{
          company_details: "",
          workspace_location: "",
          date_of_joining: ""
        }}
        validate={(values) => {
          const errors = {};
          if(!values.company_details) {
            errors.company_details = "Required";
          } else if(!(typeof values.company_details === 'object')){
            errors.company_details = "Please select company from list";
          }
          if(!values.workspace_location) {
            errors.workspace_location = "Required";
          } else if(!(typeof values.workspace_location === 'object')){
            errors.workspace_location = "Please select location from list";
          }
          if(!values.date_of_joining) {
            errors.date_of_joining = "Required";
          } 
          return errors;
        }}
        onSubmit={(values) => {
          submitHandle(values);
        }}
      >
        {({handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched}) => (
          <React.Fragment>
            {
              handleSelectFieldObject(setFieldValue)
            }
            <div className="card-content-wrap">
              <div className="form-wrap">
                <div className="form-row">
                  <div className="card-highlight-wrap card-highlight-right">
                    <div className="mr-5 text-wrap">
                      <p className="subtitle font-family-regular black-color">Employment Type</p>
                    </div>
                    <Chip 
                      label="Full-Time"
                      className="chip chip-black letter-space-2 font-family-regular text-uppercase"
                      size="medium"
                    ></Chip>
                  </div>
                </div>
                <div className="form-row">
                  <Autocomplete
                    loading={isFetchingCompanies}
                    loadingText="Fetching companies ..."
                    disablePortal
                    id="company_details"
                    options={companies}
                    onChange={(_, val)=> {
                      onCompanySelect(); 
                      companyChangeValue(val)
                    }}
                    value={values.company_details}
                    clearIcon={false}
                    freeSolo
                    filterOptions={(options) => options}
                    renderInput={(params) => 
                      <TextField {...params} 
                        label="Company's Registered Name"
                        required
                        id="company_details"
                        value={values.company_details?.label}
                        variant="outlined"
                        onChange={(event) => {
                          handleChange(event);
                          onCompanyInputChange(event?.target?.value); 
                        }}
                        onBlur={(event)=> {
                          handleBlur(event);
                        }}
                        error={
                          Boolean(errors.company_details) && touched.company_details && Boolean(errors.company_details)
                        }
                        helperText={
                          errors.company_details && touched.company_details && errors.company_details
                        }
                    />}
                  />
                </div>
                <div className="form-row">
                  <Autocomplete
                    loading={isFetchingLocations}
                    loadingText="Fetching Workplace Locations..."
                    disablePortal
                    id="workspace_location"
                    options={locations}
                    filterOptions={(options) => options}
                    onChange={(_, val)=> {onLocationOptionSelect(); locationChangeValue(val)}}
                    value={values.workspace_location}
                    clearIcon={false}
                    freeSolo
                    renderInput={(params) => 
                      <TextField {...params} 
                        label="Workplace Location Pincode"
                        required
                        id="workspace_location"
                        value={values.workspace_location?.label}
                        variant="outlined"
                        onChange={(event) => {
                          handleChange(event);
                          onLocationInputChange(event.target.value);
                        }}
                        onBlur={(event)=> {
                          handleBlur(event);
                        }}
                        error={
                          Boolean(errors.workspace_location) && touched.workspace_location && Boolean(errors.workspace_location)
                        }
                        helperText={
                          errors.workspace_location && touched.workspace_location && errors.workspace_location
                        }
                      />
                    }
                  />
                </div>
                <div className="form-row">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker 
                      label="Date of Joining *"
                      format="YYYY-MM"
                      views={['month', 'year']}
                      value={values.date_of_joining}
                      onChange={(event, val)=>{
                        setFieldValueFunction("date_of_joining", dayjs(event).format('YYYY-MM-DD'))
                      }}
                      onBlur={(event)=> {
                        handleBlur(event);
                      }}
                      maxDate={maxDate}
                    />
                  </LocalizationProvider>
                </div>
              </div>
            </div>
            <div className="card-footer-wrap card-btn-end-wrap">
              <div className="btn-wrap">
                <Btn
                  label="Cancel"
                  variant="text"
                  className="btn btn-black font-family-medium"
                  size="large"
                  onClick={props.onClose}
                />
                <Btn
                  label="Save"
                  variant="contained"
                  className="btn btn-primary font-family-medium"
                  size="large"
                  onClick={() => {handleSubmit()}}
                  type="submit"
                  disabled={isFormSubmit}
                  loading={isFormSubmit}
                />
              </div>
            </div>
          </React.Fragment>
        )}
      </Formik>
      </Card>
    </React.Fragment>
  )
}

export default EmployeeDetailsForm;
