import React, { useEffect, useState } from "react";
import Heading from "../../../../components/atoms/heading";
import HighlightSectionName from "../../../../shared/components/highLightSectionName";

import styles from "./mentorsCategory.module.scss";
import { MENTORS_DATA } from "../../../../constants/mentors";
import MentorCard from "../../../../components/mentor-card";
import "./mentorsCategory.scss"


const MentorsCategory = () => {

  const [mentorTypeFilter, setMentorTypeFilter] = useState();

  const [mentorData, setMentorData] = useState(MENTORS_DATA);
  const [mentorFilterData, setMentorFilterData] = useState([]);
  const [mentorFilterPageData, setMentorFilterPageData] = useState([]);
  const [paginate1, setPaginate1] = useState(0);
  const [paginate2, setPaginate2] = useState(6);
  

  const MENTORS_CATEGORY = [
    { type: "all", label: "All" },
    { type: "tech", label: "Tech/Engineering" },
    { type: "business", label: "Business" },
    { type: "product", label: "Product" },
    { type: "growth", label: "Growth" },
    { type: "hr", label: "HR" },
    { type: "finance", label: "Finance" },
  ]

  const handelMentorFilter = (type, page1, page2) => {
    setMentorTypeFilter(type);

    if(type == 'all') {
      let _filterData = mentorData.sort((a, b) => a.order > b.order ? 1 : -1)
      handleMentorPaginateData(_filterData, page1, page2);
      setMentorFilterData(_filterData);
    } else {
      let _filterByType = mentorData.filter((item)=>{return item.type == type});
      handleMentorPaginateData(_filterByType, page1, page2);
      setMentorFilterData(_filterByType);
    }
  }


  // slice data on type of pagination
  const handleMentorPaginateData = (data, page1, page2) => {
    setPaginate1(page1);
    setPaginate2(page2);
    setMentorFilterPageData(data.slice(page1, page2));
  }

  // handle conditon on more button
  const mentorPagination = (page1, page2) => {
    setPaginate2(page2);
    handelMentorFilter(mentorTypeFilter, page1, page2);
  }


  useEffect(() => {
    setMentorData(MENTORS_DATA);
    handelMentorFilter("all", paginate1, paginate2);
  }, []);


  return (
    <div className="py-3">
      <div className="body-container">
        <div className={styles.container}>
          <HighlightSectionName text="MEET OUR MENTORS" />

          <div className={styles.headingContainer}>
            <Heading text="Supercharge your interview preparation with them " className={`d-inline ${styles.headingOne}`} />
          </div>

          <div className="mentors-gallery-tabs">
            {
              MENTORS_CATEGORY.map((item, index) => {
                return (
                  <button 
                    className={`btn no-hover ${item.type == mentorTypeFilter ? 'btn-green' : 'btn-black-outline'}`} 
                    key={index}
                    onClick={()=>{handelMentorFilter(item.type, 0, 6)}}
                  >{item.label}</button>
                )
              })
            }
          </div>
          <div className="mentors-gallery-wrap">
            {
              mentorFilterPageData.sort((a, b) => a.order > b.order ? 1 : -1).map((item, index) => {
                return (
                  <MentorCard
                    data={item}
                  >
                  </MentorCard>
                )
              })
            }
          </div>
          {
             mentorFilterData.length != mentorFilterPageData.length && (
              <div className="btn-wrap mt-4">
                <button className="btn btn-primary-outline black-color btn-large" onClick={()=>{mentorPagination(0, paginate2 + 6)}}>+ More</button>
              </div>
            )
          }
          
          
        </div>
      </div>
    </div>
  );
};

export default MentorsCategory;
