import React, { useContext } from "react";

import HighLightSectionName from "../../shared/components/highLightSectionName";

import Heading from "../../components/atoms/heading";
import BlogCards from "./molecules/BlogCards";

import StickyCTA from "../../shared/components/stickyCTA/StickyCTA";
import BlogsContext from "../../shared/contexts/blogsContext/BlogsContext";

import styles from "./blog.module.scss";

const Blog = () => {
  // const [blogCardsData, setBlogCardsData] = useState(BLOG_CARDS);

  // const loadMore = () => {
  //   setBlogCardsData([...blogCardsData, ...BLOG_CARDS]);
  // };

  const { blogs } = useContext(BlogsContext);

  return (
    <center>
      <div className={styles.container}>
        <HighLightSectionName text="BLOGS" />
        <Heading
          text="Our thoughts, ideas and stories"
          className={styles.heading}
        />
        <BlogCards blogCards={blogs} />
        {/* <button className={styles.seeMoreCta} onClick={loadMore}>
          See more
        </button> */}
      </div>
      <StickyCTA />
    </center>
  );
};

export default Blog;
