const faqData = [
    {
    title: "What is UPSOLV?",
    content: {
      descriptions: [
        {
          text: "Upsolv is a provider of career planning subscription plans (UPSOLV Plus-Iconic) with an additional option for its members to enrol for a group insurance cover with an IRDAI-licensed General Insurance Company. Upsolv subscription plans help working professionals grow in their careers without any hiccups from unexpected job loss. Our plans include a multitude of services starting from anytime ITR filing, interview preparation, access to premium plans of Talent Acquisition portals, access to job openings through our recruiter network, mental health sessions, and an unemployment allowance.",
        },
      ],
    },
  },
  {
    title: "Is UPSOLV an insurance policy?",
    content: {
      descriptions: [
        {
          text: "No, Upsolv is not an insurance policy, it is instead a career management services provider that works with its subscribers to help them with their employment and career planning. Our subscribers can optionally choose to enroll in a group insurance cover with an IRDAI-licensed general insurance company to get access to benefits of unemployment allowance up to limits prescribed at respective salary levels by the insurance provider. These insurance benefits are available only to Upsolv members who opt for the Upsolv Iconic plans.",
        },
      ],
    },
  },
  {
    title: "Who is eligible to buy?",
    content: {
      descriptions: [
        {
          text: "You are eligible to buy the subscription plan if you are:",
          bullets: [
            {
              text: "a. Resident Indian Citizen;",
            },
            {
              text: "b. competent to contract and purchase the Subscription under applicable law, including but not limited to the Indian Contract Act, 1872;",
            },
            {
              text: "c. employed within the territory and jurisdiction of India, by an employer registered in India and having its primary place of business in India;",
            },
            {
              text: "d. employed in a permanent and full-time capacity, and not on the basis of advisory or consultancy services, part-time employment, contract or gig-work, temporary or seasonal, articleships or internships, assessment or probation, self-employed, retainership, or otherwise in any manner other than as a permanent and full-time employee;",
            },
            {
              text: "e. should have at least 6 (six) months of employment remaining before official retirement/ superannuation date.",
            },
          ],
        },
      ],
    },
  },
  {
    title: "Who cannot purchase the plan?",
    content: {
      descriptions: [
        {
          text: "You cannot purchase the Subscription if you are:",
          bullets: [
            {
              text: "a business owner, self-employed professional, or able to influence your own termination (including without limitation through a relative or any other manner of control);",
            },
            {
              text: "an affiliate, agent, officer, director, partner, member, representative and/or employee of the Provider, or an employee or relative of an affiliate, agent, officer, director, partner, member, or representative of the Provider;",
            },
            {
              text: "Key managerial personnel of the employer as defined in the Companies Act or any similar enactment; and/or",
            },
            {
              text: "serving your probation period, assessment period, notice period, performance improvement period, etc., or aware, anticipate or under the apprehension that you may be terminated or be required to serve probation period, assessment period, notice period, performance improvement period, etc.",
            },
          ],
        },
      ],
    },
  },
  {
    title: "What if I purchase the plan even if I am not eligible?",
    content: {
      descriptions: [
        {
          bullets: [
            {
              text: "In case you are ineligible to buy the plan, you can seek a refund within a period of 7 days from the date of purchase of the plan.",
            },
            {
              text: "Please note, any person ineligible to purchase the plan remains ineligible to claim any benefit under the plan.",
            },
          ],
        },
      ],
    },
  },
];

export default faqData;
