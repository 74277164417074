import React from "react";

import Nav from "../../layout/Nav";
import Body from "../../layout/Body";
import Footer from "../../layout/Footer";

import Mentors from "../../modules/mentors";
import Benefits from "../../modules/mentors/components/benefits";

const MentorPage = (props) => {
  return (
    <>
      <Nav />
      <Body>
        <Mentors />
      </Body>
      <Footer />
    </>
  );
};

export default MentorPage;
