
import React from "react";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import PlanNotCovered from "./plan-not-covered";

const PlanDetailAccordion = (props) => {

  const [expanded, setExpanded] = React.useState('');

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div>
      <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
        >
          <div className="text-wrap">
            <p className="subtitle font-family-thin black-color">What isn't covered?</p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <PlanNotCovered
            dataList={props.excludedList}
          />
        </AccordionDetails>
      </Accordion>
    </div>
  )
}

export default PlanDetailAccordion;
