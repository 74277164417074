import { Allowance, Mental, MentalHealthSvg, ResumeBuilding, Session_1to1, TAPortal } from "../../../../../assets/images";

export const HERO_SLIDER = [
  {
    title: "Upto ₹6 Lacs Salary Protection",
    image: Allowance,
  },
  {
    title: "TA Portal Subscription",
    image: Session_1to1,
  },
  {
    title: "Anytime ITR filing",
    image: TAPortal,
  },
  {
    title: "Exclusive Placement Support",
    image: ResumeBuilding,
  },
  {
    title: "1:1 session with industry domain specialist",
    image: Mental,
  },
  {
    title: "Mental Wellness sessions with experts",
    image: Mental,
  },
  {
    title: "Upto ₹6 Lacs Salary Protection",
    image: Allowance,
  },
  {
    title: "TA Portal Subscription",
    image: Session_1to1,
  },
  {
    title: "Anytime ITR filing",
    image: TAPortal,
  },
  {
    title: "Exclusive Placement Support",
    image: ResumeBuilding,
  },
  {
    title: "1:1 session with industry domain specialist",
    image: Mental,
  },
  {
    title: "Mental Wellness sessions with experts",
    image: Mental,
  },
]