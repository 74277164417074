import React, { useContext, useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
import OtpInput from "react-otp-input";

import { GetQuoteContext, UserContext } from "../../../modules/common-context";

import { validateOtpLength } from "./helpers/otp.validator";
import ValidHelper from "./molecules/validHelper";
import InvalidHelper from "./molecules/invalidHelper";

import ApiServices from "../../../services/api";
import { API_END_POINT } from "../constants/api-const";
import { setCookie } from "../../../shared/utils/cookie";
import { handleApplicationContext } from "../../../shared/helpers/computeAndSetInitialData";
import ApplicationContext from "../../../shared/contexts/applicationContext/ApplicationContext";
import fetchReferrals from "./fetchReferrals";
import APP_DATA_CONFIG from "../../../helpers/app";


const OtpForm = (props) => {
  const { setCurrView, dataPayload, setDataPayload } = useContext(GetQuoteContext);
  const { setUserDetails } = useContext(UserContext);
  const applicationContext = useContext(ApplicationContext);

  const [otp, setOtp] = useState("");
  const [isOtpValid, setIsOtpValid] = useState(undefined);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");

  const handleSnackBar = () => {
    setSnackBarOpen(false);
  }
  
  const handleOtpChange = (otp) => {
    setOtp(otp);
    const hasValidOtpLength = validateOtpLength(otp);
    setIsOtpValid('');
    if (!hasValidOtpLength) return 
    verifyUserOtp(otp);
    
  };

  const verifyUserOtp = async(otp) => {
    const { token } = dataPayload;
    const leadId = dataPayload?.lead_id;
    try {

      const data = {
        otp: otp,
        token,
        lead_id: leadId
      }
      const res = await ApiServices.post(API_END_POINT.VERIFY_OTP, data);
      if (res) {
        const data = res.data

        window.webengage.user.login(data?.user?.id);
        window.webengage.user.setAttribute("we_otp", {
          "otp": otp
        });
        window.webengage.user.setAttribute("we_sms_opt_in", true);
        window.webengage.user.setAttribute("we_whatsapp_opt_in", true);
        window.webengage.user.setAttribute("we_email_opt_in", true);

        window.webengage.user.setAttribute("we_first_name", data?.user?.name);
        window.webengage.user.setAttribute("we_phone", data?.user?.phone_number);

  
        handleApplicationContext(data?.user, applicationContext);
        setUserDetails(data.user);
        
        setDataPayload({...dataPayload, ...data.user});
        
        setCookie('js_login', true);

        getUserDashboard(data?.user)
        setIsOtpValid('valid');
        setTimeout(()=> {
          setCurrView('EMPLOYMENT_DETAILS');
          window.dataLayer.push({'event':'get-quote_OTPVerification_Success'});
          window.webengage.track("get-quote_OTPVerification_Success", {
            "otp": otp
          });
          window.webengage.user.setAttribute("get-quote_OTPVerification_Success", {
            "otp": otp
          })
          
          const payload = {
            name: data?.user?.name,
            phone_number: data?.user?.phone_number,
          }
          APP_DATA_CONFIG.GENERATE_LEAD.request('get-quote_OTPVerification_Success', leadId, payload);
        }, 500);
        fetchReferrals(applicationContext);
      }
    } catch (error) {
      const _error = error.response
      setSnackBarOpen(true);
      setSnackBarMessage(_error?.data?.message);
      setIsOtpValid('invalid');
    }
    
  }

  const getUserDashboard = async(data) => {
    const userDashboardData = await ApiServices.get(API_END_POINT.USER_DASHBOARD);

    const userPlanStatus = {
      user_plan_status : userDashboardData?.data?.user_plan_status
    }

    const _dataPayload = {
      ...data,
      ...userPlanStatus
    }

    setDataPayload(_dataPayload);
  }

  useEffect(()=> {
    setIsOtpValid('');
    setOtp('');
  }, [props?.resendTrigger])

  return (
    <div className="form-wrap">
      <Snackbar open={snackBarOpen && !!snackBarMessage} autoHideDuration={6000} onClose={handleSnackBar}>
        <Alert onClose={handleSnackBar} severity="error">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <div className="form-row">
        <div className={`${isOtpValid} otp-input-wrap`}>
          <OtpInput
            value={otp}
            onChange={handleOtpChange}
            numInputs={4}
            renderInput={(props) => <input {...props} />}
            inputType="tel"
          />
        </div>
        {
          isOtpValid === 'valid' && (
            <ValidHelper /> 
          )
        }
        {
          isOtpValid === 'invalid' && (
            <InvalidHelper /> 
          )
        }
      </div>
    </div>
  );
};

export default OtpForm;
