import { useCallback } from 'react';

export const useClipboard = () => {
  const copy = useCallback(async (value) => {
    try {
      // only strings and numbers casted to strings can be copied to clipboard
      if (typeof value !== 'string' && typeof value !== 'number') {
        throw new Error(`Cannot copy typeof ${typeof value} to clipboard, must be a string`);
      }
      // empty strings are also considered invalid
      else if (value === '') {
        throw new Error(`Cannot copy empty string to clipboard.`);
      }

      const stringifiedValue = value.toString();

      await navigator.clipboard.writeText(stringifiedValue);

      return true;
    } catch (error) {
      return false;
    }
  }, []);

  return { copy };
};