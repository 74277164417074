
import React, { useContext, useEffect, useState } from "react";
import { Chip, Dialog } from "@mui/material";

import { GetQuoteContext } from "../../../../modules/common-context";

import { Btn } from "../../../../components/index"
import { Add, Edit } from "../../../../assets/images/index";
import KycDetailsForm from "./kyc-details-form";
import KycDetailsVerify from "./kyc-details-verify";

const KycDetails = () => {

  const { dataPayload } = useContext(GetQuoteContext);
  const [panResponse, setpanResponse] = useState({ panNo: "", name: "", gender: "" })
  const [showEditModal, setToggleEditModal] = useState(false);
  const [showVerifyModal, setToggleVerifyModal] = useState(false);
  const [userDetails, setuserDetails] = useState();

  const onClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setToggleEditModal(false);
  }

  const onSuccess = (val, status) => {

    if(status === 'no-verify') {
      setToggleEditModal(false);
    } else {
      setToggleEditModal(false);
      setToggleVerifyModal(true);
    }
  }


  const onCloseVerify = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setToggleVerifyModal(false);
  }

  const isVerify = (val) => {
    if(val) {
      setToggleVerifyModal(false);
    } else {
      setToggleEditModal(true);
      setToggleVerifyModal(false);
    }
  }
  useEffect(() => {
    setuserDetails(dataPayload);
  }, [showVerifyModal, showEditModal]);
  return (
    <React.Fragment>
      <div className="plan-info-row">
        <div className="plan-info-head">
          <Chip
            label="KYC DETAILS"
            className="chip chip-light-torques letter-space-2 font-family-regular text-uppercase"
            size="medium"
          ></Chip>
          {
            userDetails?.pan == null ? (
              <Btn
            label="Add"
            startIcon={<img alt="Add" src={Add} />}
            variant="text"
            className="btn btn-black font-family-medium"
            onClick={() => { setToggleEditModal(true) }}
          />
            ) : (
              <Btn
                label="Edit"
                  startIcon={<img alt="Edit" src={Edit} />}
                  variant="text"
                  className="btn btn-black font-family-medium"
                onClick={() => { setToggleEditModal(true) }}
              />
            )}
        </div>
        {
          userDetails?.pan != null && (
            <div className="plan-info-content mt-2">
              <div className="plan-info-content-row">
                <div className="text-wrap">
                  <p className="caption black-color opacity-5 font-family-medium">PAN Card</p>
                </div>
                <div className="text-wrap">
                  <p className="caption black-color font-family-medium">{userDetails?.pan}</p>
                </div>
              </div>
            </div>
          )
        }

      </div>
      <Dialog onClose={onClose} open={showEditModal} className="dialog-wrap">
        <KycDetailsForm
          onClose={onClose}
          onSuccess={onSuccess}
          getPanResponse={(val)=>{setpanResponse(val)}} 
        />
      </Dialog>
      <Dialog onClose={onCloseVerify} open={showVerifyModal} className="dialog-wrap">
        <KycDetailsVerify 
          onClose={onCloseVerify} 
          panResponse={panResponse} 
          isVerify={(val)=>{isVerify(val)}}
        />
      </Dialog>
    </React.Fragment>
  )
}

export default KycDetails;
