import axios from 'axios'

const ApiService = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  withCredentials: true,
  params: {
    t: new Date().getTime()
  }
});

const API_URL = process.env.REACT_APP_API_BASE_URL;

export const post = (url, payload) => {
  return ApiService.post(API_URL + url, { ...payload, credentials: "include" });
};

export const get = (url, config) => {
  return ApiService.get(API_URL + url, { ...config, credentials: "include" });
};

export const patch = (url, payload) => {
  return ApiService.patch(API_URL + url, {
    ...payload,
    credentials: "include",
  });
};

const ApiServices = {
  post,
  get,
  patch,
};

export default ApiServices;
