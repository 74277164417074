import React from "react";
import BlogsContext from "./BlogsContext";
import { BLOGS } from "./blogs.constants";

const value = {
  blogs: BLOGS,
};

const BlogContextWrapper = ({ children }) => {
  return (
    <BlogsContext.Provider value={value}>{children}</BlogsContext.Provider>
  );
};

export default BlogContextWrapper;
