
import React, { useContext, useEffect, useState } from "react";
import { Alert, IconButton, Snackbar, TextField } from "@mui/material";


import Card from "../../Card";
import { GetQuoteContext } from "../../../../modules/common-context";

import { Btn } from "../../../../components/index"
import { Cancel } from "../../../../assets/images/index";
import { Formik } from "formik";
import ApiServices from "../../../../services/api";
import { PINCODE_REGEX } from "../../../../helpers/regex";
import { API_END_POINT } from "../../constants/api-const";
import APP_DATA_CONFIG from "../../../../helpers/app";

const CommunicationDetailsForm = (props) => {

  const {setDataPayload, dataPayload} = useContext(GetQuoteContext);
  const [userDetails, setUserDetails] = useState({});
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  

  // set formik field
  let setFieldValueFunction = null;

  const handleSelectFieldObject = (setFieldValue) => {
    setFieldValueFunction = setFieldValue
  }

  // handleSnackBar
  const handleSnackBar = () => {
    setSnackBarOpen(false);
  }

  // on pincode location
  const onPincodeChange = async (val) => {
    if(val != null && val.length > 5) {
      try {
        const res = await ApiServices.get(`${API_END_POINT.SEARCH_PINCODE}?pincode=${val}`);

        if(res.data.pincodes.length > 0) {
          setFieldValueFunction("state", res.data.pincodes[0].state);

          const _storeUserDetails = sessionStorage.getItem("userDetails");
          const storeUserDetails = JSON.parse(_storeUserDetails);
          setUserDetails({...storeUserDetails, state_code: res.data.pincodes[0].stateCode});
        } else {
          setSnackBarOpen(true);
          setSnackBarMessage("No Pincode found");
        }
        
      } catch (error) {
        const _error = error.response;
        setSnackBarOpen(true);
        setSnackBarMessage(_error?.data?.message);
      }
    }
  }

  useEffect(() => {
    const storeUserDetails = dataPayload;
    setUserDetails(storeUserDetails);

    if(storeUserDetails.state_code === undefined) {
      onPincodeChange(storeUserDetails.pincode);
    }

    if (storeUserDetails.address != null && storeUserDetails.pincode != null && storeUserDetails.state != null) {
      setFieldValueFunction("address", storeUserDetails.address);
      setFieldValueFunction("pincode", storeUserDetails.pincode);
      setFieldValueFunction("state", storeUserDetails.state);
    } else {
      setFieldValueFunction("address", "");
      setFieldValueFunction("pincode", "");
      setFieldValueFunction("state", "");
    }

  }, [])

  const patchUserData = async (payload) => {
    try {
      const res = await ApiServices.patch(API_END_POINT.USER_SELF, payload);
      if(res.status === 200){
        setIsFormSubmit(false);
        props.onClose();
      }

    } catch (error) {
      const _error = error.response
      setSnackBarOpen(true);
      setSnackBarMessage(_error?.data?.message);
      setIsFormSubmit(false);
    }
  }

  const submitHandle = (data) => {
    setIsFormSubmit(true);
    const lead_id = dataPayload?.lead_id;

    const _communicationDetails = {
      pincode: data.pincode,
      address: data.address,
      state: data.state,
      lead_id,
    }
    patchUserData(_communicationDetails);
    setDataPayload({...dataPayload, ...userDetails, ..._communicationDetails})
    window.dataLayer.push({'event':'get-quote_ProposalPage_CommunicationDetailsSave'});
    window.webengage.track("get-quote_ProposalPage_CommunicationDetailsSave", {
      pincode: data.pincode,
      address: data.address,
      state: data.state,
      lead_id,
    });
    window.webengage.user.setAttribute("get-quote_ProposalPage_CommunicationDetailsSave", {
      pincode: data.pincode,
      address: data.address,
      state: data.state,
      lead_id,
    });
    APP_DATA_CONFIG.GENERATE_LEAD.request("get-quote_ProposalPage_CommunicationDetailsSave", lead_id)
  }

  return (
    <React.Fragment>
      <Snackbar open={snackBarOpen && !!snackBarMessage} autoHideDuration={6000} onClose={handleSnackBar}>
        <Alert onClose={handleSnackBar} severity="error">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Card classes={"card card-shadow"}>
        <div className="card-header-wrap d-flex">
          <div className="text-wrap">
            <h3 className="font-family-bold black-color">Communication Details</h3>
          </div>
          <div className="btn-wrap">
            <IconButton
              size="small"
              onClick={props.onClose}
            >
              <img src={Cancel} alt="Cancel" />
            </IconButton>
          </div>
        </div>
        <Formik
        initialValues={{
          address: "",
          pincode: "",
          state: ""
        }}
        validate={(values) => {
          const errors = {};
          if(!values.address) {
            errors.address = "Required";
          }
          if(!values.pincode) {
            errors.pincode = "Required";
          } else if(!PINCODE_REGEX.test(values.pincode)) {
            errors.pincode = "Invalid pincode";
          }
          if(!values.state) {
            errors.state = "Required";
          } 
          return errors;
        }}
        onSubmit={(values) => {
          submitHandle(values);
        }}
      >
        {({handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched}) => (
          <React.Fragment>
            {
              handleSelectFieldObject(setFieldValue)
            }
            <div className="card-content-wrap">
              <div className="form-wrap">
                <div className="form-row">
                  <TextField 
                    id="address" 
                    label="Address" 
                    variant="outlined" 
                    required={true}
                    size="large"
                    multiline
                    maxRows={4}
                    value={values.address}
                    onChange={(event) => {
                      handleChange(event);
                    }}
                    onBlur={(event)=> {
                      handleBlur(event);
                    }}
                    error={
                      Boolean(errors.address) && touched.address && Boolean(errors.address)
                    }
                    helperText={
                      errors.address && touched.address && errors.address
                    }
                  />
                </div>
                <div className="form-row">
                  <TextField
                    label="Pincode"
                    required
                    id="pincode"
                    size="large"
                    value={values.pincode}
                    variant="outlined"
                    onChange={(event, val) => {
                      handleChange(event);
                      onPincodeChange(event.target.value);
                    }}
                    inputProps={{
                      maxLength: 6,
                    }} 
                    onBlur={(event)=> {
                      handleBlur(event);
                    }}
                    error={
                      Boolean(errors.pincode) && touched.pincode && Boolean(errors.pincode)
                    }
                    helperText={
                      errors.pincode && touched.pincode && errors.pincode
                    }
                  />
                </div>
                <div className="form-row">
                  <TextField 
                    id="state" 
                    label="State" 
                    variant="outlined"
                    size="large"
                    value={values.state}
                    disabled
                  />
                </div>
              </div>
            </div>
            <div className="card-footer-wrap card-btn-end-wrap">
              <div className="btn-wrap">
                <Btn
                  label="Cancel"
                  variant="text"
                  className="btn btn-black font-family-medium"
                  size="large"
                  onClick={props.onClose}
                />
                <Btn
                  label="Save"
                  variant="contained"
                  className="btn btn-primary font-family-medium"
                  size="large"
                  onClick={()=>{handleSubmit()}}
                  disabled={isFormSubmit}
                  loading={isFormSubmit}
                />
              </div>
            </div>
          </React.Fragment>
        )}
        </Formik>
        
      </Card>
    </React.Fragment>
  )
}

export default CommunicationDetailsForm;
