import noop from './noop';

const nativeShare = ({
  title = '',
  text = '',
  url = '',
  successCb = noop,
  failureCb = noop,
}) => {


  if (navigator.share) {
    navigator.share({
      title,
      text,
      url,
    })
      .then(() => {
        successCb()
      })
      .catch(() => {
        failureCb()
      });
  }

  return undefined;
}

export default nativeShare;
