import React from "react";

import styles from "./boldLine.module.scss";

const BoldLine = ({ text }) => {
  const parts = text.split("**");

  return (
    <span className={styles.container}>
      {parts.map((part, index) =>
        index % 2 === 0 ? (
          part
        ) : (
          <span className={styles.bold} key={index}>
            {part}
          </span>
        )
      )}
    </span>
  );
};

BoldLine.propTypes = {};

export default BoldLine;
