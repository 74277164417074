import { useState } from "react";
import { EMPTY_OBJECT } from "../../../constants/app";

const usePolicyInitiateState = () => {
  const [policyWidgetData, setPolicyWidget] = useState({
    showPolicyWidget: undefined,
    policyStatus: undefined,
    order: undefined,
    showPolicyWidgetConfigurable: undefined,
  });

  const handlePolicyWidgetUpdate = (config = EMPTY_OBJECT) => {
    if (!config) return;
    setPolicyWidget((prevPolicyWidget) => {
      const updatedPolicyWidgetData = {
        ...prevPolicyWidget,
        ...config,
      };
      return updatedPolicyWidgetData;
    });
  };

  return {
    showPolicyWidget: policyWidgetData.showPolicyWidget,
    policyStatus: policyWidgetData.policyStatus,
    order: policyWidgetData.order,
    showPolicyWidgetConfigurable: policyWidgetData.showPolicyWidgetConfigurable,
    handlePolicyWidgetUpdate,
  };
};

export default usePolicyInitiateState;
