import React from "react";

import Link from "../../../../shared/link";

import RightTick from "../../../../assets/images/rightTick.svg";

import styles from "./instantSupport.module.scss";

const StarPoint = ({ text, highlightedText, to, onClick }) => {
  return (
    <div className={styles.starPoint}>
      <img src={RightTick} alt="Right tick" width="27" height="27" />
      <p className={styles.starRightText}>
        <span>{text} </span>
        <Link to={to} onClick={()=>{onClick()}}>
          <span className={styles.bold}>{highlightedText}</span>
        </Link>
      </p>
    </div>
  );
};

export default StarPoint;
