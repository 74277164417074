import AmazonLogo from "../../../assets/images/companiesLogo/amazonLogo.webp";
import MetaLogo from "../../../assets/images/companiesLogo/metaLogo.png";
import MicrosoftLogo from "../../../assets/images/companiesLogo/microsoftLogo.jpg";
import EricssonLogo from "../../../assets/images/companiesLogo/ericssonLogo.png";
import SalesForceLogo from "../../../assets/images/companiesLogo/salesforceLogo.png";
import DellLogo from "../../../assets/images/companiesLogo/dellLogo.png";
import PhilipsLogo from "../../../assets/images/companiesLogo/philipsLogo.png";
import FlinkLogo from "../../../assets/images/companiesLogo/flinkLogo.jpg";
import BookingLogo from "../../../assets/images/companiesLogo/bookingLogo.png";
import IbmLogo from "../../../assets/images/companiesLogo/ibmLogo.png";
import SapLogo from "../../../assets/images/companiesLogo/sapLogo.png";
import MicronLogo from "../../../assets/images/companiesLogo/micronLogo.jpg";

export const layoffHeadings = [
  {
    text: "Google laid off 12693 Employees",
  },
  {
    text: "Microsoft laid off 14420 Employees",
  },
  {
    text: "Biju's laid off 12000 Employees",
  },
  {
    text: "Amazon laid off 27000 Employees",
  },
];

export const layoffCards = [
  {
    card1: {
      img: AmazonLogo,
      employees: 27000,
    },
    card2: {
      img: MetaLogo,
      employees: 21000,
    },
  },
  {
    card1: {
      img: MicrosoftLogo,
      employees: 10000,
    },
    card2: {
      img: EricssonLogo,
      employees: 8500,
    },
  },
  {
    card1: {
      img: SalesForceLogo,
      employees: 8000,
    },
    card2: {
      img: FlinkLogo,
      employees: 8000,
    },
  },
  {
    card1: {
      img: DellLogo,
      employees: 6650,
    },
    card2: {
      img: PhilipsLogo,
      employees: 6000,
    },
  },
  {
    card1: {
      img: MicronLogo,
      employees: 4800,
    },
    card2: {
      img: BookingLogo,
      employees: 4375,
    },
  },
  {
    card1: {
      img: IbmLogo,
      employees: 3900,
    },
    card2: {
      img: SapLogo,
      employees: 3000,
    },
  },
];
