import React, { useContext, useEffect, useState } from "react";

import Card from "../Card";
import QuoteHeader from "../quote-header";
import { HideCharacter } from "../../../assets/images";
import StickyCTA from "../../../shared/components/stickyCTA/StickyCTA";
import useNavigateToUrl from "../../../shared/hooks/useNavigateToUrl";
import { GetQuoteContext } from "../../../modules/common-context";
import { isMobile } from "react-device-detect";

const PlanOnHold = () => {

  const {setCurrView, dataPayload} = useContext(GetQuoteContext);
  const [userDetails, setUserDetails] = useState({});
  const navigateToUrl = useNavigateToUrl();

  useEffect(()=> {
    window.scrollTo(0, 0);
    setCurrView("EMPLOYMENT_DETAILS")
    setUserDetails(dataPayload);
  }, [dataPayload, setCurrView])
  
  return (
    <div className={`${!isMobile?'layout-background':''} layout-bg-color`}>
      <React.Fragment>
        <QuoteHeader
          logoClick={()=>{
            navigateToUrl("/");
          }}
          logoStyleWrap="cursor-pointer"
        />
        <StickyCTA 
          onClick={()=>{
            window.dataLayer.push({'event': 'get-quote_ProposalPage_Whatsapp'});
            window.webengage.track("get-quote_ProposalPage_Whatsapp");
          }}
        />
        <div className="layout-conatiner employment-layout-container"> 
          <div className="medium-container thankyou-wrap">
          <Card classes={"card bg-color-transparent w-100"}>
            <div className="card-content-wrap">
              <div className="declaration-title-wrap">
                <img alt="Thankyou" src={HideCharacter}/>
              </div>
              <div className="thankyou-info-wrap p-4">
              <div className="text-wrap mb-3">
                <p className="text-left subtitle1 black-color font-family-medium text-capitalize">Dear {userDetails?.name ?? 'Customer'},</p>
              </div>
                <div className="text-wrap">
                    <p className="text-left subtitle1 font-family-regular black-color mb-3">We appreciate your interest in Upsolv Income Protection Plan. We are currently enhancing our list of partner companies and refining our pricing structure to enhance your overall experience with us.</p>
                  <p className="text-left subtitle1 font-family-regular black-color mb-3">To ensure we provide you with the best possible service, our team is working hard to make the plan available to you as soon as possible.</p>
                  <p className="text-left subtitle1 font-family-regular black-color mb-3">We will notify you via WhatsApp/email the moment you can resume your purchase.</p>
                  <p className="text-left subtitle1 font-family-regular black-color mb-3"> You can reach out to our customer support team for any queries.</p>
                  <p className="text-left subtitle1 font-family-regular black-color">Thank you for your understanding and patience. We look forward to serving you soon.</p>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </div>
      </React.Fragment>
      <div className="copyright-wrap pt-2 pb-2">
        <div className="text-wrap">
          <p className="caption black-color text-center">© 2023 UPSOLV. All Rights Reserved.</p>
        </div>
      </div>
    </div>
  );
};

export default PlanOnHold;
