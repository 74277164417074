export const API_END_POINT = {
  SEND_OTP: 'otp/send',
  VERIFY_OTP: 'otp/verify',
  SEARCH_COMPANY: 'insurance/companies',
  SEARCH_PINCODE: 'insurance/pincodes',
  VALIDATE_PAN: 'insurance/validate-pan',
  GET_QUOTE: 'insurance/quotes',
  GET_PROPOSAL: 'insurance/proposal',
  USER_SELF: 'users/self',
  INITIATE_PAYMENT: 'payment/initiate',
  VERIFY_PAYMENT: 'payment/verify',
  GENERATE_LEAD: 'lead',
  REFRESH_LEAD: 'lead/refreshLeadId',
  GET_PROTECTION_PLAN: 'insurance/protection-plan',
  LOGOUT: 'users/logout',
  VALIDATE_REFERRAL_CODE: 'payment/referral/validate',
  USER_REFERRAL: 'users/referrals',
  GET_OUR_PROTECTION_PLAN: 'plan/getPlan',
  GET_BENEFITS: 'benefits/getAllBenefits',
  GENERATE_ORDER: 'order/create',
  GET_ORDER_PROTECTION_PLAN: 'order/plan',
  USER_DASHBOARD: 'users/dashboard',
  USER_DASHBOARD_GET_OUR_PROTECTION_PLAN: 'dashboard/getProtectionPlan',
  USER_DASHBOARD_UPGRADE_PROTECTION_PLAN: 'dashboard/upgrade/protectionPlan'
}
