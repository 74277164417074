import React from "react";

import Nav from "../../layout/Nav";
import Body from "../../layout/Body";
import Footer from "../../layout/Footer";

import Blog from "../../modules/blog";

const BlogPage = () => {
  return (
    <>
      <Nav />
      <Body>
        <Blog />
      </Body>
      <Footer />
    </>
  );
};

export default BlogPage;
