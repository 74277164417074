import React from "react";
import PropTypes from "prop-types";
import cx from "classnames";

import styles from "./blogCard.module.scss";

const BlogCard = ({ cardData, cardAlignment, rowType }) => {
  const { cardImage, heading, description, tags, readTime } = cardData;
  return (
    <div
      className={cx(styles.container, {
        [styles.verticalAlign]: cardAlignment === "vertical",
        [styles.verticalAlign__thin]: rowType === 3,
      })}
    >
      <div
        className={cx(styles.cardImageContainer, {
          [styles.horizontalAlignCardImage]: cardAlignment === "horizontal",
        })}
      >
        <img src={cardImage} alt="blog card" />
      </div>
      <div
        className={cx(styles.contentWrapper, {
          [styles.contentWrapper__thin]: cardAlignment === "horizontal",
        })}
      >
        <span className={styles.tag}>{tags[0].content}</span>
        <span className={styles.readTime}>{readTime}</span>
        <p className={styles.heading}> {heading}</p>
        <p className={styles.description}>{description}</p>
      </div>
    </div>
  );
};

BlogCard.propTypes = {
  cardAlignment: PropTypes.objectOf(["vertical", "horizontal"]),
  rowType: PropTypes.oneOf([1, 2, 3]),
};

export default BlogCard;
