import isUndefined from "../../../utils/isUndefined";

const makeSearchParams = (searchParams, searchParamsToBeForwarded) => {

  let computedSearchParams = {};

  if (isUndefined(searchParamsToBeForwarded)) {
    for (const [key, value] of searchParams.entries()) {
      computedSearchParams[key] = value;
    }
    return computedSearchParams;
  }

  searchParamsToBeForwarded?.forEach(searchParamToBeForwarded => {
    const param = searchParams.get(searchParamToBeForwarded);
    if (!param) return;
    computedSearchParams[searchParamToBeForwarded] = param;
  })

  return computedSearchParams;
}

export default makeSearchParams;
