import { API_END_POINT } from '../components/getQuotes/constants/api-const';
import ApiServices from '../services/api';
import { handleApplicationContext, handleGetQuoteContext, handleUserContext } from '../shared/helpers/computeAndSetInitialData';
import getDataFromResponse from '../shared/utils/getDataFromResponse';

const fetchDataInitialAppData = async () => ApiServices.get(API_END_POINT.USER_SELF);

const onSuccess = (params, fetchInitialAppDataResponse) => {
  const { userContext, getQuoteContext, applicationContext } = params;
  const user = getDataFromResponse(fetchInitialAppDataResponse).user;
  handleUserContext(user, userContext);
  handleGetQuoteContext(user, getQuoteContext);
  handleApplicationContext(user, applicationContext);
};

const onFailure = (_params, _err) => {
  // DO NOTHING
  return undefined;
};

const onSettled = (params, settledPromise) => {
  const {
    status,
    value,
    reason
  } = settledPromise;
  if (status === 'fulfilled') return onSuccess(params, value);
  if (status === 'rejected') return onFailure(params, reason);
  return undefined;
}

const getDataOnSettled = (settledPromise) => {
  const {
    status,
    value
  } = settledPromise;

  if (status === 'fulfilled') return getDataFromResponse(value).user;
  if (status === 'rejected') return undefined;
  return undefined;
}

const fetchInitialAppDataConfig = {
  request: fetchDataInitialAppData,
  onSettled,
  getDataOnSettled
};

export default fetchInitialAppDataConfig;
