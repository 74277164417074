import React from "react";

import Link from "../../shared/link";

import socialHandleUrls from "../constants/socialHandlesUrl";

import linkedin from "../../assets/images/social/linkedin.svg";
import instagram from "../../assets/images/social/instagram.svg";
import youtube from "../../assets/images/social/youtube.svg";
import { LogoWhite } from "../../assets/images";


import styles from "./Footer.module.scss";

const Footer = (props) => {
  return (
    <center className={styles.containerWrapper}>
      <footer className={"body-container body-container-tablet-edge"}>
        <div className={styles.container}>
          <div className={styles.body}>
            <div className={styles.leftBodyContainer}>
              <div className={styles.logoContainer}>
                <img src={LogoWhite} alt="upsolv-logo" />
              </div>
              <p className={styles.info}>
                Upsolv (formerly Jobsurance) subscription plans ensuring peace of mind against layoffs throughout your employment journey
              </p>
            </div>

            <div className={styles.rightBodyContainer}>
              <div className={styles.socialHandles}>
                <Link 
                  to={socialHandleUrls.linkedin} 
                  target="_blank"
                  onClick={()=>{
                    window.webengage.track("Footer_Linkedink");
                    window.dataLayer.push({'event': 'HomePage_Footer_Linkedin'});
                  }}
                >
                  <img
                    className={styles.socialHandle}
                    src={linkedin}
                    alt="linkedin"
                  />
                </Link>
                <Link 
                  to={socialHandleUrls.youtube} 
                  target="_blank"
                  onClick={()=>{
                    window.webengage.track("Footer_Youtube");
                    window.dataLayer.push({'event': 'HomePage_Footer_Youtube'});
                  }}
                >
                  <img
                    className={styles.socialHandle}
                    src={youtube}
                    alt="youtube"
                  />
                </Link>
                <Link 
                  to={socialHandleUrls.instagram} 
                  target="_blank"
                  onClick={()=>{
                    window.webengage.track("Footer_Instagram");
                    window.dataLayer.push({'event': 'HomePage_Footer_Instagram'})
                  }}
                >
                  <img
                    className={styles.socialHandle}
                    src={instagram}
                    alt="instagram"
                  />
                </Link>
              </div>
              <div className={styles.otherPageUrls}>
                <ul className={styles.link}>
                  <Link 
                    to="/about"
                    onClick={()=>{
                      window.webengage.track("Footer_AboutUs");
                      window.dataLayer.push({'event': 'HomePage_Footer_AboutUs'})
                    }}
                  >About us</Link>
                </ul>
                <ul className={styles.link}>
                  <Link
                    to="https://www.linkedin.com/company/jobsurance"
                    target="_blank"
                    onClick={()=>{
                      window.webengage.track("Footer_Careers");
                      window.dataLayer.push({'event': 'HomePage_Footer_Careers'})
                    }}
                  >
                    Careers
                  </Link>
                </ul>
                <ul className={styles.link}>
                  <Link 
                    to="/contact"
                    onClick={()=>{
                      window.webengage.track("Footer_ContactUs");
                      window.dataLayer.push({'event': 'HomePage_Footer_ContactUs'});
                    }}
                  >Contact Us</Link>
                </ul>
                {/* <ul className={styles.link}>
                  <Link to="/blogs">Blogs</Link>
                </ul> */}
              </div>
            </div>
          </div>

          <div className={styles.footer}>
            <p className={styles.copyRight}>
              Vicinal Jobsurance Private Limited | CIN U74909KA2023PTC170824
            </p>

            <div className={styles.footerUrls}>
              <ul className={styles.link}>
                <Link to="/terms" 
                  onClick={()=>{
                    window.dataLayer.push({'event': 'HomePage_Footer_ToS'});
                    window.webengage.track("Footer_ToS");
                  }}>ToS</Link>
              </ul>
              <ul className={styles.link}>
                <Link to="/privacy" 
                  onClick={()=>{
                    window.dataLayer.push({'event': 'HomePage_Footer_PrivacyPolicy'});
                    window.webengage.track("Footer_PrivacyPolicy");
                  }}
                >Privacy Policy</Link>
              </ul>
              <ul className={styles.link}>
                <Link to="/disclaimers" 
                  onClick={()=>{
                    window.dataLayer.push({'event': 'HomePage_Footer_Disclaimers'});
                    window.webengage.track("Footer_Disclaimers");
                  }}>Disclaimer</Link>
              </ul>
              <ul className={styles.link}>
                <Link to="/faq" 
                  onClick={()=>{
                    window.dataLayer.push({'event': 'HomePage_Footer_FAQs'})
                    window.webengage.track("Footer_FAQs");
                  }}>FAQs</Link>
              </ul>
            </div>
          </div>
        </div>
        <div className={styles.designedBy}>
          <span>
            <Link to="https://futureintelligenttech.com/ ">
              © 2023 UPSOLV. All Rights Reserved. | Designed by FIT
            </Link>
          </span>
        </div>
      </footer>
    </center>
  );
};

export default Footer;
