import howToFindJobAfterLayoff from "../../../assets/images/blogs/howToFindJobAfterLayoff.jpg";
import layoffsInStartupsTheNewNormal from "../../../assets/images/blogs/layoffsInStartupsTheNewNormal.jpg";
import unEmploymentAllowance from "../../../assets/images/blogs/unEmploymentAllowance.jpg";
import whatToDoAfterGettingLaidOffFromYourEmployer from "../../../assets/images/blogs/whatToDoAfterGettingLaidOffFromYourEmployer.jpg";

export const BLOGS = [
  {
    id: 1,
    cardImage: layoffsInStartupsTheNewNormal,
    readTime: "5 MIN TO READ",
    tags: [
      {
        content: "BUSINESS",
      },
    ],
    heading: "Layoffs in Startups : the new normal",
    description:
      "In the startup world, layoffs are an unfortunate but sometimes necessary reality.",
    postedOn: "January 12, 2023",
    postedBy: "Upsolv Admin",
    blogTags: ["EMPLOYEES", "LAYOFFS", "STARTUPS", "UNEMPLOYMENT"],
    content: {
      descriptions: [
        {
          text: "In the startup world, layoffs are an unfortunate but sometimes necessary reality. As companies try to navigate the uncertain and constantly changing landscape of building a business, difficult decisions often have to be made. These decisions can include cutting staff, in order to keep the company financially stable and moving in the right direction.",
        },
        {
          text: "When a startup is going through a round of layoffs, it can be a difficult and emotional time for everyone involved. For the employees who are being let go, it can be a traumatic and destabilizing experience. They may feel like they have failed, or that their hard work and dedication meant nothing. It can also be difficult for the remaining employees, who may feel uncertain about their own job security and may also be feeling a sense of guilt or responsibility for the layoffs.",
        },
        {
          text: "As a startup founder or leader, it’s important to understand that layoffs are never easy, but they can be necessary in order to ensure the long-term health of the company. It’s important to communicate transparently with your employees and to give them as much notice as possible. Providing severance packages, outplacement services and other forms of support can help ease the transition for those who are being let go.",
        },
        {
          text: "It’s also important to remember that layoffs are not a reflection of the worth or value of the individuals who are being let go. They are simply a business decision that has been made in the best interests of the company. The employees who are being let go should be treated with respect and dignity, and given the opportunity to move on to new opportunities.",
        },
        {
          text: "It’s also important to be mindful of how layoffs will affect the culture and morale of the remaining employees. In the wake of layoffs, it’s crucial to keep open lines of communication, be transparent about the reasons for the layoffs, and work to rebuild trust and a sense of stability within the team.",
        },
        {
          text: "Finally, it’s essential to remember that layoffs are not the end of the road for a startup. While they may be difficult and painful in the short term, they can also be a necessary step on the path to long-term success. For those who are let go, it can also be an opportunity to move on to new and exciting opportunities.",
        },
        {
          text: "In a world of uncertainty and constant change, it’s important for startup founders and leaders to be prepared for the possibility of layoffs and to approach them with empathy and a sense of purpose. By communicating transparently, treating employees with respect, and working to rebuild trust within the team, startups can come out the other side stronger and more resilient.",
        },
      ],
    },
  },
  {
    id: 2,
    cardImage: howToFindJobAfterLayoff,
    readTime: "5 MIN TO READ",
    tags: [
      {
        content: "BUSINESS",
      },
    ],
    heading: "How to find job after getting laid off from your company",
    description:
      "Losing your job can be a difficult and stressful experience, but it’s important to remember that it...",
    postedOn: "January 12, 2023",
    postedBy: "Upsolv Admin",
    blogTags: ["EMPLOYMENT", "JOBS", "LAYOFFS", "STARTUP"],
    content: {
      descriptions: [
        {
          text: "Losing your job can be a difficult and stressful experience, but it’s important to remember that it doesn’t have to be the end of your career. There are many ways to find a new job after being laid off, and taking the right steps can help you get back on your feet quickly. Here are a few tips for finding a new job after being laid off:",
          bullets: [
            {
              text: "Network: One of the best ways to find a new job is through networking. Reach out to people in your industry and let them know that you’re looking for a new opportunity. Attend industry events, join professional organizations, and use social media to connect with potential employers and recruiters.",
            },
            {
              text: "Tailor your resume: Make sure your resume is tailored to the type of job you’re applying for. Highlight the skills and experience that are most relevant to the position you’re interested in. Make sure your resume is visually appealing and easy to read, and consider including a cover letter that explains your situation and how you’re a good fit for the job.",
            },
            {
              text: "Utilize Job search engines: Utilize job search engines like LinkedIn, Indeed, Glassdoor, etc to search for the jobs you want. You can also set up job alerts on those sites, that can notify you when job opening that match your criteria is available.",
            },
            {
              text: "Consider freelance or contract work: If you’re having trouble finding a full-time job, consider freelancing or contracting. This can be a great way to keep your skills sharp and earn money while you’re looking for a permanent position.",
            },
            {
              text: "Don’t give up: Finding a new job can take time, so don’t give up if you don’t find something right away. Keep applying, networking, and searching for new opportunities. Remember to take care of yourself during this process, both emotionally and physically.",
            },
            {
              text: "Consider career counseling: there are career counselors who can help you understand your strengths and weaknesses, help you develop a new career plan, and even help you search for a job. They can also help you identify and overcome any psychological barriers that may be preventing you from getting a new job.",
            },
          ],
        },
        {
          text: "Remember that job loss and transition is not easy, but with the right mindset, tools, and strategies, you will be able to find new opportunities, eventually. Be patient, be persistent and be proactive in your job search and you’ll be back on your feet in no time",
        },
      ],
    },
  },
  {
    id: 3,
    cardImage: unEmploymentAllowance,
    readTime: "5 MIN TO READ",
    tags: [
      {
        content: "BUSINESS",
      },
    ],
    heading: "Unemployment allowance in developed countries for employees",
    postedOn: "January 12, 2023",
    postedBy: "Upsolv Admin",
    blogTags: [],
    content: {
      descriptions: [
        {
          text: "Unemployment benefits, also known as unemployment allowance, are financial assistance provided by the government to individuals who have lost their jobs. The purpose of these benefits is to provide a safety net for workers who are facing financial hardship as a result of job loss, and to help them bridge the gap between jobs.",
        },
        {
          text: "In developed countries, unemployment benefits are typically provided through a combination of government programs and contributions from employers. These benefits can take the form of cash payments, job training, or other forms of assistance. The eligibility requirements, duration, and amount of benefits vary from country to country.",
        },
        {
          text: "One of the most widely known forms of unemployment benefits is cash payments, that generally provided by the government to eligible individuals for a certain period of time. The amount of these payments can vary depending on the country and the individual’s previous income. Some countries also provide additional benefits such as health insurance or housing assistance for those who are out of work.",
        },
        {
          text: "Another form of unemployment benefits is job training programs. These programs are designed to help individuals acquire new skills and qualifications that will make them more competitive in the job market. They can include classes, apprenticeships, or on-the-job training. These programs can be particularly helpful for workers in industries that are undergoing major changes or are in decline, as they can help them transition to new fields with better job prospects.",
        },
        {
          text: "In some countries, unemployment benefits are linked to the employee’s prior contributions, meaning that individuals who have paid into the unemployment insurance system through payroll taxes will be eligible for benefits if they lose their job.",
        },
        {
          text: "To be eligible for unemployment benefits, individuals generally have to meet certain requirements. These may include being actively seeking work, being available to work, and meeting certain income or employment history requirements. The eligibility criteria and the application process will vary depending on the country and the specific program.",
        },
        {
          text: "It’s important to note that Unemployment benefits are not permanent, and the benefit period varies from country to country. It’s also important to be aware that some countries have caps on the duration of unemployment benefits, meaning that individuals may only be eligible for benefits for a certain period of time.",
        },
        {
          text: "In conclusion, unemployment benefits are a crucial safety net for workers who have lost their jobs. They can provide financial assistance, job training, and other forms of support to help individuals get back on their feet and return to the workforce. The eligibility criteria and the specifics of these benefits will vary depending on the country, but they can be a valuable resource for those who are facing financial hardship as a result of job loss",
        },
      ],
    },
  },
  {
    id: 4,
    cardImage: whatToDoAfterGettingLaidOffFromYourEmployer,
    readTime: "5 MIN TO READ",
    tags: [
      {
        content: "BUSINESS",
      },
    ],
    heading: "What to do after getting laid off from your employer",
    description:
      "Losing your job can be a difficult and stressful experience, but it’s...",
    postedOn: "January 12, 2023",
    postedBy: "Upsolv Admin",
    blogTags: [],
    content: {
      descriptions: [
        {
          text: "Losing your job can be a difficult and stressful experience, but it’s important to remember that it doesn’t have to define you or your future. There are a number of steps you can take after being laid off to help you get back on your feet and move forward with your career.",
          bullets: [
            {
              text: "Take time to process your emotions: Losing your job can be a traumatic experience, and it’s important to give yourself time to process your emotions. Allow yourself to feel your feelings, whether it’s anger, sadness, frustration, etc.",
            },
            {
              text: "Review your finances: Being laid off can have a significant impact on your finances. Review your expenses, make a budget, and try to cut costs where you can. Don’t be afraid to seek assistance from government programs or non-profit organizations that can help you with housing, food, and other expenses.",
            },
            {
              text: "Update your resume and LinkedIn profile: Once you’ve had a chance to process your emotions, it’s time to start thinking about your next job. Make sure your resume is up to date, and tailor it to the types of jobs you’re interested in. Update your LinkedIn profile as well to showcase your professional experience and network with other professionals in your industry.",
            },
            {
              text: "Network: Networking is one of the most effective ways to find a new job. Reach out to friends, family, former colleagues, and professional contacts to let them know you’re looking for work. Attend industry events and join professional organizations to connect with potential employers and recruiters.",
            },
            {
              text: "Explore new opportunities: Being laid off can be an opportunity to explore new career paths or industries. Take the time to research different types of jobs and think about what you’re truly passionate about. Keep an open mind and be willing to try something new.",
            },
            {
              text: "Stay motivated: Job searching can be discouraging at times, but it’s important to stay motivated. Set goals, make a schedule, and try to keep a positive attitude. Don’t be afraid to seek out support from friends, family, or a career counselor if you need it.",
            },
            {
              text: "Don’t be afraid to ask for help: Losing your job can be a difficult time, and it’s okay to ask for help. Reach out to your network of family and friends, and seek advice and support. Government support and unemployment benefits can also help you bridge the gap while you’re looking for a new job.",
            },
          ],
        },
        {
          text: "In conclusion, Losing your job can be difficult, but it doesn’t have to be the end of the world. By taking the time to process your emotions, updating your resume, networking, exploring new opportunities, staying motivated and asking for help, you will be able to find a new job and move forward with your career.",
        },
      ],
    },
  },
];
