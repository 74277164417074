import React from "react";
import HeroSectionV1 from "./heroSectionV1/HeroSectionV1";
import HeroSectionV2 from "./heroSectionV2/HeroSectionV2";

const HeroSection = (props) => {
  return (
    <React.Fragment>
      {/* <HeroSectionV1></HeroSectionV1> */}
      <HeroSectionV2></HeroSectionV2>
    </React.Fragment>
  );
};

export default HeroSection;
