import React, { useContext, useEffect, useState } from "react";

import { Alert, Snackbar, TextField } from "@mui/material";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { Formik } from "formik";

import Btn from "../../buttons";
import { India } from "../../../assets/images";

import { GetQuoteContext } from "../../../modules/common-context";
import ApiServices from "../../../services/api";
import { ONLY_NAME_REGEX, PHONE_NUMBER_REGEX } from "../../../helpers/regex";
import getQuoteLoginFlowReaders from "../readers/login";
import getDataFromResponse from "../../../shared/utils/getDataFromResponse";
import { API_END_POINT } from "../constants/api-const";
import StickyCTA from "../../../shared/components/stickyCTA/StickyCTA";
import APP_DATA_CONFIG from "../../../helpers/app";



const LoginForm = (props) => {
  
  const {setCurrView, setDataPayload, dataPayload} = useContext(GetQuoteContext);
  const [snackBarOpen, setSnackBarOpen] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState("");
  const [isFormSubmit, setIsFormSubmit] = useState(false);

  // set formik field
  let setFieldValueFunction = null;

  const handleSelectFieldObject = (setFieldValue) => {
    setFieldValueFunction = setFieldValue
  }

  // handleSnackBar
  const handleSnackBar = () => {
    setSnackBarOpen(false);
  }

  const submitHandle = async(data) => {
    setIsFormSubmit(true);
    try {
      const lead_id = dataPayload?.lead_id
      const payload = {
        phone_number: data?.phone_number,
        name: data?.name,
        lead_id
      }

      const res = await ApiServices.post(API_END_POINT.SEND_OTP, payload);
      const response = getDataFromResponse(res);
      const token = getQuoteLoginFlowReaders.token(response);
      if (res) {
        setDataPayload({
          ...data,
          token,
          name: data.name,
          phone_number: data.phone_number
          
        });
        setCurrView('OTP');
        setIsFormSubmit(false);
        window.dataLayer.push({'event':'get-quote_NameMobile_Continue'});
        window.webengage.track("get-quote_NameMobile_Continue", {
          name: data.name,
          phone_number: data.phone_number
        });

        window.webengage.user.setAttribute("get-quote_NameMobile_Continue", {
          name: data.name,
          phone_number: data.phone_number
        });
        
        window.webengage.user.setAttribute("we_first_name", data.name);
        window.webengage.user.setAttribute("we_phone", data.phone_number);

        const leadPayload = {
          name: data.name,
          phone_number: data.phone_number
        }
        APP_DATA_CONFIG.GENERATE_LEAD.request('get-quote_NameMobile_Continue', lead_id, leadPayload);
      }
    } catch (error) {
      const _error = error.response
      setSnackBarOpen(true);
      setSnackBarMessage(_error?.data?.message);
      setIsFormSubmit(false);
    }
  }

  useEffect(()=> {
    
    if(dataPayload.phone_number != null && dataPayload.name != null) {
      setFieldValueFunction("name", dataPayload.name)
      setFieldValueFunction("phone_number", dataPayload.phone_number)
    }

  }, [dataPayload, setFieldValueFunction])

  
  return (
    <React.Fragment>
      <Snackbar open={snackBarOpen && !!snackBarMessage} autoHideDuration={6000} onClose={handleSnackBar}>
        <Alert onClose={handleSnackBar} severity="error">
          {snackBarMessage}
        </Alert>
      </Snackbar>
      <Formik
        initialValues={{
          name: "",
          phone_number: ""
        }}
        validate={(values) => {
          const errors = {};
          if(!values.name) {
            errors.name = "Required";
          } if(!ONLY_NAME_REGEX.test(values.name)) {
            errors.name = "Only alphabets are allowed in name";
          }
          if(!values.phone_number) {
            errors.phone_number = "Required";
          } else if(!PHONE_NUMBER_REGEX.test(values.phone_number)) {
            errors.phone_number = "Enter valid phone number"
          }
          return errors;
        }}
        onSubmit={(values) => {
          submitHandle(values);
        }}
      >
        {({handleChange, handleBlur, handleSubmit, setFieldValue, values, errors, touched}) => (
          <div className="form-wrap">
            {
              handleSelectFieldObject(setFieldValue)
            }
            <div className="form-row">
              <TextField 
                id="name" 
                label="Name" 
                variant="outlined" 
                required={true}
                size="large"
                value={values.name}
                onChange={(event) => {
                  handleChange(event);
                }}
                onBlur={(event)=> {
                  handleBlur(event);
                }}
                error={
                  Boolean(errors.name) && touched.name && Boolean(errors.name)
                }
                helperText={
                  errors.name && touched.name && errors.name
                }
              />
            </div>
            <div className="form-row">
              <TextField 
                id="phone_number" 
                label="Mobile Number" 
                variant="outlined" 
                required={true}
                size="large"
                value={values.phone_number}
                type="tel"
                inputProps={{
                  maxLength: 10,
                }}        
                InputProps={{
                  startAdornment: (
                    <div className="mobile-country-wrap">
                      <img src={India} alt="India" />
                      +91 <KeyboardArrowDownIcon/>
                    </div>
                  ),
                }}
                onChange={(event) => {
                  handleChange(event);
                }}
                onBlur={(event)=> {
                  handleBlur(event);
                }}
                error={
                  Boolean(errors.phone_number) && touched.phone_number && Boolean(errors.phone_number)
                }
                helperText={
                  errors.phone_number && touched.phone_number && errors.phone_number
                }
              />
            </div>
            <div className="form-row">
              <div className="btn-large">
                <Btn 
                  label="Continue"
                  className="btn-primary font-family-medium full-width"
                  size="large"
                  type="submit"
                  onClick={() => handleSubmit()}
                  disabled={isFormSubmit}
                  loading={isFormSubmit}
                ></Btn>
              </div>
            </div>
          </div>
        )}
        
      </Formik>

      <StickyCTA 
        onClick={()=>{
          window.dataLayer.push({'event': 'get-quote_NameMobile_Whatsapp'});
          window.webengage.track("get-quote_NameMobile_Whatsapp");
        }}
      />
    </React.Fragment>
  )
}

export default LoginForm;