
import React, { useContext, useEffect, useState } from "react";
import { Chip, Dialog } from "@mui/material";

import { GetQuoteContext } from "../../../../modules/common-context";

import { Btn } from "../../../../components/index"
import { Edit } from "../../../../assets/images/index";
import EmployeeDetailsForm from "./employment-details-form";
import dayjs from "dayjs";

const EmployeeDetails = (props) => {

  const {dataPayload} = useContext(GetQuoteContext);
  const [showEditModal, setToggleEditModal] = useState(false);
  const [employeDetails, setEmployeDetails] = useState({});


  const onClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    setToggleEditModal(false);
  }

  useEffect(()=> {

    let _employeDetails = {
      employee_type: dataPayload?.employee_type,
      company_name: dataPayload?.company_name,
      company_pincode: (dataPayload?.company_pincode == null) ? '-': dataPayload?.company_pincode,
      date_of_joining: dataPayload?.date_of_joining == null ? '-' : dayjs(dataPayload?.date_of_joining).format("YYYY-MM"),
      company_pincode_id: dataPayload?.company_pincode_id 
    }
    setEmployeDetails(_employeDetails);
  }, [showEditModal])

  return (
    <React.Fragment>
      <div className="plan-info-row">
        <div className="plan-info-head">
          <Chip 
            label="EMPLOYMENT DETAILS"
            className="chip chip-light-torques letter-space-2 font-family-regular text-uppercase"
            size="medium"
          ></Chip>
          <Btn
            label="Edit"
            startIcon={<img src={Edit} alt="Edit" />}
            variant="text"
            className="btn btn-black font-family-medium"
            onClick={() => { setToggleEditModal(true) }}
          />
        </div>
        {(employeDetails?.employee_type !== null || employeDetails?.company_name !== null || employeDetails?.company_pincode !== null || employeDetails?.date_of_joining !== null) && (
        <div className="plan-info-content mt-2">
          <div className="plan-info-content-row">
            <div className="text-wrap">
              <p className="caption black-color opacity-5 font-family-medium">Employment Type</p>
            </div>
            <div className="text-wrap">
              <p className="caption black-color font-family-medium">{employeDetails?.employee_type}</p>
            </div>
          </div>
          <div className="plan-info-content-row">
            <div className="text-wrap">
              <p className="caption black-color opacity-5 font-family-medium">Company Name</p>
            </div>
            <div className="text-wrap">
              <p className="caption black-color font-family-medium">{employeDetails?.company_name}</p>
            </div>
          </div>
          <div className="plan-info-content-row">
            <div className="text-wrap">
              <p className="caption black-color opacity-5 font-family-medium">Company Branch's Pincode</p>
            </div>
            <div className="text-wrap">
              <p className="caption black-color font-family-medium">{employeDetails?.company_pincode_id !== undefined ? employeDetails?.company_pincode : '-'}</p>
            </div>
          </div>
          <div className="plan-info-content-row">
            <div className="text-wrap">
              <p className="caption black-color opacity-5 font-family-medium">Date Of Joining</p>
            </div>
            <div className="text-wrap">
              <p className="caption black-color font-family-medium">{employeDetails?.date_of_joining}</p>
            </div>
          </div>
        </div>
        )
        }
      </div>
      <Dialog onClose={onClose} open={showEditModal} className="dialog-wrap">
        <EmployeeDetailsForm 
          onClose={onClose}
          data={employeDetails}
        />
      </Dialog>
    </React.Fragment>
  )
}

export default EmployeeDetails;
