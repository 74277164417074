
import ApiServices from "../../../../services/api";
import getDataFromResponse from "../../../../shared/utils/getDataFromResponse";
import { API_END_POINT } from "../../constants/api-const";


export const fetchCompanies = async (companyName, dataPayload) => {
  const leadId = dataPayload?.lead_id;
  try {
    const requestConfig = companyName ? { params: { company_name: companyName } }: {};
    const company_name = requestConfig.params.company_name;
    const response = await ApiServices.get(
      `${API_END_POINT.SEARCH_COMPANY}?company_name=${company_name}&lead_id=${leadId}`
    );
    const companiesResponse = getDataFromResponse(response);
    const companies = companiesResponse?.companies;
    return companies;
  } catch (err) {
    return undefined;
  }
};
